import React, { useState } from "react";
import { Tabs } from "antd";
import { AssignedUsers } from "./assigned-users";
import { UserData } from "./user-data";
import { useUser } from "../../hooks/user.hook";
import { baseUrl } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";

type UserTab = 'data' | 'assigned-users';

export const UserEdit = (props: { tab?: UserTab }) => {
    const { admin, routeId: userId } = useUser();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);

    return <Tabs defaultActiveKey="data" activeKey={props.tab}
        onChange={tab => {
            navigate(baseUrl(`/users/${userId}/${tab}`));
            setCurrentTab(tab as UserTab);
        }}
        items={[{
            label: "Dane użytkownika",
            key: "data",
            children: <UserData userId={userId}/>
        }, {
            label: "Przypisani użytkownicy",
            key: "assigned-users",
            disabled: !userId || !admin,
            children: <AssignedUsers userId={userId}/>
        }]}
    />
}
