import React, { useState } from "react";
import { Space, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { VisualisationData } from "./visualisation-data";
import { VisualisationComments } from "./visualisation-comments";

type VisualisationTab = 'data' | 'comments';

export const VisualisationEdit = (props: { tab?: VisualisationTab }) => {
    const { id } = useParams();
    const visualisationId = parseInt(id ?? "") || undefined;
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);

    return <Tabs defaultActiveKey="data" activeKey={props.tab} id="visualisation-tabs"
        onChange={tab => {
            navigate(baseUrl(`/visualisations/${visualisationId}/${tab}`));
            setCurrentTab(tab as VisualisationTab);
        }}
        tabBarExtraContent={{
            right: <Space>
            </Space>
        }}
        items={[{
            label: "Dane wizualizacji",
            key: "data",
            children: <VisualisationData visualisationId={visualisationId!!}/>
        }, {
            label: "Komentarze",
            key: "comments",
            disabled: !visualisationId,
            children: <VisualisationComments visualisationId={visualisationId!!}/>
        }]}
    />
}
