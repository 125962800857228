import React, { useEffect, useState } from "react";
import { Button, Form, Input, List, notification, Space, Spin } from "antd";
import { qid, useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { PopoverForm } from "../../components/popover-form";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MarkdownEditor } from "../../components/markdown-editor";
import { RemoveButton } from "../../components/remove-button";

export const InstallationTasks = () => {
    const queryClient = useQueryClient();

    const [taskId, setTaskId] = useState<number | null>(null);
    const [editTaskId, setEditTaskId] = useState<number | null>(null);
    const [tasks, setTasks] = useState<string | null | undefined>(null);
    const [taskName, setTaskName] = useState<string | null>(null);

    const { data: installationTasks, loading: installationTasksLoading } = useSqlQuery(
        `installation-tasks`,
        from(t.installationTask, t.it)
        .where(t.it.deletedAt.isNull())
        .orderBy(t.it.id)
        .select({
            id: t.it.id,
            name: t.it.name
        })
    );

    const { data: task, loading: taskLoading } = useSqlQuerySingle(
        qid`installation-task-${taskId}`,
        from(t.installationTask, t.it)
        .where(t.it.id.eq(taskId))
        .select({
            tasks: t.it.tasks
        }), {
            enabled: taskId
        }
    );

    useEffect(() => {
        if (installationTasks && installationTasks.length > 0 && !taskId) {
            setTaskId(installationTasks[0].id);
        }
    }, [installationTasks]);

    useEffect(() => {
        setTasks(task?.tasks);
    }, [task]);

    const { mutateAsync: removeTask } = useMutation((taskId: number) => {
        return backendDelete(`/installation-tasks/${taskId}`)
        .then(() => {
            void queryClient.invalidateQueries(['installation-tasks']);
            notification.success({
                message: 'Lista została usunięta'
            });
        })
    });

    const { mutateAsync: saveTask } = useMutation(() => {
        return backendPut(`/installation-tasks/${taskId}/tasks`, tasks)
        .then(() => {
            void queryClient.invalidateQueries(['installation-tasks']);
            notification.success({
                message: 'Lista została zapisana'
            });
        })
    });

    const { mutateAsync: saveTaskName } = useMutation((taskId: number) => {
        return backendPut(`/installation-tasks/${taskId}/name`, taskName)
        .then(() => {
            setEditTaskId(null);
            setTaskName(null);
            void queryClient.invalidateQueries(['installation-tasks']);
            notification.success({
                message: 'Nazwa została zapisana'
            });
        })
    });

    return <Space align="start">
        <Space direction="vertical">
            <List loading={installationTasksLoading} dataSource={installationTasks} style={{ width: 400, height: 400, overflow: 'auto' }}
                header={
                    <PopoverForm title="Dodaj listę zadań"
                        showPopover={true}
                        onSubmit={async values => {
                            const taskId = await backendPost(`/installation-tasks`, values);
                            await queryClient.invalidateQueries([`installation-tasks`]);
                            setTaskId(taskId);
                        }}>
                        <Form.Item name="name" rules={[{ required: true, message: "Podaj nazwę" }]}>
                            <Input autoFocus/>
                        </Form.Item>
                    </PopoverForm>
                }
                renderItem={item => <List.Item
                    style={{ padding: 5, paddingLeft: 15, cursor: 'pointer', background: item.id === taskId ? '#f4f4f4' : undefined }}
                    onClick={() => setTaskId(item.id)}
                    actions={[
                        item.id === editTaskId
                            ? <Button type="link" onClick={() => saveTaskName(item.id)}>Zapisz</Button>
                            : <Button type="link" onClick={() => setEditTaskId(item.id)} disabled={!!editTaskId}>Edytuj</Button>,
                        item.id === editTaskId
                            ? <Button type="link" onClick={() => setEditTaskId(null)}>Anuluj</Button>
                            : <RemoveButton onConfirm={() => removeTask(item.id)} link={true} disabled={!!editTaskId}/>
                    ]}
                >
                    {editTaskId === item.id ? <Input defaultValue={item.name} onChange={e => setTaskName(e.target.value)} autoFocus/> : item.name}
                </List.Item>}
                bordered={true}
            />
            <Button onClick={() => saveTask()}>Zapisz</Button>
        </Space>
        <div style={{ width: 650 }}>
            <Spin spinning={taskLoading}>
                <MarkdownEditor preview={true}
                    value={tasks}
                    onChange={value => setTasks(value)}
                    height={400}/>
            </Spin>
        </div>
    </Space>
}
