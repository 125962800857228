// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-card-bordered {
  border: 1px solid #f0f0f0;
}
.dashboard-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.57142857;
  list-style: none;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-card-head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 56px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
}
.dashboard-card-head-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.dashboard-card-head-title {
  display: inline-block;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dashboard-card-body {
  padding: 24px;
  border-radius: 0 0 8px 8px;
  flex-grow: 1;
  overflow: clip;
}
`, "",{"version":3,"sources":["webpack://./web/views/dashboard/dashboard-card.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAEA;EACE,sBAAA;EACA,SAAA;EACA,UAAA;EACA,0BAAA;EACA,eAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AAAF;AAGA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;EACA,gCAAA;EACA,0BAAA;AADF;AAIA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AAFF;AAKA;EACE,qBAAA;EACA,OAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAHF;AAMA;EACE,aAAA;EACA,0BAAA;EACA,YAAA;EACA,cAAA;AAJF","sourcesContent":[".dashboard-card-bordered {\n  border: 1px solid #f0f0f0;\n}\n\n.dashboard-card {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  color: rgba(0, 0, 0, 0.88);\n  font-size: 14px;\n  line-height: 1.5714285714285714;\n  list-style: none;\n  position: relative;\n  background: #ffffff;\n  border-radius: 8px;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.dashboard-card-head {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  min-height: 56px;\n  margin-bottom: -1px;\n  padding: 0 24px;\n  color: rgba(0, 0, 0, 0.88);\n  font-weight: 600;\n  font-size: 16px;\n  background: transparent;\n  border-bottom: 1px solid #f0f0f0;\n  border-radius: 8px 8px 0 0;\n}\n\n.dashboard-card-head-wrapper {\n  width: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.dashboard-card-head-title {\n  display: inline-block;\n  flex: 1;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.dashboard-card-body {\n  padding: 24px;\n  border-radius: 0 0 8px 8px;\n  flex-grow: 1;\n  overflow: clip;\n}\n\n@layout-header-background: #1890ff;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
