import React, { useEffect } from "react";
import { Button, Card, Col, Form, Input, notification, Row, Select, Space, Spin } from "antd";
import { useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { useForm } from "antd/lib/form/Form";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { useNavigate } from "react-router-dom";
import { allRoles, getUserRoleDescription, User, UserRole } from "../../models/user";
import { baseUrl } from "../../utils/navigation";
import { useUser } from "../../hooks/user.hook";
import { validateMessages } from "../../utils/validators";
import omit from "lodash/omit";
import { RemoveButton } from "../../components/remove-button";
import { isEmpty } from "lodash";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation } from "@tanstack/react-query";
import { FooterGap } from "../../components/gap";
import { labelCol, wrapperCol } from "../../models/ui";
import { NumericInput } from "../../components/numeric-input";

export const UserData = (props: { userId?: number }) => {
    const userInfo = useUser();
    const { data: user, loading: userLoading } = useSqlQuerySingle(
        `user-${props.userId}`,
        from(t.user, t.u)
        .where(t.u.id.eq(props.userId))
        .select({
            firstName: t.u.firstName,
            lastName: t.u.lastName,
            ownerId: t.u.ownerId,
            role: t.u.role,
            username: t.u.username,
            commissionRate: t.u.commissionRate,
            email: t.u.email
        }), {
            enabled: props.userId
        }
    );

    const [form] = useForm<User>();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            form.setFieldsValue(user);
        }
        if (!props.userId) {
            form.setFieldsValue({
                role: UserRole.TRADER
            });
            form.setFieldsValue({
                commissionRate: 0
            });
        }
    }, [user])

    const { mutateAsync: saveUser, isLoading: userSaving } = useMutation((userData: User) => {
        return (props.userId
            ? backendPut(`/users/${props.userId}`, {
                ...userData, role: userInfo.admin ? userData.role : user!.role
            })
            : backendPost('/users', userData)
        ).then((userId) => {
            notification.success({
                message: 'Użytkownik został zapisany'
            });
            if (userId) {
                navigate(baseUrl(`/users/${userId}/data`));
            }
        })
    });

    const { mutateAsync: removeUser, isLoading: userRemoving } = useMutation((userId: number) => {
        return backendDelete(`/users/${userId}`)
            .then(() => {
                notification.success({
                    message: 'Użytkownik został usunięty'
                });
                navigate(baseUrl("/users"));
            })
    });

    const permission = userInfo.permissions.user(props.userId);

    return <Card style={{ padding: 0 }}>
        <Spin spinning={userLoading || userSaving || userRemoving}>
            <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onFinish={values => saveUser(omit(values, ['confirmPassword']) as User)}
                validateMessages={validateMessages}
                name="user"
            >
                <Form.Item
                    label="Login"
                    name="username"
                    rules={[{ required: true }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Imię"
                    name="firstName"
                    rules={[{ required: true }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Nazwisko"
                    name="lastName"
                    rules={[{ required: true }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="EMail"
                    name="email"
                    rules={[{ required: false }]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Hasło"
                    rules={[{
                        required: !props.userId,
                        message: 'Wprowadź hasło!',
                    }]}
                    hasFeedback
                >
                    <Input.Password autoComplete="new-password"/>
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    label={<>Potwierdź<br/>hasło</>}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: !props.userId,
                            message: 'Potwierdź wprowadzone hasło!',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                const password = getFieldValue('password');
                                if (isEmpty(password) && isEmpty(value) || password === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Hasła nie pasują do siebie!');
                            }
                        })
                    ]}
                >
                    <Input.Password autoComplete="off"/>
                </Form.Item>

                {userInfo.admin &&
                <Form.Item label="Rola" name="role">
                    <Select>
                        {allRoles.map(role => <Select.Option value={role} key={role}>
                            {getUserRoleDescription(role)}
                        </Select.Option>)}
                    </Select>
                </Form.Item>}

                {(userInfo.admin || userInfo.backOffice) &&
                <Form.Item label={<>Współczynnik<br/>porowizji</>} name="commissionRate">
                    <NumericInput/>
                </Form.Item>}

                <FooterGap/>
                <Row className="footer-actions">
                    <Col span={24}>
                        <Space>
                            {props.userId ? <RemoveButton disabled={!permission.remove} onConfirm={() => removeUser(props.userId!)}/> : null}
                            <Button type="primary" htmlType="submit" disabled={!permission.save}>
                                Zapisz
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </Card>
}
