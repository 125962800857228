import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    message,
    Row,
    Select,
    Space,
    Spin,
    Tooltip,
    Typography,
    Upload
} from "antd";
import { useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { useForm } from "antd/lib/form/Form";
import { useNavigate } from "react-router-dom";
import { RcFile, UploadProps } from "antd/lib/upload";

import "./template-data.less";
import { QuestionCircleOutlined, UploadOutlined } from "../../components/icons";
import { RemoveButton } from "../../components/remove-button";
import { useTemplateStatus } from "../../hooks/template-status.hook";
import { useUser } from "../../hooks/user.hook";
import { getBase64RawData, getFileExtension, withExtension } from "../../utils/utils";
import { from } from "../../utils/sql-builder";
import { Model, t } from "../../models/db";
import { ProtectedImg } from "../../components/protected-img";
import { FooterGap, Gap } from "../../components/gap";
import { ProtectedImgPreview } from "../../components/protected-img-preview";
import { FileData } from "../../components/file-uploader";
import { useQueryClient } from "@tanstack/react-query";
import { CalculatorSelect } from "../../components/selects/calculator-select";
import { labelCol, wrapperCol } from "../../models/ui";
import { useAcceptTemplate, useRemoveTemplate, useSaveTemplate } from "./template.hook";
import { getCurrentTemplateVersion, TemplateType } from "../../models/model";
import range from "lodash/range";
import { DefaultOptionType } from "rc-select/lib/Select";

export const TemplateData = (props: { templateId?: number }) => {
    const queryClient = useQueryClient();
    const user = useUser();

    const { data: template, loading: templateLoading } = useSqlQuerySingle(
        `template-data-${props.templateId}`,
        from(t.template, t.t)
        .where(t.t.id.eq(props.templateId))
        .select({
            id: t.t.id,
            name: t.t.name,
            templateType: t.t.templateType,
            templateFlags: t.t.templateFlags,
            version: t.t.version,
            active: t.t.active,
            filename: t.t.filename,
            internalFilename: t.t.internalFilename,
            calculatorId: t.t.calculatorId,
            ownerId: t.t.ownerId
        }), {
            enabled: props.templateId
        }
    );

    const { templateAccepted, templateStatusLoading } = useTemplateStatus(props.templateId);

    const { data: templateUsed, loading: templateUsedLoading } = useSqlQuerySingle(
        `template-used-${props.templateId}`,
        from(t.template, t.t)
        .where(t.t.calculatorId.eq(template?.calculatorId))
        .select({
            id: t.t.id,
            ownerId: t.t.ownerId
        }), {
            enabled: template?.calculatorId
        }
    );

    const { data: calculators, loading: calculatorsLoading } = useSqlQuery(
        `template-calculators-${props.templateId}`,
        from(t.calculator, t.c)
        .where(t.c.active.isTrue(), user.manager)
        .where(t.c.acceptedAt.isNotNull())
        .or(t.c.id.eq(template?.calculatorId), template?.calculatorId)
        .orderByAsc(t.c.name)
        .select({
            id: t.c.id,
            type: t.c.type,
            name: t.c.name,
            active: t.c.active,
            ownerId: t.c.ownerId
        }), {
            enabled: props.templateId && template || !props.templateId
        }
    );

    const [fileList, updateFileList] = useState<FileData[]>([]);
    const [fileData, setFileData] = useState<string | null>(null);
    const [templateType, setTemplateType] = useState<TemplateType | null>(null);
    const [previewFile, setPreviewFile] = useState<string | undefined>(undefined);

    const uploadProps: UploadProps = {
        listType: "picture",
        className: "template-upload-list",
        fileList,
        beforeUpload: file => {
            if (file.type !== 'application/pdf') {
                void message.error(`${file.name} nie jest plikiem pdf`);
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = evt => {
                    const base64File = evt.target?.result as string ?? '';
                    if (base64File.length > 0) {
                        const rawData = getBase64RawData(base64File);
                        setFileData(rawData);
                        updateFileList([{
                            ...file,
                            id: 0,
                            fileData: rawData,
                            fileDataB64: base64File,
                            name: file.name,
                            originFileObj: {} as RcFile
                        }]);
                    }
                }
                reader.onerror = function () {
                    console.error("Error file uploading");
                }
            }
            return false;
        },
        onRemove: file => {
            queryClient.setQueryData([`template-data-${props.templateId}`], () => ({
                ...template,
                internalFilename: ''
            } as Model.Template.Data));
            updateFileList([]);
            setFileData(null);
            setTimeout(() => {
                form.resetFields(["filename"]);
            });
        },
        onPreview: file => {
            if (!file.url && !file.preview) {
                return false;
            }
            const filePath = withExtension(file.url!!, getFileExtension(file.fileName!!) ?? "jpg")
            setPreviewFile(filePath);
        },
        itemRender: (originNode, file, fileList, actions) => {
            return <>
                <Gap/>
                <Space direction="vertical">
                    <Typography.Text>{file.name}</Typography.Text>
                    <ProtectedImg
                        filename={file.fileName}
                        disabled={!permissions.edit}
                        imgData={(file as FileData).fileDataB64}
                        actions={actions}
                        style={{ width: 200 }}/>
                </Space>
            </>
        }
    };

    const [form] = useForm<Model.Template.Data>();
    const navigate = useNavigate();

    useEffect(() => {
        if (template) {
            form.setFieldsValue({
                ...template,
                filename: {
                    file: {
                        name: template.filename
                    }
                }
            });
            setTemplateType(template.templateType);
            updateFileList([{
                id: 0,
                name: template.filename,
                fileName: template.internalFilename,
                fileData: '',
                url: template.internalFilename,
                uid: '',
                size: 0,
                type: "application/pdf",
                originFileObj: {} as RcFile
            }]);
        }
    }, [template]);

    useEffect(() => {
        form.setFieldValue('version', getCurrentTemplateVersion(templateType))
    }, [templateType]);

    const { mutateAsync: saveTemplate, isLoading: templateSaving } = useSaveTemplate();
    const { mutateAsync: acceptTemplate, isLoading: templateAccepting } = useAcceptTemplate();
    const { mutateAsync: removeTemplate, isLoading: templateRemoving } = useRemoveTemplate();

    const permissions = user.permissions.template(props.templateId, templateAccepted, templateType);
    const assemblyTemplate = templateType === TemplateType.ASSEMBLY_FV || templateType === TemplateType.ASSEMBLY_HP;

    const currentVersion = getCurrentTemplateVersion(templateType, 1);
    const versions = range(1, currentVersion! + 1).map(v => ({
        key: v,
        value: v,
        label: `Wersja ${v}`
    } as DefaultOptionType));

    return <Card style={{ padding: 0 }}>
        <Spin
            spinning={templateLoading || templateUsedLoading || calculatorsLoading || templateSaving || templateRemoving || templateAccepting || templateStatusLoading}>
            <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onFinish={values => {
                    const file = (values.filename as any).file as any;
                    saveTemplate({
                        ...values,
                        templateFlags: values.templateFlags ?? 0,
                        filename: file.name,
                        id: props.templateId,
                        fileData
                    }).then(() => {
                        setFileData(null);
                    });
                }}
                name="template"
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input disabled={!permissions.edit}/>
                </Form.Item>
                <Form.Item
                    label="Rodzaj"
                    name="templateType"
                    rules={[{ required: true }]}
                >
                    <Select disabled={!permissions.edit}
                        onChange={type => {
                            const templateType = type as number;
                            setTemplateType(templateType);
                            form.setFieldsValue({
                                templateFlags: templateType === TemplateType.OFFER ? 0 : 2
                            });
                            if (assemblyTemplate) {
                                form.setFieldsValue({
                                    calculatorId: undefined
                                });
                            }
                        }}>
                        <Select.Option value={TemplateType.OFFER}>Oferta</Select.Option>
                        <Select.Option value={TemplateType.AGREEMENT}>Umowa</Select.Option>
                        <Select.Option value={TemplateType.ASSEMBLY_FV}>Arkusz (fotowoltaika)</Select.Option>
                        <Select.Option value={TemplateType.ASSEMBLY_HP}>Arkusz (pompa ciepła)</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Wersja"
                    name="version"
                    rules={[{ required: true }]}
                    wrapperCol={{ span: 6}}
                >
                    <Select disabled={!permissions.edit || (templateType !== TemplateType.ASSEMBLY_FV && templateType !== TemplateType.ASSEMBLY_HP)} options={versions}/>
                </Form.Item>
                {assemblyTemplate ?
                <Form.Item
                    label="Aktywny"
                    name="active"
                    valuePropName="checked"
                    initialValue={true}
                    rules={[{ required: true }]}
                >
                    <Checkbox/>
                </Form.Item> : null}
                {templateType === TemplateType.AGREEMENT ? <Form.Item
                    label="Opcje"
                    name="templateFlags"
                    rules={[{ required: true }]}
                >
                    <Select disabled={!permissions.edit}>
                        <Select.Option value={0} disabled>Opcje nieokreślone</Select.Option>
                        <Select.Option value={1}>Szablon 50%</Select.Option>
                        <Select.Option value={2}>Szablon 100%</Select.Option>
                    </Select>
                </Form.Item> : null}
                <Form.Item
                    label="Szablon"
                    name="filename"
                    rules={[{ required: true }]}
                >
                    <Upload {...uploadProps} disabled={!permissions.edit}>
                        <Button disabled={!permissions.edit} icon={<UploadOutlined/>}>Wybierz plik</Button>
                    </Upload>
                </Form.Item>
                {template?.id ?
                    <Form.Item label={" "} colon={false}>
                        <Alert message={"Zmiana pliku spowoduje usunięcie isntiejących mapowań"} showIcon={true}
                            type={'warning'}/>
                    </Form.Item> : null}
                {(templateType === TemplateType.OFFER || templateType === TemplateType.AGREEMENT) ? <Form.Item
                    label={
                        <span>
                            Kalkulator&nbsp;
                            {templateUsed?.id &&
                                <Tooltip title="Ten kalkulator jest już wykorzystywany w ofertach. Zmiana niezalecana.">
                                    <QuestionCircleOutlined/>
                                </Tooltip>}
                        </span>
                    }
                    name="calculatorId"
                    rules={[{ required: true }]}
                >
                    <CalculatorSelect calculators={calculators} canEdit={permissions.edit}/>
                </Form.Item> : null}
                <FooterGap/>
                <Row className="footer-actions">
                    <Col span={24}>
                        <Space>
                            {props.templateId ? <RemoveButton disabled={!permissions.remove}
                                onConfirm={() => removeTemplate(props.templateId!)}/> : null}
                            {props.templateId ?
                                <Button disabled={!permissions.accept} onClick={() => acceptTemplate({ templateId: props.templateId!, templateAccepted })}>
                                    Akceptuj
                                </Button> : null}
                            <Button disabled={!permissions.save} type="primary" htmlType="submit">
                                Zapisz
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Spin>
        {previewFile ? <ProtectedImgPreview onClose={() => setPreviewFile(undefined)} filename={previewFile}/> : null}
    </Card>
}
