import { Button, Modal } from "antd";
import React, { useState } from "react";
import { VisualisationData } from "./visualisation-data";

export const VisualisationPreview = ({ visualisationId, offerId }: { visualisationId: number | undefined | null, offerId: number | undefined }) => {
    const [showAssemblyDetails, setShowAssemblyDetails] = useState(false);

    const showAssemblyParameters = () => {
        setShowAssemblyDetails(true);
    };

    const closeAssemblyParameters = () => {
        setShowAssemblyDetails(false);
    };

    return <>
        <Button onClick={showAssemblyParameters} disabled={!visualisationId}>Podgląd</Button>
        <Modal centered={true} closable={false}
            open={showAssemblyDetails}
            onOk={closeAssemblyParameters}
            width="80vw"
            cancelButtonProps={{ style: { display: 'none' } }}
            bodyStyle={{ height: '80vh', overflow: 'auto', border: 'none' }}>
            {visualisationId ?
                <VisualisationData visualisationId={visualisationId}
                    previewMode={true}/> : null}
        </Modal>
    </>
}
