import React, { useEffect } from "react";
import { Button, Card, Checkbox, Col, Form, Input, notification, Row, Select, Space, Spin } from "antd";
import { useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { useForm } from "antd/lib/form/Form";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { refreshCalculatorStatus, useCalculatorStatus } from "../../hooks/calculator-status.hook";
import { RemoveButton } from "../../components/remove-button";
import { useUser } from "../../hooks/user.hook";
import { useMutation } from "@tanstack/react-query";
import { from } from "../../utils/sql-builder";
import { Model, t } from "../../models/db";
import { FooterGap } from "../../components/gap";
import { labelCol, wrapperCol } from "../../models/ui";

export const CalculatorData = (props: { calculatorId?: number }) => {
    const user = useUser();

    const { data: calculator, loading: calculatorLoading } = useSqlQuerySingle(
        `calculator-${props.calculatorId}`,
        from(t.calculator, t.c)
        .where(t.c.id.eq(props.calculatorId))
        .select({
            id: t.c.id,
            name: t.c.name,
            type: t.c.type,
            active: t.c.active,
            acceptedAt: t.c.acceptedAt,
            createdAt: t.c.createdAt,
            ownerId: t.c.ownerId
        }), {
            enabled: props.calculatorId
        }
    );

    const [form] = useForm<Model.Calculator.Data>();
    const navigate = useNavigate();

    useEffect(() => {
        if (calculator) {
            form.setFieldsValue(calculator);
        }
    }, [calculator]);

    const { calculatorAccepted, calculatorStatusLoading } = useCalculatorStatus(props.calculatorId);

    const { mutateAsync: acceptCalculator, isLoading: calculatorAccepting } = useMutation(async (calculatorId: number) => {
        await backendPut(`/calculators/${calculatorId}/accept`);
        if (!calculatorAccepted) {
            notification.success({
                message: 'Kalkulator został zaakceptowany'
            });
        }
        refreshCalculatorStatus(calculatorId);
    });

    const { mutateAsync: removeCalculator, isLoading: calculatorRemoving } = useMutation(async (calculatorId: number) => {
        await backendDelete(`/calculators/${calculatorId}`);
        notification.success({
            message: 'Kalkulator został usunięty'
        });
        navigate(baseUrl("/calculators"));
    });

    const { mutateAsync: saveCalculator, isLoading: calculatorSaving } = useMutation(async (calculator: Model.Calculator.Data) => {
        const calculatorId = await (props.calculatorId
            ? backendPut<number>(`/calculators/${props.calculatorId}`, calculator)
            : backendPost<number>('/calculators', calculator)
        );
        notification.success({
            message: 'Kalkulator został zapisany'
        });
        if (calculatorId) {
            navigate(baseUrl(`/calculators/${calculatorId}/data`));
        }
    });

    const permission = user.permissions.calculator(props.calculatorId, calculatorAccepted);

    return <Card style={{ padding: 0 }}>
        <Spin spinning={calculatorLoading || calculatorSaving || calculatorAccepting || calculatorStatusLoading || calculatorRemoving}>
            <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onFinish={values => saveCalculator(values)}
                name="calculator"
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input disabled={calculatorAccepted}/>
                </Form.Item>

                <Form.Item
                    label="Rodzaj"
                    name="type"
                    rules={[{ required: true }]}
                >
                    <Select disabled={calculatorAccepted}>
                        <Select.Option value="photovoltaics" key="photovoltaics">Fotowoltaika</Select.Option>
                        <Select.Option value="heat-pump" key="heat-pump">Pompa ciepła</Select.Option>
                        <Select.Option value="industry-pump" key="industry-pump">Pompa przemysłowa</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Aktywny"
                    name="active"
                    valuePropName="checked"
                    initialValue={false}
                    rules={[{ required: true }]}
                >
                    <Checkbox/>
                </Form.Item>

                <FooterGap/>
                <Row className="footer-actions">
                    <Col span={24}>
                        <Space>
                            {props.calculatorId ? <RemoveButton disabled={!permission.remove} onConfirm={() => removeCalculator(props.calculatorId!)}/> : null}
                            {props.calculatorId ? <Button disabled={!permission.accept} onClick={() => acceptCalculator(props.calculatorId!)}>
                                Akceptuj
                            </Button> : null}
                            <Button type="primary" htmlType="submit" disabled={!permission.save}>
                                Zapisz
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </Card>
}
