import { DynamicContent } from "../../../components/dynamic-content";
import { Area, CartesianGrid, ComposedChart, Line, Tooltip, XAxis, YAxis } from "recharts";
import { DashboardCard } from "../dashboard-card";
import React from "react";
import { sql, useSqlQueryRaw } from "../../../hooks/sql-query.hook";
import dayjs from "dayjs";
import { CustomTooltip } from "../custom-tooltip";
import { capitalize } from "lodash";

type Data = { month: number | string, paidAmount: number, expectedAmount: number };

export const InvoicesPayments = () => {
    const { data: payments } = useSqlQueryRaw<Data>('invoices-payments', sql`
        with periods as (
            select d as period_start,(d + '1 month'::interval - '1 microsecond'::interval) period_end
              from generate_series(
                date_trunc('month', now()) - interval '6 months',
                date_trunc('month', now()), '1 month'::interval
              ) d
        )
        select extract(month from p.period_start) - 1 as month,
            coalesce(sum(case when rp.paid_at is not null then rp.amount else 0 end), 0) as paid_amount,
            coalesce(sum(case when rp.paid_at is null then rp.amount else 0 end), 0) as expected_amount
         from periods p
         left join realisation r
         on r.created_at between p.period_start and p.period_end
         and r.deleted_at is null
         left join realisation_payment rp
         on r.id = rp.realisation_id
         group by 1
         order by 1
    `, {
        map: rows => rows.map(row => ({
            ...row,
            month: capitalize(dayjs.months()[row.month as number])
        }))
    });

    return <DashboardCard title="Płatności za ostanie 6 miesięcy" key="clients">
        <DynamicContent>
            {(width, height) => {
                return <ComposedChart width={width} height={height} data={payments}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#129a74" stopOpacity={0.1}/>
                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>
                    <XAxis name={"Miesiąc"} dataKey={"month"}/>
                    <YAxis allowDecimals={false}/>
                    <Tooltip content={<CustomTooltip<Data> text={data => `${data.month}: ${data.paidAmount} zł`}/>}/>
                    <CartesianGrid vertical={false} stroke="#DDD"/>
                    <Area type="monotone" dataKey="paidAmount" strokeWidth={1} fillOpacity={1}
                        fill="url(#colorUv)"/>
                </ComposedChart>
            }}
        </DynamicContent>
    </DashboardCard>
}
