declare var BASE_NAME: string;

export function baseUrl(url: string) {
    if (url === '/') {
        return BASE_NAME == '' ? url : `/${BASE_NAME}`;
    }
    let baseUrl = BASE_NAME == ''
        ? BASE_NAME
        : `/${BASE_NAME}`;
    if (url && url.length > 0 && url[0] !== '/') {
        baseUrl += '/';
    }
    return baseUrl + url;
}

export function withoutBaseUrl(url: string) {
    if (BASE_NAME != '' && url.indexOf(`/${BASE_NAME}`) === 0) {
        return url.substring(1 + BASE_NAME.length);
    }
    return url;
}