import { Button, Form, Popover, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { ButtonType } from "antd/lib/button";

interface PopoverFormProps<T> {
    title?: string;
    buttonType?: ButtonType;
    children?: React.ReactNode;
    onSubmit: (values: T) => void;
    onVisibleChange?: (visible: boolean) => void;
    onBefore?: () => boolean;
    buttonEnabled?: boolean;
    showPopover?: boolean;
    loading?: boolean;
    values?: { [key: string]: any },
}

export const PopoverForm = <T extends {}>(props: PopoverFormProps<T>) => {
    const [form] = useForm();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (props.values && visible) {
            form.setFieldsValue(props.values);
        }
    }, [props.values, visible]);

    const content =
        <Form layout="vertical" form={form} onFinish={values => {
            props.onSubmit(values);
            setVisible(false);
            form.resetFields();
            if (props.onVisibleChange) {
                props.onVisibleChange(false);
            }
        }}>
            {props.children}
            <Form.Item style={{marginBottom: 0}}>
                <Space>
                    <Button onClick={() => {
                        form.resetFields();
                        setVisible(false);
                        if (props.onVisibleChange) {
                            props.onVisibleChange(false);
                        }
                    }}>
                        Anuluj
                    </Button>
                    <Button htmlType="submit" type="primary">
                        OK
                    </Button>
                </Space>
            </Form.Item>
        </Form>

    return <Popover
        content={content}
        placement="rightTop"
        trigger="click"
        open={visible && props.showPopover}
        onOpenChange={visible => {
            if (props.buttonEnabled) {
                if (props.onVisibleChange) {
                    props.onVisibleChange(visible)
                }
                if (props.onBefore?.()) {
                    setVisible(true);
                } else {
                    setVisible(visible);
                }
            }
        }}
    >
        <Button
            type={props.buttonType || "default"}
            disabled={!props.buttonEnabled}
            loading={props.loading}
            onClick={() => {
                if (!props.showPopover) {
                    props.onSubmit({} as T);
                }
            }}
        >
            {props.title}
        </Button>
    </Popover>

}
