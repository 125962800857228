// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-preview div.ant-spin-nested-loading {
  height: 100%;
}
.img-preview div.ant-spin {
  max-height: none;
}
.pdf-viewer {
  border: none;
  height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./web/components/protected-img-preview.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;AAAJ;AAFA;EAMI,gBAAA;AADJ;AAKA;EACE,YAAA;EACA,mBAAA;AAHF","sourcesContent":[".img-preview {\n  div.ant-spin-nested-loading {\n    height: 100%;\n  }\n\n  div.ant-spin {\n    max-height: none;\n  }\n}\n\n.pdf-viewer {\n  border: none;\n  height: fit-content;\n}\n\n//.react-pdf__Page__canvas {\n//   position: absolute !important;\n//    top: 50% !important;\n//    left: 50% !important;\n//    transform: translate(-50%, 0%) !important;\n//}\n//\n//.react-pdf__Page__textContent {\n//    position: absolute !important;\n//    top: 50% !important;\n//    left: 50% !important;\n//    transform: translate(-50%, 0%) !important;\n//}\n\n@layout-header-background: #1890ff;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
