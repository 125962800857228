import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import "./color-picker.less";

interface ColorPickerProps {
    value?: string;
    onChange?: (color: string) => void
}

export const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
    const [pickerVisible, setPickerVisible] = useState(false);
    const pickerRef = useRef<HTMLDivElement>(null);
    const [pickerPos, setPickerPos] = useState<DOMRect | null>(null);
    
    useEffect(() => {
        if (pickerRef.current && pickerVisible) {
            const r = pickerRef.current.getBoundingClientRect();
            setPickerPos(r);
        } else {
            setPickerPos(null)
        }
    }, [pickerRef.current, pickerVisible]);

    return <div className="color-picker">
        <div className="swatch" ref={pickerRef} onClick={() => {
            setPickerVisible(!pickerVisible);
        }}>
            <div className="color" style={{backgroundColor: value}}/>
        </div>
        {pickerVisible && pickerPos ? <div className="popover" style={{ left: (pickerPos?.right ?? 0) + 5, bottom: window.innerHeight - (pickerPos?.bottom ?? 0)}}>
            <div className="cover" onClick={() => setPickerVisible(false)}/>
            <SketchPicker
                color={value || '#000000'}
                onChange={v => {
                    if (onChange) {
                        onChange(v.hex);
                    }
                }}
            />
        </div> : null}

    </div>
}