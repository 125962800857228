import React, { useState } from "react";
import { Space, Tabs, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { AgreementData } from "./agreement-data";
import { PopoverForm } from "../../components/popover-form";
import { useAcceptAgreement, useSaveAgreement } from "./agreement.hook";
import { ExportService } from "../../api/export-service";
import { useMutation } from "@tanstack/react-query";
import { Model } from "../../models/db";
import { Query } from "./agreement-query";

type AgreementTab = 'data';

export const AgreementEdit = (props: { tab?: AgreementTab }) => {
    const { id } = useParams();
    const agreementId = parseInt(id ?? "") || undefined;
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);

    const { data: agreement, loading: agreementLoading } = Query.Agreement.use(agreementId);

    const [agreementData, setAgreementData] = useState<Model.Agreement.T | undefined>(undefined);

    const { mutateAsync: exportAgreement, isLoading: exportingAgreement } = useMutation((agreementId: number) => {
        return ExportService.exportAgreement(agreementId);
    });

    const { mutateAsync: acceptAgreement, isLoading: agreementAccepting } = useAcceptAgreement();
    const { mutateAsync: saveAgreement, isLoading: agreementSaving } = useSaveAgreement();

    const acceptWarning = <div style={{ paddingBottom: 10}}>
        <Typography.Text type="danger">Eksport umowy spowoduje jej zaakceptowanie</Typography.Text>
        <br/>
        <Typography.Text type="danger">i wyłączenie możliwości jej edycji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    return <Tabs defaultActiveKey="data" activeKey={props.tab} id="agreement-tabs"
        onChange={tab => {
            navigate(baseUrl(`/agreements/${agreementId}/${tab}`));
            setCurrentTab(tab as AgreementTab);
        }}
        tabBarExtraContent={agreementId && {
            right: <Space>
                <PopoverForm
                    title="Eksportuj umowę"
                    buttonEnabled={!exportingAgreement && !agreementAccepting && !!agreement?.templateId}
                    showPopover={!agreement?.acceptedAt}
                    loading={exportingAgreement || agreementAccepting || agreementSaving}
                    onSubmit={() => {
                        saveAgreement(agreementData!).then(() => {
                            acceptAgreement(agreementId).then(() => {
                                void exportAgreement(agreementId);
                            });
                        });
                    }}>
                    {acceptWarning}
                </PopoverForm>
            </Space>
        }}
        items={[{
            label: "Dane umowy",
            key: "data",
            children: <AgreementData
                agreementId={agreementId}
                agreementLoading={agreementLoading}
                agreement={agreement}
                agreementData={agreementData}
                setAgreementData={setAgreementData}
            />
        }]}
    />
}
