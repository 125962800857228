import { Nil } from "../utils/utils";

export function getCalculatorTypeDesc(calculatorType: string): string {
    if (calculatorType === 'photovoltaics') {
        return 'Fotowoltaika';
    } else if (calculatorType === 'heat-pump') {
        return 'Pompa ciepła';
    }
    return 'Nieznany';
}

export enum TemplateType {
    OFFER = 0,
    AGREEMENT = 1,
    ASSEMBLY_FV = 2,
    ASSEMBLY_HP = 3
}

export enum TemplateMailType {
    OFFER = 0
}

export const templateVersion = {
    [TemplateType.OFFER]: 1,
    [TemplateType.AGREEMENT]: 1,
    [TemplateType.ASSEMBLY_FV]: 2,
    [TemplateType.ASSEMBLY_HP]: 1,
    ['heat-pump']: 1,
    ['photovoltaics']: 2
};

export function getCurrentTemplateVersion(templateType: TemplateType | null, defaultVersion: number | undefined = 1): number | undefined {
    return templateType ? templateVersion[templateType] : defaultVersion;
}

export function getTemplateTypeDesc(templateType: TemplateType): string {
    if (templateType === TemplateType.AGREEMENT) {
        return 'Umowa';
    } else if (templateType === TemplateType.OFFER) {
        return 'Oferta';
    } else if (templateType === TemplateType.ASSEMBLY_FV) {
        return 'Arkusz (fotowoltaika)';
    } else if (templateType === TemplateType.ASSEMBLY_HP) {
        return 'Arkusz (popmpa ciepła)';
    }
    return 'Nieznany';
}

export function getTemplateType(assemblyType: Nil<AssemblyParametersType>): Nil<TemplateType> {
    if (assemblyType === "photovoltaics") {
        return TemplateType.ASSEMBLY_FV;
    } else if (assemblyType === "heat-pump") {
        return TemplateType.ASSEMBLY_HP;
    }
    return undefined;
}

export enum ClientType {
    PERSON = 0,
    COMPANY = 1
}

export interface IdValidation {
    available: boolean
}

export function getClientTypeDesc(clientType: ClientType): string {
    if (clientType === ClientType.COMPANY) {
        return 'Firma';
    } else if (clientType === ClientType.PERSON) {
        return 'Osoba fizyczna';
    }
    return 'Nieznany';
}

export interface EvaluationError {
    fieldId: string;
    error: string;
    formula?: string;
    fieldIdChain: string[];
}

export interface EvaluationFieldValue {
    value: number | string | null;
    disabled: boolean | null;
    disabledValue: number | string | null;
    hidden: boolean | null;
    hiddenValue: number | string | null;
}

export function getEvaluatedFieldValue(fld: EvaluationFieldValue): number | string | boolean | null {
    let v = null;
    if (fld.hidden === true) {
        v = fld.hiddenValue
    } else if (fld.disabled === true) {
        v = fld.disabledValue
    }
    return v ?? fld.value;
}

export interface EvaluationResult {
    mainValue: number;
    fieldValues: {
        [key: string]: EvaluationFieldValue
    };
    errors: EvaluationError[];
}

export type AssemblyParametersType = 'photovoltaics' | 'heat-pump';
