import React, { useState } from "react";
import { Space, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { ProjectData } from "./project-data";
import { ProjectComments } from "./project-comments";

type ProjectTab = 'data' | 'comments';

export const ProjectEdit = (props: { tab?: ProjectTab }) => {
    const { id } = useParams();
    const projectId = parseInt(id ?? "") || undefined;
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);

    return <Tabs defaultActiveKey="data" activeKey={props.tab} id="project-tabs"
        onChange={tab => {
            navigate(baseUrl(`/projects/${projectId}/${tab}`));
            setCurrentTab(tab as ProjectTab);
        }}
        tabBarExtraContent={{
            right: <Space>
            </Space>
        }}
        items={[{
            label: "Dane projektu",
            key: "data",
            children: <ProjectData projectId={projectId!!}/>
        }, {
            label: "Komentarze",
            key: "comments",
            children: <ProjectComments projectId={projectId!!}/>
        }]}
    />
}
