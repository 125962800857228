// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-grid-moving * {
  pointer-events: none;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./web/components/absolute-layout/absolute-layout.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;AACnB","sourcesContent":[".inner-grid-moving * {\n  pointer-events: none;\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
