import { GridCellPos } from "../../components/absolute-layout/absolute-layout";
import { UserRole } from "../../models/user";

export enum FieldType {
    Dictionary = 0,
    Text = 1,
    NumericInput = 2,
    Formula = 3,
    TextInput = 4
}

export enum FieldScope {
    Public = 0,
    Limited = 1
}

export interface LayoutItem extends GridCellPos {
    id?: number | null;

    fieldType: FieldType;
    fieldScope: FieldScope;
    fieldRoles: UserRole[];
    fieldId: string;
    calculatorDictId?: number | null;
    alias?: string | null;
    value?: string;
    backgroundColor?: string;
    textColor?: string;
    fontSize?: number;
    fontWeight?: number;
    horzAlign?: number;
    vertAlign?: number;
    mainValue: boolean;
    roundValue: boolean;
    formatValue: boolean;
}

export type CalculatorData = { [key: string]: any };

export function getFieldTypeStr(fieldType: FieldType) {
    switch (fieldType) {
        case FieldType.Dictionary:
            return "Słownik";
        case FieldType.Text:
            return "Pole tekstowe";
        case FieldType.NumericInput:
            return "Wartość numeryczna";
        case FieldType.Formula:
            return "Formuła";
        case FieldType.TextInput:
            return "Wartość tekstowa"
        default:
            return "";
    }
}

export function isReferenceField(fieldType: FieldType | null | undefined) {
    return fieldType === FieldType.Dictionary ||
        fieldType === FieldType.Formula ||
        fieldType === FieldType.NumericInput ||
        fieldType === FieldType.TextInput ||
        fieldType === FieldType.Text;
}

export function getLayoutItemPos(item: LayoutItem): GridCellPos {
    return {
        x0: item.x0,
        xp0: item.xp0,
        xl0: item.xl0,
        y0: item.y0,
        yp0: item.yp0,
        yl0: item.yl0,
        x1: item.x1,
        xp1: item.xp1,
        xl1: item.xl1,
        y1: item.y1,
        yp1: item.yp1,
        yl1: item.yl1
    }
}
