import { Avatar, Col, Dropdown, Layout, Row, Tooltip } from "antd";
import React from "react";
import { clearStorage } from "./api/backend-api";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "./hooks/user.hook";
import { baseUrl } from "./utils/navigation";
import { getBuildInfo, isDevEnv } from "./utils/utils";
import { CheckOutlined, DashboardOutlined, DesktopOutlined, UserOutlined } from "./components/icons";
import { getUserRoleDescription, getUserRoles, UserRole } from "./models/user";
import { useSqlQuery } from "./hooks/sql-query.hook";
import { appStore } from "./store/store";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useQueryClient } from "@tanstack/react-query";
import { appTheme } from "./theme";
import { from } from "./utils/sql-builder";
import { t } from "./models/db";

export const AppHeader = () => {
    const { user, manager } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const loginPage = location.pathname.startsWith(baseUrl('/login'));
    const queryClient = useQueryClient();

    const { data: assignedUsers } = useSqlQuery(
        `assigned-users-names-${user?.id}`,
        from(t.user)
        .where(t.user.deletedAt.isNull())
        .orderBy(t.user.firstName)
        .orderBy(t.user.lastName)
        .select({
            id: t.user.id,
            firstName: t.user.firstName,
            lastName: t.user.lastName
        }), {
            enabled: user && manager
        }
    );

    const menuItems: ItemType[] = user ? [{
        key: "system",
        icon: <DesktopOutlined/>,
        label: <NavLink to={baseUrl(`/system`)}>System</NavLink>
    }, {
        key: "settings",
        icon: <DashboardOutlined/>,
        label: <NavLink to={baseUrl(`/users/${user.id}`)}>Ustawienia</NavLink>
    }, {
        key: "logout",
        icon: <UserOutlined/>,
        onClick: () => {
            clearStorage();
            navigate(baseUrl("/login"));
        },
        label: "Wyloguj"
    }, user.role === UserRole.ADMIN ? {
        key: "role",
        label: "Rola",
        children: getUserRoles().map(role => ({
            key: role,
            onClick: () => {
                appStore.update(s => {
                    s.secondaryRole = role
                });
                void queryClient.invalidateQueries();
            },
            icon: user.secondaryRole === role ? <CheckOutlined/> : null,
            label: getUserRoleDescription(role)
        }))
    }: null]: [];

    const userMenu = user ? menuItems : null;

    const userRole = getUserRoleDescription(user.role) +
        (user.role === UserRole.ADMIN && user.secondaryRole && user.secondaryRole !== user.role ? ` / ${getUserRoleDescription(user.secondaryRole)}` : '');

    return <Layout.Header className="app-header" style={{ background: appTheme.colorPrimary }}>
        <Row>
            <Col span={8}>
                <div className="app-info">
                    <div className="logo">Unima Energia{isDevEnv() ? " DEV" : ""}</div>
                    <div className="build-info">{getBuildInfo()}</div>
                </div>
            </Col>
            {!loginPage && user && user.firstName && user.lastName && userMenu &&
            <Col span={16}>
                <div className="user-info">
                    <div>
                        <div className="user-name">{`${user.firstName} ${user.lastName}`}</div>
                        {assignedUsers ? <Tooltip title={<div style={{ height: 200, overflow: 'auto' }}>
                            {assignedUsers?.map(user => <div key={user.id}>{user.firstName} {user.lastName}</div>)}
                        </div>} trigger="click">
                            <div className="user-role">{userRole}</div>
                        </Tooltip> : null}
                        {!assignedUsers ? <div className="user-role">{userRole}</div> : null}
                    </div>
                    <Dropdown menu={{ items: menuItems }}>
                        <Avatar icon={<UserOutlined/>} style={{ cursor: "pointer" }}/>
                    </Dropdown>
                </div>
            </Col>}
        </Row>
    </Layout.Header>
}
