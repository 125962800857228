import React, { useState } from "react";
import { Button, Card, Col, Form, Input, Row, Spin, Typography } from "antd";
import { backendGet, backendPost, clearStorage, storeTokens, storeUser } from "../../api/backend-api";
import { Store } from 'antd/lib/form/interface';
import { useLocation, useNavigate } from "react-router-dom";

import { UserInfo } from "../../models/user";
import { baseUrl } from "../../utils/navigation";
import { LockOutlined, UserOutlined } from "../../components/icons";
import { useQueryClient } from "@tanstack/react-query";
import { UnimaLogo } from "./unima-logo";
import { Gap } from "../../components/gap";

export const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const [loginError, setLoginError] = useState("");
    const [validating, setValidating] = useState(false);

    let from = (location.state as any)?.from?.pathname || "/";

    const onFinish = (values: Store) => {
        setLoginError("");
        setValidating(true);
        backendPost("/auth/login", {
            username: values.username,
            password: values.password
        }).then(async resp => {
            storeTokens(resp.access_token, resp.refresh_token);
            queryClient.clear();
            const userInfo = await backendGet<UserInfo | undefined>(`/users/${resp.username}`);
            if (userInfo) {
                storeUser(userInfo);
                navigate(baseUrl(from ?? "/"));
            }
        }).catch(error => {
            console.error(error);
            clearStorage();
            setLoginError("Invalid username or password");
        }).finally(() => {
            setValidating(false)
        })
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = () => {
        setLoginError("");
    }

    return <Row justify="center" align="middle" style={{ height: 'calc(100vh - 64px)', backgroundColor: 'white' }}>
        <Col>
            <Spin spinning={validating}>
                <Card style={{ padding: 0, width: 500 }}>
                    <div style={{ display: "grid", placeItems: "center" }}><UnimaLogo/></div>
                    <Gap height={20}/>
                    <Form
                        name="login"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onChange={onChange}
                        labelCol={{ flex: '60px' }}
                    >
                        <Form.Item
                            label="Login"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>}/>
                        </Form.Item>

                        <Form.Item
                            label="Hasło"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon"/>}/>
                        </Form.Item>

                        <Row>
                            <Col flex={4} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography.Text type="danger" strong={true}>
                                    {loginError}
                                </Typography.Text>
                            </Col>
                            <Col flex={1} style={{ textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit">
                                    Zaloguj się
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Spin>
        </Col>
    </Row>;
};
