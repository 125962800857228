import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { backendGetBlob } from "../api/backend-api";
import './protected-img-preview.less';
import { makeBlob } from "../utils/utils";
import { useResizeObserver } from "../hooks/resize-observer.hook";

export const ProtectedImgPreview = ({ filename, onClose }: { filename: string | undefined, onClose: () => void }) => {

    const isPdf = filename?.toLowerCase()?.endsWith('.pdf');
    const [fileData, setFileData] = useState<Blob | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const contentSize = useResizeObserver(".ant-modal-body", filename);

    useEffect(() => {
        if (filename) {
            setIsLoading(true);
            backendGetBlob(`/files/${filename}`)
                .then(data => data.data)
                .then(data => setFileData(data))
                .finally(() => setIsLoading(false))

        }
    }, [filename]);

    return <>
        <Modal centered={true} closable={false} keyboard={true}
            open={!!filename}
            onCancel={onClose}
            onOk={onClose}
            width="80vw"
            className="img-preview"
            cancelButtonProps={{ style: { display: 'none' } }}
            bodyStyle={{ height: '80vh', overflow: isPdf ? 'unset' : 'auto',  border: 'none', display: 'grid', placeContent: 'center' }}>
            <Spin spinning={isLoading}>
                {fileData && contentSize.width > 0 && contentSize.height > 0 ? <div style={{ height: '100%' }}>
                    {isPdf ? <iframe className="pdf-viewer"
                        style={{ width: contentSize.width, height: contentSize.height }}
                        src={makeBlob(fileData)}>
                        </iframe> :
                        <img src={makeBlob(fileData)} alt={'img'}/>}
                </div> : null}
            </Spin>
        </Modal>
    </>
}
