import { Space } from "antd";
import { formatDate } from "../utils/utils";
import React from "react";
import { UserRole } from "../models/user";

const statusMap = {
    'In realisation': { name: 'Przyjęty do realizacji', installation: false },
    'In verification': { name: 'W weryfikacji', installation: false },
    'Incomplete documents': { name: 'Dokumenty niekompletne', installation: false },
    'Documents completed': { name: 'Dokumenty kompletne', installation: false },
    'Client resigned': { name: 'Klient zrezygnował', installation: false },

    'Completing components': { name: 'Kompletowanie podzespołów', installation: true },
    'Submitted for installation': { name: 'Przekazany do montażu', installation: true },
    'Installation completed': { name: 'Montaż zakończony', installation: true },
    'No realisation': { name: 'Błędnie przekazano, brak realizacji', installation: true },
    'Submitted for commission': { name: 'Przekazany do rozliczenia prowizji', installation: true }
} as const;


type RealisationStatusId = keyof typeof statusMap;

export function getStatusLocale(status: string | undefined) {
    return (status && statusMap[status as RealisationStatusId]) ? statusMap[status as RealisationStatusId].name : 'Nieznany status';
}

export function getStatusList(role?: UserRole): { id: RealisationStatusId, name: string, disabled: boolean }[] {
    return Object.keys(statusMap).map(st => {
        const status = st as RealisationStatusId;
        const statusInfo = statusMap[status];
        return {
            id: status,
            name: statusInfo.name,
            disabled: role !== undefined && (role !== UserRole.ADMIN && (
                role === UserRole.COORDINATOR && !statusInfo.installation ||
                role === UserRole.BACK_OFFICE && statusInfo.installation) ||
                status === 'Submitted for installation' ||
                status === 'Submitted for commission')
        }
    });
}

export const RealisationStatus = (props: { status: string, createdAt: number }) => {
    return <div>
        <Space direction="vertical" size="small">
            <div>{getStatusLocale(props.status as RealisationStatusId)}</div>
            <div style={{ fontSize: 11, color: '#cc7b26' }}>{formatDate(props.createdAt)}</div>
        </Space>
    </div>
}
