import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Space, Spin, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import { RemoveButton } from "../../components/remove-button";
import { useUser } from "../../hooks/user.hook";
import {
    useAcceptAgreement,
    useMoveAgreementToRealisation,
    useRemoveAgreement,
    useSaveAgreement
} from "./agreement.hook";
import { PopoverForm } from "../../components/popover-form";
import { Model } from "../../models/db";
import { OfferSelect } from "../../components/selects/offer-select";
import { TemplateSelect } from "../../components/selects/template-select";
import { FooterGap } from "../../components/gap";
import { labelCol, wrapperCol } from "../../models/ui";
import { Query } from "./agreement-query";

interface AgreementDataProps {
    agreementId?: number;
    agreement: Query.Agreement.T | undefined;
    agreementLoading: boolean;
    agreementData: Model.Agreement.Data | undefined;
    setAgreementData: (agreement: Model.Agreement.Data) => void;
}

export const AgreementData = ({ agreementId, agreement, agreementLoading, agreementData, setAgreementData }: AgreementDataProps) => {
    const user = useUser();
    const [ offerId, setOfferId ] = useState<number | null>(null);

    const agreementAccepted = !!agreement?.acceptedAt;
    const agreementInRealisation = !!agreement?.realisationId;

    const { data: offers, loading: offersLoading } = Query.AgreementOffers.use(agreementId, agreement?.offerId);
    const { data: templates, loading: templatesLoading } = Query.AgreementTemplates.use(agreementId, offerId, agreement?.templateId);

    const [form] = useForm<Model.Agreement.Data>();

    useEffect(() => {
        if (agreement && offers) {
            setAgreementData(agreement);
            form.setFieldsValue(agreement!);
        }
        if (agreement?.offerId != null) {
            setOfferId(agreement.offerId);
        }
    }, [agreement, offers]);

    useEffect(() => {
        if (templates && templates.length > 0 && !agreementId) {
            setAgreementData({ ...agreementData, templateId: templates[0].id });
            form.setFieldsValue({
                templateId: templates[0].id
            });
        }
    }, [templates]);

    useEffect(() => {
        if (offerId !== null) {
            form.setFieldsValue({
                templateId: null
            });
        }
    }, [offerId]);

    const { mutateAsync: saveAgreement, isLoading: agreementSaving } = useSaveAgreement();
    const { mutateAsync: moveAgreementToRealisation, isLoading: movingAgreementToRealisation } = useMoveAgreementToRealisation();
    const { mutateAsync: acceptAgreement, isLoading: agreementAccepting } = useAcceptAgreement();
    const { mutateAsync: removeAgreement, isLoading: agreementRemoving } = useRemoveAgreement();

    const permission = user.permissions.agreement(agreementId, agreementAccepted)

    const moveToRealisationWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Przekazujesz umowę do realizacji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    return <Card style={{ padding: 0 }}>
        <Spin spinning={agreementLoading || offersLoading || agreementSaving || templatesLoading || agreementRemoving || agreementAccepting || movingAgreementToRealisation}>
            <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onValuesChange={(values: Store) => {
                    if (values.offerId !== undefined && values.offerId !== null) {
                        setOfferId(values.offerId);
                    }
                    setAgreementData({ ...agreementData, ...values });
                }}
                onFinish={() => saveAgreement(agreementData!)}
                name="agreement"
            >
                {agreementId ? <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input disabled={!permission.edit}/>
                </Form.Item> : null}
                <Form.Item
                    label="Oferta"
                    name="offerId"
                    rules={[{ required: true }]}
                >
                    <OfferSelect offers={offers} canEdit={permission.editOffer}/>
                </Form.Item>
                <Form.Item
                    label="Szablon"
                    name="templateId"
                    rules={[{ required: true }]}
                >
                    <TemplateSelect templates={templates} canEdit={permission.editTemplate}/>
                </Form.Item>
                <FooterGap/>
                <Row className="footer-actions">
                    <Col span={24}>
                        <Space>
                            {agreementId ? <RemoveButton disabled={!permission.remove}
                                onConfirm={() => removeAgreement(agreementId)}/> : null}
                            {agreementId ? <Button disabled={!permission.accept} onClick={() => {
                                saveAgreement(agreementData!).then(() => {
                                    void acceptAgreement(agreementId);
                                });
                            }}>
                                Akceptuj
                            </Button> : null}
                            {agreementId ? <PopoverForm
                                title="Przekaż do realizacji"
                                buttonEnabled={agreementAccepted && !agreementInRealisation}
                                showPopover={!agreementInRealisation}
                                loading={movingAgreementToRealisation}
                                onSubmit={() => {
                                    saveAgreement(agreement!).then(() => {
                                        void moveAgreementToRealisation(agreementId);
                                    });
                                }}>
                                {moveToRealisationWarning}
                            </PopoverForm> : null}
                            <Button type="primary" htmlType="submit" disabled={!permission.save}>
                                Zapisz
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </Card>
}
