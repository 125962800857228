import { Form, FormInstance, Input, Modal, Select, Space } from "antd";
import { MarkdownEditor } from "../../components/markdown-editor";
import React from "react";

interface RealisationTasksProps {
    fieldNumber: number;
    canEdit: boolean;
    canEditView: boolean;
    form: FormInstance;
    installationTasks: { id: number, name: string, tasks: string }[] | undefined;
}

export const RealisationTasks = (props: RealisationTasksProps) => {
    const tasksFiledName = `installationTaskList${props.fieldNumber}`;
    const nameFiledName = `installationTaskListName${props.fieldNumber}`;

    return <Space direction="vertical">
        {props.canEdit ? <Select placeholder="Wybierz z szablonów" onChange={tasks => {
            if (props.form.getFieldValue(tasksFiledName)) {
                Modal.confirm({
                    title: "Czy na pewno chcesz nadpisać listę zadań?",
                    okText: "Tak",
                    cancelText: "Nie",
                    centered: true,
                    onOk: () => {
                        props.form.setFieldValue(tasksFiledName, tasks);
                    }
                })
            } else {
                props.form.setFieldValue(tasksFiledName, tasks)
            }
        }} style={{ width: 500 }}>
            {props.installationTasks?.map(task => <Select.Option key={task.id} value={task.tasks ?? ''}>
                {task.name}
            </Select.Option>)}
        </Select> : null}
        <Form.Item name={nameFiledName} noStyle>
            <Input type={props.canEdit ? "text" : "hidden"} style={{ width: 500 }} placeholder="Nazwa listy"/>
        </Form.Item>
        <Form.Item name={tasksFiledName}>
            <MarkdownEditor height={200} preview={true} previewAsDefault={true}
                canEdit={props.canEdit}
                canEditView={props.canEditView}
            />
        </Form.Item>
    </Space>
}
