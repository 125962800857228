import React, { useEffect, useState } from "react";
import { Button, Card, Col, Descriptions, Form, FormInstance, Input, Row, Space, Spin, Typography } from "antd";
import { validateMessages } from "../../utils/validators";
import { RemoveButton } from "../../components/remove-button";
import { useUser } from "../../hooks/user.hook";
import { FooterGap } from "../../components/gap";
import { FileData } from "../../components/file-uploader";
import { RcFile } from "antd/lib/upload";
import {
    useAcceptAssemblyParameters,
    useAssemblyParametersInfo,
    useRemoveAssemblyParameters,
    useSaveAssemblyParameters
} from "./assembly-parameters.hook";
import { Store } from "antd/lib/form/interface";
import { PopoverForm } from "../../components/popover-form";
import { AssemblyParametersOperation } from "./assembly-parameters-edit";
import { ClientSelect } from "../../components/selects/client-select";
import { TemplateSelect } from "../../components/selects/template-select";
import { wrapperCol } from "../../models/ui";
import { Query } from "./assembly-parameters-query";
import { Model } from "../../models/db";
import { AssemblyParametersType, getTemplateType } from "../../models/model";
import { getAssemblyParametersDataPvV1 } from "./assembly-parameters-data-pv-v1";
import { getAssemblyParametersDataPvV2 } from "./assembly-parameters-data-pv-v2";
import { DescriptionsItemType } from "antd/es/descriptions";
import { getAssemblyParametersDataHpV1 } from "./assembly-parameters-data-hp-v1";

export const AssemblyParametersData = (props: {
    assemblyType: AssemblyParametersType | undefined,
    version: number | undefined,
    form: FormInstance<Model.AssemblyParameters.Data>,
    operation: AssemblyParametersOperation,
    setOperation: (operation: AssemblyParametersOperation) => void,
    assemblyParametersId?: number,
    projectId?: number,
    previewMode?: boolean,
    setAssemblyParametersData?: (data: Model.AssemblyParameters.Data) => void
}) => {
    const user = useUser();
    const { form, setOperation, operation } = props;

    const info = useAssemblyParametersInfo(props.assemblyParametersId);

    const getAssemblyParametersData = (values: Store) => {
        return {
            id: props.assemblyParametersId,
            type: props.assemblyType,
            name: values.name,
            clientId: values.clientId,
            templateId: values.templateId,
            attrs: values.attrs,
            documents: fileList.map((f: FileData) => ({
                id: f.id,
                fileData: f.fileData,
                filename: f.name,
                documentGroup: f.group
            }))
        } as Model.AssemblyParameters.Data
    }

    const { data: assemblyParameters, loading: assemblyParametersLoading } = Query.AssemblyParameters.use(props.assemblyParametersId);
    const { data: templates, loading: templatesLoading } = Query.AssemblyParameters.Template.use(props.assemblyParametersId, assemblyParameters?.templateId, getTemplateType(props.assemblyType));
    const { data: documents, loading: documentsLoading } = Query.AssemblyParameters.Document.use(props.assemblyParametersId);
    const { data: clients, loading: clientsLoading } = Query.AssemblyParameters.Clients.use(props.assemblyParametersId, props.previewMode);

    const [fileList, setFileList] = useState<FileData[]>([]);
    const [templateVersion, setTemplateVersion] = useState(props.version);

    useEffect(() => {
        if (assemblyParameters && documents) {
            props.setAssemblyParametersData?.({
                ...assemblyParameters,
                documents
            });
            form.setFieldsValue(assemblyParameters);
        }
    }, [assemblyParameters, documents]);

    useEffect(() => {
        if (documents) {
            setFileList(documents.map(doc => ({
                id: doc.id,
                uid: `${doc.id}`,
                name: doc.filename,
                group: doc.documentGroup,
                fileData: '',
                fileName: doc.internalFilename,
                originFileObj: {} as RcFile,
                url: doc.internalFilename
            })));
        }
    }, [documents]);

    const { mutateAsync: saveAssemblyParameters, isLoading: assemblyParametersSaving } = useSaveAssemblyParameters();
    const { mutateAsync: acceptAssemblyParameters, isLoading: assemblyParametersAccepting } = useAcceptAssemblyParameters();
    const { mutateAsync: removeAssemblyParameters, isLoading: assemblyParametersRemoving } = useRemoveAssemblyParameters();

    const acceptWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Akceptacja arkusza wyłączy możliwość jego edycji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const permissions = user.permissions.assemblyParameters(
        props.assemblyParametersId, info.assemblyParametersAccepted, [assemblyParameters?.ownerId]);

    const version = props.version ?? templateVersion;

    const installationLocation = Form.useWatch(['attrs', 'installationLocation'], form);
    const buildingType = Form.useWatch(['attrs', 'buildingType'], form);
    const addressType = Form.useWatch(['attrs', 'addressType'], form);
    const roofType = Form.useWatch(['attrs', 'roofType'], form);
    const roofSheathing = Form.useWatch(['attrs', 'roofSheathing'], form);
    const roofMaterial = Form.useWatch(['attrs', 'roofMaterial'], form);
    const inverterConnection = Form.useWatch(['attrs', 'inverterConnection'], form);
    const attachments = Form.useWatch(['attrs', 'attachments'], form);

    const basement = Form.useWatch(['attrs', 'basement'], form);
    const groundFloor = Form.useWatch(['attrs', 'groundFloor'], form);
    const firstFloor = Form.useWatch(['attrs', 'firstFloor'], form);
    const secondFloor = Form.useWatch(['attrs', 'secondFloor'], form);
    const attic = Form.useWatch(['attrs', 'attic'], form);

    useEffect(() => {
        if (installationLocation === "ground") {
            form.setFieldValue(['attrs', 'roofType'], "freestandig")
        } else if (installationLocation === "roof") {
            form.setFieldValue(['attrs', 'roofType'], undefined);
        }
    }, [installationLocation]);

    let assemblyParametersItems: DescriptionsItemType[] = [];

    const formDisabled = props.previewMode || info.assemblyParametersAccepted;

    if (props.assemblyType === 'photovoltaics') {
        if (version === 1) {
            assemblyParametersItems = getAssemblyParametersDataPvV1(
                formDisabled,
                operation, props.previewMode, fileList, setFileList, buildingType, addressType, roofSheathing, roofMaterial, inverterConnection, attachments
            )
        } else if (version === 2) {
            assemblyParametersItems = getAssemblyParametersDataPvV2(
                form,
                formDisabled,
                operation, props.previewMode, fileList, setFileList,
                installationLocation, buildingType, addressType, roofType, roofSheathing, roofMaterial, inverterConnection, attachments
            )
        }
    } else if (props.assemblyType === 'heat-pump') {
        assemblyParametersItems = getAssemblyParametersDataHpV1(
            operation, props.previewMode, fileList, setFileList, basement, groundFloor, firstFloor, secondFloor, attic
        )
    }

    const items: DescriptionsItemType[] = [{
        key: 'name',
        label: <div className="required-form-item">Nazwa</div>,
        children: <Form.Item
            name="name"
            rules={[{ required: true }]}
        >
            <Input/>
        </Form.Item>
    }, {
        key: 'client',
        label: <div className="required-form-item">Klient</div>,
        children: <Form.Item
            name="clientId"
            rules={[{ required: true }]}
        >
            <ClientSelect clients={clients} canEdit={true}/>
        </Form.Item>
    }, {
        key: 'template',
        label: <div className="required-form-item">Szablon</div>,
        children: <Form.Item
            name="templateId"
            rules={[{ required: true }]}
        >
            <TemplateSelect templates={templates} canEdit={permissions.editTemplate} onChange={(templateId) => {
                setTemplateVersion(templates?.find(t => t.id === templateId)?.version)
            }}/>
        </Form.Item>
    }, ...assemblyParametersItems];

    const content = <Spin spinning={assemblyParametersLoading || documentsLoading || assemblyParametersSaving || assemblyParametersRemoving || clientsLoading || templatesLoading}>
        <Form
            form={form}
            labelCol={{ flex: '150px' }}
            wrapperCol={wrapperCol}
            onValuesChange={() => {
                const values = getAssemblyParametersData(form.getFieldsValue());
                props.setAssemblyParametersData?.(values);
            }}
            onFinish={values => saveAssemblyParameters(getAssemblyParametersData(values))}
            name="client"
            labelWrap={true}
            requiredMark={false}
            validateMessages={validateMessages}
            disabled={formDisabled}
        >
            <Descriptions bordered style={{ backgroundColor: '#fff' }} labelStyle={{ width: 120 }} column={1} items={items}/>
            <FooterGap/>
            {!props.previewMode ?
                <Row className="footer-actions">
                    <Col span={24}>
                    <Space>
                        {props.assemblyParametersId ? <PopoverForm
                            title="Akceptuj"
                            buttonEnabled={permissions.accept && !info.assemblyParametersAccepted}
                            showPopover={!info.assemblyParametersAccepted}
                            loading={assemblyParametersAccepting}
                            onSubmit={() => setOperation('accept')}>
                            {acceptWarning}
                        </PopoverForm> : null}
                        {props.assemblyParametersId ?
                            <RemoveButton
                                disabled={!permissions.remove}
                                onConfirm={() => removeAssemblyParameters(props.assemblyParametersId!)}/> : null}
                        <Button type="primary" disabled={!permissions.save} onClick={() => setOperation('save')}>
                            Zapisz
                        </Button>
                    </Space>
                </Col>
            </Row> : null}
        </Form>
    </Spin>;

    return props.previewMode ? content : <Card style={{ padding: 0, width: "100%" }}>{content}</Card>
}
