export function readSplitterSizes(id: string, defaultSizes?: number[]) {
    const pos = localStorage.getItem(`${id}-splitter-pos`);
    if (pos) {
        return JSON.parse(pos) || defaultSizes;
    }
    return defaultSizes;
}

export function storeSplitterSizes(id: string, sizes: number[]) {
    localStorage.setItem(`${id}-splitter-pos`, JSON.stringify(sizes));
}
