import { Space } from "antd";
import { formatDate } from "../utils/utils";
import React from "react";
import "./last-comment.less";
import { MarkdownText } from "./markdown-text";

export const LastComment = (props: { createdAt?: number, comment: string }) => {
    return <Space direction="vertical" size="small">
        <div style={{ wordBreak: 'break-all', height: 60 }} className="last-comment">
            <MarkdownText text={props.comment}/>
        </div>
        <div style={{ fontSize: 11, color: '#cc7b26' }}>{formatDate(props.createdAt)}</div>
    </Space>
}
