import React, { useEffect, useState } from "react";
import { Button, Card, Col, notification, Row, Spin, Transfer } from "antd";
import { qid, useSqlQuery } from "../../hooks/sql-query.hook";
import { TransferItem } from "antd/lib/transfer";
import { backendPut } from "../../api/backend-api";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation } from "@tanstack/react-query";

export const AssignedUsers = (props: { userId: number }) => {
    const [targetUsers, setTargetUsers] = useState<string[]>([]);
    const [dataSource, setDataSource] = useState<TransferItem[]>([]);

    const { data: assignedUsers, loading: assignedUsersLoading } = useSqlQuery(
        qid`assigned-users-${props.userId}`,
        from(t.userAccess, t.ua)
        .where(t.ua.userId.eq(props.userId))
        .select({
            id: t.ua.accessUserId,
            userId: t.ua.userId
        })
    );

    const { data: allUsers, loading: allUsersLoading } = useSqlQuery(
        `all-users`,
        from(t.user, t.u)
        .orderBy(t.u.lastName)
        .orderBy(t.u.firstName)
        .select({
            id: t.u.id,
            username: t.u.username,
            firstName: t.u.firstName,
            lastName: t.u.lastName,
            ownerId: t.u.ownerId
        })
    );

    useEffect(() => {
        if (assignedUsers) {
            setTargetUsers(assignedUsers.map(u => u.id.toString()));
        }
    }, [assignedUsers]);

    useEffect(() => {
        if (allUsers) {
            setDataSource(allUsers.map(u => ({
                key: u.id.toString(),
                title: `${u.firstName} ${u.lastName}`,
            })));
        }
    }, [allUsers]);

    const handleChange = (nextTargetKeys: string[]) => {
        setTargetUsers(nextTargetKeys);
    };

    const { mutateAsync: updateAssignedUsers, isLoading: assignedUserSaving } = useMutation(
        async ({ userId, assignedUsersIds }: { userId: number, assignedUsersIds: number[] }) => {
            await backendPut('/users/assigned-users', {
                userId,
                assignedUsersIds
            });
            notification.success({
                message: 'Uaktualniono przypisania'
            });
        }
    );

    return <Card style={{ padding: 0, width: 700 }}>
        <Spin spinning={allUsersLoading || assignedUsersLoading || assignedUserSaving}>
            <Row style={{ marginBottom: 24 }}>
                <Col span={24}>
                    <Transfer
                        titles={["Dostępni użytkownicy", "Przypisani użytkownicy"]}
                        style={{ justifyContent: "center" }}
                        dataSource={dataSource}
                        showSearch
                        targetKeys={targetUsers}
                        onChange={handleChange}
                        listStyle={{
                            width: 350,
                            height: 400,
                        }}
                        render={item => item.title || '-'}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit" onClick={
                        () => updateAssignedUsers({
                            userId: props.userId,
                            assignedUsersIds: targetUsers.map(id => parseInt(id))
                        })
                    }>
                        Zapisz
                    </Button>
                </Col>
            </Row>
        </Spin>
    </Card>
}
