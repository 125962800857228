import { DashboardCard } from "../dashboard-card";
import React from "react";
import { sql, useSqlQueryRaw } from "../../../hooks/sql-query.hook";
import { Timeline } from "antd";
import { formatDatetime } from "../../../utils/utils";
import { UserRole } from "../../../models/user";

type Data = { name: string, dateStart: number }

export const UpcomingEvents = () => {
    const { data: events } = useSqlQueryRaw<Data>('upcoming-events', sql`
        select ica.name, ica.date_start
          from installation_calendar ica
          join "user" u on u.id = ica.owner_id
         where ica.deleted_at is null and ica.date_start >= current_timestamp
           and u.role not in (${UserRole.TRADER}, ${UserRole.MANAGER}, ${UserRole.DESIGNER}, ${UserRole.COORDINATOR})
         order by ica.date_start
         limit 5
    `);

    return <DashboardCard title="Nadchodzące wydarzenia" key="upcoming-events">
        <Timeline
            items={events?.map(event => ({
                children: <>
                    <span style={{ color: "gray" }}>{formatDatetime(event.dateStart, false)}</span> {event.name}
                </>
            }))}
        />
    </DashboardCard>
}
