import React, { useMemo, useRef } from "react";
import { Table, Typography } from "antd";
import { appStore } from "../store/store";
import { Gap } from "./gap";
import { ListState } from "../store/state";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useResizeObserver, useResizeObserverRef } from "../hooks/resize-observer.hook";

export const TableView = (props: {
    toolbar?: React.ReactNode, total: number, loading: boolean, scrollWidth: number
    columns: any[], data: any[] | undefined,
    listKey: keyof ListState,
    onFiltersChange?: (filters: Record<string, FilterValue | null>) => void,
    summary?: React.ReactNode,
    style?: React.CSSProperties
}) => {
    const filter = appStore.useState(s => s[props.listKey]);
    const gapHeight = 10;
    const viewKey = `table-view-${props.listKey}`;
    const tableViewRef = useRef(null);
    const scrollSize = useResizeObserverRef(tableViewRef);
    const headerSize = useResizeObserver(`.${viewKey} .ant-table-header`, tableViewRef.current);
    const footerSize = useResizeObserver(`.${viewKey} .ant-table-footer`, tableViewRef.current);
    const paginationSize = useResizeObserver(`.${viewKey} .ant-table-pagination`, tableViewRef.current);

    const paginationHeight = paginationSize.height + paginationSize.marginTop + paginationSize.marginBottom;
    const footerHeight = footerSize.height + footerSize.marginTop + footerSize.marginBottom + footerSize.paddingTop + footerSize.paddingBottom;

    const columns = useMemo(() => {
        return props.columns.map(c => {
            if (c.key === 'action') {
                return c;
            }
            const col = {...c, sorter: true };
            if (filter.sortColumn === c.dataIndex) {
                col.defaultSortOrder = filter.sortDirection > 0 ? "ascend" : "descend";
            }
            return col;
        });
    }, [props.columns])

    return <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', ...props.style }} className={viewKey}>
        {props.toolbar ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {props.toolbar}
        </div> : null}
        <Gap height={gapHeight}/>
        <div style={{ flexGrow: 1, overflow: "hidden", height: scrollSize.height }} ref={tableViewRef}>
            <Table bordered={true} columns={columns} dataSource={props.data} loading={props.loading}
                locale={{
                    // emptyText: <div className="ant-table-content"/>
                }}
                className="table-view"
                style={{ height: Math.max(0, scrollSize.height - paginationHeight - footerHeight) }}
                onChange={(pagination, filters, sorter) => {
                    appStore.update(s => {
                        s[props.listKey].currentPage = pagination.current! - 1;
                        s[props.listKey].pageSize = pagination.pageSize ?? 0;
                        s[props.listKey].sortColumn = (sorter as SorterResult<any>)?.field as string ?? null;
                        s[props.listKey].sortDirection = (sorter as SorterResult<any>)?.order === "ascend" ? 1 : -1;
                        if (props.onFiltersChange) {
                            props.onFiltersChange(filters);
                        }
                    });
                }} pagination={{
                    total: props.total || 1,
                    pageSize: filter.pageSize,
                    showSizeChanger: true,
                    hideOnSinglePage: false,
                    style: { paddingTop: footerHeight },
                    showTotal: total => <Typography.Text style={{ marginRight: 10 }}>Liczba rekordów: {props.total}</Typography.Text>
                }} scroll={{
                    x: props.scrollWidth,
                    y: Math.max(0, scrollSize.height - headerSize.height - paginationHeight - footerHeight)
                }}
                tableLayout="fixed"
                footer={props.summary ? () => props.summary : undefined}
            />
        </div>
    </div>
}
