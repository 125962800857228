import { Button, Select, Space, Tooltip } from "antd";
import { filterBySearch } from "../../utils/utils";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "../icons";
import { useUser } from "../../hooks/user.hook";
import { VisualisationItem } from "./visualisation-item";
import { baseUrl } from "../../utils/navigation";

export const VisualisationSelect = (
    { visualisations, canEdit, ...props }: {
        visualisations: { id: number, name: string }[] | undefined,
        canEdit: boolean
    }) => {
    const { permissions } = useUser();
    const navigate = useNavigate();
    const value = (props as any).value;
    return <Space className="select">
        <Select disabled={!canEdit} showSearch={true}
            allowClear={true} filterOption={filterBySearch} {...props}>
            {visualisations?.map(v => <Select.Option key={v.id} value={v.id} search={v.name + v.id}>
                <VisualisationItem id={v.id} name={v.name}/>
            </Select.Option>)}
        </Select>
        {permissions.panel.visualisations ? <Tooltip title="Przejdź do wizualizacji" placement="topLeft">
            <Button disabled={!value} icon={<ArrowRightOutlined/>} onClick={() => navigate(baseUrl(`/visualisations/${value}`))}/>
        </Tooltip> : null}
    </Space>
}
