import { DynamicContent } from "../../../components/dynamic-content";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";
import { DashboardCard } from "../dashboard-card";
import React from "react";
import { sql, useSqlQueryRaw } from "../../../hooks/sql-query.hook";
import { capitalize } from "lodash";
import { CustomTooltip } from "../custom-tooltip";

type Data = { month: number, newClients: number }

export const NewClients = () => {
    const { data: clients } = useSqlQueryRaw<Data>('clients-by-month', sql`
        with periods as (
            select d as period_start,(d + '1 month'::interval - '1 microsecond'::interval) period_end
              from generate_series(
                date_trunc('month', now()) - interval '2 months',
                date_trunc('month', now()), '1 month'::interval
              ) d
        )
        select extract(month from p.period_start) - 1 as month, count(client.id) as new_clients
         from periods p
         left join client
           on created_at between p.period_start and p.period_end and deleted_at is null
         group by 1
         order by 1
    `);

    return <DashboardCard title="Nowi klienci" key="clients">
        <DynamicContent>
            {(width, height) => {
                return <BarChart width={width} height={height} data={clients?.map(c => ({
                    ...c,
                    month: capitalize(dayjs.months()[c.month]),
                }))}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#129a74" stopOpacity={0.5}/>
                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.5}/>
                        </linearGradient>
                    </defs>
                    <XAxis name={"Miesiąc"} dataKey={"month"}/>
                    <YAxis allowDecimals={false}/>
                    <Tooltip
                        cursor={{ fill: "rgba(0, 0, 0, 0.05)"}}
                        content={<CustomTooltip<Data> text={data => `${data.month}: ${data.newClients}`}/>}
                    />
                    <CartesianGrid strokeDasharray="3 3"/>
                    <Bar dataKey={"newClients"} stroke="#006991" strokeWidth={1} fillOpacity={1} fill="url(#colorUv)"/>
                </BarChart>
            }}
        </DynamicContent>
    </DashboardCard>
}
