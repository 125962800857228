import { useResizeObserver } from "../../../hooks/resize-observer.hook";
import { useUser } from "../../../hooks/user.hook";
import React, { useEffect, useState } from "react";
import { AbsoluteLayout, GridLayout } from "../../../components/absolute-layout/absolute-layout";
import { Alert } from "antd";
import { NewClients } from "./new-clients";
import { NewAgreements } from "./new-agreements";
import { RealisationStatuses } from "./realisation-statuses";
import { InvoicesPayments } from "./invoices-payments";
import { UpcomingEvents } from "./upcomins-events";
import { getGridCellPos, gridSize } from "../dashboard-utils";
import { ClientsToAccept } from "./clients-to-accept";

export const AdminDashboard = (props: { editing: boolean, dashboardLayout: GridLayout | undefined, onUpdateDashboardLayout: (layout: GridLayout) => void }) => {
    const contentSize = useResizeObserver("#app-content-body");
    const { user, admin } = useUser();

    const [layout, setLayout] = useState<GridLayout>([]);

    useEffect(() => {
        if (props.dashboardLayout) {
            setLayout([{
                ...getGridCellPos('greeting', props.dashboardLayout, 0, 0, 10, 2),
                key: 'greeting',
                component: <Alert message={`Witaj ${user.firstName}!`} type="success" key="greeting"/>
            }, {
                ...getGridCellPos('clients', props.dashboardLayout, 0, 3, 20, 14),
                key: 'clients',
                component: <NewClients/>
            }, {
                ...getGridCellPos('realisation-statuses', props.dashboardLayout, 0, 18, 20, 14),
                key: 'realisation-statuses',
                component: <RealisationStatuses/>
            }, {
                ...getGridCellPos('invoices-payments', props.dashboardLayout, 21, 18, 20, 14),
                key: 'invoices-payments',
                component: <InvoicesPayments/>
            }, {
                ...getGridCellPos('upcoming-events', props.dashboardLayout, 0, 33, 20, 14),
                key: 'upcoming-events',
                component: <UpcomingEvents/>
            }, {
                ...getGridCellPos('agreements', props.dashboardLayout, 21, 3, 20, 14),
                key: 'agreements',
                component: <NewAgreements/>
            }, {
                ...getGridCellPos('clients-to-accept', props.dashboardLayout, 21, 33, 30, 25),
                key: 'clients-to-accept',
                component: <ClientsToAccept/>
            }])
        }
    }, [props.dashboardLayout]);

    return <AbsoluteLayout editing={props.editing}
        pinning={false}
        width={contentSize.width}
        height={contentSize.height}
        snapToGrid={gridSize}
        snapDist={gridSize}
        snapToMargins={0}
        showGrid={props.editing}
        showPins={false}
        showSnappingLines={false}
        colWidth={gridSize}
        rowHeight={gridSize}
        layout={layout}
        onUpdateLayout={(layout) => {
            setLayout(layout);
            props.onUpdateDashboardLayout(layout);
        }}
    />;
}
