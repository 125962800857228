import { backendGetBlob } from "./backend-api";
import { downloadBlob } from "../utils/utils";

export namespace ExportService {

    export function exportOfferCalculator(offerId: number) {
        return backendGetBlob(`/export/offer-calculator/${offerId}?ts=${Date.now()}`)
        .then(response => downloadBlob(response))
    }

    export function exportOffer(offerId: number) {
        return backendGetBlob(`/export/offer-template/${offerId}?ts=${Date.now()}`)
        .then(response => downloadBlob(response))
    }

    export function exportAgreement(agreementId: number) {
        return backendGetBlob(`/export/agreement-template/${agreementId}?ts=${Date.now()}`)
        .then(response => downloadBlob(response))
    }

    export function exportAssemblyParameters(assemblyParametersId: number) {
        return backendGetBlob(`/export/assembly-parameters-template/${assemblyParametersId}?ts=${Date.now()}`)
        .then(response => downloadBlob(response))
    }

}
