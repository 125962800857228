import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, Space, Spin } from "antd";
import { useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { useForm } from "antd/lib/form/Form";
import { validateMessages } from "../../utils/validators";
import { emailValidator, nipValidator, peselValidator, regonValidator } from "../../utils/form-validators";
import { RemoveButton } from "../../components/remove-button";
import { useUser } from "../../hooks/user.hook";
import { from } from "../../utils/sql-builder";
import { Model, t } from "../../models/db";
import { FooterGap } from "../../components/gap";
import { labelCol, wrapperCol } from "../../models/ui";
import { useAcceptClient, useRemoveClient, useSaveClient } from "./client.hook";
import { ClientType } from "../../models/model";
import { useQueryClient } from "@tanstack/react-query";

const CommonForm = () => {
    return <>
        <Form.Item
            label="EMail:"
            name="email"
            rules={[
                { required: false },
                { validator: emailValidator }
            ]}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Telefon:"
            name="phoneNumber"
            rules={[
                { required: false }
            ]}
        >
            <Input/>
        </Form.Item>
    </>
}

const AddressForm = () => {
    return <>
        <Form.Item
            label="Miasto"
            name={t.cl.city.name}
            rules={[{ required: true }]}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Kod pocztowy"
            name={t.cl.postCode.name}
            rules={[{ required: true }]}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Ulica"
            name={t.cl.address.name}
            rules={[{ required: true }]}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Poczta"
            name={t.cl.post.name}
            rules={[{ required: true }]}
        >
            <Input/>
        </Form.Item>
    </>
}

const PersonForm = ({ clientId }: { clientId?: number }) => {
    return <>
        <Form.Item
            label="Imię:"
            name={t.cl.firstName.name}
            rules={[{ required: true }]}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Nazwisko:"
            name={t.cl.lastName.name}
            rules={[{ required: true }]}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="PESEL:"
            name={t.cl.pesel.name}
            hasFeedback
            rules={[
                { required: false },
                { validator: (rule, value) => peselValidator(clientId, rule, value) }
            ]}
        >
            <Input/>
        </Form.Item>
        <CommonForm/>
        <AddressForm/>
    </>
}

const CompanyForm = ({ clientId, clientAccepted, acceptedNip }: { clientId?: number, clientAccepted?: boolean, acceptedNip?: string }) => {
    return (
        <>
            <Form.Item
                label="Nazwa:"
                name={t.cl.name.name}
                rules={[{ required: true }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="NIP:"
                name={t.cl.nip.name}
                rules={[
                    { required: true },
                    {
                        warningOnly: true,
                        validator: (rule, value) => nipValidator(clientId, clientAccepted, acceptedNip, rule, value)
                    }
                ]}
                hasFeedback
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="REGON:"
                name={t.cl.regon.name}
                rules={[
                    { validator: regonValidator }
                ]}
                hasFeedback
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="KRS:"
                name={t.cl.courtRegister.name}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Sąd (miasto):"
                name={t.cl.courtCity.name}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Sąd (wydział):"
                name={t.cl.courtDepartment.name}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Reprezentant spółki 1:"
                name={t.cl.companyRepresentative1.name}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Reprezentant spółki 2:"
                name={t.cl.companyRepresentative2.name}
            >
                <Input/>
            </Form.Item>
            <CommonForm/>
            <AddressForm/>
        </>
    )
}

export const ClientData = (props: { clientId?: number }) => {
    const user = useUser();
    const queryClient = useQueryClient();

    const { data: client, loading: clientLoading } = useSqlQuerySingle(
        `client-${props.clientId}`,
        from(t.client, t.cl)
        .where(t.cl.id.eq(props.clientId))
        .select({
            id: t.cl.id,
            firstName: t.cl.firstName,
            lastName: t.cl.lastName,
            ownerId: t.cl.ownerId,
            createdAt: t.cl.createdAt,
            updatedAt: t.cl.updatedAt,
            acceptedAt: t.cl.acceptedAt,
            pesel: t.cl.pesel,
            nip: t.cl.nip,
            acceptedNip: t.cl.acceptedNip,
            regon: t.cl.regon,
            courtRegister: t.cl.courtRegister,
            courtCity: t.cl.courtCity,
            courtDepartment: t.cl.courtDepartment,
            companyRepresentative1: t.cl.companyRepresentative1,
            companyRepresentative2: t.cl.companyRepresentative2,
            name: t.cl.name,
            clientType: t.cl.clientType,
            city: t.cl.city,
            postCode: t.cl.postCode,
            address: t.cl.address,
            post: t.cl.post,
            email: t.cl.email,
            phoneNumber: t.cl.phoneNumber
        }), {
            enabled: props.clientId
        }
    );
    const [clientType, setClientType] = useState<ClientType | null>(null);

    const [form] = useForm<Model.Client.Data>();

    useEffect(() => {
        if (client) {
            form.setFieldsValue(client);
            setClientType(client.clientType);
        }
    }, [client]);

    const { mutateAsync: saveClient, isLoading: clientSaving } = useSaveClient();
    const { mutateAsync: acceptClient, isLoading: clientAccepting } = useAcceptClient();
    const { mutateAsync: removeClient, isLoading: clientRemoving } = useRemoveClient();

    const permissions = user.permissions.client(props.clientId, [client?.ownerId]);

    return <Card style={{ padding: 0 }}>
        <Spin spinning={clientLoading || clientSaving || clientRemoving}>
            <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onFinish={values => saveClient({ ...values, id: props.clientId })}
                name="client"
                validateMessages={validateMessages}
            >
                <Form.Item label="Rodzaj klienta:" name="clientType">
                    <Select onChange={value => setClientType(value as number)}>
                        <Select.Option value={ClientType.PERSON}>
                            Osoba fizyczna
                        </Select.Option>
                        <Select.Option value={ClientType.COMPANY}>
                            Firma
                        </Select.Option>
                    </Select>
                </Form.Item>

                {clientType === ClientType.PERSON && <PersonForm clientId={props.clientId}/>}
                {clientType === ClientType.COMPANY && <CompanyForm clientId={props.clientId} clientAccepted={!!client?.acceptedAt} acceptedNip={client?.nip}/>}

                <FooterGap/>
                <Row className="footer-actions">
                    <Col span={24}>
                        <Space>
                            {props.clientId ? <RemoveButton disabled={!permissions.remove} onConfirm={() => removeClient(props.clientId!)}/> : null}
                            {props.clientId && !client?.acceptedAt && permissions.accept
                                ? <Button onClick={async () => {
                                    await acceptClient(props.clientId!)
                                    void queryClient.invalidateQueries([`client-${props.clientId}`]);
                                }} loading={clientAccepting}>Akceptuj</Button>
                                : null}
                            <Button type="primary" htmlType="submit" disabled={!permissions.save}>
                                Zapisz
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </Card>
}
