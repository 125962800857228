import React, { useEffect, useRef } from "react";
import Editor, { loader, Monaco } from "@monaco-editor/react";
import { editor } from "monaco-editor/esm/vs/editor/editor.api.js";
import { Tabs } from "antd";
import { MarkdownText } from "./markdown-text";

const MARKDOWN_LANG_ID = "markdown";

interface MarkdownEditorProps {
    value?: string | null,
    onChange?: (value: string) => void,
    height?: string | number,
    preview?: boolean,
    previewAsDefault?: boolean,
    canEdit?: boolean,
    canEditView?: boolean
}

export const MarkdownEditor = ({ value, onChange, height, preview = false, previewAsDefault = false, canEdit = true, canEditView = true }: MarkdownEditorProps) => {
    const editorRef = useRef<editor.IStandaloneCodeEditor>();
    const monacoRef = useRef<Monaco>();

    useEffect(() => {
        loader.init().then(monaco => (monacoRef.current = monaco));
    }, []);

    const editor = <Editor
        className={"markdown-editor" + (preview ? " markdown-editor-preview" : "") }
        onMount={editor => {
            editorRef.current = editor
            editorRef.current.onDidChangeModelContent(() => {
                const value = editorRef.current?.getValue();
                if (onChange) {
                    onChange(value ?? '');
                }
            });
        }}
        height={height}
        language={MARKDOWN_LANG_ID}
        value={value ?? ''}
        theme={MARKDOWN_LANG_ID}
        options={{
            lineNumbers: "off",
            minimap: {
                enabled: false
            },
            scrollbar: {
                vertical: "hidden",
                horizontal: "hidden"
            },
            renderLineHighlight: "none",
            glyphMargin: false,
            folding: false,
            fontSize: 13,
            lineDecorationsWidth: 0,
            lineNumbersMinChars: 0,
            wordWrap: "on",
            suggestOnTriggerCharacters: true,
            padding: {
                top: 0,
                bottom: 0
            },
            fixedOverflowWidgets: true
        }}
    />;

    const markdown = <MarkdownText text={value ?? ''} updateText={text => {
        onChange?.(text);
    }} canEdit={canEditView}/>;

    return (
        canEdit ? (
            preview ? <Tabs type="card" tabPosition="bottom" tabBarStyle={{ marginTop: 0 }}
                defaultActiveKey={previewAsDefault ? "preview" : "editor"}
                items={[{
                    label: "Tekst",
                    key: "editor",
                    children: editor
                }, {
                    label: "Podgląd",
                    key: "preview",
                    style: {
                        height: height,
                        border: '1px solid #f0f0f1',
                        borderBottom: preview ? 'none' : '1px solid #f0f0f1'
                    },
                    children: markdown
                }]}
            /> : editor
        ) : markdown
    )
}

export function initMarkdownLanguage(monaco: Monaco) {
    monaco.languages.register({
        id: MARKDOWN_LANG_ID
    });
    monaco.editor.defineTheme(MARKDOWN_LANG_ID, {
        base: "vs",
        inherit: true,
        rules: [],
        colors: {}
    })
}
