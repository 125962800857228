import React, { useState } from "react";
import { Button, Checkbox, Divider, Popover, Select, Space, Switch, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../hooks/user.hook";
import { CalculatorData } from "./calculator-data";
import { CalculatorLayout } from "./calculator-layout";
import { CalculatorDicts } from "./calculator-dicts";
import { baseUrl } from "../../utils/navigation";
import { qid, useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { backendPost } from "../../api/backend-api";
import { useCalculatorStatus } from "../../hooks/calculator-status.hook";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { allRoles, fieldRoles, UserRole } from "../../models/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

type LayoutTab = 'data' | 'layout' | 'dictionaries';


export const CalculatorEdit = (props: { tab?: LayoutTab }) => {
    const { id } = useParams();
    const calculatorId = parseInt(id ?? "") || undefined;
    const { admin } = useUser();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);
    const [layoutMode, setLayoutMode] = useState(0);
    const [fieldIdMode, setFieldIdMode] = useState(false);
    const [fieldInfoMode, setFieldInfoMode] = useState(false);
    const [roles, setRoles] = useState<UserRole[]>(allRoles);
    const queryClient = useQueryClient();

    const { data: parentCalculator, loading: parentCalculatorLoading } = useSqlQuerySingle(
        qid`parent-calculator-${calculatorId}`,
        from(t.calculator, t.c)
        .where(t.c.id.eq(calculatorId))
        .and(t.c.deletedAt.isNull())
        .select({
            id: t.c.parentId.nvl(t.c.id),
            ownerId: t.c.ownerId
        }), {
            enabled: calculatorId
        }
    );

    const { data: calculators, loading: calculatorLoading } = useSqlQuery(
        qid`calculator-versions-${calculatorId}`,
        from(t.calculator, t.c)
        .where(t.c.parentId.eq(parentCalculator?.id))
        .and(t.c.deletedAt.isNull())
        .orderByDesc(t.c.createdAt)
        .select({
            id: t.c.id,
            createdAt: t.c.createdAt,
            acceptedAt: t.c.acceptedAt,
            ownerId: t.c.ownerId
        }), {
            enabled: parentCalculator?.id
        }
    );

    const { calculatorAccepted, calculatorStatusLoading } = useCalculatorStatus(calculatorId);

    const { mutateAsync: saveVersion, isLoading: savingVersion } = useMutation((calculatorId: number) => {
       return backendPost(`/calculators/${calculatorId}/copy`)
           .then(() => queryClient.invalidateQueries([qid`calculator-versions-${calculatorId}`]));
    });

    const { mutateAsync: makePrimary, isLoading: makingPrimary } = useMutation((calculatorId: number) => {
       return backendPost(`/calculators/${calculatorId}/make-primary`)
    });

    return <Tabs defaultActiveKey="data" activeKey={props.tab}
        onChange={tab => {
            navigate(baseUrl(`/calculators/${calculatorId}/${tab}`));
            setCurrentTab(tab as LayoutTab);
        }}
        tabBarExtraContent={currentTab === "layout" ? {
            right: <Space>
                {layoutMode == 1 ? <Checkbox
                    checked={fieldIdMode}
                    onChange={() => setFieldIdMode(!fieldIdMode)}
                >Id</Checkbox> : null}
                {layoutMode == 1 ? <Checkbox
                    checked={fieldInfoMode}
                    onChange={() => setFieldInfoMode(!fieldInfoMode)}
                >Info</Checkbox> : null}
                {!calculatorAccepted && !calculatorStatusLoading ? <Switch
                    checkedChildren={"Tryb testowy"}
                    unCheckedChildren={"Tryb projektowy"}
                    style={{width: 130}}
                    checked={layoutMode == 1}
                    onChange={checked => setLayoutMode(checked ? 1 : 0)}
                /> : null}
                {!calculatorAccepted && !calculatorStatusLoading ? <Popover
                    placement="bottomLeft"
                    content={<Checkbox.Group options={fieldRoles} defaultValue={allRoles} style={{ width: 140 }}
                    onChange={values => {
                        setRoles(values as UserRole[]);
                    }}/>}
                ><Button style={{width: 80}} size="small">Role</Button></Popover> : null}
                <Select
                    style={{ width: 170 }} size="small"
                    loading={calculatorLoading || parentCalculatorLoading || savingVersion || makingPrimary}
                    disabled={calculatorLoading || parentCalculatorLoading || savingVersion || makingPrimary}
                    onChange={calculatorId => {
                        queryClient.invalidateQueries([`calculator-items-${calculatorId}`]).then(() => {
                            navigate(baseUrl(`/calculators/${calculatorId}/layout`));
                        });
                    }}
                    value={calculatorId}
                    popupMatchSelectWidth={false}
                    dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                <Space direction="vertical">
                                    <Button size="small" disabled={calculatorAccepted} onClick={() => saveVersion(calculatorId!)}>Zapisz wersję</Button>
                                    <Button size="small" disabled={calculatorAccepted} onClick={() => makePrimary(calculatorId!)}>Ustaw jako aktualny</Button>
                                </Space>
                            </div>
                        </div>
                    )}
                >
                    <Select.Option value={parentCalculator?.id ?? -1}>Wersja aktualna</Select.Option>
                    {calculators?.map(c => (
                        <Select.Option key={c.id} value={c.id}>Kopia z {dayjs(c.createdAt).format("DD.MM.YYYY HH:mm")}</Select.Option>
                    ))}
                </Select>
            </Space>
        } : undefined}
        items={[{
            label: "Dane kalkulatora",
            key: "data",
            children: <CalculatorData calculatorId={calculatorId}/>
        }, {
            label: "Układ kalkulatora",
            key: "layout",
            disabled: !calculatorId || !admin,
            children: <CalculatorLayout calculatorId={calculatorId}
                layoutMode={layoutMode} roles={roles} fieldIdMode={fieldIdMode} fieldInfoMode={fieldInfoMode}/>
        }, {
            label: "Słowniki",
            key: "dictionaries",
            disabled: !calculatorId || !admin,
            children: <CalculatorDicts calculatorId={calculatorId}/>
        }]}
    />
}
