import { ListCriteria } from "../store/state";
import { FilterValue } from "antd/lib/table/interface";
import { QueryFieldBase } from "./sql-builder";
import { isNumber } from "lodash";

export function getListCriteria(searchFields: QueryFieldBase<any>[], dateField: QueryFieldBase<any>, deleteField: QueryFieldBase<any> | null, filter: ListCriteria) {
    let where = deleteField ? `${deleteField?.qualifiedName} is null` : "1 = 1";
    if (filter.search) {
        where += ` and concat(${searchFields.map(sf => sf.qualifiedName).join(', ')}) ilike '%${filter.search}%'`;
    }
    if (filter.periodFrom) {
        where += ` and ${dateField.qualifiedName} >= '${filter.periodFrom.startOf('month').format('YYYY/MM/DD HH:mm:ss')}'`;
    }
    if (filter.periodTo) {
        where += ` and ${dateField.qualifiedName} <= '${filter.periodTo.endOf('month').format('YYYY/MM/DD HH:mm:ss')}'`;
    }
    return where;
}

export function withLikeCriteria(field: QueryFieldBase<any>, value: string | number | null) {
    return value ? ` and ${field.qualifiedName} ilike '%${value}%'` : "";
}

export function withEqualCriteria(field: QueryFieldBase<any>, value: string | number | null) {
    const v = typeof value === 'string' ? `'${value}'` : value;
    return value ? ` and ${field.qualifiedName} = ${v}` : "";
}

export function withInCriteria(field: QueryFieldBase<any>, value: number[] | undefined) {
    return value && value.length > 0 ? ` and ${field.qualifiedName} in (${value.join(', ')})` : "";
}

export function withFilterValueCriteria(field: QueryFieldBase<any>, value: FilterValue | null) {
    return value && value.length > 0 ? ` and ${field.qualifiedName} in (${value.map(v => isNumber(v) ? v : `'${v}'`).join(', ')})` : "";
}
