import React, { useEffect } from "react";
import { Button, Card, Checkbox, Col, Form, Input, notification, Row, Space, Spin } from "antd";
import { qid, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { useForm } from "antd/lib/form/Form";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { validateMessages } from "../../utils/validators";
import { RemoveButton } from "../../components/remove-button";
import { useUser } from "../../hooks/user.hook";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation } from "@tanstack/react-query";
import { FooterGap } from "../../components/gap";
import { labelCol, wrapperCol } from "../../models/ui";

export const InstallationCrewData = (props: { installationCrewId?: number }) => {
    const user = useUser();

    const { data: installationCrew, loading: installationCrewLoading } = useSqlQuerySingle(
        qid`installation-crew-${props.installationCrewId}`,
        from(t.installationCrew, t.ic)
        .where(t.ic.id.eq(props.installationCrewId))
        .select({
            id: t.ic.id,
            name: t.ic.name,
            crew: t.ic.crew,
            active: t.ic.active,
            ownerId: t.ic.ownerId,
            createdAt: t.ic.createdAt,
            updatedAt: t.ic.updatedAt,
        }), {
            enabled: props.installationCrewId
        }
    );
    const [form] = useForm<typeof installationCrew>();
    const navigate = useNavigate();

    useEffect(() => {
        if (installationCrew) {
            form.setFieldsValue(installationCrew);
        }
    }, [installationCrew]);

    const { mutateAsync: saveInstallationCrew, isLoading: installationCrewSaving } = useMutation((installationCrew: any) => {
        return (props.installationCrewId
            ? backendPut(`/installation-crews/${props.installationCrewId}`, installationCrew)
            : backendPost('/installation-crews', installationCrew)
        ).then((clientId) => {
            notification.success({
                message: 'Ekipa montażowa został zapisana'
            });
            if (clientId) {
                navigate(baseUrl(`/installation-crews/${clientId}/data`));
            }
        })
    });

    const { mutateAsync: removeInstallationCrew, isLoading: installationCrewRemoving } = useMutation((installationCrewId: number) => {
        return backendDelete(`/installation-crews/${installationCrewId}`)
            .then(() => {
                notification.success({
                    message: 'Ekipa montażowa została usunięta'
                });
                navigate(baseUrl("/installation-crews"));
            })
    });

    const permissions = user.permissions.client(props.installationCrewId, [installationCrew?.ownerId]);

    return <Card style={{ padding: 0 }}>
        <Spin spinning={installationCrewLoading || installationCrewSaving || installationCrewRemoving}>
            <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onFinish={values => saveInstallationCrew(values)}
                name="client"
                validateMessages={validateMessages}
            >
                <Form.Item label="Nazwa"
                    name="name"
                    rules={[{ required: true }]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Skład"
                    name="crew">
                    <Input.TextArea rows={10}/>
                </Form.Item>

                <Form.Item
                    label="Aktywna"
                    name="active"
                    valuePropName="checked"
                    initialValue={false}
                    rules={[{ required: true }]}
                >
                    <Checkbox/>
                </Form.Item>

                <FooterGap/>
                <Row className="footer-actions">
                    <Col span={24}>
                        <Space>
                            {props.installationCrewId ? <RemoveButton disabled={!permissions.remove} onConfirm={() => removeInstallationCrew(props.installationCrewId!)}/> : null}
                            <Button type="primary" htmlType="submit" disabled={!permissions.save}>
                                Zapisz
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </Card>
}
