// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.protected-img {
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;
  object-fit: contain;
  transition: border-color 0.3s;
  position: relative;
  text-align: center;
}
.protected-img a {
  object-fit: contain;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}
.protected-img a img {
  object-fit: contain;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s;
}
.protected-img:hover {
  border-color: #1890ff;
}
.protected-img:hover .actions {
  opacity: 1;
}
.protected-img:hover img {
  opacity: 0.5;
}
.protected-img .actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
  background: transparent;
}
.protected-img .no-preview {
  text-align: center;
  display: flex;
  height: 100%;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./web/components/protected-img.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;EACA,6BAAA;EACA,kBAAA;EACA,kBAAA;AACF;AAVA;EAYI,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;AACJ;AAjBA;EAmBM,mBAAA;EACA,WAAA;EACA,UAAA;EACA,wBAAA;AACN;AAGE;EACE,qBAAA;AADJ;AAAE;EAGI,UAAA;AAAN;AAHE;EAMI,YAAA;AAAN;AAhCA;EAqCI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,mBAAA;EACA,gCAAA;EACA,UAAA;EACA,oBAAA;EACA,uBAAA;AAFJ;AA3CA;EAiDI,kBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;AAHJ","sourcesContent":[".protected-img {\n  width: 100%;\n  height: 100%;\n  border: 1px solid #d9d9d9;\n  border-radius: 8px;\n  padding: 8px;\n  object-fit: contain;\n  transition: border-color 0.3s;\n  position: relative;\n  text-align: center;\n\n  a {\n    object-fit: contain;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    display: block;\n\n    img {\n      object-fit: contain;\n      width: 100%;\n      opacity: 1;\n      transition: opacity 0.3s;\n    }\n  }\n\n  &:hover {\n    border-color: #1890ff;\n    .actions {\n      opacity: 1;\n    }\n    img {\n      opacity: 0.50;\n    }\n  }\n\n  .actions {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    z-index: 10;\n    white-space: nowrap;\n    transform: translate(-50%, -50%);\n    opacity: 0;\n    transition: all 0.3s;\n    background: transparent;\n  }\n\n  .no-preview {\n    text-align: center;\n    display: flex;\n    height: 100%;\n    align-items: center\n  }\n}\n\n@layout-header-background: #1890ff;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
