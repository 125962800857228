import { Breadcrumb, Button, Form, Input, Select, Tooltip } from "antd";
import { backendPost } from "../../api/backend-api";
import React, { useEffect, useState } from "react";
import { CalculatorData, FieldType, LayoutItem } from "./layout-types";
import { sql, useSqlQueryRaw } from "../../hooks/sql-query.hook";
import { getGridCellPos, isItemVisible, makeElementStyle } from "./layout-utils";
import { AbsoluteLayout, GridCell } from "../../components/absolute-layout/absolute-layout";
import { formatMoney, getFieldContent, round } from "../../utils/utils";
import Axios from "axios";
import { useDebouncedEffect } from "../../hooks/debaunce.hook";
import groupBy from "lodash/groupBy";
import values from "lodash/values";
import isNaN from "lodash/isNaN";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { useForm } from "antd/lib/form/Form";
import "./calculator-evaluator.less";
import { numberToUserRoles } from "../../utils/converters";
import { UserRole } from "../../models/user";
import { EvaluationError, EvaluationFieldValue, EvaluationResult, getEvaluatedFieldValue } from "../../models/model";
import classNames from "classnames";

interface CalculatorEvaluatorProps {
    calculatorId?: number;
    width: number;
    height: number;
    calculatorData?: CalculatorData | null;
    roles: UserRole[];
    debugData?: boolean;
    onChange?: (values: CalculatorData) => void;
    loading?: boolean;
    disabled?: boolean;
    fieldId?: boolean;
    fieldInfo?: boolean;
    items?: LayoutItem[];
}

interface DictionaryValueItem {
    colValId: number;
    dictId: number;
    columnId: string;
    row: number;
    value: any;
    columnName: string;
    columnType: number;
    isDefault: boolean;
}

//returns array of columns by row
function getDictionaryRows(dictionaryValues: DictionaryValueItem[], dictionaryId: number): DictionaryValueItem[][] {
    const dictItems = dictionaryValues
        .filter(dv => dv.dictId === dictionaryId);

    return values(groupBy(dictItems, di => di.row));
}

const FormulaTooltip = (props: { items: LayoutItem[], item: LayoutItem, error?: EvaluationError }) => {
    const [fieldId, setFieldId] = useState(props.item.fieldId);

    const itemFormula = props.items.find(it => it.fieldId === fieldId)?.value?.trim();
    const ids = (props.error?.fieldIdChain?.length ?? 0 > 0) ? props?.error?.fieldIdChain! : [props.item.fieldId];
    const fieldIdChain = <Breadcrumb items={ids.map(id => ({
        title: <a href="" onClick={e => {
            e.preventDefault();
            setFieldId(id);
        }}>{id === fieldId ? <strong style={{ color: '#000000' }}>{id}</strong> : id}</a>
    }))}/>;

    return <div style={{ height: 200, display: 'flex', flexDirection: 'column' }}>
        {fieldIdChain}
        <div style={{height: 1, marginTop: 2, marginBottom: 2, borderBottom: '1px dashed #a58b40'}}/>
        {props.error ? <div>
            <pre style={{ color: 'red', whiteSpace: 'pre-wrap', fontSize: 11, margin: 0 }}>{props.error.error}</pre>
        </div> : null}
        <pre style={{whiteSpace: 'pre-wrap', fontSize: 11, overflow: 'auto', flexGrow: 1, background: 'rgba(0, 0, 0, 0.10)', margin: 0 }}>{itemFormula}</pre>
    </div>;
}

const createElement = (
    items: LayoutItem[], item: LayoutItem,
    fieldValues?: { [key: string]: EvaluationFieldValue },
    dictionariesData?: DictionaryValueItem[],
    error?: EvaluationError,
    calculatorDisabled: boolean = false,
    debugData: boolean = false,
    showFieldId: boolean = false,
    showFiledInfo: boolean = false,
    roles: UserRole[] = []
) => {
    const itemValue = getFieldContent(item.value);

    let fieldValue = itemValue ?? fieldValues?.[item.fieldId]?.value;

    const fieldHidden = fieldValues?.[item.fieldId]?.hidden ?? false;
    const fieldDisabled = fieldValues?.[item.fieldId]?.disabled ?? false;
    const fieldHiddenValue = fieldValues?.[item.fieldId]?.hiddenValue;
    const fieldDisabledValue = fieldValues?.[item.fieldId]?.disabledValue;

    const disabled = fieldDisabled || calculatorDisabled;
    const hidden = fieldHidden;
    const value = fieldHidden ? fieldHiddenValue : (fieldDisabled ? fieldDisabledValue : fieldValue);

    let dictRows: DictionaryValueItem[][] = [];
    if (dictionariesData && item.calculatorDictId) {
        dictRows = getDictionaryRows(dictionariesData, item.calculatorDictId);
    }
    let el = null;
    let tooltip = null;
    const hideItem = hidden || !isItemVisible(item, roles);

    const itemStyle = makeElementStyle(item);
    if (hideItem) {
        itemStyle.visibility = 'hidden';
    }
    if (disabled && (item.fieldType === FieldType.TextInput || item.fieldType == FieldType.NumericInput)) {
        itemStyle.background = '#f6f6f6';
        itemStyle.cursor = 'auto';
    }

    if (debugData && (error || (item.value && !itemValue))) {
        tooltip = <FormulaTooltip items={items} item={item} error={error}/>
    }

    const evaluationError = !hideItem && debugData && error;
    const mainValue = !hideItem && item.mainValue;

    let formattedValue = value;

    if (item.roundValue) {
        const num = parseFloat(value as string);
        formattedValue = round(isNaN(num) ? null : num);
    } else if (item.formatValue) {
        const num = parseFloat(value as string);
        formattedValue = formatMoney(isNaN(num) ? null : num, 2, ',', ' ');
    }

    if (item.fieldType === FieldType.Text) {
        el = <div className="layout-element" style={itemStyle}>{formattedValue}</div>;
    } else if (item.fieldType === FieldType.NumericInput) {
        el = <Form.Item name={item.fieldId} noStyle>
            <Input className="layout-element" type="number" style={itemStyle} disabled={disabled} allowClear={true}/>
        </Form.Item>
    } else if (item.fieldType === FieldType.TextInput) {
        el = <Form.Item name={item.fieldId} noStyle>
            <Input.TextArea className="layout-element"
                style={itemStyle}
                disabled={disabled} allowClear={true}/>
        </Form.Item>
    } else if (item.fieldType === FieldType.Dictionary) {
        el = <Form.Item name={item.fieldId} noStyle>
            <Select className="layout-element select-field" popupMatchSelectWidth={false} style={itemStyle} disabled={disabled} allowClear={true} size="small">
                {dictRows.map(dr =>
                    <Select.Option value={dr[0].row} key={dr[0].row} style={{ overflow: hidden }}>
                        <div>{dr[0].value}</div>
                        {debugData ?
                        <div className="select-field-option-debug" style={{fontSize: 11, fontWeight: "normal", maxWidth: 500}}>wiersz: {dr[0].row} [{
                            dr.map(e => `${e.columnId}:${e.value}`).join(', ')
                        }]
                        </div> : null}
                    </Select.Option>)
                }
            </Select>
        </Form.Item>
    } else if (item.fieldType === FieldType.Formula) {
        el = <div className="layout-element formula" style={itemStyle}>{formattedValue}</div>;
    }

    return <div key={item.id} /*style={debugStyle}*/
        className={classNames('layout-element-container', {
            'evaluation-error': evaluationError,
            'main-value': mainValue
        })}>
        {tooltip ? <>
            {el}
            {error ?
            <Tooltip placement="top" title={tooltip} trigger="hover" overlayInnerStyle={{ zIndex: 100, color: '#000000', width: 350 }} color="#fdd663">
                <div className="evaluation-debug-button" style={{ background: error ? 'rgba(255,0,0,0.5)' : 'rgba(113,166,113,0.5)'}}>Error</div>
            </Tooltip> : null}
            {!error && showFiledInfo ?
            <Tooltip placement="top" title={tooltip} trigger="hover" overlayInnerStyle={{ zIndex: 100, color: '#000000', width: 350 }} color="#fdd663">
                <div className="evaluation-debug-button" style={{ background: error ? 'rgba(255,0,0,0.5)' : 'rgba(113,166,113,0.5)'}}>Info</div>
            </Tooltip> : null}
        </> : el}
        {showFieldId ? <div className="field-id">{item.alias ?? item.fieldId}</div> : null}
    </div>
}

export const CalculatorEvaluator = ({ calculatorId, calculatorData, width, height, roles, debugData, onChange, loading, disabled, fieldId, fieldInfo, items }: CalculatorEvaluatorProps) => {
    const [testForm] = useForm();
    const [evaluationResult, setEvaluationResult] = useState<EvaluationResult | null>(null);
    const [evaluationLoading, setEvaluationLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [layout, setLayout] = useState<LayoutItem[]>([]);
    const [values, setValues] = useState<any>({});

    useDebouncedEffect(() => {
        if (initialized) {
            const source = Axios.CancelToken.source();
            setEvaluationLoading(true);

            const fields = {} as { [fieldId: string]: any }

            for (const [key, value] of Object.entries(values)) {
                const disabled = evaluationResult?.fieldValues?.[key]?.disabled ?? false;
                const hidden = evaluationResult?.fieldValues?.[key]?.hidden ?? false;

                if (!disabled && !hidden) {
                    fields[key] = value;
                }
            }

            if (onChange && !isEmpty(fields)) {
                onChange(fields);
            }

            backendPost<EvaluationResult>(`/evaluation/calculator/${calculatorId}`, {
                fields
            }, {
                cancelToken: source.token
            }).then(result => {
                setEvaluationResult(result);
                for (const [key, value] of Object.entries(result.fieldValues)) {
                    testForm.setFieldValue(key, getEvaluatedFieldValue(value));
                }
            }).finally(() => {
                setEvaluationLoading(false);
            });
            return () => {
                source.cancel();
            }
        }
    }, 500, [values, initialized]);

    const { data: layoutItems, loading: layoutItemsLoading } = useSqlQueryRaw<LayoutItem>(
        `calculator-items-${calculatorId}`,
        sql`select id as key, id, owner_id,
               calculator_dict_id,
               field_type, field_scope, field_roles, field_id,
               alias, value,
               background_color,
               text_color, font_size, font_weight, horz_align, vert_align,
               main_value, round_value, format_value,
               x0, xl0, xp0, y0, yl0, yp0,
               x1, xl1, xp1, y1, yl1, yp1
            from calculator_item
            where calculator_id = ${calculatorId}
            and deleted_at is null
            order by id`,
        {
            enabled: calculatorId && (items?.length ?? 0) === 0,
            map: rows => rows.map(r => ({
                ...r,
                fieldRoles: numberToUserRoles(r.fieldRoles as unknown as number)
            }))
        }
    );

    const { data: dictionariesData, loading: dictionariesDataLoading, loaded: dictionariesDataLoaded } = useSqlQueryRaw<DictionaryValueItem>(
        `dictionaries-data-${calculatorId}`,
        sql`
            select cdv.id as col_val_id,
                cd.id as dict_id,
                cdc.owner_id,
                cdc.column_id,
                cdv.row,
                cdv.value,
                cdc.column_name,
                cdc.column_type,
                cd.default_row = cdv.row as is_default
            from calculator_dict_column cdc
            inner join calculator_dict_value cdv
                on cdc.id = cdv.calculator_dict_column_id
                and cdc.deleted_at is null
            inner join calculator_dict cd
                on cd.id = cdc.calculator_dict_id
                and cd.deleted_at is null
            where cd.calculator_id = ${calculatorId}
                and cdc.deleted_at is null
            order by cd.id, cdv.row, cdc.id`, {
            enabled: calculatorId,
        }
    );

    useEffect(() => {
        if (!initialized && layoutItems && dictionariesDataLoaded && dictionariesData) {
            setInitialized(true);
            const data = isNil(calculatorData) ? {} : calculatorData;
            setValues(data);
            testForm.setFieldsValue(data);
        }
    }, [layoutItems, dictionariesData, dictionariesDataLoaded]);

    useEffect(() => {
        if (layoutItems) {
            setLayout(layoutItems);
        }
    }, [layoutItems]);

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {!disabled ? <Button type='default' onClick={() => {
                if (layoutItems && dictionariesDataLoaded && dictionariesData) {
                    const data = {} as CalculatorData;
                    setValues(data);
                    testForm.resetFields();
                }
            }}
                style={{ position: 'absolute', right: 25, top: 20, width: 100, height: 30, zIndex: 1002, background: 'rgba(255,255,255,0.8)' }}>
                Resetuj
            </Button> : null}
            <Form form={testForm} onValuesChange={() => {
                const values = testForm.getFieldsValue();
                setValues(values);
            }}>
                {width > 0 && height > 0 && layoutItems ? <AbsoluteLayout
                    className="layout evaluator"
                    rowHeight={35}
                    showGrid={false}
                    editing={false}
                    layout={layout.map((l, idx) => {
                        const error = evaluationResult?.errors.find(e => e.fieldId === (l.alias ?? l.fieldId));

                        return {
                            key: l.id!.toString(),
                            idx: idx,
                            component: createElement(
                                layoutItems,
                                l,
                                evaluationResult?.fieldValues,
                                dictionariesData,
                                error,
                                disabled,
                                debugData,
                                fieldId,
                                fieldInfo,
                                roles),
                            ...getGridCellPos(l)
                        } as GridCell;
                    })}
                    width={width}
                    height={height}
                /> : null}
            </Form>
            {evaluationLoading || dictionariesDataLoading || layoutItemsLoading || !!loading
                ? <div className="progress-bar">
                    <div className="progress-bar-value"/>
                </div> : null}
        </div>
    )
}
