import React from "react";
import { Button, Select, Space, Tooltip } from "antd";
import { filterBySearch } from "../../utils/utils";
import { CalculatorItem } from "./calculator-item";
import { useUser } from "../../hooks/user.hook";
import { ArrowRightOutlined } from "../icons";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";

export const CalculatorSelect = (
    { calculators, canEdit, loading, ...props }: {
        calculators: { id: number, name: string, type: string, active: boolean }[] | undefined,
        canEdit?: boolean, loading?: boolean,
        [key: string]: any
    }) => {
    const { admin } = useUser();
    const navigate = useNavigate();
    const value = (props as any).value;
    return <Space className="select">
        <Select disabled={canEdit === undefined ? canEdit : !canEdit} showSearch={true} allowClear={true} loading={loading}
            filterOption={filterBySearch} style={{ flexGrow: 1, width: '100%' }} {...props}>
            {calculators?.map(c => <Select.Option value={c.id} key={c.id} search={c.name + c.id}>
                <CalculatorItem id={c.id} type={c.type} name={c.name} active={admin && c.active}/>
            </Select.Option>)}
        </Select>
        {admin ? <Tooltip title="Przejdź do kalkulatora" placement="topLeft">
            <Button disabled={!value} icon={<ArrowRightOutlined/>} onClick={() => navigate(baseUrl(`/calculators/${value}`))}/>
        </Tooltip> : null}
    </Space>
}
