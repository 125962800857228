import { useMutation } from "@tanstack/react-query";
import { Model } from "../../models/db";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { notification } from "antd";
import { baseUrl } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";

export const useSaveClient = () => {
    const navigate = useNavigate();
    return useMutation(async (client: Model.Client.Data) => {
        const clientId = await (client.id
            ? backendPut<number>(`/clients/${client.id}`, client)
            : backendPost<number>('/clients', client)
        );
        notification.success({
            message: 'Klient został zapisany'
        });
        if (clientId) {
            navigate(baseUrl(`/clients/${clientId}/data`));
        }
    });
}

export const useAcceptClient = () => {
    const navigate = useNavigate();
    return useMutation(async (clientId: number) => {
        await backendPut<number>(`/clients/${clientId}/accept`);
        notification.success({
            message: 'Klient został zaakceptowany'
        });
        navigate(baseUrl(`/clients/${clientId}/data`));
    });
}

export const useRemoveClient = () => {
    const navigate = useNavigate();
    return useMutation(async (clientId: number) => {
        await backendDelete(`/clients/${clientId}`);
        notification.success({
            message: 'Klient został usunięty'
        });
        navigate(baseUrl("/clients"));
    });
}
