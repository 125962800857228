import { allRoles, roleMap, UserRole } from "../models/user";

export function numberToUserRoles(n: number): UserRole[] {
    const roles: UserRole[] = [];
    allRoles.forEach(role => {
        if (roleMap[role] && (roleMap[role] & n) > 0) {
            roles.push(role);
        }
    })
    return roles;
}
