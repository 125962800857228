import React from "react"

export const EcbLangHelp = () => {
    return <div>
        <h4>Podstawowy układ</h4>
        <pre>
            disabled: [false/true];<br/>
            disabled_value: [value];<br/>
            hidden: [false/true];<br/>
            hidden_value: [value];<br/>
            [value]<br/>
        </pre>
        
        Do prawidłowego działania program wymaga tylko zwracanej wartości - [value].
        Wartosć to wyrażenie które może być liczbą, tekstem, funkcją lub kombinacją arytmetyczną. Np:
        <pre>
            disabled: [fld-5] = 10;<br/>
            disabled_value: 8;<br/>
            [fld-4]-[fld-3]*2<br/>
        </pre>
        
        Wartością wyjściową będzie wartość <code>[fld-4]-[fld-3]*2</code> o ile wyrażenie <code>[fld-5] = 10</code>
        nie będzie spełnione - w przeiwnym razie będzie to <code>8</code>.
        Jeśli w programie byłoby dodatkowo <code>hidden_value</code> i warunek <code>hidden</code> byłby spełniony to wartością wyjściową
        będzie wartość <code>hidden_value</code> (ma on pierwszeństwo przed <code>disabled_value</code>).
        
        <h4>Warunki logiczne</h4>
        
        Warunki w programie mogą być wyrażone poprzez funkcję <code>IF(warunek, wartość_0, wartość_1)</code> lub przez słowo kluczowe <code>IF</code>.<br></br>
        Druga forma bedzię czytelniejsza przy większym zagęszczeniu warunków. Np warunek z funkcją <code>IF</code>:
        <pre>
            IF([fld-5] &gt; [fld-8],<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;10,<br/>
            &nbsp;&nbsp;&nbsp;&nbsp;IF([fld-6] = 1, 3, 5)<br/>
            )<br/>
        </pre>
        może być zastąpiony wyrażeniem:
        <pre>
            IF ([fld-5] &gt; [fld-8]) 10<br/>
            ELSE IF ([fld-6] = 1) 3<br/>
            ELSE 5<br/>
        </pre>
        
        <h4>Zmienne</h4>
        
        W przypadku bardziej skomplikowanych programów można posłużyć się zmiennymi:<br/>
        
        <pre>
            v0: 10;<br/>
            v1: @v0 + [fld-5];<br/>
            (@v0 + @v1) * 2<br/>
        </pre>
        
        Odwołanie do zmiennej odbywa się przez referencję (znak małpy <code>@</code>). Zmienna jest dostępna jeśli jest użyta po jej zadeklarowaniu.
        
        <h4>Dynamiczne referencje</h4>
        
        Dowolne wartości można przypisać do zmiennej i użyć dalej w wyrażeniu. Jest to szczególnie przydatne przy wartościach słownikowych. Np mając słownik <code>towary</code>:
        <pre>
            nazwa | cena<br/>
            ------------<br/>
            T0    | 10<br/>
            T1    | 5<br/>
            T2    | 20<br/>
            <br/>
        </pre>
        można przypisać do zmiennej wartość ze słownika spełniającą dany warunek np:
        <pre>
            if ([towary:cena]@v &gt; 10) @v else 0
        </pre>
        Zmienna <code>v</code> będzie miała wartość 20 gdyż ta cena ze słownika spełniła warunek.
    </div>
    
} 