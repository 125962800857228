import React from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/user.hook";
import { CalculatorEvaluator } from "./calculator-evaluator";
import { useResizeObserver } from "../../hooks/resize-observer.hook";

export const CalculatorTest = () => {
    const { id } = useParams();
    const calculatorId = parseInt(id ?? "") || undefined;
    const { user } = useUser();

    const contentSize = useResizeObserver(".ant-layout-content");

    return <CalculatorEvaluator
        width={contentSize.width}
        height={contentSize.height - 16}
        calculatorId={calculatorId}
        roles={[user.secondaryRole ?? user.role]}
    />
}
