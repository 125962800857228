import { DynamicContent } from "../../../components/dynamic-content";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { DashboardCard } from "../dashboard-card";
import React from "react";
import { sql, useSqlQueryRaw } from "../../../hooks/sql-query.hook";
import { getStatusLocale } from "../../../components/realisation-status";
import { CustomTooltip } from "../custom-tooltip";

const palette = ["rgba(18,154,116,0.62)","rgba(213,171,80,0.62)","rgba(255,112,112,0.62)","rgba(112,184,255,0.62)","#e9ff70"];

type Data = { status: string, realisationsCount: number };

export const RealisationStatuses = () => {
    const { data: statuses } = useSqlQueryRaw<Data>('realisations-by-status', sql`
        select status, count(*) as realisations_count
         from realisation
         where deleted_at is null and created_at >= current_date - interval '3 months'
         group by status
         order by status
    `, {
        map: rows => rows.map(row => ({
            status: getStatusLocale(row.status),
            realisationsCount: row.realisationsCount
        }))
    });

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {value}
            </text>
        );
    };

    return <DashboardCard title="Statusy realizacji z ostatnich 3 miesięcy" key="realisation-statuses">
        <DynamicContent>
            {(width, height) => {
                return <PieChart width={width} height={height}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#129a74" stopOpacity={0.5}/>
                            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.5}/>
                        </linearGradient>
                    </defs>
                    <Pie data={statuses} nameKey="status" dataKey="realisationsCount" cx="50%" cy="50%" fill="blue"
                        label={renderCustomizedLabel}
                        labelLine={false}

                    >
                        {statuses?.map((s, idx) => <Cell key={`cell/${idx}`} stroke="#036991" strokeWidth={1} fill={palette[idx % palette.length]}/>)}
                    </Pie>
                    <Tooltip content={<CustomTooltip<Data> text={data => `${data.status}: ${data.realisationsCount}`}/>}/>
                    <Legend layout="vertical" verticalAlign="bottom"/>
                </PieChart>
            }}
        </DynamicContent>
    </DashboardCard>
}
