// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-upload-list .ant-upload-list-item {
  width: 412px;
}
`, "",{"version":3,"sources":["webpack://./web/views/templates/template-data.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ","sourcesContent":[".template-upload-list .ant-upload-list-item {\n    width: 412px;\n}\n@layout-header-background: #1890ff;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
