import React from "react";
import { Button, Checkbox, DatePicker, Input, notification, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { usePaginatedSqlQuery } from "../../hooks/sql-query.hook";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { useUser } from "../../hooks/user.hook";
import { appStore } from "../../store/store";
import { useDebounce } from "../../hooks/debaunce.hook";
import { getListCriteria, withFilterValueCriteria } from "../../utils/sql";
import { formatDate } from "../../utils/utils";
import { backendDelete, backendPost } from "../../api/backend-api";
import { RemoveButton } from "../../components/remove-button";
import { TableView } from "../../components/table-view";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dayjs } from "dayjs";

export const Templates = () => {
    const queryClient = useQueryClient();

    const user = useUser();
    const filter = appStore.useState(s => s.templateList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const { mutateAsync: removeTemplate } = useMutation((templateId: number) => {
        return backendDelete(`/templates/${templateId}`)
            .then(() => {
                void queryClient.invalidateQueries(['templates']);
                notification.success({
                    message: 'Szablon został usunięty'
                });
            })
    });

    const { mutateAsync: makeCopy, isLoading: makingCopy } = useMutation((templateId: number) => {
        return backendPost(`/templates/${templateId}/copy`)
            .then(() => queryClient.invalidateQueries([`templates`]));
    });

    const criteria = {...filter, search: debouncedSearch};

    let where = getListCriteria(
        [t.t.id, t.t.filename, t.t.name, t.c.name],
        t.t.createdAt, t.t.deletedAt,
        criteria
    );

    if (user.admin) {
        where += withFilterValueCriteria(t.t.acceptedAt.isNotNull().cast('int'), filter.accepted);
    } else {
        where += " and t.accepted_at is not null";
    }

    where += withFilterValueCriteria(t.c.active.cast('int'), filter.activeCalculator)
        + withFilterValueCriteria(t.t.active.cast('int'), filter.active);

    const { total, data, loading } = usePaginatedSqlQuery(
        'templates', criteria,
        from(t.template.as(t.t))
        .join(t.calculator.as(t.c).id, t.t.calculatorId)
        .where(where)
        .orderByAlias(criteria.sortColumn, criteria.sortDirection)
        .select({
            key: t.t.id,
            id: t.t.id,
            name: t.t.name,
            templateType: t.t.templateType,
            filename: t.t.filename,
            userId: t.t.userId,
            ownerId: t.t.ownerId,
            calculator: t.c.name,
            createdAt: t.t.createdAt,
            accepted: t.t.acceptedAt.isNotNull().asBool(),
            activeCalculator: t.c.active,
            active: t.t.active
        })
    );

    const columns: ColumnsType<typeof data[number]> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
        width: '30%'
    }, {
        title: 'Rodzaj',
        dataIndex: 'templateType',
        width: '25%',
        render: value => value === 0 ? "Oferta" : (value === 1 ? "Umowa" : "Arkusz")
    }, {
        title: 'Plik',
        dataIndex: 'filename',
        width: '40%'
    }, {
        title: 'Aktywny',
        dataIndex: 'active',
        width: 130,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.active
    }, {
        title: 'Kalkulator',
        dataIndex: 'calculator',
        width: '30%'
    }, {
        title: 'Kalkulator aktywny',
        dataIndex: 'activeCalculator',
        width: 150,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: user.admin ? [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ] : undefined,
        filteredValue: filter.activeCalculator
    }, {
        title: 'Zaakceptowany',
        dataIndex: 'accepted',
        width: 170,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: user.admin ? [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ] : undefined,
        filteredValue: filter.accepted
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: value => formatDate(value)
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 210,
        render: (_, item) => {
            return <Space>
                {user.permissions.templateList.edit([item.userId]) && <Link to={baseUrl(`/templates/${item.id}/data`)}>Edytuj</Link>}
                {user.permissions.templateList.edit([item.userId]) && <Button type="link" onClick={() => makeCopy(item.id)}>Kopiuj</Button>}
                <RemoveButton link disabled={!user.permissions.templateList.remove(item.accepted)} onConfirm={() => removeTemplate(item.id)}/>
            </Space>
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading || makingCopy}
        scrollWidth={1510}
        listKey={'templateList'}
        onFiltersChange={filters => {
            appStore.update(s => {
                s.templateList.accepted = filters.accepted;
                s.templateList.activeCalculator = filters.activeCalculator;
                s.templateList.active = filters.active;
            });
        }}
        toolbar={
            <>
                <Link to={baseUrl("/templates/create")}><Button>Dodaj szablon</Button></Link>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.templateList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.templateList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['templates']);
                        }}
                    />
                    <Input style={{width: 200}} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.templateList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
