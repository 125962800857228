import React from "react";
import { Button, DatePicker, Input, notification, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { usePaginatedSqlQuery } from "../../hooks/sql-query.hook";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/user.hook";
import { baseUrl } from "../../utils/navigation";
import { appStore } from "../../store/store";
import { useDebounce } from "../../hooks/debaunce.hook";
import { getListCriteria } from "../../utils/sql";
import { formatDate } from "../../utils/utils";
import { getUserRoleDescription } from "../../models/user";
import { RemoveButton } from "../../components/remove-button";
import { backendDelete } from "../../api/backend-api";
import { TableView } from "../../components/table-view";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dayjs } from "dayjs";

export const Users = () => {
    const user = useUser();
    const filter = appStore.useState(s => s.userList);
    const debouncedSearch = useDebounce(filter.search, 500);
    const queryClient = useQueryClient();

    const { mutateAsync: removeUser } = useMutation(async (userId: number) => {
        await backendDelete(`/users/${userId}`);
        void queryClient.invalidateQueries(['users']);
        notification.success({
            message: 'Użytkownik został usunięty'
        });
    });

    const criteria = {...filter, search: debouncedSearch};
    const where = getListCriteria(
        [t.u.id, t.u.username, t.u.firstName, t.u.lastName],
        t.u.createdAt, t.u.deletedAt,
        criteria
    );

    const { total, data, loading } = usePaginatedSqlQuery(
        'users', criteria,
        from(t.user.as(t.u))
        .where(where)
        .orderByAlias(criteria.sortColumn, criteria.sortDirection)
        .select({
            key: t.u.id,
            id: t.u.id,
            username: t.u.username,
            firstName: t.u.firstName,
            lastName: t.u.lastName,
            createdAt: t.u.createdAt,
            role: t.u.role,
            ownerId: t.u.ownerId
        })
    );

    const columns: ColumnsType<typeof data[number]> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Login',
        dataIndex: 'username',
    }, {
        title: 'Imię',
        dataIndex: 'firstName',
    }, {
        title: 'Nazwisko',
        dataIndex: 'lastName',
    }, {
        title: 'Rola',
        dataIndex: 'role',
        render: (value: number) => getUserRoleDescription(value)
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: value => formatDate(value)
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (_, item) => {
            return <Space>
                {user.permissions.userList.edit([item.id]) ?
                    <Link to={baseUrl(`/users/${item.id}`)}>Edytuj</Link> : null}
                {user.permissions.userList.remove([item.id]) ?
                    <RemoveButton link onConfirm={() => removeUser(item.id)}/> : null}
            </Space>
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading}
        scrollWidth={1000}
        listKey={'userList'}
        toolbar={
            <>
                <Link to={baseUrl("/users/create")}><Button>Dodaj użytkownika</Button></Link>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.userList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.userList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['users']);
                        }}
                    />
                    <Input style={{width: 200}} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.userList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
