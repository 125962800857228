import React from "react";
import { Space } from "antd";

export const NameWithDesc = (props: { name: string, desc?: string }) => {
    if (props.desc) {
        return <div>
            <Space direction="vertical" size="small">
                <div>{props.name}</div>
                <div style={{ fontSize: 11, color: '#cc7b26' }}>{props.desc}</div>
            </Space>
        </div>
    } else {
        return <div>{props.name}</div>
    }
}
