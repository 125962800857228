import React, { useEffect, useState } from "react";
import { Tabs, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import {
    useAcceptAssemblyParameters,
    useAssemblyParametersInfo,
    useExportAssemblyParameters,
    useSaveAssemblyParameters
} from "./assembly-parameters.hook";
import { PopoverForm } from "../../components/popover-form";
import { useForm } from "antd/lib/form/Form";
import { Model } from "../../models/db";
import { Nil } from "../../utils/utils";
import { AssemblyParametersType } from "../../models/model";
import { AssemblyParametersData } from "./assembly-parameters-data";

type AssemblyParametersTab = 'data';
export type AssemblyParametersOperation = 'accept' | 'save' | 'export' | undefined;

export const AssemblyParametersEdit = (props: { tab?: AssemblyParametersTab, type?: AssemblyParametersType }) => {
    const { id } = useParams();

    const assemblyParametersId = parseInt(id ?? "") || undefined;
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);

    const [assemblyParametersData, setAssemblyParametersData] = useState<Nil<Model.AssemblyParameters.Data>>(undefined);

    const { templateConnected, assemblyParametersAccepted, assemblyParametersType, version: assemblyParametersVersion } = useAssemblyParametersInfo(assemblyParametersId);
    const { mutateAsync: saveAssemblyParameters, isLoading: assemblyParametersSaving } = useSaveAssemblyParameters();
    const { mutateAsync: exportAssemblyParameters, isLoading: exportingAssemblyParameters } = useExportAssemblyParameters();
    const { mutateAsync: acceptAssemblyParameters, isLoading: assemblyParametersAccepting } = useAcceptAssemblyParameters();

    const version = assemblyParametersId ? assemblyParametersVersion : undefined;
    const assemblyType = !assemblyParametersId ? props.type : assemblyParametersType;

    const acceptWarning = <div style={{ paddingBottom: 10}}>
        <Typography.Text type="danger">Eksport arkusza spowoduje jego zaakceptowanie</Typography.Text>
        <br/>
        <Typography.Text type="danger">i wyłączenie możliwości jego edycji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const [operation, setOperation] = useState<AssemblyParametersOperation>(undefined);
    const [form] = useForm<Model.AssemblyParameters.Data>();

    useEffect(() => {
        if (operation === 'accept') {
            form.validateFields()
                .then(() => saveAssemblyParameters(assemblyParametersData!))
                .then(() => acceptAssemblyParameters(assemblyParametersId!));
        } else if (operation === 'save') {
            form.submit();
        } else if (operation === 'export') {
            if (!assemblyParametersAccepted) {
                form.validateFields()
                    .then(() => saveAssemblyParameters(assemblyParametersData!))
                    .then(() => acceptAssemblyParameters(assemblyParametersId!))
                    .then(() => exportAssemblyParameters(assemblyParametersId!));
            } else {
                void exportAssemblyParameters(assemblyParametersId!);
            }
        }
        setOperation(undefined);
    }, [operation]);


    return <Tabs defaultActiveKey="data" activeKey={props.tab}
        onChange={tab => {
            navigate(baseUrl(`/assembly-parameters/${assemblyParametersId}/${tab}`));
            setCurrentTab(tab as AssemblyParametersTab);
        }}
        tabBarExtraContent={assemblyParametersId && {
            right:
                <PopoverForm
                    title="Eksportuj arkusz"
                    buttonEnabled={!exportingAssemblyParameters && templateConnected}
                    showPopover={!assemblyParametersAccepted}
                    loading={exportingAssemblyParameters || assemblyParametersSaving || assemblyParametersAccepting}
                    onSubmit={() => setOperation('export')}>
                    {acceptWarning}
                </PopoverForm>

        }}
        items={[{
            label: "Dane ustaleń montażowych",
            key: "data",
            disabled: version === undefined,
            children: <AssemblyParametersData
                assemblyType={assemblyType}
                version={version}
                form={form}
                operation={operation}
                setOperation={setOperation}
                assemblyParametersId={assemblyParametersId}
                setAssemblyParametersData={setAssemblyParametersData}
            />
        }]}
    >
    </Tabs>
}
