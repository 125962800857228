import { Button, Select, Space, Tooltip } from "antd";
import { filterBySearch } from "../../utils/utils";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "../icons";
import { TemplateItem } from "./template-item";
import { useUser } from "../../hooks/user.hook";
import { baseUrl } from "../../utils/navigation";

type T = { id: number, name: string, type: number, version: number };

export const TemplateSelect = (
    { templates, canEdit, ...props }: {
        templates: T[] | undefined,
        canEdit: boolean,
        onChange?: (templateId: number) => void
    }) => {
    const { permissions } = useUser();
    const navigate = useNavigate();
    const value = (props as any).value;
    return <Space className="select">
        <Select disabled={!canEdit} showSearch={true}
            allowClear={true} filterOption={filterBySearch} {...props}>
            {templates?.map(t => <Select.Option key={t.id} value={t.id} search={t.name + t.id}>
                <TemplateItem id={t.id} name={t.name} type={t.type}/>
            </Select.Option>)}
        </Select>
        {permissions.panel.templates ? <Tooltip title="Przejdź do szablonu" placement="topLeft">
            <Button disabled={!value} icon={<ArrowRightOutlined/>} onClick={() => navigate(baseUrl(`/templates/${value}`))}/>
        </Tooltip> : null}
    </Space>
}
