import { RcFile, UploadProps } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { message, Upload } from "antd";
import { getBase64RawData, getFileExtension, readUploadedFile, withExtension } from "../utils/utils";
import React, { useState } from "react";
import { PlusOutlined } from "./icons";
import { ProtectedImg } from "./protected-img";
import { ProtectedImgPreview } from "./protected-img-preview";
import UploadList from "antd/es/upload/UploadList";

export interface FileData extends UploadFile {
    id: number | undefined;
    group?: string | undefined;
    fileData: Blob | string | undefined;
    fileDataB64?: string | undefined;
}

export type SetFileList = ((value: (((prevState: FileData[]) => FileData[]) | FileData[])) => void);

interface FileUploaderProps {
    canEdit: boolean;
    fileList: FileData[];
    group?: string;
    setFileList: SetFileList
}

export const FileUploader = ({ fileList, setFileList, canEdit, group = undefined }: FileUploaderProps) => {
    const [previewFile, setPreviewFile] = useState<string | undefined>(undefined);

    const uploadProps: UploadProps = {
        listType: "picture-card",
        className: "realisation-upload-list",
        fileList: fileList.filter(f => f.group === group),
        maxCount: 20,
        multiple: true,
        onChange: () => false,
        onRemove: (file: UploadFile) => {
            setFileList(fileList.filter((f => {
                return f.uid !== file.uid;

            })))
            return true;
        },
        beforeUpload: (file: RcFile) => {
            if (file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpeg') {
                void message.error(`${file.name} nie jest plikiem pdf, png lub jpg`);
                return false;
            }

            readUploadedFile(file).then(fileData => ({
                id: undefined,
                group,
                uid: file.uid,
                name: file.name,
                originFileObj: file,
                fileData: getBase64RawData(fileData),
                fileDataB64: fileData
            })).then(file => setFileList(files => [...files, file]));

            return false;
        },
        onPreview: file => {
            if (!file.url && !file.preview) {
                return false;
            }
            const filePath = withExtension(file.url!!, getFileExtension(file.fileName!!) ?? "jpg")
            setPreviewFile(filePath);
        },
        itemRender: (originNode, file, fileList, actions) => {
            return <ProtectedImg
                filename={file.fileName}
                disabled={!canEdit}
                imgData={(file as FileData).fileDataB64}
                actions={actions}/>
        }
    };

    return <>
        <Upload {...uploadProps} disabled={!canEdit}>
            <div>
                <PlusOutlined/>
                <div style={{ marginTop: 8 }}>Wybierz pliki</div>
            </div>
        </Upload>
        {previewFile ? <ProtectedImgPreview
            onClose={() => setPreviewFile(undefined)}
            filename={previewFile}/> : null}
    </>

}
