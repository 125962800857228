import React, { useEffect, useRef, useState } from "react";
import { Button, DatePicker, Form, Input, Modal, notification, Select, Space, Spin } from "antd";
import { qid, useSqlQuery } from "../../hooks/sql-query.hook";
import { useUser } from "../../hooks/user.hook";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import dayJsPlugin from 'fullcalendar-plugin-dayjs';
import interactionPlugin, { EventDragStopArg } from "@fullcalendar/interaction";
import { DateSelectArg, EventClickArg, EventSourceInput } from "@fullcalendar/core";
import plLocale from '@fullcalendar/core/locales/pl';
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { NumericInput } from "../../components/numeric-input";
import { RemoveButton } from "../../components/remove-button";
import { getPastelColor } from "../../utils/utils";
import tinycolor from "tinycolor2";
import './installation-calendar.less';
import { CalendarEvent } from "../../models/calendar-event";
import { useResizeObserver } from "../../hooks/resize-observer.hook";
import { isBackOffice, isCoordinator, isDesigner, isDirector, isManager, isTrader, UserRole } from "../../models/user";

const getEventsQueryId = (currentDate: Date | undefined, calendarType: string, crewId: number | undefined) =>
    qid`installation-calendar-${calendarType}-${currentDate?.getFullYear()}-${(currentDate?.getMonth() ?? 0) + 1}-${(currentDate?.getDate())}-${crewId}`;

export const InstallationCalendar = (props: {
    crewMode?: boolean,  crewId?: number, realisationId?: number, embedded?: boolean,
    onSelectEvent?: (event: CalendarEvent) => void,
    onClose?: () => void,
    calendarEventId?: number,
}) => {
    const queryClient = useQueryClient();
    const { user } = useUser();

    const { mutateAsync: saveEvent, isLoading: eventSaving } = useMutation((calendarEvent: any) => {
        return (calendarEvent.id
            ? backendPut(`/installation-calendar/${calendarEvent.id}`, calendarEvent)
            : backendPost('/installation-calendar', calendarEvent)
        ).then((eventId) => {
            void queryClient.invalidateQueries([getEventsQueryId(dateStart, calendarType, props.crewId)]);
            notification.success({
                message: 'Wydarzenie zostało zapisane'
            });
        })
    });

    const { mutateAsync: saveEventDates, isLoading: eventDatesSaving } = useMutation((calendarEvent: { id: number, dateStart: Date, dateEnd: Date }) => {
        return backendPut(`/installation-calendar/${calendarEvent.id}/dates`, {
            dateStart: calendarEvent.dateStart,
            dateEnd: calendarEvent.dateEnd
        }).then(() => {
            void queryClient.invalidateQueries([getEventsQueryId(dateStart, calendarType, props.crewId)]);
            notification.success({
                message: 'Wydarzenie zostało przeniesione'
            });
        })
    });

    const { mutateAsync: removeEvent, isLoading: eventRemoving } = useMutation((eventId: number) => {
        return backendDelete(`/installation-calendar/${eventId}`)
        .then(() => {
            void queryClient.invalidateQueries([getEventsQueryId(dateStart, calendarType, props.crewId)]);
            notification.success({
                message: 'Wydarzenie zostało usunięte'
            });
        })
    });

    const isEditable = (realisationId: number | undefined) => props.realisationId ? realisationId === props.realisationId : true

    const [currentRange, setCurrentRange] = useState<[Date, Date] | undefined>(undefined);

    const calendarRef = useRef<FullCalendar>(null);
    const calendarType = (calendarRef.current?.getApi().view.type ?? 'dayGridMonth').toLowerCase();

    const [eventEdit, setEventEdit] = useState(false);
    const [form] = useForm();

    const dateStart = currentRange?.[0];
    const dateEnd = new Date(currentRange?.[1]?.getTime() ?? 0);
    dateEnd?.setUTCHours(23, 59, 59, 999);

    const { data, loading } = useSqlQuery(
        getEventsQueryId(dateStart, calendarType, props.crewId),
        from(t.installationCalendar, t.ica)
        .innerJoin(t.user, t.u)
        .on(t.u.id.eq(t.ica.ownerId))
        .leftJoin(t.installationCrew, t.ic)
        .on(t.ic.id.eq(t.ica.installationCrewId))
        // .where(where)
        .where(t.ica.dateStart.lte(dateEnd).and(t.ica.dateEnd.gte(dateStart)))
        .where(t.ica.deletedAt.isNull())
        .where(t.ica.installationCrewId.eq(props.crewId), props.crewId)
        .where(t.ica.installationCrewId.isNotNull(), props.crewMode)
        .where(t.ica.installationCrewId.isNotNull().or(t.ica.ownerId.eq(user.id)), isCoordinator(user))
        .where(t.ica.ownerId.eq(user.id),
            isTrader(user) || isManager(user) || isDesigner(user))
        .where(t.u.role.notIn([UserRole.TRADER, UserRole.MANAGER, UserRole.DESIGNER, UserRole.COORDINATOR]),
            isDirector(user) || isBackOffice(user))
        .orderByDesc(t.ic.id)
        .select({
            key: t.ica.id,
            id: t.ica.id,
            name: t.ica.name,
            crewName: t.ic.name,
            description: t.ica.description,
            installationCrewId: t.ica.installationCrewId,
            realisationId: t.ica.realisationId,
            dateStart: t.ica.dateStart,
            dateEnd: t.ica.dateEnd,
            ownerId: t.ica.ownerId,
            createdAt: t.ica.createdAt,
            createdBy: t.u.fullName()
        }), {
            enabled: currentRange
        }
    );

    let eventId = parseInt(Form.useWatch('id', form) ?? "-1", 10);
    const calendarEvent = data?.find(e => e.id == eventId);

    const { data: installationCrews, loading: installationCrewsLoading } = useSqlQuery(
        qid`installation-calendar-crews-${eventId}`,
        from(t.installationCrew, t.ic)
        .where(t.ic.deletedAt.isNull().and(t.ic.active.isTrue()))
        .or(t.ic.id.eq(eventId), !isNaN(eventId))
        .orderBy(t.ic.name)
        .select({
            id: t.ic.id,
            name: t.ic.name
        }), {
            enabled: (props.crewMode || calendarEvent?.installationCrewId) && eventEdit
        }
    );

    const [calendarEvents, setCalendarEvents] = useState<EventSourceInput>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const containerSize = useResizeObserver(containerRef);

    useEffect(() => {
        if (data && data.length > 0) {
            setCalendarEvents(data?.map(row => {
                const eventColor = row.installationCrewId ? getPastelColor(row.installationCrewId) : undefined;
                const borderColor = eventColor ? tinycolor(eventColor).darken(25).toString() : undefined;
                return {
                    id: row.id.toString(),
                    title: row.name + (row.crewName ? ` / ${row.crewName}` : ''),
                    start: row.dateStart,
                    end: row.dateEnd,
                    backgroundColor: eventColor,
                    borderColor: borderColor,
                    editable: isEditable(row.realisationId),
                    className: row.id === props.calendarEventId || row.realisationId === props.realisationId ? 'selected-event'
                        : (props.realisationId && row.realisationId !== props.realisationId ? 'read-only-event' : '')
                }
            }));
        }
    }, [data]);

    const calendarEl = <Spin spinning={loading}>
        <FullCalendar
            handleWindowResize={false}
            contentHeight={containerSize.height - 40 - 20 * (props.embedded ? 1 : 2)}
            ref={calendarRef}
            timeZone={'UTC'}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, dayJsPlugin]}
            headerToolbar={{
                left: 'prev,next today addEventButton',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,list'
            }}
            forceEventDuration={true}
            nowIndicator={true}
            slotEventOverlap={true}
            eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: true
            }}
            events={calendarEvents}
            customButtons={{
                addEventButton: {
                    text: 'Dodaj wydarzenie',
                    click: () => {
                        form.resetFields();
                        setEventEdit(true);
                    }
                }
            }}
            initialView="dayGridMonth"
            editable={!installationCrewsLoading && !loading}
            selectable={true}
            eventClick={(clickEvent: EventClickArg) => {
                const event = clickEvent.event;
                const eventId = parseInt(event.id, 10);
                const calendarEvent = data?.find(e => e.id == eventId);
                if (calendarEvent) {
                    if (props.onSelectEvent) {
                        props.onSelectEvent(calendarEvent);
                    } else if (isEditable(calendarEvent.realisationId)) {
                        form.setFieldsValue({
                            ...calendarEvent,
                            dateStart: dayjs.utc(calendarEvent.dateStart),
                            dateEnd: dayjs.utc(calendarEvent.dateEnd)
                        });
                        setEventEdit(true);
                    }
                }
            }}
            select={(event: DateSelectArg) => {
                form.resetFields();
                form.setFieldsValue({
                    dateStart: dayjs.utc(event.start),
                    dateEnd: dayjs.utc(event.end).subtract(1, 'millisecond')
                });
                setEventEdit(true);
            }}
            eventDrop={(dropEvent: EventDragStopArg) => {
                const event = dropEvent.event;
                const eventId = parseInt(event.id, 10);
                const calendarEvent = data?.find(e => e.id == eventId);
                if (calendarEvent && event.start && event.end) {
                    void saveEventDates({
                        id: eventId,
                        dateStart: event.start,
                        dateEnd: event.end
                    });
                }
            }}
            datesSet={(e) => {
                setCurrentRange([e.view.activeStart, e.view.activeEnd]);
            }}
            locale={plLocale}
        />
    </Spin>;

    const style: React.CSSProperties = props.embedded
        ? { width: '100%', height: '100%' }
        : { flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' };

    return <>
        <div ref={containerRef} style={style}>
            {calendarEl}
        </div>
        <Modal
            title={eventId ? `Wydarzenie ${eventId}` : 'Nowe wydarzenie'}
            centered
            open={eventEdit}
            closable={true}
            onCancel={() => setEventEdit(false)}
            footer={<Space>
                {eventId ? <RemoveButton onConfirm={() =>
                    removeEvent(eventId)
                    .then(() => setEventEdit(false))
                }/> : null}
                <Button loading={eventSaving} onClick={() => {
                    form.validateFields().then((values) => {
                        const event = { ...values };
                        if (!values.installationCrewId) {
                            if (props.crewId) {
                                event.installationCrewId = props.crewId
                            } else if (calendarEvent?.installationCrewId) {
                                event.installationCrewId = calendarEvent.installationCrewId;
                            }
                        }
                        if (props.realisationId) {
                            event.realisationId = props.realisationId
                        } else if (calendarEvent?.realisationId) {
                            event.realisationId = calendarEvent.realisationId;
                        }
                        saveEvent(event).then(() => {
                            setEventEdit(false);
                        });
                    });
                }}>Zapisz</Button>
                <Button onClick={() => setEventEdit(false)}>Anuluj</Button>
            </Space>}
        >
            <Form form={form} labelCol={{ flex: '20%' }} wrapperCol={{ flex: 'auto' }} disabled={eventSaving}>
                <Form.Item name="id" hidden>
                    <NumericInput/>
                </Form.Item>
                <Form.Item name="name" label="Nazwa"
                    rules={[{ required: true }]}>
                    <Input/>
                </Form.Item>
                {props.crewMode ? <Form.Item name="installationCrewId" label="Ekipa"
                    rules={[{ required: props.crewMode }]}>
                    <Select loading={installationCrewsLoading}>
                        {installationCrews?.map(ic => <Select.Option value={ic.id} key={ic.id}>
                            {ic.name}
                        </Select.Option>)}
                    </Select>
                </Form.Item> : null}
                <Form.Item name="dateStart" label="Od"
                    rules={[{ required: true }]}>
                    <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
                </Form.Item>
                <Form.Item name="dateEnd" label="Do"
                    rules={[{ required: true }]}>
                    <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm"/>
                </Form.Item>
                <Form.Item name="description" label="Opis">
                    <Input.TextArea rows={5}/>
                </Form.Item>
            </Form>
        </Modal>
    </>;
}
