import React from "react";

export const UnimaLogo = () => {
    return <svg id="unima-logo-footer-svg" data-name="Warstwa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 694 227" style={{ width: 200 }}>
        <path className="cls-1"
            d="M276.62,103h-19V92.9h-.42A26,26,0,0,1,235,105c-21.26,0-26.61-12-26.61-30V30.25h20V71.36c0,12,3.51,17.88,12.81,17.88,10.84,0,15.48-6.06,15.48-20.84V30.25h20Z"></path>
        <path className="cls-1"
            d="M284.79,30.25h19V40.38h.42a26,26,0,0,1,22.24-12.1c21.26,0,26.61,12,26.61,30V103h-20V61.92c0-12-3.52-17.87-12.81-17.87-10.84,0-15.49,6.05-15.49,20.83V103h-20Z"></path>
        <path className="cls-1" d="M381.79,19h-20V2.52h20Zm-20,11.26h20V103h-20Z"></path>
        <path className="cls-1"
            d="M391.09,30.25H410V40.1h.28c5.21-7.46,12.81-11.82,22.25-11.82,9.15,0,17.31,3.38,21.25,12.1a26.46,26.46,0,0,1,22-12.1c15.48,0,26.6,7.18,26.6,26V103h-20V61.78c0-9.71-.84-17.73-12.24-17.73s-13.38,9.29-13.38,18.44V103h-20V62.21c0-8.45.56-18.16-12.11-18.16-3.94,0-13.51,2.53-13.51,16.75V103h-20Z"></path>
        <path className="cls-1"
            d="M510.19,52.63c1.13-18.72,17.88-24.35,34.21-24.35,14.5,0,32,3.24,32,20.69V86.84c0,6.62.71,13.24,2.54,16.19H558.62a30.32,30.32,0,0,1-1.41-7c-6.33,6.62-15.62,9-24.49,9-13.8,0-24.78-6.9-24.78-21.82,0-16.47,12.39-20.41,24.78-22.1s23.65-1.41,23.65-9.57c0-8.59-5.91-9.86-13-9.86-7.6,0-12.53,3.1-13.24,11Zm46.18,14.78c-3.38,3-10.42,3.1-16.61,4.23S527.93,75,527.93,82.34s5.77,9.29,12.25,9.29c15.63,0,16.19-12.39,16.19-16.75Z"></path>
        <path className="cls-1"
            d="M224.82,165.36c.56,12.67,6.75,18.44,17.87,18.44,8,0,14.51-4.93,15.77-9.43h17.6c-5.63,17.17-17.6,24.49-34.07,24.49-23,0-37.16-15.76-37.16-38.29,0-21.82,15.06-38.43,37.16-38.43,24.78,0,36.74,20.83,35.34,43.22Zm32.52-12.67c-1.83-10.14-6.2-15.49-15.91-15.49-12.67,0-16.33,9.86-16.61,15.49Z"></path>
        <path className="cls-1"
            d="M287.75,124.11h19v10.14h.42a26,26,0,0,1,22.24-12.11c21.26,0,26.61,12,26.61,30v44.76H336v-41.1c0-12-3.52-17.88-12.81-17.88-10.84,0-15.49,6.05-15.49,20.83v38.15h-20Z"></path>
        <path className="cls-1"
            d="M386.3,165.36c.56,12.67,6.76,18.44,17.88,18.44,8,0,14.5-4.93,15.77-9.43h17.59c-5.63,17.17-17.59,24.49-34.07,24.49-22.94,0-37.16-15.76-37.16-38.29,0-21.82,15.06-38.43,37.16-38.43,24.78,0,36.75,20.83,35.34,43.22Zm32.52-12.67c-1.83-10.14-6.19-15.49-15.91-15.49-12.67,0-16.33,9.86-16.61,15.49Z"></path>
        <path className="cls-1"
            d="M449.23,124.11h19v13.51h.28c3.66-9.15,13.51-15.48,23.23-15.48a14.9,14.9,0,0,1,4.36.7v18.59a35.84,35.84,0,0,0-7.18-.71c-14.64,0-19.71,10.56-19.71,23.37v32.8h-20Z"></path>
        <path className="cls-1"
            d="M572.56,192.25c0,12-4.22,32.23-37.73,32.23-14.36,0-31.11-6.75-32.1-23.36h19.85c1.83,7.46,7.89,10,14.92,10,11.13,0,16.19-7.6,16-18v-9.57h-.28c-4.36,7.6-13.09,11.26-21.82,11.26-21.82,0-31.11-16.61-31.11-36.6,0-18.87,10.84-36,31.25-36,9.58,0,16.9,3.24,21.68,11.68h.28v-9.71h19Zm-19-32.66c0-11.83-4.08-22.39-17-22.39-11.26,0-16.19,9.86-16.19,20.7,0,10.41,3.94,21.82,16.19,21.82C547.92,179.72,553.55,170,553.55,159.59Z"></path>
        <path className="cls-1" d="M607.76,112.85h-20V96.38h20Zm-20,11.26h20v72.78h-20Z"></path>
        <path className="cls-1"
            d="M621.28,146.49c1.12-18.72,17.88-24.35,34.21-24.35,14.5,0,32,3.24,32,20.69V180.7c0,6.62.71,13.24,2.54,16.19H669.7a29.79,29.79,0,0,1-1.4-7c-6.34,6.62-15.63,9-24.5,9C630,198.86,619,192,619,177c0-16.47,12.39-20.41,24.78-22.1s23.65-1.41,23.65-9.57c0-8.59-5.91-9.86-13-9.86-7.6,0-12.53,3.1-13.23,11Zm46.17,14.79c-3.38,2.95-10.42,3.09-16.61,4.22S639,168.88,639,176.2s5.78,9.29,12.25,9.29c15.63,0,16.19-12.39,16.19-16.75Z"></path>
        <path className="cls-1"
            d="M67.48,30.94A63.46,63.46,0,0,0,4,94.4V199.76H109.37a63.45,63.45,0,0,0,63.46-63.45V30.94Zm31.2,120.8h-20V141.67a26,26,0,0,1-22.2,12c-21.25,0-26.6-12-26.6-30V79h20v41.11c0,12,3.52,17.88,12.81,17.88,8.18,0,12.83-3.46,14.62-11.51L98.68,147.8Zm0-35.08v20.11L78.13,116.21c0-.71,0-1.42,0-2.17V86l21.38,21.37A43.48,43.48,0,0,0,98.68,116.66ZM147,151.74H127V110.63c0-12-3.52-17.88-12.81-17.88-5.87,0-9.91,1.8-12.4,5.77L82.2,79h16V89a26,26,0,0,1,22.19-12C141.62,77,147,89,147,107Z"></path>
    </svg>

}
