// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}
.Resizer:hover {
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 11px;
  margin: 0 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
}
.Resizer.horizontal:hover,
.Resizer.horizontal.resizing {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.vertical {
  width: 11px;
  margin: 0 0;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
.Resizer.vertical:hover,
.Resizer.vertical.resizing {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.DragLayer {
  z-index: 1;
  pointer-events: none;
}
.DragLayer.resizing {
  pointer-events: auto;
}
.DragLayer.horizontal {
  cursor: row-resize;
}
.DragLayer.vertical {
  cursor: col-resize;
}
`, "",{"version":3,"sources":["webpack://./web/resizer.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,UAAA;EACA,sBAAA;EACA,4BAAA;AACJ;AAEA;EACI,uBAAA;AAAJ;AAGA;EACI,YAAA;EACA,WAAA;EACA,4CAAA;EACA,+CAAA;EACA,kBAAA;AADJ;AAIA;;EACI,wCAAA;EACA,2CAAA;AADJ;AAIA;EACI,WAAA;EACA,WAAA;EACA,6CAAA;EACA,8CAAA;EACA,kBAAA;AAFJ;AAKA;;EACI,yCAAA;EACA,0CAAA;AAFJ;AAKA;EACI,UAAA;EACA,oBAAA;AAHJ;AAMA;EACI,oBAAA;AAJJ;AAOA;EACI,kBAAA;AALJ;AAQA;EACI,kBAAA;AANJ","sourcesContent":[".Resizer {\n    background: #000;\n    opacity: .2;\n    z-index: 1;\n    box-sizing: border-box;\n    background-clip: padding-box;\n}\n\n.Resizer:hover {\n    transition: all 2s ease;\n}\n\n.Resizer.horizontal {\n    height: 11px;\n    margin: 0 0;\n    border-top: 5px solid rgba(255, 255, 255, 0);\n    border-bottom: 5px solid rgba(255, 255, 255, 0);\n    cursor: row-resize;\n}\n\n.Resizer.horizontal:hover, .Resizer.horizontal.resizing {\n    border-top: 5px solid rgba(0, 0, 0, 0.5);\n    border-bottom: 5px solid rgba(0, 0, 0, 0.5);\n}\n\n.Resizer.vertical {\n    width: 11px;\n    margin: 0 0;\n    border-left: 5px solid rgba(255, 255, 255, 0);\n    border-right: 5px solid rgba(255, 255, 255, 0);\n    cursor: col-resize;\n}\n\n.Resizer.vertical:hover, .Resizer.vertical.resizing {\n    border-left: 5px solid rgba(0, 0, 0, 0.5);\n    border-right: 5px solid rgba(0, 0, 0, 0.5);\n}\n\n.DragLayer {\n    z-index: 1;\n    pointer-events: none;\n}\n\n.DragLayer.resizing {\n    pointer-events: auto;\n}\n\n.DragLayer.horizontal {\n    cursor: row-resize;\n}\n\n.DragLayer.vertical {\n    cursor: col-resize;\n}\n\n@layout-header-background: #1890ff;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
