import React, { useState } from "react";
import { Button, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "./icons";
import { useIsMounted } from "../hooks/mounted.hook";

export const RemoveButton = ({ onConfirm, disabled, link, children }:
    { onConfirm: () => Promise<void>, disabled?: boolean, link?: boolean, children?: React.ReactNode }) => {

    const [loading, setLoading] = useState(false);
    const isMounted = useIsMounted();

    const confirm = () => {
        setLoading(true);
        onConfirm().finally(() => {
            if (isMounted.current) {
                setLoading(false);
            }
        })
    }

    return <Popconfirm
        disabled={disabled || loading}
        title="Jesteś pewien ?"
        icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
        onConfirm={() => confirm()}
        okText="Tak"
        cancelText="Nie"
    >
        <Button danger type={link ? "link" : "default"} disabled={disabled || loading} loading={loading}>
            {children ? children : 'Usuń'}
        </Button>
    </Popconfirm>
}
