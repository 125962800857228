import React from 'react';
import { createRoot } from 'react-dom/client';
import "@fortawesome/fontawesome-free/css/all.css";
import "./index.less";
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, Layout } from "antd";
import { AppHeader } from "./header";
import plPL from "antd/lib/locale/pl_PL"
import "dayjs/locale/pl";

import { initEcbLanguage } from "./views/calculators/formula-editor";
import { initMarkdownLanguage } from "./components/markdown-editor";
import { AppRoutes } from "./app-routes";
import { loader } from "@monaco-editor/react";
import * as monaco from 'monaco-editor';
import { QueryClient, QueryClientConfig, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import { appTheme } from "./theme";

const queryConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            // networkMode: "always"
        }
    }
};

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale("pl");

const queryClient = new QueryClient(queryConfig);

function initApp() {
    const rootEl = document.getElementById('root');
    const root = createRoot(rootEl!);
    root.render(
        <ConfigProvider locale={plPL} theme={{
            token: appTheme
        }}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Layout>
                        <AppHeader/>
                        <AppRoutes/>
                    </Layout>
                </BrowserRouter>
            </QueryClientProvider>
        </ConfigProvider>,
    );
}

loader.config({ monaco });
initEcbLanguage(monaco);
initMarkdownLanguage(monaco);
initApp();
