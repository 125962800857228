import React from "react";
import { Button, DatePicker, Input, notification, Select, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { usePaginatedSqlQuery } from "../../hooks/sql-query.hook";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { useAssignedUsers, useUser } from "../../hooks/user.hook";
import { appStore } from "../../store/store";
import { useDebounce } from "../../hooks/debaunce.hook";
import { getListCriteria, withInCriteria } from "../../utils/sql";

import { CreatedBy } from "../../components/created-by";
import { RemoveButton } from "../../components/remove-button";
import { backendDelete } from "../../api/backend-api";
import { TableView } from "../../components/table-view";
import { filterByLabel } from "../../utils/utils";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dayjs } from "dayjs";

export const InstallationCrews = () => {
    const queryClient = useQueryClient();
    const user = useUser();
    const filter = appStore.useState(s => s.installationCrewList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const { mutateAsync: removeInstallationCrew } = useMutation((installationCrewId: number) => {
        return backendDelete(`/installation-crews/${installationCrewId}`)
            .then(() => {
                void queryClient.invalidateQueries(['installation-crews']);
                notification.success({
                    message: 'Ekipa została usunięta'
                });
            })
    });

    const criteria = {...filter, search: debouncedSearch};
    const where = getListCriteria(
        [t.ic.id, t.ic.name, t.ic.crew],
        t.ic.createdAt, t.ic.deletedAt,
        criteria
    )
        + withInCriteria(t.ic.ownerId, filter.users);

    const { total, data, loading } = usePaginatedSqlQuery(
        'installation-crews', criteria,
        from(t.installationCrew.as(t.ic))
        .join(t.user.as(t.u).id, t.ic.ownerId)
        .where(where)
        .orderByDesc(t.ic.id)
        .select({
            key: t.ic.id,
            id: t.ic.id,
            name: t.ic.name,
            ownerId: t.ic.ownerId,
            createdAt: t.ic.createdAt,
            createdBy: t.u.fullName()
        })
    );

    const { users, usersLoading } = useAssignedUsers();

    const columns: ColumnsType<typeof data[number]> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
        width: '60%'
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: (value, row) =>
            <CreatedBy createdAt={value} createdBy={row.createdBy} fullInfo={user.permissions.creatorName}/>
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (_, item) => {
            return <Space>
                {user.permissions.installationCrewList.edit([item.ownerId]) ?
                    <Link to={baseUrl(`/installation-crews/${item.id}/data`)}>Edytuj</Link> : null}
                {user.permissions.installationCrewList.remove([item.ownerId]) ?
                    <RemoveButton link disabled={!user.permissions.installationCrewList.removeEnable} onConfirm={() => removeInstallationCrew(item.id)}/> : null}
            </Space>
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading}
        scrollWidth={1000}
        listKey={'installationCrewList'}
        toolbar={
            <>
                <Link to={baseUrl("/installation-crews/create")}><Button>Dodaj ekipę</Button></Link>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.installationCrewList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.installationCrewList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['installation-crews']);
                        }}
                    />
                    {user.permissions.userFilter ?
                        <Select mode="multiple" placeholder="Opiekun" style={{ width: 250 }} value={filter.users} loading={usersLoading} options={users} onChange={value => {
                            appStore.update(s => {
                                s.installationCrewList.users = value;
                            });
                        }}
                            filterOption={filterByLabel}
                            allowClear={true}/> : null}
                    <Input style={{ width: 200 }} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.installationCrewList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
