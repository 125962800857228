import { FieldScope, FieldType, LayoutItem } from "./layout-types";
import React from "react";
import { GridCellPos } from "../../components/absolute-layout/absolute-layout";
import { UserRole } from "../../models/user";

export const makeElementStyle = (item: LayoutItem) => {
    const getFlexAlign = (align?: number) => align === 1
        ? 'center'
        : (align === 2)
            ? 'flex-end'
            : 'flex-start'
    ;

    const style = {} as React.CSSProperties;
    style.fontSize = item.fontSize;
    style.fontWeight = item.fontWeight;
    style.color = item.textColor;
    style.backgroundColor = item.backgroundColor;
    style.display = 'flex';
    style.alignItems = getFlexAlign(item.vertAlign);
    style.overflow = 'hidden';
    style.justifyContent = getFlexAlign(item.horzAlign);
    if (item.fieldType === FieldType.TextInput || item.fieldType === FieldType.NumericInput) {
        style.height = '100%';
    }
    return style;
}

export function getGridCellPos(g: GridCellPos): GridCellPos {
    return {
        x0: g.x0,
        xl0: g.xl0,
        xp0: g.xp0,
        y0: g.y0,
        yl0: g.yl0,
        yp0: g.yp0,
        x1: g.x1,
        xl1: g.xl1,
        xp1: g.xp1,
        y1: g.y1,
        yl1: g.yl1,
        yp1: g.yp1
    }
}

export function isItemVisible(item: LayoutItem, roles: UserRole[]): boolean {
    return item.fieldScope === FieldScope.Public || (item.fieldScope === FieldScope.Limited &&
        item.fieldRoles.find(fr => roles.find(r => r === fr)) !== undefined);
}
