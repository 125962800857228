import React, { useEffect } from 'react';
import { useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { CalculatorEvaluator } from "../calculators/calculator-evaluator";
import { useUser } from "../../hooks/user.hook";
import { backendPut } from "../../api/backend-api";
import { parseJson } from "../../utils/utils";
import { from, QueryField } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { CalculatorData } from "../calculators/layout-types";
import { useMutation } from "@tanstack/react-query";
import { useResizeObserver } from "../../hooks/resize-observer.hook";
import { Query } from "./offer-query";

export const OfferCalculator = ({ offerId, onCalculatorUpdate, tabsId }: { offerId: number | undefined, onCalculatorUpdate?: (updating: boolean) => void, tabsId: string }) => {
    const { user } = useUser();

    const contentSize = useResizeObserver(".ant-tabs-content-holder");

    const { data: offer, loading: offerLoading } = Query.Offer.use(offerId);

    const { data, loading } = useSqlQuerySingle(
        `offer-calculator-${offerId}`,
        from(t.offer, t.o)
        .where(t.o.id.eq(offerId))
        .select({
            id: t.o.id,
            calculatorId: t.o.calculatorId,
            calculatorData: t.o.calculatorData,
            ownerId: t.o.ownerId
        }), {
            enabled: offerId,
            map: row => {
                return {
                    ...row,
                    calculatorData: parseJson(row.calculatorData) as QueryField<CalculatorData>
                }
            }
        }
    );

    const { mutateAsync: saveCalculatorData, isLoading: savingCalculatorData } = useMutation((values: any) => {
        return backendPut(`/offers/${offerId}/data`, JSON.stringify(values))
    });

    useEffect(() => {
        if (onCalculatorUpdate) {
            onCalculatorUpdate(savingCalculatorData);
        }
    }, [savingCalculatorData]);

    return offer ? <CalculatorEvaluator
        width={contentSize.width}
        height={contentSize.height - 16}
        calculatorId={offer.calculatorId}
        calculatorData={data?.calculatorData}
        roles={[user.secondaryRole ?? user.role]}
        onChange={values => saveCalculatorData(values)}
        disabled={!!offer.acceptedAt}
        loading={offerLoading || loading || savingCalculatorData}
    /> : null
}
