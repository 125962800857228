import { getUser } from "../api/backend-api";
import {
    getUserPermissions,
    isAdmin,
    isBackOffice,
    isCoordinator,
    isDesigner, isDirector,
    isManager,
    isTrader,
    UserInfo,
    UserRole
} from "../models/user";
import { useParams } from "react-router-dom";
import { useSqlQuery } from "./sql-query.hook";
import { appStore } from "../store/store";
import { from } from "../utils/sql-builder";
import { t } from "../models/db";

export function useUser() {
    const secondaryRole = appStore.useState(state => state.secondaryRole);
    const user = {
        ...getUser(),
        secondaryRole
    } as UserInfo;
    const trader = isTrader(user);
    const admin = isAdmin(user);
    const manager = isManager(user);
    const backOffice = isBackOffice(user);
    const designer = isDesigner(user);
    const coordinator = isCoordinator(user);
    const director = isDirector(user);
    const { id } = useParams();
    const routeId = parseInt(id ?? "");
    const permissions = getUserPermissions(user)

    return { user, routeId, trader, manager, admin, director, backOffice, designer, coordinator, permissions, userRole: secondaryRole ?? user.role };
}

export function useAssignedUsers(role?: UserRole) {
    const { admin, backOffice, user } = useUser();

    const data = useSqlQuery(
        `assigned-users-${user.id}-${role}`,
        from(t.user.as(t.u))
        .where(t.u.role.eq(role), role)
        .select({
            key: t.u.id,
            value: t.u.id,
            label: t.u.fullName()
        }), {
            enabled: admin || backOffice || user.id
        });

    return { users: data.data, usersLoading: data.loading }
}
