import React, { useEffect, useRef, useState } from "react";
import { sql, useSqlQueryRaw } from "../../hooks/sql-query.hook";
import Editor, { loader, Monaco } from "@monaco-editor/react";
import {
    CancellationToken,
    editor,
    languages,
    MarkerSeverity,
    Position
} from "monaco-editor/esm/vs/editor/editor.api.js";
import { ecbLanguageConf, ecbMonarchLanguage } from "./ecb-lang-def";
import CompletionItemProvider = languages.CompletionItemProvider;
import CompletionItemKind = languages.CompletionItemKind;
import CompletionItem = languages.CompletionItem;

const ECB_LANG_ID = "ecb";

export interface FormulaError {
    line: number;
    startIdx: number;
    stopIdx: number;
    error: string;
}

interface FormulaEditorProps {
    value?: string,
    errors: FormulaError[];
    onChange?: (value: string) => void,
    calculatorId: number;
    onMatchFieldId?: (fieldId: string | null) => void;
}

export const FormulaEditor = ({ value, errors, onChange, calculatorId, onMatchFieldId }: FormulaEditorProps) => {
    const editorRef = useRef<editor.IStandaloneCodeEditor>();
    const monacoRef = useRef<Monaco>();
    const [editorValue, setEditorValue] = useState('');

    const { data: rawFields, loading: rawFieldsLoading } = useSqlQueryRaw<{ field: string }>(
        `raw-fields-${calculatorId}`,
        sql`
            select coalesce(ci.alias, ci.field_id) || ' (' ||
                   case
                       when ci.field_type = 0 then cd.dict_name
                       when ci.field_type = 1 then 'tekst'
                       when ci.field_type = 2 then 'liczba'
                       when ci.field_type = 3 then 'formuła'
                       when ci.field_type = 4 then 'tekst'
                       else ''
                   end || ')' as field, ci.owner_id
            from calculator_item ci
                 left join calculator_dict cd
                 on cd.id = ci.calculator_dict_id
            where ci.calculator_id = ${calculatorId}
              and ci.field_type in (0, 1, 2, 3, 4)
            order by coalesce(ci.alias, ci.field_id)
        `,
        { enabled: calculatorId }
    );


    const { data: columnFields, loading: columnFieldsLoading } = useSqlQueryRaw<{ field: string }>(
        `fields-${calculatorId}`,
        sql`
            select coalesce(ci.alias, ci.field_id) || ':' || cdc.column_id as field, ci.owner_id
            from calculator_item ci
                 inner join calculator_dict cd
                 on cd.id = ci.calculator_dict_id
                 inner join calculator_dict_column cdc
                 on cd.id = cdc.calculator_dict_id
            where ci.calculator_id = ${calculatorId}
              and cdc.column_type in (0, 1, 2, 3)
            order by field
        `,
        { enabled: calculatorId }
    );

    const { data: dictFields, loading: dictFieldsLoading } = useSqlQueryRaw<{ field: string }>(
        `rowFields-${calculatorId}`,
        sql`
            select cd.dict_id || ':' || cdc.column_id || ':' || '{numer-wiersza}' as field, cd.owner_id
            from calculator_dict cd
            inner join calculator_dict_column cdc on cd.id = cdc.calculator_dict_id
            where cd.calculator_id = ${calculatorId}
              and cdc.column_type in (0, 1, 2, 3)
            order by cd.id, cdc.id`,
        { enabled: calculatorId }
    );

    useEffect(() => {
        loader.init().then(monaco => (monacoRef.current = monaco));
    }, []);

    useEffect(() => {
        if (errors && monacoRef.current && editorRef.current) {
            monacoRef.current.editor.setModelMarkers(
                editorRef.current.getModel()!,
                "",
                errors.map(err => {
                    return {
                        startLineNumber: err.line,
                        endLineNumber: err.line,
                        startColumn: err.startIdx + 1,
                        endColumn: err.stopIdx + 1,
                        severity: MarkerSeverity.Error,
                        message: err.error
                    };
                })
            );
        }
    });

    useEffect(() => {
        const handler = monacoRef.current?.languages.registerCompletionItemProvider(
            ECB_LANG_ID,
            makeEcbCompletionItemProvider(rawFields && columnFields && dictFields
                ? rawFields.concat(columnFields).concat(dictFields)
                : []
            )
        );
        return () => {
            handler?.dispose()
        }
    }, [monacoRef.current, rawFields, columnFields, dictFields])

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.onDidChangeCursorPosition(evt => {
                const offset = editorRef.current?.getModel()?.getOffsetAt(evt.position);
                const v = editorRef.current?.getModel()?.getValue() ?? '';
                const p = (offset ?? 0) - 1;
                let s = p;
                while (s >= 0 && v[s] != '[' && v[s] != ']') --s;
                let e = s;
                while (e >= 0 && e < v.length && v[e] != ':' && v[e] !=  ']') ++e;

                const fld = s != e
                    ? v.substring(s + 1, e)
                    : null;

                if (onMatchFieldId) {
                    onMatchFieldId(fld);
                }
            });
            editorRef.current.onDidChangeModelContent(() => {
                const value = editorRef.current?.getValue();
                setEditorValue(value ?? '');
            });
        }
    }, [editorRef.current]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (onChange) {
                onChange(editorValue ?? '');
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [editorValue]);

    useEffect(() => {
        setEditorValue(value ?? '');
    }, [value]);

    return (
        <Editor
            className="formula-editor"
            onMount={editor => editorRef.current = editor}
            height="300px"
            language={ECB_LANG_ID}
            value={editorValue}
            theme={ECB_LANG_ID}
            loading={columnFieldsLoading || dictFieldsLoading || rawFieldsLoading}
            options={{
                lineNumbers: "off",
                minimap: {
                    enabled: false
                },
                scrollbar: {
                    vertical: "hidden",
                    horizontal: "hidden"
                },
                renderLineHighlight: "none",
                glyphMargin: false,
                folding: false,
                fontSize: 13,
                lineDecorationsWidth: 0,
                lineNumbersMinChars: 0,
                wordWrap: "on",
                suggestOnTriggerCharacters: true,
                padding: {
                    top: 0,
                    bottom: 0
                },
                fixedOverflowWidgets: true
            }}
        />
    )
}

function num3(n: number): string {
    if (n < 10) {
        return `00${n}`
    } else if (n < 100) {
        return `0${n}`
    } else {
        return `${n}`
    }
}

const makeEcbCompletionItemProvider = (fields: { field: string }[]): CompletionItemProvider => {
    return {
        triggerCharacters: ["["],
        provideCompletionItems(
            model: editor.ITextModel,
            position: Position,
            context: languages.CompletionContext,
            token: CancellationToken
        ): languages.ProviderResult<languages.CompletionList> {
            const word = model.getWordUntilPosition(position);
            const range = {
                startLineNumber: position.lineNumber,
                endLineNumber: position.lineNumber,
                startColumn: word.startColumn,
                endColumn: word.endColumn
            };
            return {
                incomplete: false,
                suggestions: fields
                .map(
                    (f, idx) => ({
                        label: f.field,
                        kind: CompletionItemKind.Field,
                        insertText: f.field.replace(/\s+\(.*/, ''),
                        range,
                        sortText: `a${num3(idx)}`
                    } as CompletionItem)
                ).concat(['IF', 'ROUND_UP', 'ROUND_DOWN', 'CEIL', 'FLOOR'].map(f => ({
                        label: f,
                        kind: CompletionItemKind.Function,
                        insertText: f,
                        range,
                        sortText: `b`
                    } as CompletionItem
                ))).concat(['TRUE', 'FALSE', 'disabled', 'disabled_value', 'hidden', 'hidden_value'].map(f => ({
                        label: f,
                        kind: CompletionItemKind.Keyword,
                        insertText: f,
                        range,
                        sortText: `b`
                    } as CompletionItem
                )))
            };
        },
        resolveCompletionItem(
            item: languages.CompletionItem,
            token: CancellationToken
        ): languages.ProviderResult<languages.CompletionItem> {
            return undefined;
        }
    }
};

export function initEcbLanguage(monaco: Monaco) {
    monaco.languages.register({
        id: ECB_LANG_ID
    });
    monaco.languages.setLanguageConfiguration(ECB_LANG_ID, ecbLanguageConf);
    monaco.languages.setMonarchTokensProvider(
        ECB_LANG_ID,
        ecbMonarchLanguage
    );
    monaco.editor.defineTheme(ECB_LANG_ID, {
        base: "vs",
        inherit: true,
        rules: [
            { token: 'field', foreground: 'ca7a7a' },
            { token: 'number', background: 'ff0000' },
            { token: 'string.double', foreground: 'ff0000' },
            { token: 'comment', foreground: '9a9a9a' },
        ],
        colors: {

        }
        // colors: {
        //     'editor.background': '#e3dec4',
        //     'field': '#ca7a7a'
        // }
    })
}
