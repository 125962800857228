import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { notification } from "antd";
import { baseUrl } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useUser } from "../../hooks/user.hook";
import omit from "lodash/omit";
import { Model } from "../../models/db";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { Query } from "./agreement-query";

export const useSaveAgreement = () => {
    const navigate = useNavigate();
    const user = useUser();
    return useMutation(async (agreement: Model.Agreement.Data) => {
        if (user.admin || !agreement.acceptedAt) {
            const agreementId = await (agreement.id !== undefined
                ? backendPut<void>(`/agreements/${agreement.id}`, omit(agreement, 'acceptedAt'))
                : backendPost<number>('/agreements', agreement)
            );
            notification.success({
                message: 'Umowa została zapisana'
            });
            if (agreementId) {
                navigate(baseUrl(`/agreements/${agreementId}/data`));
            }
        }
        return Promise.resolve();
    });
}

export const useAcceptAgreement = () => {
    const queryClient = useQueryClient();
    return useMutation(async (agreementId: number) => {
        const agreementAccepted = await backendPut(`/agreements/${agreementId}/accept`);
        if (agreementAccepted) {
            notification.success({
                message: 'Umowa została zaakceptowana'
            });
        }
        refreshAgreement(queryClient, agreementId);
    });
}

export const useMoveAgreementToRealisation = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation(async (agreementId: number) => {
        const realisationId = await backendPost(`/agreements/${agreementId}/realise`);
        refreshAgreement(queryClient, agreementId);
        notification.success({
            key: `agreement-${agreementId}`,
            message: <div>
                <div>{`Umowa została przekazana do realizacji ${realisationId}`}</div>
                <div><a onClick={() => {
                    notification.destroy(`agreement-${agreementId}`);
                    navigate(baseUrl(`/realisations-verification/${realisationId}/data`))
                }}>Przejdź do realizacji</a></div>
            </div>,
            duration: 0
        });
        navigate(baseUrl("/agreements"));
    });
}

export const useRemoveAgreement = () => {
    const navigate = useNavigate();
    return useMutation(async (agreementId: number) => {
        await backendDelete(`/agreements/${agreementId}`);
        notification.success({
            message: 'Umowa została usunięta'
        });
        navigate(baseUrl("/agreements"));
    });
}

export function refreshAgreement(queryClient: QueryClient, agreementId: number | undefined) {
    if (agreementId) {
        void queryClient.invalidateQueries([Query.Agreement.key(agreementId)]);
    }
}
