import { DashboardCard } from "../dashboard-card";
import React from "react";
import { usePaginatedSqlQuery } from "../../../hooks/sql-query.hook";
import { from } from "../../../utils/sql-builder";
import { t } from "../../../models/db";
import { ColumnsType } from "antd/lib/table";
import { CreatedBy } from "../../../components/created-by";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../utils/navigation";
import { TableView } from "../../../components/table-view";
import { appStore } from "../../../store/store";

export const ClientsToAccept = () => {
    const filter = appStore.useState(s => s.clientToAcceptList);

    const { total, data, loading } = usePaginatedSqlQuery(
        'clients-to-accept', filter,
        from(t.client.as(t.cl))
        .join(t.user.as(t.u).id, t.cl.userId)
        .where(t.cl.acceptedAt.isNull())
        .orderBy(t.cl.fullName())
        .select({
            id: t.cl.id,
            name: t.cl.fullName(),
            nip: t.cl.nip,
            createdAt: t.cl.createdAt,
            createdBy: t.u.fullName()
        })
    );

    const columns: ColumnsType<typeof data[number]> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
        width: '60%'
    }, {
        title: 'NIP / Pesel',
        dataIndex: 'uid',
        width: '40%'
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: (value, row) =>
            <CreatedBy createdAt={value} createdBy={row.createdBy} fullInfo={true}/>
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: (_, item) => {
            return <Space>
                <Link to={baseUrl(`/clients/${item.id}/data`)}>Edytuj</Link>
            </Space>
        }
    }];

    return <DashboardCard title="Klienci do akceptacji" key="clients-to-accept">
        <TableView
            columns={columns}
            data={data}
            total={total}
            loading={loading}
            scrollWidth={1000}
            listKey={'clientToAcceptList'}
            style={{ height: '100%' }}
        />
    </DashboardCard>
}
