import { RefObject, useEffect, useRef, useState } from "react";
import { isString } from "lodash";

interface ElementSize {
    width: number;
    height: number;
    marginLeft: number;
    marginRight: number;
    marginTop: number;
    marginBottom: number;
    paddingLeft: number;
    paddingRight: number;
    paddingTop: number;
    paddingBottom: number;
}

function getElementSize(entry: ResizeObserverEntry): ElementSize {
    const style = window.getComputedStyle(entry.target);
    return {
        width: entry.contentRect.width,
        height: entry.contentRect.height,
        marginLeft: parseInt(style.getPropertyValue('margin-left'), 10),
        marginRight: parseInt(style.getPropertyValue('margin-right'), 10),
        marginTop: parseInt(style.getPropertyValue('margin-top'), 10),
        marginBottom: parseInt(style.getPropertyValue('margin-bottom'), 10),
        paddingLeft: parseInt(style.getPropertyValue('padding-left'), 10),
        paddingRight: parseInt(style.getPropertyValue('padding-right'), 10),
        paddingTop: parseInt(style.getPropertyValue('padding-top'), 10),
        paddingBottom: parseInt(style.getPropertyValue('padding-bottom'), 10),
    }
}

export const useResizeObserverRef = (elRef: RefObject<Element>): ElementSize => {
    const [size, setSize] = useState<ElementSize>({
        width: 0,
        height: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0
    });

    const observer = new ResizeObserver((ns: ResizeObserverEntry[]) => {
        const style = window.getComputedStyle(ns[0].target);
        setSize(getElementSize(ns[0]));
    });

    useEffect(() => {
        if (elRef.current) {
            observer.observe(elRef.current);
        }
        return () => {
            observer.disconnect();
        }
    }, [elRef.current])

    return size;
}

export const useResizeObserver = (selector: string | React.RefObject<Element> | undefined | null, enabled: any = true): ElementSize => {
    const [size, setSize] = useState<ElementSize>({
        width: 0,
        height: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0
    });

    const observer = new ResizeObserver((ns: ResizeObserverEntry[]) => {
        setSize(getElementSize(ns[0]));
    });

    useEffect(() => {
        if (enabled) {
            const el = isString(selector) ? document.querySelector(selector) : selector?.current;
            if (el) {
                observer.observe(el);
            }
        }
        return () => {
            observer.disconnect();
        }
    }, [selector, enabled])

    return size;
}
