import React from "react";
import { Button, Checkbox, DatePicker, Input, Select, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { useAssignedUsers, useUser } from "../../hooks/user.hook";
import { useDebounce } from "../../hooks/debaunce.hook";
import { appStore } from "../../store/store";
import { CreatedBy } from "../../components/created-by";
import { RemoveButton } from "../../components/remove-button";
import { TableView } from "../../components/table-view";
import { filterByLabel } from "../../utils/utils";
import { useRemoveAgreement } from "./agreement.hook";
import { useQueryClient } from "@tanstack/react-query";
import { NvlLink } from "../../components/nvl-link";
import { Dayjs } from "dayjs";
import { Query } from "./agreement-query";

export const Agreements = () => {
    const queryClient = useQueryClient();
    const user = useUser();
    const filter = appStore.useState(s => s.agreementList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const { mutateAsync: removeAgreement } = useRemoveAgreement();
    const criteria = {...filter, search: debouncedSearch};
    const { data, total, loading } = Query.Agreements.use(criteria);
    const { users, usersLoading } = useAssignedUsers();

    const columns: ColumnsType<Query.Agreements.T> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
        width: '30%'
    }, {
        title: 'Klient',
        dataIndex: 'client',
        width: '30%',
        render: (value, row) => <NvlLink value={row.client} link={baseUrl(`/clients/${row.clientId}/data`)}/>
    }, {
        title: 'Oferta',
        dataIndex: 'offer',
        width: '30%',
        render: (value, row) => <NvlLink value={row.offer} link={baseUrl(`/offers/${row.offerId}/data`)}/>
    }, {
        title: 'Zaakceptowana',
        dataIndex: 'accepted',
        width: 170,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.accepted
    }, {
        title: 'Przekazana do realizacji',
        dataIndex: 'realised',
        width: 150,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.realised
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: (value, row) => <CreatedBy createdAt={value} createdBy={row.createdBy} fullInfo={user.permissions.creatorName}/> ,
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (_, item) => {
            return <Space>
                {user.permissions.agreementList.edit([item.ownerId]) ?
                    <Link to={baseUrl(`/agreements/${item.id}/data`)}>Edytuj</Link> : null}
                {user.permissions.agreementList.remove([item.ownerId]) ?
                    <RemoveButton link disabled={!user.permissions.agreementList.removeEnable(item.accepted)}
                        onConfirm={() => removeAgreement(item.id)}/> : null}
            </Space>
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading}
        scrollWidth={1240}
        listKey={'agreementList'}
        onFiltersChange={filters => {
            appStore.update(s => {
                s.agreementList.accepted = filters.accepted;
                s.agreementList.realised = filters.realised;
            });
        }}
        toolbar={
            <>
                <Link to={baseUrl("/agreements/create")}><Button>Dodaj umowę</Button></Link>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.agreementList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.agreementList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['agreements']);
                        }}
                    />
                    {user.permissions.userFilter ?
                        <Select mode="multiple" placeholder="Opiekun" style={{ width: 250 }} value={filter.users}
                            options={users} loading={usersLoading} onChange={value => {
                            appStore.update(s => {
                                s.agreementList.users = value;
                            });
                        }}
                            filterOption={filterByLabel}
                            allowClear={true}/> : null}
                    <Input style={{ width: 200 }} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.agreementList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
