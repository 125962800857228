import { AxiosResponse } from "axios";
import { FilterFunc } from "rc-select/lib/Select";
import { FormInstance } from "antd";
import { parse as parseContentDisposition } from "content-disposition-header";
import dayjs from "dayjs";
import React from "react";

export type Nil<T> = T | null | undefined;
export type Overwrite<T, NewT> = Omit<T, keyof NewT> & NewT;

export function parseJson(value: any): any {
    try {
        return JSON.parse(value);
    } catch(e) {
        console.error("Json parsing error", e)
        return {}
    }
}

export function formatDate(date: string | number | undefined): string | null {
    return date ? dayjs(date).format("DD.MM.YYYY") : null;
}

export function formatDatetime(date: string | number | undefined, seconds = true, time = true): string | null {
    let dateFormat = "DD.MM.YYYY";
    if (time) {
        dateFormat += " HH:mm";
    }
    if (seconds) {
        dateFormat += ":SS";
    }
    return date ? dayjs(date).format(dateFormat) : null;
}

export function formatDatetimeRange(dateStart: string | number | undefined, dateEnd: string | number | undefined, time = true): string | null {
    const ds = formatDatetime(dateStart, false, time);
    const de = formatDatetime(dateEnd, false, time);
    return ds && de ? `${ds} - ${de}` : null;
}

export function formatMoney(value: number | null, decimalCount = 2, decimal = ".", thousands = ","): string | null {
    if (value === null) {
        return null;
    }
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = value < 0 ? "-" : "";
        const amount = Math.abs(Number(value) || 0).toFixed(decimalCount)
        const iv = parseInt(amount);
        let i = iv.toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign +
            (j ? i.substring(0, j) + thousands : '') +
            i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount ? decimal + Math.abs(value - iv).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        return null;
    }
}

export function round(value: number | null): string | null {
    return value !== null ? value.toFixed(2) : null;
}

export function downloadBlob(response: AxiosResponse) {
    const cd = parseContentDisposition(response.headers?.['content-disposition'] ?? '');
    let url = window.URL.createObjectURL(new Blob([response.data], { type: "application/x-download" }));
    let a = document.createElement('a');
    a.href = url;
    a.download = cd.parameters['filename'];
    a.click();
}

declare var GIT_COMMIT: string;
declare var BUILD_NUMBER: string;
declare var DEV_ENV: boolean;

export const getGitCommit = () => `GIT-${GIT_COMMIT}`;
export const getBuildNumber = () => `BN-${BUILD_NUMBER}`;
export const getBuildInfo = () => `${getBuildNumber()} / ${getGitCommit()}`;
export const isDevEnv = () => DEV_ENV;

export function nullString(s: string | undefined | null): string | null {
    const sn = (s ?? '').trim();
    return sn.length === 0 ? null : sn;
}

export function withExtension(filename: string | undefined, ext: string): string {
    const pos = filename?.lastIndexOf(".") ?? 0;
    return (filename?.substr(0, pos < 0 ? filename.length : pos) ?? "") + "." + ext;
}

export function getFileExtension(filename: string | undefined): string | undefined {
    const pos = filename?.lastIndexOf(".") ?? -1;
    return pos >= 0 ? filename!!.substr(pos + 1) : undefined;
}

export function getPreviewFileExtension(filename: string): string {
    const ext = getFileExtension(filename) ?? 'jpg';
    if (ext.toLowerCase() === "pdf") {
        return "jpg";
    }
    return ext;
}

export function getBase64RawData(base64Data: string) {
    const headerEnd = base64Data.indexOf(',');
    return base64Data.substring(headerEnd + 1);
}

export function readUploadedFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = evt => {
            const base64File = evt.target?.result as string ?? '';
            if (base64File.length > 0) {
                resolve(base64File);
            } else {
                reject("Error file uploading");
            }
        }
        reader.onerror = function() {
            reject("Error file uploading")
        }
    });
}

export const filterByLabel: FilterFunc<{ label: string }> = (inputValue, option) => {
    return (option ? option.label.toLowerCase().indexOf(inputValue.toLowerCase()) : -1) >= 0;
}

export const filterBySearch: FilterFunc<{ search: string }> = (inputValue, option) => {
    return (option ? option.search.toLowerCase().indexOf(inputValue.toLowerCase()) : -1) >= 0;
}

export const filterByChildren: FilterFunc<{ children: any }> = (inputValue, option) => {
    return (option ? option.children.toString().toLowerCase().indexOf(inputValue.toLowerCase()) : -1) >= 0;
}

export const isFormValid = (form: FormInstance): boolean => {
    return !form.getFieldsError().some(field => field.errors.length > 0);
}

export function makeBlob(data: Blob | string) {
    return (data instanceof Blob) ? URL.createObjectURL(data) : data;
}

export function getPastelColor(input: number): string {
    const h = (input * 137.508) % 360;
    return `hsl(${h}, 60%, 75%)`;
}

export function isStringEqual(a: string | null | undefined, b: string | null | undefined) {
    const a0 = a ?? '';
    const b0 = b ?? '';
    return a0 === b0;
}

export function renderVersion(version: number | undefined, components: React.ReactNode[]) {
    if (version !== undefined) {
        for (let i = 1; i <= components.length; i++) {
            if (version === i) {
                return components[i - 1];
            }
        }
    }
    return null;
}

export function hashCode(str: string): number {
    let hash = 0, i = 0, len = str.length;
    while (i < len) {
        hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
    }
    return hash + 2147483647 + 1;
}

export function getFieldContentType(s: string | undefined): number {
    if (!s) {
        return 0;
    }
    if (s.match(/^\s*"\w+"[\s|;]*]$/)) {
        return 1;
    }
    if (s.match(/^\s*[0-9]+('.'[0-9]+)?[\s|;]*$/)) {
        return 2;
    }
    return 3;
}

export function getFieldContent(s: string | undefined): string | undefined {
    if (s) {
        let r = s.match(/^\s*"(.*)"[\s|;]*$/);
        if (r) {
            return r[1];
        }
        r = s.match(/^\s*[0-9]+('.'[0-9]+)?[\s|;]*$/);
        if (r) {
            return r[1];
        }
    }
    return undefined;
}
