import React, { useState } from "react";
import { Button, notification, Space } from "antd";
import { backendGet, backendPost } from "../../api/backend-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CalculatorSelect } from "../../components/selects/calculator-select";
import { Query } from "../offers/offer-query";
import TextArea from "antd/lib/input/TextArea";

export const SuperAdmin = () => {
    const queryClient = useQueryClient();

    const { data: calculators, loading: calculatorsLoading } = Query.SuperAdmin.Calculators.use();
    const [calculatorJson, setCalculatorJson] = useState('');

    const { mutateAsync: validateCalculators, isLoading: validatingCalculators } = useMutation(() => {
        return backendGet(`/evaluation/validate-all`)
        .then(() => {
            notification.success({
                message: 'Weryfikacja ukończona'
            });
        })
    });

    const { mutateAsync: exportCalculator, isLoading: exportingCalculator } = useMutation(async (calculatorId: number) => {
        const data = await backendGet(`/export/calculator/${calculatorId}`);
        notification.success({
            message: 'Eksport ukończony'
        });
        return data;
    });

    const { mutateAsync: importCalculator, isLoading: importingCalculator } = useMutation(async (dump: object) => {
        const data = await backendPost(`/calculators/copy-from-json`, dump);
        notification.success({
            message: 'Import ukończony'
        });
        return data;
    });

    return <Space align="start">
        <Space direction="vertical">
            <Button onClick={() => validateCalculators()} loading={validatingCalculators}>
                Weryfikuj kalkulatory
            </Button>
            <Space direction="vertical" style={{ width: 500 }}>
                <CalculatorSelect calculators={calculators} loading={calculatorsLoading || exportingCalculator}
                canEdit={true}
                onChange={(calculatorId: number) => {
                    exportCalculator(calculatorId).then(data => {
                        const json = JSON.stringify(data, null, "  ");
                        setCalculatorJson(json);
                    })
                }}/>
                {/*<pre style={{ height: 300, overflow: 'auto', background: 'rgba(0,0,0,0.03)' }}>{calculatorJson}</pre>*/}
                <TextArea value={calculatorJson} rows={20} onChange={data => setCalculatorJson(data.target.value)}/>
                <Button onClick={() => {
                    void importCalculator(JSON.parse(calculatorJson));
                }} loading={importingCalculator}>Importuj</Button>
            </Space>
        </Space>
    </Space>
}
