import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Descriptions, Form, Input, Row, Select, Space, Spin, Timeline, Typography, } from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatDate, formatDatetime, isFormValid, } from "../../utils/utils";
import { RcFile } from "antd/lib/upload";
import { useUser } from "../../hooks/user.hook";
import { FileData, FileUploader } from "../../components/file-uploader";
import {
    useMarkVisualisationAsRealised,
    useMoveVisualisationToRealisation,
    useSaveVisualisation
} from "./visualisation.hook";
import { getStatusList, getStatusLocale } from "../../components/visualisation-status";
import { MarkdownEditor } from "../../components/markdown-editor";
import { FooterGap, Gap, Hr } from "../../components/gap";
import { PopoverForm } from "../../components/popover-form";
import { Model } from "../../models/db";
import { ClientSelect } from "../../components/selects/client-select";
import { defaultLabelCol, wrapperCol } from "../../models/ui";
import { Query } from "./visualisation-query";

interface VisualisationDataProps {
    visualisationId: number;
    previewMode?: boolean;
}

export const VisualisationData = ({ visualisationId, previewMode }: VisualisationDataProps) => {
    const user = useUser();

    const { data: visualisation, loading: visualisationLoading } = Query.Visualisation.use(visualisationId);
    const { data: visualisationDocuments, loading: visualisationDocumentsLoading } = Query.Visualisation.Documents.use(visualisationId);
    const { data: visualisationStatuses, loading: visualisationStatusesLoading } = Query.Visualisation.Statuses.use(visualisationId);
    const { data: clients, loading: clientsLoading } = Query.Visualisation.Clients.use(visualisationId);

    const [form] = useForm<Model.Visualisation.Data>();
    const [fileList, setFileList] = useState<FileData[]>([]);

    const { mutateAsync: saveVisualisation, isLoading: visualisationSaving } = useSaveVisualisation();
    const { mutateAsync: moveVisualisationToRealisation, isLoading: movingVisualisationToRealisation } = useMoveVisualisationToRealisation();
    const { mutateAsync: markVisualisationAsRealised, isLoading: markingVisualisationAsRealised } = useMarkVisualisationAsRealised();

    useEffect(() => {
        if (visualisation) {
            form.setFieldsValue(visualisation);
        }
    }, [visualisation]);

    useEffect(() => {
        if (visualisationDocuments) {
            setFileList(visualisationDocuments.map(doc => ({
                id: doc.id,
                uid: `${doc.id}`,
                name: doc.filename,
                group: doc.documentGroup,
                fileData: '',
                fileName: doc.internalFilename,
                originFileObj: {} as RcFile,
                url: doc.internalFilename
            })));
        }
    }, [visualisationDocuments]);

    const permissions = user.permissions.visualisation(visualisationId, visualisation?.status, [visualisation?.userId, visualisation?.ownerId, visualisation?.clientOwnerId]);

    const moveToRealisationWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Przekazujesz wizualizację do realizacji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const markAsRealisedWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Przekazujesz wizualizację do APH.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const getVisualisationData = (values: Model.Visualisation.Data) => ({
        id: visualisationId,
        name: values.name ?? visualisation?.name,
        clientId: values.clientId ?? visualisation?.clientId,
        status: visualisationId ? values.status : 'Data completing',
        comment: values.comment,
        attrs: values.attrs ?? {},
        documents: fileList.map((f: FileData) => ({
            id: f.id,
            fileData: f.fileData,
            filename: f.name,
            documentGroup: f.group
        }))
    } as Model.Visualisation.Data);

    return <Spin spinning={visualisationLoading || visualisationStatusesLoading || visualisationDocumentsLoading || visualisationSaving || clientsLoading}>
        <Form
            form={form}
            labelCol={defaultLabelCol}
            wrapperCol={wrapperCol}
            layout={"vertical"}
            onFinish={values => {
                saveVisualisation(getVisualisationData(values));
            }}
            name="visualisation"
            disabled={previewMode}>
            <Descriptions bordered style={{ backgroundColor: '#fff' }} labelStyle={{ width: 120 }}>
                {visualisationId ? <Descriptions.Item label="Opiekun" span={3}>{visualisation?.owner}</Descriptions.Item> : null}
                <Descriptions.Item label="Klient" span={3}>
                    {permissions.edit ? <Form.Item
                        name="clientId"
                        rules={[{ required: true }]}
                    >
                        <ClientSelect clients={clients} canEdit={true}/>
                    </Form.Item> : <>
                        {visualisation?.client}
                        <Hr/>
                        <div>{formatDate(visualisation?.clientCreatedAt)}</div>
                        <div style={{ fontSize: 11, color: '#cc7b26' }}>{visualisation?.clientOwner}</div>
                    </>}
                </Descriptions.Item>
                <Descriptions.Item label="Nazwa" span={3}>
                    {permissions.edit ? <Form.Item name="name" rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item> : <>
                        {visualisation?.name}
                    </>}
                </Descriptions.Item>
                <Descriptions.Item label="Parametry" span={3}>
                    <Form.Item name={['attrs', 'address']} label="Adres instalacji lub lokalizacja GPS" rules={[{ required: true }]}>
                        <Input disabled={!permissions.edit}/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'roofSlopeSize']} label="Wymiary połaci dachu" rules={[{ required: true }]}>
                        <Input disabled={!permissions.edit}/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'roofToleSize']} label="Wymiary dachówki" rules={[{ required: true }]}>
                        <Input disabled={!permissions.edit}/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'plannedCapacity']} label="Ilość kW planowanych do oferty" rules={[{ required: true }]}>
                        <Input disabled={!permissions.edit}/>
                    </Form.Item>
                </Descriptions.Item>
                {visualisationId ? <Descriptions.Item label="Status" span={3}>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        {permissions.editStatus ?
                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                             <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Form.Item name="status" noStyle>
                                    <Select style={{ width: "100%" }} disabled={!permissions.changeStatus}>
                                        {getStatusList().map(s => <Select.Option
                                            value={s.id}
                                            key={s.id}
                                            disabled={s.id === 'Completed'}>
                                            {s.name}
                                        </Select.Option>)}
                                    </Select>
                                </Form.Item>
                                <br/>
                                <Form.Item name="comment" label="Komentarz do nowego statusu">
                                    <MarkdownEditor height={100} preview={true}/>
                                </Form.Item>
                            </div>
                        </div> : null}
                        {!permissions.editStatus ?
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Form.Item noStyle initialValue={visualisation?.status}>
                                <div>{getStatusLocale(visualisation?.status)}</div>
                                <div style={{ fontSize: 11, color: '#cc7b26' }}>
                                    {formatDate(visualisation?.statusUpdatedAt)}
                                </div>
                            </Form.Item>
                        </div> : null}
                        <div style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", marginLeft: 10 }}>
                            <div style={{ marginBottom: 30 }}/>
                            <Timeline mode="left" style={{ width: "100%", marginTop: 10 }} items={
                                visualisationStatuses?.map((status, idx) => ({
                                    key: `status/${idx}`,
                                    label: formatDatetime(status.createdAt),
                                    color: idx === visualisationStatuses?.length - 1 ? "green" : "gray",
                                    children: getStatusLocale(status.status)
                                }))
                            }/>
                        </div>
                    </div>
                </Descriptions.Item> : null}
                <Descriptions.Item label="Dokumenty" span={3}>
                    <Alert message="Dołącz zdjęcia połaci dachu z perspektywy oraz zdjęcia otwartej skrzynki elektrycznej ukazującej zabezpieczenia klienta" type="warning"/>
                    <Gap/>
                    <FileUploader group="visualisation" fileList={fileList} setFileList={setFileList} canEdit={permissions.editDocuments && !previewMode}/>
                </Descriptions.Item>
            </Descriptions>
            <FooterGap/>
             {!previewMode ?
                <Row className="footer-actions">
                    <Col span={24}>
                    <Space>
                        {visualisationId && permissions.showSendToRealisation ? <PopoverForm
                            title="Przekaż do realizacji"
                            buttonEnabled={permissions.sendToRealisation}
                            showPopover={permissions.sendToRealisation}
                            loading={movingVisualisationToRealisation}
                            onBefore={() => isFormValid(form)}
                            onSubmit={() => {
                                saveVisualisation(getVisualisationData(form.getFieldsValue()))
                                    .then(() => moveVisualisationToRealisation(visualisationId));
                            }}>
                            {moveToRealisationWarning}
                        </PopoverForm> : null}
                        {visualisationId && permissions.showMarkAsRealised ? <PopoverForm
                            title="Przekaż do APH"
                            buttonEnabled={permissions.markAsRealised}
                            showPopover={permissions.markAsRealised}
                            loading={markingVisualisationAsRealised}
                            onSubmit={() => {
                                form.validateFields().then(() => {
                                    saveVisualisation(getVisualisationData(form.getFieldsValue()))
                                        .then(() => markVisualisationAsRealised(visualisationId));
                                })
                            }}>
                            {markAsRealisedWarning}
                        </PopoverForm> : null}
                        <Button type="primary" htmlType="submit" disabled={!permissions.save}>
                            Zapisz
                        </Button>
                    </Space>
                </Col>
            </Row> : null}
        </Form>
    </Spin>
}
