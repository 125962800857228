import { Divider, Space, Typography } from "antd";
import React from "react";

export const VisualisationItem = (props: { id: number, name: string }) => {
    return <Space size={0}>
        <div>{props.name}</div>
        <Divider type="vertical" orientationMargin={3}/>
        <Typography.Text type="secondary" style={{ fontSize: 10 }}>{props.id}</Typography.Text>
    </Space>
}
