import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { usePaginatedSqlQuery, useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { Nil } from "../../utils/utils";
import { getListCriteria, withFilterValueCriteria, withInCriteria } from "../../utils/sql";
import { AssemblyParametersCriteria } from "../../store/state";
import { TemplateType } from "../../models/model";

export namespace Query {
    export namespace Assemblies {
        export const key = () => `agreements`;
        export const query = (criteria: AssemblyParametersCriteria & {
            search: string | null
        }) => {
            const where = getListCriteria(
                [t.ap.id, t.cl.firstName, t.cl.lastName, t.cl.name, t.ap.name],
                t.ap.createdAt, t.ap.deletedAt,
                criteria
            )
            + withFilterValueCriteria(t.ap.acceptedAt.isNotNull().cast('int'), criteria.accepted)
            + withInCriteria(t.ap.ownerId, criteria.users);

            return from(t.assemblyParameters.as(t.ap))
                .join(t.user.as(t.u).id, t.ap.ownerId)
                .join(t.client.as(t.cl).id, t.ap.clientId)
                .where(where)
                .orderByAlias(criteria.sortColumn, criteria.sortDirection)
                .select({
                    key: t.ap.id,
                    id: t.ap.id,
                    type: t.ap.type,
                    ownerId: t.ap.ownerId,
                    createdAt: t.ap.createdAt,
                    name: t.ap.name,
                    clientId: t.cl.id,
                    client: t.cl.fullName(),
                    createdBy: t.u.fullName(),
                    accepted: t.ap.acceptedAt.isNotNull().asBool()
                });
        }

        export type T = ReturnType<typeof query>;

        export const use = (criteria: AssemblyParametersCriteria & {
            search: string | null
        }) => {
            return usePaginatedSqlQuery(
                key(), criteria,
                query(criteria)
            );
        }
    }

    export namespace AssemblyParameters {
        export const key = (assemblyParametersId: Nil<number>) => `assembly-parameters-${assemblyParametersId}`;
        export const query = (assemblyParametersId: Nil<number>) =>
            from(t.assemblyParameters.as(t.ap))
            .where(t.ap.id.eq(assemblyParametersId))
            .select({
                id: t.ap.id,
                type: t.ap.type,
                name: t.ap.name,
                ownerId: t.ap.ownerId,
                clientId: t.ap.clientId,
                templateId: t.ap.templateId,
                attrs: t.ap.attrs
            });

        export type T = ReturnType<typeof query>;

        export const use = (assemblyParametersId: Nil<number>) => useSqlQuerySingle(
            key(assemblyParametersId),
            query(assemblyParametersId), {
                enabled: assemblyParametersId,
                map: row => ({
                    ...row,
                    attrs: JSON.parse((row.attrs as any).value)
                })
            }
        );

        export namespace Document {
            export const key = (assemblyParametersId: Nil<number>) => `assembly-parameters-documents-${assemblyParametersId}`;
            export const query = (assemblyParametersId: Nil<number>) =>
                from(t.assemblyParametersDocument.as(t.apd))
                .join(t.assemblyParameters.as(t.ap).id, t.apd.assemblyParametersId)
                .where(t.apd.assemblyParametersId.eq(assemblyParametersId))
                .and(t.apd.deletedAt.isNull())
                .orderBy(t.apd.id)
                .select({
                    id: t.apd.id,
                    filename: t.apd.filename,
                    internalFilename: t.apd.internalFilename,
                    documentGroup: t.apd.documentGroup
                });

            export const use = (assemblyParametersId: Nil<number>) => useSqlQuery(
                key(assemblyParametersId),
                query(assemblyParametersId), {
                    enabled: assemblyParametersId
                }
            );
        }

        export namespace Template {
            export const key = (assemblyParametersId: Nil<number>) => `assembly-parameters-templates-${assemblyParametersId}`;
            export const query = (templateId: Nil<number>, templateType: Nil<TemplateType>) =>
                from(t.template, t.t)
                .where(
                    t.t.acceptedAt.isNotNull()
                    .and(t.t.templateType.eq(templateType))
                    .and(t.t.active.isTrue())
                    .and(t.t.deletedAt.isNull())
                ).or(t.t.id.eq(templateId), templateId)
                .orderByAsc(t.t.name)
                .select({
                    id: t.t.id,
                    name: t.t.name,
                    type: t.t.templateType,
                    ownerId: t.t.ownerId,
                    version: t.t.version
                });

            export const use = (assemblyParametersId: Nil<number>, templateId: Nil<number>, templateType: Nil<TemplateType>) => useSqlQuery(
                key(assemblyParametersId),
                query(templateId, templateType), {
                    enabled: (templateId || !assemblyParametersId) && templateType
                }
            );
        }

        export namespace Clients {
            export const key = (assemblyParametersId: Nil<number>) => `assembly-parameters-clients-${assemblyParametersId}`;
            export const query = (assemblyParametersId: Nil<number>, previewMode: Nil<boolean>) =>
                previewMode ?
                    from(t.assemblyParameters, t.ap)
                    .innerJoin(t.client, t.cl)
                    .on(t.ap.clientId.eq(t.cl.id))
                    .where(t.ap.id.eq(assemblyParametersId))
                    .orderBy(t.cl.name)
                    .select({
                        id: t.cl.id,
                        name: t.cl.fullName(),
                        type: t.cl.clientType
                    })
                    : from(t.client, t.cl)
                    .leftJoin(t.assemblyParameters, t.ap, assemblyParametersId)
                    .on(t.ap.id.eq(assemblyParametersId).and(t.ap.clientId.eq(t.cl.id)))
                    .orderBy(t.cl.name)
                    .where(t.cl.acceptedAt.isNotNull())
                    .or(t.ap.id.isNotNull(), assemblyParametersId)
                    .select({
                        id: t.cl.id,
                        name: t.cl.fullName(),
                        type: t.cl.clientType
                    })

            export const use = (assemblyParametersId: Nil<number>, previewMode: Nil<boolean>) => useSqlQuery(
                key(assemblyParametersId),
                query(assemblyParametersId, previewMode), {
                    enabled: previewMode && assemblyParametersId || !previewMode
                }
            );
        }
    }
}
