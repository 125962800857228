import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { qid, usePaginatedSqlQuery, useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { getListCriteria, withEqualCriteria, withInCriteria } from "../../utils/sql";
import { ProjectCriteria } from "../../store/state";
import { Nil } from "../../utils/utils";

export namespace Query {
    export namespace Projects {
        export const key = () => `projects`;
        export const query = (criteria: ProjectCriteria & {
            search: string | null
        }) => {
            const where = getListCriteria(
                [t.project.id, t.project.name, t.client.firstName, t.client.lastName, t.client.name],
                t.project.createdAt, t.project.deletedAt,
                criteria
            )
                + withEqualCriteria(t.project.status, criteria.status)
                + withInCriteria(t.project.ownerId, criteria.users);

            return from(t.project)
                .join(t.offer.id, t.project.offerId)
                .join(t.client.id, t.offer.clientId)
                .join(t.user.as(t.u0).id, t.client.ownerId)
                .join(t.user.as(t.u1).id, t.project.ownerId)
                .join(t.user.as(t.u2).id, t.project.userId)
                .leftJoin(t.projectComment.as(t.pc))
                .on(t.pc.projectId.eq(t.project.id).and(t.pc.isLast.isTrue()))
                .where(where)
                .and(t.client.deletedAt.isNull())
                .orderByAlias(criteria.sortColumn, criteria.sortDirection)
                .select({
                    key: t.project.id,
                    id: t.project.id,
                    createdAt: t.project.createdAt,
                    createdBy: t.u2.fullName(),
                    userId: t.project.userId,
                    name: t.project.name,
                    status: t.project.status,
                    statusUpdatedAt: t.project.statusUpdatedAt,
                    ownerId: t.project.ownerId,
                    lastComment: t.pc.comment,
                    lastCommentCreatedAt: t.pc.createdAt,
                    clientId: t.client.id,
                    client: t.client.fullName(),
                    clientOwnerId: t.client.ownerId,
                    clientCreatedBy: t.u0.fullName(),
                    owner: t.u1.fullName()
                });
        }

        export type T = ReturnType<typeof query>;

        export const use = (criteria: ProjectCriteria & {
            search: string | null
        }) => {
            return usePaginatedSqlQuery(
                key(), criteria,
                query(criteria)
            );
        }
    }

    export namespace Project {
        export const key = (projectId: Nil<number>) => `project-${projectId}`;
        export const query = (projectId: Nil<number>) =>
            from(t.project)
            .join(t.offer.id, t.project.offerId)
            .join(t.client.id, t.offer.clientId)
            .join(t.assemblyParameters.id, t.project.assemblyParametersId)
            .join(t.user.as(t.u0).id, t.offer.ownerId)
            .join(t.user.as(t.u1).id, t.project.ownerId)
            .join(t.user.as(t.u2).id, t.assemblyParameters.ownerId)
            .where(t.project.id.eq(projectId))
            .select({
                name: t.project.name,
                ownerId: t.project.ownerId,
                offerOwnerId: t.offer.ownerId,
                client: t.client.fullName(),
                clientId: t.offer.clientId,
                offerId: t.offer.id,
                clientCreatedAt: t.client.createdAt,
                status: t.project.status,
                statusUpdatedAt: t.project.statusUpdatedAt,
                offerOwner: t.u0.fullName(),
                offerCreatedAt: t.offer.createdAt,
                offerAccepted: t.offer.acceptedAt.isNotNull().asBool(),
                offer: t.offer.name,
                owner: t.u1.fullName(),
                projectCreatedAt: t.project.createdAt,
                attrs: t.project.attrs,
                userId: t.project.userId,
                assemblyParametersId: t.project.assemblyParametersId,
                assemblyParametersCreatedAt: t.assemblyParameters.createdAt,
                assemblyParametersOwner: t.u2.fullName(),
                assemblyParametersName: t.assemblyParameters.name
            });

        export type T = ReturnType<typeof query>;

        export const use = (projectId: Nil<number>) => useSqlQuerySingle(
            key(projectId),
            query(projectId), {
                enabled: projectId,
                map: row => ({
                    ...row,
                    attrs: JSON.parse((row.attrs as any).value)
                })
            }
        );

        export namespace Documents {
            export const key = (projectId: Nil<number>) => `project-documents-${projectId}`;
            export const query = (projectId: Nil<number>) =>
                from(t.projectDocument.as(t.pd))
                .join(t.project.id, t.pd.projectId)
                .where(t.pd.projectId.eq(projectId))
                .and(t.pd.deletedAt.isNull())
                .orderBy(t.pd.id)
                .select({
                    id: t.pd.id,
                    filename: t.pd.filename,
                    internalFilename: t.pd.internalFilename,
                    documentGroup: t.pd.documentGroup
                });

            export type T = ReturnType<typeof query>;

            export const use = (projectId: Nil<number>) => useSqlQuery(
                key(projectId),
                query(projectId),  {
                    enabled: projectId
                }
            );
        }

        export namespace Statuses {
            export const key = (projectId: Nil<number>) => `project-statuses-${projectId}`;
            export const query = (projectId: Nil<number>) =>
                from(t.projectHistory.as(t.ph))
                .join(t.project.id, t.ph.projectId)
                .where(t.ph.projectId.eq(projectId))
                .orderBy(t.ph.createdAt)
                .select({
                    status: t.ph.status,
                    createdAt: t.ph.createdAt
                });

            export type T = ReturnType<typeof query>;

            export const use = (projectId: Nil<number>) => useSqlQuery(
                key(projectId),
                query(projectId), {
                    enabled: projectId
                }
            );
        }

        export namespace Offers {
            export const key = (projectId: Nil<number>) => `project-offer-${projectId}`;
            export const query = (projectId: Nil<number>) =>
                from(t.offer)
                .join(t.client.id, t.offer.clientId)
                .join(t.calculator.id, t.offer.calculatorId)
                .join(t.project.offerId, t.offer.id)
                .where(t.offer.acceptedAt.isNotNull())
                .where(t.project.id.eq(projectId).or(t.project.id.isNull()), projectId)
                .where(t.project.id.isNull(), !projectId)
                .orderBy(t.offer.name)
                .orderBy(t.offer.id)
                .selectDistinctOn([t.offer.name, t.offer.id], {
                    id: t.offer.id,
                    name: t.offer.name,
                    calculatorType: t.calculator.type,
                    clientId: t.offer.clientId,
                    clientName: t.client.fullName(),
                    accepted: t.offer.acceptedAt.isNotNull().asBool()
                });

            export type T = ReturnType<typeof query>;

            export const use = (projectId: Nil<number>) => useSqlQuery(
                key(projectId),
                query(projectId)
            );
        }

        export namespace Assemblies {
            export const key = (projectId: Nil<number>) => `project-assemblies-${projectId}`;
            export const query = (clientId: Nil<number>, assemblyParametersId: Nil<number>) =>
                from(t.assemblyParameters.as(t.ap))
                .where(t.ap.clientId.eq(clientId), clientId)
                .and(t.ap.acceptedAt.isNotNull())
                .or(t.ap.id.eq(assemblyParametersId), assemblyParametersId)
                .orderBy(t.ap.name)
                .select({
                    id: t.ap.id,
                    name: t.ap.name,
                    accepted: t.ap.acceptedAt.isNotNull().asBool()
                });

            export type T = ReturnType<typeof query>;

            export const use = (projectId: Nil<number>, assemblyParametersId: Nil<number>, clientId: Nil<number>) => useSqlQuery(
                key(projectId),
                query(clientId, assemblyParametersId), {
                    enabled: clientId || assemblyParametersId,
                    params: {
                        clientId
                    }
                }
            );
        }
    }
}
