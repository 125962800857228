import React from "react";
import { Divider } from "antd";

export const Gap = ({ height = 10 }: { height?: number }) => {
    return <div style={{ minHeight: height }}/>
}

export const FooterGap = () => {
    return <div className="footer-actions-gap"/>
}

export const InlineHorzGap = ({ width = 10 }: { width?: number }) => {
    return <div style={{ minWidth: width, display: 'inline-block' }}/>
}

export const HorzGap = ({ width = 10 }: { width?: number }) => {
    return <div style={{ minWidth: width }}/>
}

export const Hr = () => <Divider style={{ marginTop: 5, marginBottom: 5 }}/>;
