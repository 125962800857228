import { getQueryFieldVal, QueryField, QueryFieldType, QueryRecord, QueryTable } from "../utils/sql-builder";
import { CalculatorData } from "../views/calculators/layout-types";
import { Dayjs } from "dayjs";
import { Overwrite } from "../utils/utils";
import { TemplateMailType, TemplateType } from "./model";

class TrackedTable extends QueryTable {
    id = this.number("id")
    userId = this.number("user_id");

    createdAt = this.number("created_at");
    updatedAt = this.number("updated_at");
    deletedAt = this.number("deleted_at");

    constructor(tableName: string, alias?: string) {
        super(tableName, alias);
    }
}

class OwnershipTable extends TrackedTable {
    ownerId = this.number("owner_id");

    constructor(tableName: string, alias?: string) {
        super(tableName, alias);
    }
}

class ProjectTable extends OwnershipTable {
    name = this.string("name");
    status = this.string("status");
    visualisationId = this.number("visualisation_id");
    offerId = this.number("offer_id");
    attrs = this.custom<object>("attrs");
    assemblyParametersId = this.nullNumber("assembly_parameters_id");
    statusUpdatedAt = this.number("status_updated_at");

    constructor(tableAlias?: string) {
        super("project", tableAlias);
    }
}

class ProjectDocumentTable extends TrackedTable {
    projectId = this.number("project_id");
    filename = this.string("filename");
    internalFilename = this.string("internal_filename");
    documentType = this.number("document_type");
    documentGroup = this.string("document_group");

    constructor(tableAlias?: string) {
        super("project_document", tableAlias);
    }
}

class ProjectHistoryTable extends TrackedTable {
    projectId = this.number("project_id");
    status = this.string("status")

    constructor(tableAlias?: string) {
        super("project_history", tableAlias);
    }
}

class ProjectCommentTable extends TrackedTable {
    projectId = this.number("project_id");
    comment = this.string("comment");
    status = this.string("status");
    isLast = this.boolean("is_last");

    constructor(tableAlias?: string) {
        super("project_comment", tableAlias);
    }
}

class OfferTable extends OwnershipTable {
    name = this.string("name");
    clientId = this.number("client_id");
    calculatorId = this.number("calculator_id");
    calculatorData = this.custom<CalculatorData>("calculator_data");
    templateId = this.nullNumber("template_id");
    realisationAddress = this.string("realisation_address");
    visualisationId = this.nullNumber("visualisation_id");
    acceptedAt = this.number("accepted_at");
    mailSentById = this.number("mail_sent_by_id");
    mailSentAt = this.number("mail_sent_at");
    mailSentTo = this.number("mail_sent_to");
    mailSubject = this.string("mail_subject");
    mailContent = this.string("mail_content");

    constructor(tableAlias?: string) {
        super("offer", tableAlias);
    }
}

class OfferMailHistory extends TrackedTable {
    offerId = this.number("offer_id");
    subject = this.string("subject");
    content = this.string("content");
    emailFrom = this.string("email_from");
    emailTo = this.string("email_to");

    constructor(tableAlias?: string) {
        super("offer_mail_history", tableAlias);
    }
}

class AssemblyParametersTable extends OwnershipTable {
    type = this.custom<"photovoltaics" | "heat-pump">("type");
    name = this.string("name");
    status = this.string("status");
    clientId = this.number("client_id");
    templateId = this.number("template_id");
    attrs = this.custom<object>("attrs");
    version = this.number("version");
    acceptedAt = this.number("accepted_at");

    constructor(tableAlias?: string) {
        super("assembly_parameters", tableAlias);
    }
}

class AssemblyParametersDocumentTable extends TrackedTable {
    assemblyParametersId = this.number("assembly_parameters_id");
    filename = this.string("filename");
    internalFilename = this.string("internal_filename");
    documentType = this.number("document_type");
    documentGroup = this.string("document_group");

    constructor(tableAlias?: string) {
        super("assembly_parameters_document", tableAlias);
    }
}

class VisualisationTable extends OwnershipTable {
    name = this.string("name");
    status = this.string("status");
    statusUpdatedAt = this.number("status_updated_at");
    clientId = this.number("client_id");
    attrs = this.custom<object>("attrs");

    constructor(tableAlias?: string) {
        super("visualisation", tableAlias);
    }
}

class VisualisationDocumentTable extends TrackedTable {
    visualisationId = this.number("visualisation_id");
    filename = this.string("filename");
    internalFilename = this.string("internal_filename");
    documentType = this.number("document_type");
    documentGroup = this.string("document_group");

    constructor(tableAlias?: string) {
        super("visualisation_document", tableAlias);
    }
}

class VisualisationCommentTable extends TrackedTable {
    visualisationId = this.number("visualisation_id");
    comment = this.string("comment");
    status = this.string("status");
    isLast = this.boolean("is_last");

    constructor(tableAlias?: string) {
        super("visualisation_comment", tableAlias);
    }
}

class VisualisationHistoryTable extends TrackedTable {
    visualisationId = this.number("visualisation_id");
    status = this.string("status")

    constructor(tableAlias?: string) {
        super("visualisation_history", tableAlias);
    }
}

class ClientTable extends OwnershipTable {
    firstName = this.string("first_name");
    lastName = this.string("last_name");
    pesel = this.string("pesel");
    nip = this.string("nip");
    acceptedNip = this.string("accepted_nip");
    regon = this.string("regon");
    courtRegister = this.string("court_register");
    courtCity = this.string("court_city");
    courtDepartment = this.string("court_department");
    companyRepresentative1 = this.string("company_representative_1");
    companyRepresentative2 = this.string("company_representative_2");
    name = this.string("name");
    clientType = this.number("client_type");
    city = this.string("city");
    postCode = this.string("post_code");
    address = this.string("address");
    post = this.string("post");
    email = this.string("email");
    phoneNumber = this.string("phone_number");
    acceptedAt = this.number("accepted_at");

    fullName(table?: string) {
        return new QueryField<string>(QueryFieldType.EXPRESSION,
            `case ${getQueryFieldVal(this.clientType, table)} when 0 then ${getQueryFieldVal(this.firstName, table)} || ' ' || ${getQueryFieldVal(this.lastName, table)} else ${getQueryFieldVal(this.name, table)} end`)
    }

    uid(table?: string) {
        return new QueryField<string>(QueryFieldType.EXPRESSION,
            `case ${getQueryFieldVal(this.clientType, table)} when 0 then ${getQueryFieldVal(this.pesel, table)} else ${getQueryFieldVal(this.nip, table)} end`)
    }

    constructor(tableAlias?: string) {
        super("client", tableAlias);
    }
}

class UserTable extends OwnershipTable {
    username = this.string("username");
    password = this.string("password");
    firstName = this.string("first_name");
    lastName = this.string("last_name");
    role = this.number("role");
    language = this.number("language");
    commissionRate = this.number("commission_rate");
    dashboardLayout = this.string("dashboard_layout");
    email = this.string("email");

    fullName(table?: string) {
        return new QueryField<string>(QueryFieldType.EXPRESSION,
            `${getQueryFieldVal(this.firstName, table)} || ' ' || ${getQueryFieldVal(this.lastName, table)}`)
    }

    constructor(tableAlias?: string) {
        super('"user"', tableAlias);
    }
}

class UserAccessTable extends QueryTable {
    userId = this.number("user_id");
    accessUserId = this.number("access_user_id");
    accessMode = this.number("access_mode");

    constructor(tableAlias?: string) {
        super("user_access", tableAlias);
    }
}

class CalculatorTable extends OwnershipTable {
    parentId = this.number("parent_id");
    name = this.string("name");
    type = this.string("type");
    active = this.boolean("active");
    acceptedAt = this.number("accepted_at");

    constructor(tableAlias?: string) {
        super("calculator", tableAlias);
    }
}

class TemplateTable extends OwnershipTable {
    name = this.string("name");
    filename = this.string("filename");
    calculatorId = this.number("calculator_id");
    mappings = this.string("mappings");
    internalFilename = this.string("internal_filename");
    templateType = this.custom<TemplateType>("template_type");
    templateFlags = this.number("template_flags");
    active = this.boolean("active");
    version = this.number("version");
    acceptedAt = this.number("accepted_at");

    constructor(tableAlias?: string) {
        super("template", tableAlias);
    }
}

class TemplateMailTable extends OwnershipTable {
    name = this.string("name");
    templateType = this.custom<TemplateMailType>("template_type");
    version = this.number("version");
    subject = this.string("subject");
    content = this.string("content");

    constructor(tableAlias?: string) {
        super("template_mail", tableAlias);
    }
}

class AgreementTable extends OwnershipTable {
    name = this.string("name");
    offerId = this.number("offer_id");
    filename = this.string("filename");
    totalPrice = this.number("total_price");
    templateId = this.nullNumber("template_id");
    acceptedAt = this.number("accepted_at");

    constructor(tableAlias?: string) {
        super("agreement", tableAlias);
    }
}

class RealisationTable extends OwnershipTable {
    agreementId = this.number("agreement_id");
    status = this.string("status");
    statusUpdatedAt = this.number("status_updated_at");
    paymentCount = this.number("payment_count");

    coordinatorId = this.number("coordinator_id");

    installation = this.number("installation");
    commission = this.number("commission");

    installationTaskList1 = this.string("installation_task_list_1");
    installationTaskListName1 = this.string("installation_task_list_name_1");
    installationTaskList2 = this.string("installation_task_list_2");
    installationTaskListName2 = this.string("installation_task_list_name_2");
    installationTaskList3 = this.string("installation_task_list_3");
    installationTaskListName3 = this.string("installation_task_list_name_3");
    installationTaskList4 = this.string("installation_task_list_4");
    installationTaskListName4 = this.string("installation_task_list_name_4");
    installationTaskList5 = this.string("installation_task_list_5");
    installationTaskListName5 = this.string("installation_task_list_name_5");

    calculatorMargin = this.number("calculator_margin");
    commissionRate = this.number("commission_rate");
    commissionCorrection = this.number("commission_correction");
    commissionCorrectionType = this.number("commission_correction_type");
    commissionValue = this.number("commission_value");
    commissionPaymentDate = this.number("commission_payment_date");
    mainValue = this.number("main_value");

    constructor(tableAlias?: string) {
        super("realisation", tableAlias);
    }
}

class RealisationDocumentTable extends TrackedTable {
    realisationId = this.number("realisation_id");
    filename = this.string("filename");
    internalFilename = this.string("internal_filename");
    documentType = this.number("document_type");
    documentGroup = this.string("document_group");

    constructor(tableAlias?: string) {
        super("realisation_document", tableAlias);
    }
}

class RealisationHistoryTable extends TrackedTable {
    realisationId = this.number("realisation_id");
    status = this.string("status")

    constructor(tableAlias?: string) {
        super("realisation_history", tableAlias);
    }
}

class RealisationPaymentTable extends TrackedTable {
    realisationId = this.number("realisation_id");
    invoiceNumber = this.string("invoice_number");
    amount = this.number("amount");
    ordinalNumber = this.number("ordinal_number");
    paidAt = this.number("paid_at");
    expectedAt = this.number("expected_at");
    comment = this.string("comment");

    constructor(tableAlias?: string) {
        super("realisation_payment", tableAlias);
    }
}

class RealisationCommentTable extends TrackedTable {
    realisationId = this.number("realisation_id");
    comment = this.string("comment");
    status = this.string("status");
    isLast = this.boolean("is_last");

    constructor(tableAlias?: string) {
        super("realisation_comment", tableAlias);
    }
}

class InstallationCrewTable extends OwnershipTable {
    name = this.string("name");
    crew = this.string("crew");
    active = this.boolean("active");

    constructor(tableAlias?: string) {
        super("installation_crew", tableAlias);
    }
}

class InstallationTaskTable extends OwnershipTable {
    name = this.string("name");
    tasks = this.string("tasks");

    constructor(tableAlias?: string) {
        super("installation_task", tableAlias);
    }
}

class InstallationCalendarTable extends OwnershipTable {
    installationCrewId = this.number("installation_crew_id");
    realisationId = this.number("realisation_id");
    name = this.string("name");
    description = this.string("description");
    dateStart = this.number("date_start");
    dateEnd = this.number("date_end");

    constructor(tableAlias?: string) {
        super("installation_calendar", tableAlias);
    }
}

class LoggedActionsTable extends QueryTable {
    id = this.number("id");
    eventId = this.number("event_id");
    entityId = this.number("entity_id");
    action = this.string("action");
    changedFields = this.string("changed_fields");
    rowData = this.string("row_data");
    schemaName = this.string("schema_name");
    tableName_ = this.string("table_name");
    actionTstampClk = this.number("action_tstamp_clk");
    userId = this.number("user_id");

    constructor(tableAlias?: string) {
        super("logged_actions", tableAlias);
    }
}

export namespace t {
    export const client = new ClientTable();
    export const cl = new ClientTable("cl");

    export const visualisation = new VisualisationTable();
    export const v = new VisualisationTable("v");

    export const visualisationDocument = new VisualisationDocumentTable();
    export const vd = new VisualisationDocumentTable("vd");

    export const visualisationComment = new VisualisationCommentTable();
    export const vc = new VisualisationCommentTable("vc");

    export const visualisationHistory = new VisualisationHistoryTable();
    export const vh = new VisualisationHistoryTable("vh");

    export const project = new ProjectTable();
    export const p = new ProjectTable("p");

    export const projectDocument = new ProjectDocumentTable();
    export const pd = new ProjectDocumentTable("pd");

    export const projectComment = new ProjectCommentTable();
    export const pc = new ProjectCommentTable("pc");

    export const projectHistory = new ProjectHistoryTable();
    export const ph = new ProjectHistoryTable("ph");

    export const assemblyParameters = new AssemblyParametersTable();
    export const ap = new AssemblyParametersTable("ap");

    export const assemblyParametersDocument = new AssemblyParametersDocumentTable();
    export const apd = new AssemblyParametersDocumentTable("apd");

    export const offer = new OfferTable();
    export const o = new OfferTable("o");

    export const offerMailHistory = new OfferMailHistory();
    export const omh = new OfferMailHistory("o");

    export const template = new TemplateTable();
    export const t = new TemplateTable("t");

    export const templateMail = new TemplateMailTable();
    export const tm = new TemplateMailTable("tm");

    export const agreement = new AgreementTable();
    export const a = new AgreementTable("a");

    export const realisation = new RealisationTable();
    export const r = new RealisationTable("r");

    export const realisationDocument = new RealisationDocumentTable();
    export const rd = new RealisationDocumentTable("rd");

    export const realisationComment = new RealisationCommentTable();
    export const rc = new RealisationCommentTable("rc");

    export const realisationHistory = new RealisationHistoryTable();
    export const rh = new RealisationHistoryTable("rh");

    export const realisationPayment = new RealisationPaymentTable();
    export const rp = new RealisationPaymentTable("rp");

    export const calculator = new CalculatorTable();
    export const c = new CalculatorTable("c");

    export const installationCrew = new InstallationCrewTable();
    export const ic = new InstallationCrewTable("ic");

    export const installationTask = new InstallationTaskTable();
    export const it = new InstallationTaskTable("it");

    export const installationCalendar = new InstallationCalendarTable();
    export const ica = new InstallationCalendarTable("ica");

    export const user = new UserTable();
    export const u = new UserTable("u");
    export const u0 = new UserTable("u0");
    export const u1 = new UserTable("u1");
    export const u2 = new UserTable("u2");
    export const u3 = new UserTable("u3");

    export const userAccess = new UserAccessTable();
    export const ua = new UserAccessTable("ua");

    export const loggedActions = new LoggedActionsTable();
    export const la = new LoggedActionsTable("la");
}

export namespace Model {
    export namespace Calculator {
        export type T = Partial<QueryRecord<CalculatorTable>>;
        export type Data = T;
    }

    export namespace Client {
        export type T = Partial<QueryRecord<ClientTable>>;
        export type Data = T;
    }

    export namespace Template {
        export type T = Partial<QueryRecord<TemplateTable>>;
        export type Data = Overwrite<T, {
            filename: any
        }>;
    }

    export namespace Offer {
        export type T = Partial<QueryRecord<OfferTable>>;
        export type Data = T;
    }

    export namespace Project {
        export type T = Partial<QueryRecord<ProjectTable>>;

        export namespace Document {
            export type T = Partial<QueryRecord<ProjectDocumentTable>>;
        }

        export type Data = Overwrite<T, {
            comment: string | undefined;
            documents: (Document.T & { fileData?: string | Blob | undefined })[];
        }>;
    }

    export namespace Visualisation {
        export type T = Partial<QueryRecord<VisualisationTable>>;

        export namespace Document {
            export type T = Partial<QueryRecord<VisualisationDocumentTable>>;
        }

        export type Data = Overwrite<T, {
            comment: string | undefined;
            documents: (Document.T & { fileData?: string | Blob | undefined })[];
        }>;
    }

    export namespace Agreement {
        export type T = Partial<QueryRecord<AgreementTable>>;
        export type Data = T;
    }

    export namespace Realisation {
        export type T = Partial<QueryRecord<RealisationTable>>;
        export namespace Document {
            export type T = Partial<QueryRecord<RealisationDocumentTable>>;
        }
        export namespace Payment {
            export type T = Partial<QueryRecord<RealisationPaymentTable>>;
        }
        export namespace Comment {
            export type T = Partial<QueryRecord<RealisationCommentTable>>;
        }
        export namespace History {
            export type T = Partial<QueryRecord<RealisationHistoryTable>>;
        }

        export type Data = Overwrite<T, {
            commissionPaymentDate: number | Dayjs | undefined | null;
            comment: string | undefined;
            documents: (Document.T & { fileData?: string | Blob | undefined })[];
            payments: Overwrite<Payment.T, {
                paidAt: number | Dayjs | undefined | null;
                expectedAt: number | Dayjs | undefined | null;
            }>[];
        }>
    }

    export namespace AssemblyParameters {
        export type T = Partial<QueryRecord<AssemblyParametersTable>>;

        export type Data = T & {
            documents: (Document.T & { fileData?: string | Blob | undefined })[];
        };

        export namespace Document {
            export type T = Partial<QueryRecord<AssemblyParametersDocumentTable>>
        }
    }
}
