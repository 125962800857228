import React, { useState } from "react";
import { Button, Card, Form, List, notification, Spin } from "antd";
import { qid, useSqlQuery } from "../../hooks/sql-query.hook";
import { useForm } from "antd/lib/form/Form";
import { formatDatetime } from "../../utils/utils";
import { backendPost } from "../../api/backend-api";
import { getStatusLocale } from "../../components/realisation-status";
import { MarkdownText } from "../../components/markdown-text";
import { MarkdownEditor } from "../../components/markdown-editor";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Comment } from "@ant-design/compatible";

interface RealisationCommentsProps {
    realisationId: number;
}

export const RealisationComments = ({ realisationId }: RealisationCommentsProps) => {

    const { data: comments, loading: commentsLoading } = useSqlQuery(
        qid`realisation-comments-${realisationId}`,
        from(t.realisation, t.r)
        .innerJoin(t.realisationComment, t.rc)
        .on(t.r.id.eq(t.rc.realisationId))
        .innerJoin(t.user, t.u)
        .on(t.u.id.eq(t.rc.userId))
        .where(t.rc.realisationId.eq(realisationId))
        .orderBy(t.rc.id)
        .select({
            id: t.rc.id,
            userId: t.rc.userId,
            comment: t.rc.comment,
            status: t.rc.status,
            createdAt: t.rc.createdAt,
            user: t.u.fullName()
        }), {
            enabled: realisationId
        }
    );

    const { mutateAsync: saveComment, isLoading: commentSaving } = useMutation((comment: string) => {
        const queryClient = useQueryClient();
        return backendPost<{ comment: string }>(`/realisations/${realisationId}/comment`, { comment })
            .then(() => {
                void queryClient.invalidateQueries([`realisation-comments-${realisationId}`]);
                notification.success({
                    message: 'Komentarz został zapisany'
                });
        });
    });

    const [form] = useForm();
    const commentsCount = comments?.length ?? 0;
    const [comment, setComment] = useState("");

    return <Card style={{ padding: 0, width: "100%" }}>
        <Spin spinning={commentsLoading}>
            <List
                dataSource={comments ?? []}
                header={`${commentsCount} ${commentsCount === 1 ? 'odpowiedź' : 'odpowiedzi'}`}
                itemLayout="horizontal"
                renderItem={props => <Comment
                    content={<MarkdownText text={props.comment}/>}
                    author={props.user}
                    datetime={`${formatDatetime(props.createdAt)} / ${getStatusLocale(props.status)}`}
                />}
            />
            <Form form={form} style={{ marginTop: 20 }} onFinish={values => {
                saveComment(values.comment);
            }} onValuesChange={values => {
                setComment(values.comment);
            }}>
                <div style={{ display: 'flex', alignItems: 'stretch', width: '100%', height: 300, gap: 10 }}>
                    <div style={{ width: 300 }}>
                        <Form.Item name="comment" rules={[{ required: true }]}>
                            <MarkdownEditor height={300}/>
                        </Form.Item>
                    </div>
                    <div style={{ border: '1px solid #f0f0f1', height: '100%', width: '100%', overflow: 'auto'}}>
                        <MarkdownText text={comment}/>
                    </div>
                </div>
                <Form.Item style={{ marginTop: 25 }}>
                    <Button htmlType="submit" loading={commentSaving} type="primary">
                        Dodaj komentarz
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    </Card>
}
