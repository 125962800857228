import { Button, Checkbox, Col, Form, Input, Radio, Row, Select, Space, Switch, Tabs, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { FieldScope, FieldType, isReferenceField, LayoutItem } from "./layout-types";
import { useForm } from "antd/lib/form/Form";
import { backendPost, backendPut } from "../../api/backend-api";
import { FormulaEditor, FormulaError } from "./formula-editor";
import { ColorPicker } from "../../components/color-picker";
import range from "lodash/range";
import { RemoveButton } from "../../components/remove-button";
import { nullString } from "../../utils/utils";
import { fieldRolesNoAdmin } from "../../models/user";
import { useMutation } from "@tanstack/react-query";
import { EcbLangHelp } from "./ecb-lang-help";

interface ElementPropertiesProps {
    calculatorId: number;
    currentLayoutItem: LayoutItem | null;
    onUpdate: (item: LayoutItem) => void;
    onMatchFieldId?: (fieldId: string | null) => void;
    onRemove: (item: LayoutItem | null) => Promise<void>;
}

export const ElementProperties = ({ calculatorId, currentLayoutItem, onUpdate, onMatchFieldId, onRemove } : ElementPropertiesProps) => {
    const [form] = useForm<LayoutItem>();
    const [layoutItem, setLayoutItem] = useState<LayoutItem | null>(null);
    const [formulaErrors, setFormulaErrors] = useState<FormulaError[]>([]);
    const [helpOpen, setHelpOpen] = useState(false);

    const { mutateAsync: saveItem } = useMutation(({ item }: { item: LayoutItem }) => {
        return backendPut(`/calculators/${item.id}/item`, item);
    });

    const { mutateAsync: validateFormula } = useMutation(({ fieldType, formula }: { fieldType: FieldType, formula?: string }) => {
        return backendPost(`/evaluation/validate`, {
            fieldType,
            formula
        });
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            if (layoutItem != null) {
                void saveItem({ item: layoutItem });
                if (layoutItem.value) {
                    validateFormula({ fieldType: layoutItem.fieldType, formula: layoutItem.value }).then(error => {
                        if (error.line >= 0) {
                            setFormulaErrors([error]);
                        } else {
                            setFormulaErrors([]);
                        }
                    })
                }
                onUpdate(layoutItem);
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [layoutItem]);

    useEffect(() => {
        if (currentLayoutItem) {
            form.setFieldsValue({
                ...currentLayoutItem,
                fontSize: currentLayoutItem.fontSize ?? 16,
                fontWeight: currentLayoutItem.fontWeight ?? 400,
                horzAlign: currentLayoutItem.horzAlign ?? 0,
                vertAlign: currentLayoutItem.vertAlign ?? 1,
                textColor: currentLayoutItem.textColor ?? '#ffffff',
                backgroundColor: currentLayoutItem.backgroundColor ?? '#000000',
                roundValue: currentLayoutItem.roundValue,
                formatValue: currentLayoutItem.formatValue
            });
        }
    }, [currentLayoutItem]);

    const fieldScope = Form.useWatch('fieldScope', form);

    return <Form form={form}
        onValuesChange={() => {
            const formValues = form.getFieldsValue();
            const layoutItem = {
                ...currentLayoutItem,
                ...formValues,
                alias: nullString(formValues.alias)
            };
            setLayoutItem(layoutItem);
        }}
        layout={"vertical"}
        style={{ paddingTop: 10, height: '100%' }}>
        <Tabs defaultActiveKey="value" tabPosition="right" style={{ height: '100%' }}
            items={[{
                label: "Wartość",
                key: "value",
                children: <>
                    <div style={{ display: 'flex' }}>
                        <Form.Item name="value" rules={[{
                            required: true,
                            message: "Wprowadź wyrażenie"
                        }]} style={{ flexGrow: 1 }}>
                            <FormulaEditor
                                calculatorId={calculatorId}
                                onMatchFieldId={onMatchFieldId}
                                errors={formulaErrors}
                            />
                        </Form.Item>
                        <div style={{
                            width: 150,
                            paddingLeft: 10
                        }}>
                            <Form.Item name="roundValue" label="Zaokrąglaj wynik" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Form.Item name="formatValue" label="Formatuj wynik" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                            <Button onClick={() => setHelpOpen(true)}>Pomoc</Button>
                        </div>
                    </div>
                    <Drawer
                        title="Pomoc"
                        placement="right"
                        closable={true}
                        onClose={() => setHelpOpen(false)}
                        open={helpOpen}
                        getContainer={false}>
                        <EcbLangHelp/>
                    </Drawer>
                </>
            }, {
                label: "Styl",
                key: "style",
                children:                 <Row>
                    <Col span={16}>
                        <Space size="large">
                            <Form.Item name="horzAlign" label="Wyrównanie w poziomie:">
                                <Radio.Group options={[
                                    { value: 0, label: "Lewo" },
                                    { value: 1, label: "Środek" },
                                    { value: 2, label: "Prawo" }
                                ]} optionType="button"/>
                            </Form.Item>
                            <Form.Item name="vertAlign" label="Wyrównanie w pionie:">
                                <Radio.Group options={[
                                    { value: 0, label: "Góra" },
                                    { value: 1, label: "Środek" },
                                    { value: 2, label: "Dół" }
                                ]} optionType="button"/>
                            </Form.Item>
                        </Space>
                        <br/>
                        <Space size="large">
                            <Form.Item name="textColor" label="Kolor tekstu">
                                <ColorPicker/>
                            </Form.Item>
                            <Form.Item name="backgroundColor" label="Kolor tła">
                                <ColorPicker/>
                            </Form.Item>
                            <Form.Item name="fontSize" label="Rozmiar czcionki">
                                <Select>
                                    {range(10, 24).map(size => <Select.Option key={size} value={size}>
                                        {size}
                                    </Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item name="fontWeight" label="Grubość czcionki">
                                <Select>
                                    {range(400, 800, 100).map(weight => <Select.Option key={weight} value={weight}>
                                        {weight}
                                    </Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Space>
                        <br/>
                        <Space size="large">
                        </Space>
                    </Col>
                </Row>
            }, {
                label: "Opcje",
                key: "options",
                children: <Row>
                    <Col span={8}>
                        <Space size="large" style={{ alignItems: "flex-start" }}>
                            <Form.Item name="fieldScope" label="Zasięg pola">
                                <Select>
                                    <Select.Option value={FieldScope.Public}>
                                        Pole publiczne
                                    </Select.Option>
                                    <Select.Option value={FieldScope.Limited}>
                                        Ograniczone
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            {fieldScope === FieldScope.Limited ?
                            <Form.Item name="fieldRoles" label="Przypisane role">
                                <Checkbox.Group
                                    options={fieldRolesNoAdmin}/>
                            </Form.Item> : null }

                            <RemoveButton onConfirm={() => onRemove(currentLayoutItem)}>
                                Usuń pole
                            </RemoveButton>
                        </Space>
                        {isReferenceField(currentLayoutItem?.fieldType) && <>
                            <Form.Item name="alias" label="Alias:">
                                <Input autoComplete="off"/>
                            </Form.Item>
                            <Form.Item name="mainValue" label="Główne pole?" valuePropName="checked">
                                <Switch/>
                            </Form.Item>
                        </>}
                    </Col>
                </Row>
            }]}
        />
    </Form>
}
