import { useCallback, useEffect, useState } from "react";

export function useDebounce<T>(value: T | null, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState<T | null>(value);

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
    );

    return debouncedValue;
}

export const useDebouncedEffect = (effect: () => void, delay: number, deps: any[]) => {
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
}