import React from "react";
import { Route, Routes } from "react-router-dom";
import { baseUrl } from "./utils/navigation";
import { App } from "./app";
import { Dashboard } from "./views/dashboard/dashboard";
import { Users } from "./views/users/users";
import { UserEdit } from "./views/users/user-edit";
import { Clients } from "./views/clients/clients";
import { Calculators } from "./views/calculators/calculators";
import { CalculatorEdit } from "./views/calculators/calculator-edit";
import { Login } from "./views/login/login";
import { ClientEdit } from "./views/clients/client-edit";
import { Offers } from "./views/offers/offers";
import { OfferEdit } from "./views/offers/offer-edit";
import { CalculatorTest } from "./views/calculators/calculator-test";
import { Templates } from "./views/templates/templates";
import { TemplateEdit } from "./views/templates/template-edit";
import { AdminRoute, SecuredRoute, UserRoute } from "./components/admin-route";
import { Agreements } from "./views/agreements/agreements";
import { AgreementEdit } from "./views/agreements/agreement-edit";
import { Audit } from "./views/audit/audit";
import { Realisations } from "./views/realisations/realisations";
import { RealisationEdit } from "./views/realisations/realisation-edit";
import { Visualisations } from "./views/visualisations/visualisations";
import { VisualisationEdit } from "./views/visualisations/visualisation-edit";
import { Projects } from "./views/projects/projects";
import { ProjectEdit } from "./views/projects/project-edit";
import { AssemblyParameters } from "./views/assembly-parameters/assembly-parameters";
import { AssemblyParametersEdit } from "./views/assembly-parameters/assembly-parameters-edit";
import { InstallationCrewEdit } from "./views/installation-crews/installation-crew-edit";
import { InstallationCrews } from "./views/installation-crews/installation-crews";
import { InstallationCalendar } from "./views/installation-calendar/installation-calendar";
import { System } from "./views/system/system";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path={baseUrl(`/`)} element={<SecuredRoute><App/></SecuredRoute>}>
                <Route path="" element={<Dashboard/>}/>
                <Route path="system" element={<AdminRoute><System/></AdminRoute>}/>
                <Route path="users" element={<AdminRoute><Users/></AdminRoute>}/>
                <Route path="users/:id" element={<UserRoute><UserEdit tab="data"/></UserRoute>}/>
                <Route path="users/:id/data" element={<AdminRoute><UserEdit tab="data"/></AdminRoute>}/>
                <Route path="users/:id/assigned-users" element={<AdminRoute><UserEdit tab="assigned-users"/></AdminRoute>}/>
                <Route path="users/create" element={<AdminRoute><UserEdit/></AdminRoute>}/>
                <Route path="clients" element={<Clients/>}/>
                <Route path="clients/:id" element={<ClientEdit tab="data"/>}/>
                <Route path="clients/:id/data" element={<ClientEdit tab="data"/>}/>
                <Route path="assembly-parameters" element={<AssemblyParameters/>}/>
                <Route path="assembly-parameters/:id" element={<AssemblyParametersEdit tab="data"/>}/>
                <Route path="assembly-parameters/:id/data" element={<AssemblyParametersEdit tab="data"/>}/>
                <Route path="assembly-parameters/create-pv" element={<AssemblyParametersEdit type="photovoltaics"/>}/>
                <Route path="assembly-parameters/create-hp" element={<AssemblyParametersEdit type="heat-pump"/>}/>
                <Route path="visualisations" element={<Visualisations/>}/>
                <Route path="visualisations/:id" element={<VisualisationEdit tab="data"/>}/>
                <Route path="visualisations/:id/data" element={<VisualisationEdit tab="data"/>}/>
                <Route path="visualisations/:id/comments" element={<VisualisationEdit tab="comments"/>}/>
                <Route path="templates" element={<AdminRoute><Templates/></AdminRoute>}/>
                <Route path="templates/:id" element={<AdminRoute><TemplateEdit tab="data"/></AdminRoute>}/>
                <Route path="templates/:id/data" element={<AdminRoute><TemplateEdit tab="data"/></AdminRoute>}/>
                <Route path="templates/:id/mappings" element={<AdminRoute><TemplateEdit tab="mappings"/></AdminRoute>}/>
                <Route path="templates/create" element={<AdminRoute><TemplateEdit/></AdminRoute>}/>
                <Route path="offers" element={<Offers/>}/>
                <Route path="offers/:id" element={<OfferEdit tab="data"/>}/>
                <Route path="offers/:id/data" element={<OfferEdit tab="data"/>}/>
                <Route path="offers/:id/calculator" element={<OfferEdit tab="calculator"/>}/>
                <Route path="offers/create" element={<OfferEdit/>}/>
                <Route path="projects" element={<Projects/>}/>
                <Route path="projects/:id" element={<ProjectEdit tab="data"/>}/>
                <Route path="projects/:id/data" element={<ProjectEdit tab="data"/>}/>
                <Route path="projects/:id/comments" element={<ProjectEdit tab="comments"/>}/>
                <Route path="agreements" element={<Agreements/>}/>
                <Route path="agreements/:id" element={<AgreementEdit tab="data"/>}/>
                <Route path="agreements/:id/data" element={<AgreementEdit tab="data"/>}/>
                <Route path="agreements/create" element={<AgreementEdit/>}/>
                <Route path="realisations-verification" element={<Realisations verification={true}/>}/>
                <Route path="realisations-verification/:id" element={<RealisationEdit tab="data"/>}/>
                <Route path="realisations-verification/:id/data" element={<RealisationEdit tab="data"/>}/>
                <Route path="realisations-verification/:id/comments" element={<RealisationEdit tab="comments"/>}/>
                <Route path="realisations-verification/:id/calculator" element={<RealisationEdit tab="calculator"/>}/>
                <Route path="realisations-installation" element={<Realisations installation={true}/>}/>
                <Route path="realisations-installation/:id" element={<RealisationEdit tab="data"/>}/>
                <Route path="realisations-installation/:id/data" element={<RealisationEdit tab="data"/>}/>
                <Route path="realisations-installation/:id/comments" element={<RealisationEdit tab="comments"/>}/>
                <Route path="realisations-installation/:id/calculator" element={<RealisationEdit tab="calculator"/>}/>
                <Route path="realisations-commissions" element={<Realisations commissions={true}/>}/>
                <Route path="realisations-commissions/:id" element={<RealisationEdit tab="data"/>}/>
                <Route path="realisations-commissions/:id/data" element={<RealisationEdit tab="data"/>}/>
                <Route path="realisations-commissions/:id/comments" element={<RealisationEdit tab="comments"/>}/>
                <Route path="realisations-commissions/:id/calculator" element={<RealisationEdit tab="calculator"/>}/>
                <Route path="installation-crews" element={<InstallationCrews/>}/>
                <Route path="installation-crews/:id" element={<InstallationCrewEdit tab="data"/>}/>
                <Route path="installation-crews/:id/data" element={<InstallationCrewEdit tab="data"/>}/>
                <Route path="calendar" element={<InstallationCalendar/>}/>
                <Route path="calculators" element={<Calculators/>}/>
                <Route path="calculators/:id" element={<AdminRoute><CalculatorEdit tab="data"/></AdminRoute>}/>
                <Route path="calculators/:id/data" element={<AdminRoute><CalculatorEdit tab="data"/></AdminRoute>}/>
                <Route path="calculators/:id/layout" element={<AdminRoute><CalculatorEdit tab="layout"/></AdminRoute>}/>
                <Route path="calculators/:id/dictionaries" element={<AdminRoute><CalculatorEdit tab="dictionaries"/></AdminRoute>}/>
                <Route path="calculators/:id/test" element={<CalculatorTest/>}/>
                <Route path="calculators/create" element={<AdminRoute><CalculatorEdit/></AdminRoute>}/>
                <Route path="audit" element={<AdminRoute><Audit/></AdminRoute>}/>
            </Route>
            <Route path={baseUrl("/login")} element={<Login/>}/>
        </Routes>
    )
}
