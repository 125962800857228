import React from "react";

export const CustomTooltip = <T,>({ active, payload, text }: { active?: boolean, payload?: any, text: (data: T) => string }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                border: "1px solid gray",
                borderRadius: 6,
                padding: 6,
                background: "rgba(255, 255, 255, 0.8)"
            }}>
                {text(payload[0].payload)}
            </div>
        );
    }

    return null;
};
