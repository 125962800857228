import { Divider, Space, Typography } from "antd";
import React from "react";

import { getClientTypeDesc } from "../../models/model";

export const ClientItem = (props: { id: number, name: string, type: number }) => {
    return <Space size={0}>
        <div>{props.name}</div>
        <Divider type="vertical" orientationMargin={3}/>
        <Typography.Text type="secondary" style={{ fontSize: 10 }}>{props.id}</Typography.Text>
        <Divider type="vertical" orientationMargin={3}/>
        <Typography.Text type="warning" style={{ fontSize: 10 }}>{getClientTypeDesc(props.type)}</Typography.Text>
    </Space>
}
