import { Breadcrumb, Layout, Menu } from "antd";
import isNaN from "lodash/isNaN";
import React from "react";

import "./app.less";
import "./markdown.less";
import "./resizer.less";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { ItemType as BreadcrumbItemType } from "antd/lib/breadcrumb/Breadcrumb";
import { baseUrl, withoutBaseUrl } from "./utils/navigation";
import { useUser } from "./hooks/user.hook";
import {
    AuditOutlined,
    CalculatorOutlined,
    ContainerOutlined,
    DashboardOutlined,
    DollarOutlined,
    FilePdfOutlined,
    TeamOutlined,
    ToolOutlined,
    UserOutlined,
    DatabaseOutlined,
    ScissorOutlined,
    BgColorsOutlined,
    MenuUnfoldOutlined,
    InboxOutlined,
    PushpinOutlined, CalendarOutlined
} from "./components/icons";
import { ItemType as MenuItemType } from "antd/lib/menu/hooks/useItems";
import { appTheme } from "./theme";

const { Content, Sider } = Layout;

function breadcrumbItemRender(route: BreadcrumbItemType, params: any, routes: BreadcrumbItemType[], paths: string[]) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
        <span>{(route as any).title}</span>
    ) : (
        <Link to={baseUrl(paths.join('/'))}>{(route as any).title}</Link>
    );
}

interface RouteSection {
    token?: string;
    name: string;
    id?: string;
    next?: RouteSection[]
}

function makeBreadcrumbRoutes(path: string): BreadcrumbItemType[] {
    const routeMap: RouteSection[] = [{
        token: '',
        name: 'Start',
        next: [{
            token: 'users',
            name: 'Lista użytkowników',
            next: [{
                token: '{id}',
                name: 'Edycja użytkownika',
                next: [{
                    token: 'assigned-users',
                    name: 'Przypisani użytkownicy'
                }]
            }, {
                token: 'create',
                name: 'Nowy użytkownik'
            }]
        }, {
            token: 'calculators',
            name: 'Lista kalkulatorów',
            next: [{
                token: '{id}',
                name: 'Edycja kalkulatora',
                next: [{
                    token: 'dicts',
                    name: 'Słowniki'
                }, {
                    token: 'layout',
                    name: 'Układ'
                }]
            }, {
                token: 'create',
                name: 'Nowy kalkulator'
            }]
        }, {
            token: 'templates',
            name: 'Lista szablonów',
            next: [{
                token: '{id}',
                name: 'Edycja szablonu',
                next: [{
                    token: 'mappings',
                    name: 'Mapowania'
                }]
            }, {
                token: 'create',
                name: 'Nowy szablon'
            }]
        }, {
            token: 'clients',
            name: 'Lista klientów',
            next: [{
                token: '{id}',
                name: 'Edycja klienta'
            }, {
                token: 'create',
                name: 'Nowy klient'
            }]
        }, {
            token: 'assembly-parameters',
            name: 'Arkusze ustaleń montażowych',
            next: [{
                token: '{id}',
                name: 'Edycja arkusza'
            }, {
                token: 'create-pv',
                name: 'Nowy arkusz (fotowoltaika)'
            }, {
                token: 'create-hp',
                name: 'Nowy arkusz (pompa ciepła)'
            }]
        }, {
            token: 'visualisations',
            name: 'Lista wizualizacji',
            next: [{
                token: '{id}',
                name: 'Edycja wizualizacji',
                next: [{
                    token: 'comments',
                    name: 'Komentarze'
                }]
            }, {
                token: 'create',
                name: 'Nowa wizualizacja'
            }]
        }, {
            token: 'offers',
            name: 'Lista ofert',
            next: [{
                token: '{id}',
                name: 'Edycja oferty'
            }, {
                token: 'create',
                name: 'Nowa oferta'
            }, {
                token: 'calculator',
                name: 'Kalkulator'
            }]
        }, {
            token: 'projects',
            name: 'Lista projektów',
            next: [{
                token: '{id}',
                name: 'Edycja projektu',
                next: [{
                    token: 'comments',
                    name: 'Komentarze'
                }]
            }, {
                token: 'create',
                name: 'Nowy projekt'
            }]
        }, {
            token: 'agreements',
            name: 'Lista umów',
            next: [{
                token: '{id}',
                name: 'Edycja umowy'
            }, {
                token: 'create',
                name: 'Nowa umowa'
            }]
        }, {
            token: 'realisations-verification',
            name: 'Lista realizacji (weryfikacja)',
            next: [{
                token: '{id}',
                name: 'Edycja realizacji',
                next: [{
                    token: 'comments',
                    name: 'Komentarze'
                }, {
                    token: 'calculator',
                    name: 'Kalkulator'
                }]
            }],
        }, {
            token: 'realisations-installation',
            name: 'Lista realizacji (montaż)',
            next: [{
                token: '{id}',
                name: 'Edycja realizacji',
                next: [{
                    token: 'comments',
                    name: 'Komentarze'
                }, {
                    token: 'calculator',
                    name: 'Kalkulator'
                }]
            }],
        }, {
            token: 'realisations-commissions',
            name: 'Lista realizacji (prowizje)',
            next: [{
                token: '{id}',
                name: 'Edycja realizacji',
                next: [{
                    token: 'comments',
                    name: 'Komentarze'
                }, {
                    token: 'calculator',
                    name: 'Kalkulator'
                }]
            }],
        }, {
            token: 'installation-crews',
            name: 'Lista ekip montażowych',
            next: [{
                token: '{id}',
                name: 'Edycja ekipy montażowej'
            }, {
                token: 'create',
                name: 'Nowa ekipa montażowa'
            }]
        }, {
            token: 'calendar',
            name: 'Kalendarz'
        }]
    }]

    if (path === "/system") {
        return [{
            title: "System",
            path: '/system'
        }]
    } else if (path === "/") {
        return [{
            title: routeMap[0].name,
            path: '/'
        }]
    } else {
        const sections = path.split('/');
        let rm = routeMap;
        const routes = [];
        for (const section of sections) {
            const route = rm?.find(v => {
                if (v.token === '{id}' && !isNaN(parseInt(section))) {
                    v.id = section;
                    return true;
                } else if (section.indexOf(v.token ?? '') === 0) {
                    return true;
                }
                return false;
            });

            if (route) {
                if (route.next) {
                    rm = route.next
                }
                routes.push({
                    title: (route.name + (route.id ? ' ' + route.id : '')) || "-",
                    path: section === "" ? "/" : section
                })
            } else {
                break;
            }
        }
        return routes;
    }
}

function menuItem(hasPermission: boolean, key: string, icon: React.ReactNode, label: React.ReactNode, children?: MenuItemType[]): MenuItemType | null {
    return hasPermission ? {
        key,
        icon,
        label,
        children
    } : null
}

export const App = () => {
    const { permissions } = useUser();
    const location = useLocation();
    const pathname = withoutBaseUrl(location.pathname);
    const breadcrumbRoutes = makeBreadcrumbRoutes(pathname);
    const pathElements = [...pathname.matchAll(/(\/[\w\-]+)/g), '/dashboard'].map(a => a[0].substring(1))
    let selectedKey = pathElements?.[0]

    return <Layout className={"app"}>
        <Sider width={170} className="app-sider" style={{ borderRight: '1px solid ' + appTheme.colorPrimary }} collapsible={false}>
            <Menu
                mode="inline"
                selectedKeys={[selectedKey]}
                defaultOpenKeys={['realisations']}
                style={{ height: '100%', borderRight: 0 }}
                items={[
                    menuItem(true, "", <DashboardOutlined/>, <NavLink to={baseUrl("/")}>Start</NavLink>),
                    menuItem(permissions.panel.users, "users", <UserOutlined/>, <NavLink to={baseUrl("/users")}>Użytkownicy</NavLink>),
                    menuItem(permissions.panel.calculators, "calculators", <CalculatorOutlined/>, <NavLink to={baseUrl("/calculators")}>Kalkulatory</NavLink>),
                    menuItem(permissions.panel.templates, "templates", <FilePdfOutlined/>, <NavLink to={baseUrl("/templates")}>Szablony</NavLink>),
                    menuItem(permissions.panel.clients, "clients", <TeamOutlined/>, <NavLink to={baseUrl("/clients")}>Klienci</NavLink>),
                    menuItem(permissions.panel.assemblies, "assembly-parameters", <MenuUnfoldOutlined/>, <NavLink to={baseUrl("/assembly-parameters")}>Arkusze</NavLink>),
                    menuItem(permissions.panel.visualisations, "visualisations", <BgColorsOutlined/>, <NavLink to={baseUrl("/visualisations")}>Wizualizacje</NavLink>),
                    menuItem(permissions.panel.offers, "offers", <ContainerOutlined/>, <NavLink to={baseUrl("/offers")}>Oferty</NavLink>),
                    menuItem(permissions.panel.projects, "projects", <ScissorOutlined/>, <NavLink to={baseUrl("/projects")}>Projekty</NavLink>),
                    menuItem(permissions.panel.agreements, "agreements", <AuditOutlined/>, <NavLink to={baseUrl("/agreements")}>Umowy</NavLink>),
                    menuItem(permissions.panel.realisations, "realisations", <ToolOutlined/>, "Realizacje", [
                        menuItem(permissions.panel.realisations, "realisations-verification", <InboxOutlined/>, <NavLink to={baseUrl("/realisations-verification")}>Weryfikacja</NavLink>),
                        menuItem(permissions.panel.realisations, "realisations-installation", <PushpinOutlined/>, <NavLink to={baseUrl("/realisations-installation")}>Montaż</NavLink>),
                        menuItem(permissions.panel.realisations, "realisations-commissions", <DollarOutlined/>, <NavLink to={baseUrl("/realisations-commissions")}>Prowizje</NavLink>),
                        menuItem(permissions.panel.installationCrews, "installation-crews", <TeamOutlined/>, <NavLink to={baseUrl("/installation-crews")}>Ekipy</NavLink>),
                    ]),
                    menuItem(permissions.panel.calendar, "calendar", <CalendarOutlined/>, <NavLink to={baseUrl("/calendar")}>Kalendarz</NavLink>),
                    menuItem(permissions.panel.audit, "audit", <DatabaseOutlined/>, <NavLink to={baseUrl("/audit")}>Audyt</NavLink>)
                ]}
            />
        </Sider>
        <Layout className="app-content">
            <Breadcrumb
                style={{ marginTop: 16, marginBottom: 0 }}
                className="nav-breadcrumb"
                items={breadcrumbRoutes}
                itemRender={breadcrumbItemRender}/>
            <Content style={{ overflow: "auto" }} id="app-content-body">
                <Outlet/>
            </Content>
        </Layout>
    </Layout>
}
