import React, { useState } from "react";
import { Button, Space, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { RealisationData } from "./realisation-data";
import { RealisationComments } from "./realisation-comments";
import { ExportService } from "../../api/export-service";
import { OfferCalculator } from "../offers/offer-calculator";
import { useMutation } from "@tanstack/react-query";
import { Query } from "./realisation-query";

type RealisationTab = 'data' | 'comments' | 'calculator';

export const RealisationEdit = (props: { tab?: RealisationTab }) => {
    const { id } = useParams();
    const realisationId = parseInt(id ?? "") || undefined;
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);

    const { data: realisation, loading: realisationLoading } = Query.Realisation.use(realisationId);

    const { mutateAsync: exportOfferCalculator, isLoading: exportingCalculator } = useMutation((offerId: number) => {
        return ExportService.exportOfferCalculator(offerId);
    });

    const { mutateAsync: exportOffer, isLoading: exportingOffer } = useMutation((offerId: number) => {
        return ExportService.exportOffer(offerId);
    });

    const { mutateAsync: exportAgreement, isLoading: exportingAgreement } = useMutation((agreementId: number) => {
        return ExportService.exportAgreement(agreementId);
    });

    return <Tabs defaultActiveKey="data" activeKey={props.tab} id="realisation-tabs"
        onChange={tab => {
            const realisationType = realisation?.installation ? 'installation' : (realisation?.commission ? 'commissions' : 'verification');
            navigate(baseUrl(`/realisations-${realisationType}/${realisationId}/${tab}`));
            setCurrentTab(tab as RealisationTab);
        }}
        tabBarExtraContent={{
            right: <Space>
                <Button loading={exportingCalculator} disabled={realisationLoading}
                    onClick={() => exportOfferCalculator(realisation!.offerId)}>Eksportuj kalkulator</Button>
                <Button loading={exportingOffer} disabled={realisationLoading}
                    onClick={() => exportOffer(realisation!.offerId)}>Eksportuj ofertę</Button>
                <Button loading={exportingAgreement} disabled={realisationLoading}
                    onClick={() => exportAgreement(realisation!.agreementId)}>Eksportuj umowę</Button>
            </Space>
        }}
        items={[{
            label: "Dane realizacji",
            key: "data",
            children: <RealisationData
                realisationId={realisationId!!}
                realisation={realisation}
                realisationLoading={realisationLoading}
            />
        }, {
            label: "Komentarze",
            key: "comments",
            children: <RealisationComments realisationId={realisationId!!}/>
        }, {
            label: "Kalkulator",
            key: "calculator",
            disabled: !realisation,
            children: <OfferCalculator offerId={realisation?.offerId} tabsId={"realisation-tabs"}/>
        }]}
    />
}
