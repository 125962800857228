import { Divider, Space, Typography } from "antd";
import React from "react";

import { getCalculatorTypeDesc } from "../../models/model";

export const CalculatorItem = (props: { id: number, type: string, name: string, active: boolean }) => {
    return <Space size={0}>
        <div>{props.name}</div>
        <Divider type="vertical" orientationMargin={3}/>
        <Typography.Text type="secondary" style={{ fontSize: 10 }}>{props.id}</Typography.Text>
        {props.active && <>
            <Divider type="vertical" orientationMargin={3}/>
            <Typography.Text type="success" style={{ fontSize: 10 }}>Aktywny</Typography.Text>
        </>}
        <Divider type="vertical" orientationMargin={3}/>
        <Typography.Text type="warning" style={{ fontSize: 10 }}>{getCalculatorTypeDesc(props.type)}</Typography.Text>
    </Space>
}
