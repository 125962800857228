import { validateEmail, validateNip, validatePesel, validateRegon } from "./validators";
import { RuleObject, StoreValue } from "rc-field-form/lib/interface";
import { backendGet } from "../api/backend-api";

import { IdValidation } from "../models/model";
import { Nil } from "./utils";

export async function peselValidator(clientId: number | undefined, rule: RuleObject, value: StoreValue) {
    const err = validatePesel(value);
    if (err) {
        return Promise.reject(err);
    } else if (value && value.length > 0) {
        const idValidation = clientId
            ? await backendGet<IdValidation>(`/clients/${clientId}/pesel/${value}`)
            : await backendGet<IdValidation>(`/clients/pesel/${value}`);

        return idValidation.available
            ? Promise.resolve()
            : Promise.reject("Ten numer pesel jest zajęty");
    }
}

export async function nipValidator(clientId: Nil<number>, clientAccepted: Nil<boolean>, acceptedNip: Nil<string>, rule: RuleObject, value: StoreValue) {
    const err = validateNip(value);
    if (err) {
        return Promise.reject(err);
    } else if (value && value.length > 0) {
        const idValidation = clientId
            ? await backendGet<IdValidation>(`/clients/${clientId}/nip/${value}`)
            : await backendGet<IdValidation>(`/clients/nip/${value}`);

        return idValidation.available
            ? Promise.resolve()
            : (acceptedNip !== value
                ? Promise.reject(`Ten numer NIP jest zajęty. Będziesz mógł użyć tego klienta po akceptacji przez dział BO.`)
                : Promise.resolve());
    }
}

export function regonValidator(rule: RuleObject, value: StoreValue, callback: (error?: string) => void) {
    const err = validateRegon(value);
    if (err) {
        return Promise.reject(err);
    } else {
        return Promise.resolve();
    }
}

export function emailValidator(rule: RuleObject, value: StoreValue, callback: (error?: string) => void) {
    const err = validateEmail(value);
    if (err) {
        return Promise.reject(err);
    } else {
        return Promise.resolve();
    }
}
