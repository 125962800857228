import React, { useState } from "react";
import { useUser } from "../../hooks/user.hook";
import { Button, Col, Row, Space, Spin } from "antd";
import { AdminDashboard } from "./admin/admin-dashboard";
import { TraderDashboard } from "./trader/trader-dashboard";
import { useMutation } from "@tanstack/react-query";
import { GridLayout } from "../../components/absolute-layout/absolute-layout";
import { backendPut } from "../../api/backend-api";
import { useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";

export const Dashboard = () => {
    const { user, admin, director, backOffice } = useUser();
    const [editing, setEditing] = useState(false);

    const [dashboardLayout, setDashboardLayout] = useState<GridLayout>([]);

    const { data, loading: dashboardLayoutLoading } = useSqlQuerySingle('dashboard-layout',
        from(t.user)
        .where(t.user.id.eq(user.id))
        .select({
            dashboardLayout: t.user.dashboardLayout
        }), {
            map: row => ({
                dashboardLayout: JSON.parse((row.dashboardLayout as any).value)
            }),
            defaultValue: {
                dashboardLayout: {
                    value: "[]"
                }
            }
        }
    );

    const { mutateAsync: saveDashboardLayout, isLoading: savingDashboardLayout } = useMutation(async (layout: GridLayout) => {
        return backendPut(`/users/layout`, layout.map(l => ({
            key: l.key,
            x0: l.x0,
            xl0: l.xl0,
            xp0: l.xp0,
            y0: l.y0,
            yl0: l.yl0,
            yp0: l.yp0,
            x1: l.x1,
            xl1: l.xl1,
            xp1: l.xp1,
            y1: l.y1,
            yl1: l.yl1,
            yp1: l.yp1,
        })));
    });

    return <Spin spinning={dashboardLayoutLoading}>
        {admin || director || backOffice ?
            <AdminDashboard
                editing={editing}
                dashboardLayout={data?.dashboardLayout as any}
                onUpdateDashboardLayout={setDashboardLayout}/>
            : <TraderDashboard
                editing={editing}
                dashboardLayout={data?.dashboardLayout as any}
                onUpdateDashboardLayout={setDashboardLayout}/>
        }
            <Row className="footer-actions">
                <Col span={24}>
                    <Space>
                        <Button type="primary"
                            loading={savingDashboardLayout}
                            disabled={savingDashboardLayout}
                            onClick={() => {
                                if (editing) {
                                    void saveDashboardLayout(dashboardLayout);
                                }
                                setEditing(!editing);
                            }}>
                            {editing ? "Zapisz" : "Edytuj"}
                        </Button>
                    </Space>
                </Col>
            </Row>

    </Spin>
}

