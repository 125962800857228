import React, { useEffect, useState } from 'react';
import { sql, useSqlQueryRaw, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { backendPut } from "../../api/backend-api";
import { Alert, Button, Card, Col, Form, notification, Row, Spin, TreeSelect } from "antd";
import { useForm } from "antd/lib/form/Form";
import forIn from "lodash/forIn";
import { useTemplateStatus } from "../../hooks/template-status.hook";
import { FieldType } from "../calculators/layout-types";
import { useUser } from "../../hooks/user.hook";
import { useMutation } from '@tanstack/react-query';
import { labelColLg, wrapperCol } from "../../models/ui";
import { FooterGap } from "../../components/gap";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";

const { TreeNode } = TreeSelect;

interface MappingField {
    templateField: string;
    calculatorField: string;
}

function parseFieldItems(mappings: string): MappingField[] {
    const valuesPattern = /\[(.*)\]:\[(.*)\]/
    return mappings ? mappings.split(';')
    .filter(m => m)
    .map(m => valuesPattern.exec(m))
    .filter(m => m && m.values())
    .map(m => {
        return {
            templateField: m![1],
            calculatorField: m![2]
        }
    }) : [];
}

export const TemplateMappings = ({ templateId }: { templateId?: number }) => {
    const user = useUser();

    const { data: template, loading: templateLoading } = useSqlQuerySingle(
        `template-mappings-${templateId}`,
        from(t.template)
        .where(t.template.id.eq(templateId))
        .select({
            templateType: t.template.templateType,
            mappings: t.template.mappings,
            calculatorId: t.template.calculatorId,
            ownerId: t.template.ownerId
        }), {
            enabled: templateId
        }
    );

    const { data: rawFields, loading: rawFieldsLoading } = useSqlQueryRaw<{ fieldId: string, fieldName: string, field: string, fieldType: number }>(
        `template-raw-fields-${template?.calculatorId}`,
        sql`
            select ci.field_id, coalesce(ci.alias, ci.field_id) as field_name, coalesce(ci.alias, ci.field_id) || ' [' ||
                   case
                       when ci.field_type = 0 then cd.dict_name
                       when ci.field_type = 1 then 'tekst'
                       when ci.field_type = 2 then 'liczba'
                       when ci.field_type = 3 then 'formuła'
                       when ci.field_type = 4 then 'tekst'
                       else ''
                    end || ']' as field, field_type, ci.owner_id
            from calculator_item ci
                 left join calculator_dict cd
                 on cd.id = ci.calculator_dict_id
            where ci.calculator_id = ${template?.calculatorId}
              and ci.field_type in (0, 1, 2, 3, 4)
            order by coalesce(ci.alias, ci.field_id)
        `,
        { enabled: template?.calculatorId }
    );

    useEffect(() => {
        if (template?.mappings) {
            const fields = parseFieldItems(template.mappings);
            const values = {} as any;
            fields.forEach(field =>
                values[field.templateField] = field.calculatorField
            );
            setFields(fields);
            form.setFieldsValue(values);
        }
    }, [template]);

    const { templateAccepted, templateStatusLoading } = useTemplateStatus(templateId);

    const permissions = user.permissions.template(templateId, templateAccepted, template?.templateType);

    const [fields, setFields] = useState<MappingField[]>([]);

    const { mutateAsync: saveMappings, isLoading: mappingsSaving } = useMutation((values: any) => {
        let mappings = ""
        forIn(values, (value, key) => {
            mappings += `[${key}]:[${value ? value : ''}];`;
        });
        return backendPut(`/templates/${templateId}/mappings`, mappings)
            .then(() => {
                notification.success({
                    message: 'Mapowania zostały zapisane'
                });
            })
    });

    const [form] = useForm();

    return <Card style={{ padding: 0 }}>
        <Spin spinning={templateLoading || rawFieldsLoading || mappingsSaving || templateStatusLoading}>
            <Form
                form={form}
                labelCol={labelColLg}
                labelWrap={true}
                colon={false}
                wrapperCol={wrapperCol}
                onFinish={values => {
                    saveMappings(values);
                }}
                name="template"
                layout={"horizontal"}
            >
                {fields.length === 0 ? <Alert type="warning" message="Plik szablonu nie zawiera żadnych pól" style={{ marginBottom: 20 }}/>: null}
                {fields.map(f => {
                    return <Form.Item key={f.templateField}
                        label={f.templateField}
                        name={f.templateField}
                    >
                        <TreeSelect allowClear={true} showSearch={true} treeDefaultExpandAll={true} popupMatchSelectWidth={false} disabled={!permissions.edit} treeNodeFilterProp={'title'}>
                            <TreeNode title="Arkusz" value={"assemblyParameters"} selectable={false}>
                                <TreeNode value={"assemblyParameters.id"} title={"Id"}/>
                                <TreeNode title="Fotowoltaika" value={"pv"} selectable={false}>
                                    <TreeNode value={"assemblyParameters.groupInstallationLocation"} title={"Lokalizacja instalacji"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.addressType"} title={"Adres"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.addressType.sameAsClient"} title={"Taki sam jak klienta"}/>
                                            <TreeNode value={"assemblyParameters.addressType.anotherAddress"} title={"Inny"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.anotherAddress.address"} title={"Ulica i nr budynku / lokalu"}/>
                                        <TreeNode value={"assemblyParameters.anotherAddress.postCode"} title={"Kod pocztowy"}/>
                                        <TreeNode value={"assemblyParameters.anotherAddress.town"} title={"Miejscowość"}/>
                                        <TreeNode value={"assemblyParameters.installationLocation"} title={"Umiejscowienie instalacji"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.installationLocation.ground"} title={"Na gruncie"}/>
                                            <TreeNode value={"assemblyParameters.installationLocation.roof"} title={"Na dachu"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.buildingType"} title={"Rodzaj budynku"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.buildingType.residential"} title={"Budynek mieszkalny"}/>
                                            <TreeNode value={"assemblyParameters.buildingType.garage"} title={"Garaż"}/>
                                            <TreeNode value={"assemblyParameters.buildingType.industrial"} title={"Budynek przemysłowy"}/>
                                            <TreeNode value={"assemblyParameters.buildingType.another"} title={"Inny rodzaj budynku"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.buildingDescription"} title={"Zastosowanie / Opis"}/>
                                        <TreeNode value={"assemblyParameters.propertyStatus"} title={"Status nieruchomości"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.propertyStatus.private"} title={"Własność"}/>
                                            <TreeNode value={"assemblyParameters.propertyStatus.rent"} title={"Najem"}/>
                                            <TreeNode value={"assemblyParameters.propertyStatus.lease"} title={"Dzierżawa"}/>
                                            <TreeNode value={"assemblyParameters.propertyStatus.underMonumentsConservator"} title={"Objęty konserwatorem zabytków"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.roofSheathing"} title={"Poszycie dachu"} seletable={false}>
                                            <TreeNode value={"assemblyParameters.roofSheathing.steelTile"} title={"Blachodachówka"}/>
                                            <TreeNode value={"assemblyParameters.roofSheathing.plainTile"} title={"Dachówka karpiówka"}/>
                                            <TreeNode value={"assemblyParameters.roofSheathing.papaBoarding"} title={"Papa / deskowanie"}/>
                                            <TreeNode value={"assemblyParameters.roofSheathing.trapezoidalMetalSheet"} title={"Blacha trapezowa"}/>
                                            <TreeNode value={"assemblyParameters.roofSheathing.ceramicTile"} title={"Dachówka ceramiczna"}/>
                                            <TreeNode value={"assemblyParameters.roofSheathing.concreteTile"} title={"Dachówka betonowa"}/>
                                            <TreeNode value={"assemblyParameters.roofSheathing.papaConcrete"} title={"Papa / beton"}/>
                                            <TreeNode value={"assemblyParameters.roofSheathing.another"} title={"Inne"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.trapezoidalDistance"} title={"Odległość między trapezami (cm)"}/>
                                        <TreeNode value={"assemblyParameters.anotherRoofSheathingDescription"} title={"Opis (Poszycie dachu: Inne)"}/>
                                    </TreeNode>
                                    <TreeNode value={"assemblyParameters.groupRoofConstruction"} title={"Konstrukcja dachu"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.roofMaterial"} title={"Materiał"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.roofMaterial.wood"} title={"Drewno"}/>
                                            <TreeNode value={"assemblyParameters.roofMaterial.steal"} title={"Stal"}/>
                                            <TreeNode value={"assemblyParameters.roofMaterial.concrete"} title={"Beton"}/>
                                            <TreeNode value={"assemblyParameters.roofMaterial.another"} title={"Inne"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.raftersDistance"} title={"Odległość pomiędzy krokwiami (cm)"}/>
                                        <TreeNode value={"assemblyParameters.anotherRoofMaterialDescription"} title={"Opis (Materiał: Inny)"}/>
                                    </TreeNode>
                                    <TreeNode value={"assemblyParameters.groupBuildingGround"} title={"Budynek / Działka"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.roofType"} title={"Rodzaj dachu"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.roofType.flat"} title={"Płaski"}/>
                                            <TreeNode value={"assemblyParameters.roofType.gableRoof"} title={"Dach dwuspadowy"}/>
                                            <TreeNode value={"assemblyParameters.roofType.monoPitchedRoof"} title={"Dach jednospadowy"}/>
                                            <TreeNode value={"assemblyParameters.roofType.hipRoof"} title={"Dach czterospadowy"}/>
                                            <TreeNode value={"assemblyParameters.roofType.freestandig"} title={"Wolnostojący (Grunt)"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.buildingHeight"} title={"Wysokość budynku (m)"}/>
                                        <TreeNode value={"assemblyParameters.roofLength"} title={"Długość dachu (m)"}/>
                                        <TreeNode value={"assemblyParameters.roofEdgeLength"} title={"Długość krawędzi dachu (m)"}/>
                                        <TreeNode value={"assemblyParameters.roofBackLength"} title={"Długość grzebietu (m)"}/>
                                        <TreeNode value={"assemblyParameters.roofInclinationAngle"} title={"Kąt pochylenia dachu (°)"}/>
                                        <TreeNode value={"assemblyParameters.sideALength"} title={"Długość boku A (m)"}/>
                                        <TreeNode value={"assemblyParameters.sideBLength"} title={"Długość boku B (m)"}/>
                                    </TreeNode>
                                    <TreeNode value={"assemblyParameters.groupCurrentSettings"} title={"Ustalenia elektryczne"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.currentInputPower"} title={"Obecna moc przyłączeniowa (z faktury, kW)"}/>
                                        <TreeNode value={"assemblyParameters.mainMeterFuse"} title={"Bezpiecznik przy liczniku głównym (A)"}/>
                                        <TreeNode value={"assemblyParameters.annualElectricityConsumption"} title={"Roczne zużycie energii elektrycznej (kWh)"}/>
                                        <TreeNode value={"assemblyParameters.meterNumber"} title={"Nr licznika (z faktury)"}/>
                                        <TreeNode value={"assemblyParameters.energyConsumptionPointNumber"} title={"Nr PPE (z faktury)"}/>
                                        <TreeNode value={"assemblyParameters.electricInstallationType"} title={"Instalacja elektryczna w budynku"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.electricInstallationType.singlePhase"} title={"Jednofazowa"}/>
                                            <TreeNode value={"assemblyParameters.electricInstallationType.threePhase"} title={"Trójfazowa"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.lightningProtection"} title={"Instalacja odgromowa"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.lightningProtection.yes"} title={"Tak"}/>
                                            <TreeNode value={"assemblyParameters.lightningProtection.no"} title={"Nie"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.switchboardGround"} title={"Uziemienie w rozdzielnicy"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.switchboardGround.yes"} title={"Tak"}/>
                                            <TreeNode value={"assemblyParameters.switchboardGround.no"} title={"Nie"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.moduleInverterDistance"} title={"Odległość moduł - falownik"}/>
                                        <TreeNode value={"assemblyParameters.inverterSwitchboardDistance"} title={"Odległość falownik - rozdielnia"}/>
                                    </TreeNode>
                                    <TreeNode value={"assemblyParameters.groupSolarPanels"} title={"Panele fotowoltaiczne"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.installationDesiredPower"} title={"Wskazanie pożądanej mocy instalacji (kWp)"}/>
                                        <TreeNode value={"assemblyParameters.panelsCount"} title={"Ilość paneli (szt)"}/>
                                    </TreeNode>
                                    <TreeNode value={"assemblyParameters.groupInverter"} title={"Inwerter"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.inverterInstallationPlace"} title={"Miejsce montażu"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.inverterInstallationPlace.inside"} title={"Wewnątrz"}/>
                                            <TreeNode value={"assemblyParameters.inverterInstallationPlace.outside"} title={"Na zwenątrz"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.inverterLocation"} title={"Lokalizacja"}/>
                                        <TreeNode value={"assemblyParameters.cableRoute"} title={"Trasa kablowa prowadzona będzie"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.cableRoute.technicalShaft"} title={"Szacht techniczny"}/>
                                            <TreeNode value={"assemblyParameters.cableRoute.facade"} title={"Po elewacji"}/>
                                            <TreeNode value={"assemblyParameters.cableRoute.another"} title={"Inne"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.inverterConnection"} title={"Połączenie przez"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.inverterConnection.lan"} title={"Lan"}/>
                                            <TreeNode value={"assemblyParameters.inverterConnection.wifi"} title={"Wi-Fi"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.wifiRange"} title={"Zasięg Wi-Fi"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.wifiRange.yes"} title={"Tak"}/>
                                            <TreeNode value={"assemblyParameters.wifiRange.no"} title={"Nie"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.connectionDescription"} title={"Uwagi"}/>
                                    </TreeNode>
                                    <TreeNode value={"assemblyParameters.groupAdditionalMaterials"} title={"Dodatkowe materiały i informacje"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.agreementType"} title={"Typ umowy z OSD"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.agreementType.severable"} title={"Rozdzielna"}/>
                                            <TreeNode value={"assemblyParameters.agreementType.comprehensive"} title={"Kompleksowa"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.agreementRecipient"} title={"Na kogo jest umowa z OSD"}/>
                                        <TreeNode value={"assemblyParameters.modulesBringing"} title={"Możliwość wnoszenia modułów"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.modulesBringing.hump"} title={"Zwyżka"}/>
                                            <TreeNode value={"assemblyParameters.modulesBringing.ladder"} title={"Drabina"}/>
                                            <TreeNode value={"assemblyParameters.modulesBringing.roofHatch"} title={"Właz dachowy"}/>
                                            <TreeNode value={"assemblyParameters.modulesBringing.crane"} title={"Dźwig"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.attachments"} title={"Załączniki"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.attachments.planView"} title={"Widok planu"}/>
                                            <TreeNode value={"assemblyParameters.attachments.terrainMap"} title={"Mapka terenu"}/>
                                            <TreeNode value={"assemblyParameters.attachments.constructionPlan"} title={"Plan budowy"}/>
                                            <TreeNode value={"assemblyParameters.attachments.drafts"} title={"Szkice"}/>
                                            <TreeNode value={"assemblyParameters.attachments.pictures"} title={"Zdjęcia"}/>
                                            <TreeNode value={"assemblyParameters.attachments.invoices"} title={"Faktury"}/>
                                            <TreeNode value={"assemblyParameters.attachments.another"} title={"Inne"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.attachmentsAnotherDescription"} title={"Opis (Załączniki: Inne)"}/>
                                        <TreeNode value={"assemblyParameters.attachmentsDescription"} title={"Uwagi (Załączniki)"}/>
                                    </TreeNode>
                                    <TreeNode value={"assemblyParameters.description"} title={"Uwagi"}/>
                                </TreeNode>
                                <TreeNode title="Pompa ciepła" value={"hp"} selectable={false}>
                                    <TreeNode title="Informacje techniczne" value={"groupTechnicalDetails"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.buildingType.hp"} title={"Rodzaj budynku"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.buildingType.outbuilding"} title={"Budynek gospodarczy"}/>
                                            <TreeNode value={"assemblyParameters.buildingType.house"} title={"Dom"}/>
                                            <TreeNode value={"assemblyParameters.buildingType.serviceBuilding"} title={"Budynek usługowy"}/>
                                            <TreeNode value={"assemblyParameters.buildingType.underConstruction"} title={"W trakcie budowy"}/>
                                            <TreeNode value={"assemblyParameters.buildingType.inhabited"} title={"Zamieszkały"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.constructionYear"} title={"Rok budowy"}/>
                                        <TreeNode value={"assemblyParameters.heatIndicator"} title={"Wskaźnik OZC (zapotrzebowanie budynku na ciepło kWh/m2 rocznie, kW)"}/>
                                        <TreeNode value={"assemblyParameters.currentHeatSource"} title={"Aktualne źródło energii cieplnej"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.currentHeatSource.carbon"} title={"Węgiel"}/>
                                            <TreeNode value={"assemblyParameters.currentHeatSource.wood"} title={"Drewno"}/>
                                            <TreeNode value={"assemblyParameters.currentHeatSource.gas"} title={"Gaz"}/>
                                            <TreeNode value={"assemblyParameters.currentHeatSource.ecoPeaCoal"} title={"Ekogoszek"}/>
                                            <TreeNode value={"assemblyParameters.currentHeatSource.fuelOil"} title={"Olej opałowy"}/>
                                            <TreeNode value={"assemblyParameters.currentHeatSourceRemarks"} title={"Uwagi"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.heatSystem"} title={"Sposób ogrzewania pomieszczeń"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.heatSystem.castIronRadiators"} title={"Grzejniki żeliwne"}/>
                                            <TreeNode value={"assemblyParameters.heatSystem.steelRadiators"} title={"Grzejniki stalowe"}/>
                                            <TreeNode value={"assemblyParameters.heatSystem.aluminumRadiators"} title={"Grzejniki Alu"}/>
                                            <TreeNode value={"assemblyParameters.heatSystem.floorHeating"} title={"Ogrzewanie podłogowe"}/>
                                            <TreeNode value={"assemblyParameters.heatSystem.mixedRadiatorsAndFloorHeating"} title={"Mieszane grzejniki/podł."}/>
                                            <TreeNode value={"assemblyParameters.heatSystemRemarks"} title={"Uwagi"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.heatingCircuitsNumber"} title={"Ilość obiegów grzewczych"}/>
                                        <TreeNode value={"assemblyParameters.supplyPipesCrossSection"} title={"Przekrój rur zasilających"}/>
                                        <TreeNode value={"assemblyParameters.pipeMaterial"} title={"Materiał z którego wykonane są rury"}/>
                                        <TreeNode value={"assemblyParameters.indoorHeatingInstallationType"} title={"Rodzaj instalacji grzewczej wewnątrz budynku"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.indoorHeatingInstallationType.planned"} title={"Planowana"}/>
                                            <TreeNode value={"assemblyParameters.indoorHeatingInstallationType.existing"} title={"Istniejąca"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.underfloorHeatingWithHeatingZones"} title={"Ogrzewanie podłogowe ze strefami grzewczymi"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.underfloorHeatingWithHeatingZones.yes"} title={"Tak"}/>
                                            <TreeNode value={"assemblyParameters.underfloorHeatingWithHeatingZones.no"} title={"Nie"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.underfloorHeatingAndRadiators"} title={"Ogrzewanie podłogowe i grzejniki"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.underfloorHeatingAndRadiators.yes"} title={"Tak"}/>
                                            <TreeNode value={"assemblyParameters.underfloorHeatingAndRadiators.no"} title={"Nie"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.electricalInstallation"} title={"Instalacja elektryczna"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.electricalInstallation.singlePhase"} title={"1 Fazowa"}/>
                                            <TreeNode value={"assemblyParameters.electricalInstallation.threePhase"} title={"3 Fazowa"}/>
                                        </TreeNode>
                                    </TreeNode>
                                    <TreeNode title="Dane dotyczące budynku" value={"groupBuildingDetails"} selectable={false}>
                                        <TreeNode value={"assemblyParameters.heatedBuildingSurface"} title={"Ogrzewana powierzchnia budynku (m2)"}/>
                                        <TreeNode value={"assemblyParameters.garageArea"} title={"Powierzchnia garażu (jeśli będzie ogrzewany) (m2)"}/>
                                        <TreeNode value={"assemblyParameters.wallsHeatingThicknessAndType"} title={"Grubość i rodzaj ocieplenia ścian (cm)"}/>
                                        <TreeNode value={"assemblyParameters.flooringHeatingThicknessAndType"} title={"Grubość i rodzaj ocieplenia posadzki (cm)"}/>
                                        <TreeNode value={"assemblyParameters.atticCeilingHeatingThicknessAndType"} title={"Grubość i rodzaj ocieplenia poddasza/stropu (materiał/cm)"}/>
                                        <TreeNode value={"assemblyParameters.boilerRoomDimensions"} title={"Wymiary kotłowni (szer/wys)"}/>
                                        <TreeNode value={"assemblyParameters.windowsMaterial"} title={"Rodzaj okien (materiał)"}/>
                                        <TreeNode value={"assemblyParameters.windowsChambers"} title={"Rodzaj okien (ilość komór)"}/>
                                        <TreeNode value={"assemblyParameters.buildingRecuperation"} title={"Czy w budynku jest/będzie rekuperacja"}/>
                                        <TreeNode value={"assemblyParameters.buildingPhotovoltaics"} title={"Czy w budynku jest/będzie fotowoltaika (kW)"}/>
                                        <TreeNode value={"assemblyParameters.buildingAlternativeHeating"} title={"Czy w budynku jest alternatywne ogrzewanie (CWU/CO)"}/>
                                        <TreeNode value={"assemblyParameters.adultsNumber"} title={"Ilość mieszkańców (osoby dorosłe)"}/>
                                        <TreeNode value={"assemblyParameters.childrenNumber"} title={"Ilość mieszkańców (dzieci)"}/>
                                        <TreeNode value={"assemblyParameters.groupBathroom"} title={"Łazienka"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.bathroomNumber"} title={"Ilość"}/>
                                            <TreeNode value={"assemblyParameters.bathroomDimensions"} title={"Wymiary"}/>
                                            <TreeNode value={"assemblyParameters.bathroomHeating"} title={"Ogrzewanie"}/>
                                            <TreeNode value={"assemblyParameters.bath"} title={"Wanna"}/>
                                            <TreeNode value={"assemblyParameters.shower"} title={"Prysznic"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.groupBedroom"} title={"Sypialnia"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.bedroomNumber"} title={"Ilość"}/>
                                            <TreeNode value={"assemblyParameters.bedroomDimensions"} title={"Wymiary"}/>
                                            <TreeNode value={"assemblyParameters.bedroomHeating"} title={"Ogrzewanie"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.groupLivingRoom"} title={"Salon"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.livingRoomNumber"} title={"Ilość"}/>
                                            <TreeNode value={"assemblyParameters.livingRoomDimensions"} title={"Wymiary"}/>
                                            <TreeNode value={"assemblyParameters.livingRoomHeating"} title={"Ogrzewanie"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.groupAnotherRoom"} title={"Inne"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.anotherRoom"} title={"Nazwa"}/>
                                            <TreeNode value={"assemblyParameters.anotherRoomNumber"} title={"Ilość"}/>
                                            <TreeNode value={"assemblyParameters.anotherRoomDimensions"} title={"Wymiary"}/>
                                            <TreeNode value={"assemblyParameters.anotherRoomHeating"} title={"Ogrzewanie"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.waterHeatingPreferredTemp"} title={"Preferowana temperatura CWU (C)"}/>
                                        <TreeNode value={"assemblyParameters.heatingPreferredTemp"} title={"Preferowana temperatura CO (C)"}/>
                                        <TreeNode value={"assemblyParameters.buildingKind"} title={"Typ budynku"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.buildingKind.singleFamily"} title={"Jednorodzinny"}/>
                                            <TreeNode value={"assemblyParameters.buildingKind.multiFamily"} title={"Wielorodzinny"}/>
                                            <TreeNode value={"assemblyParameters.buildingKind.terraceHouse"} title={"Szeregowiec"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.roofType.hp"} title={"Rodzaj dachu"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.roofType.hp.flatRoof"} title={"Dach płaski"}/>
                                            <TreeNode value={"assemblyParameters.roofType.hp.monoPitchedRoof"} title={"Dach jednospadowy"}/>
                                            <TreeNode value={"assemblyParameters.roofType.hp.gableRoof"} title={"Dach dwuspadowy"}/>
                                            <TreeNode value={"assemblyParameters.roofType.hp.complicatedRoof"} title={"Dach skomplikowany"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.groupBasement"} title={"Piwnica"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.basement"} title={"Tak/Nie"}/>
                                            <TreeNode value={"assemblyParameters.basementWarming"} title={"Rodzaj ocieplenia"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.groupGroundFloor"} title={"Parter"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.groundFloor"} title={"Tak/Nie"}/>
                                            <TreeNode value={"assemblyParameters.groundFloorWarming"} title={"Rodzaj ocieplenia"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.groupFirstFloor"} title={"Piętro 1"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.firstFloor"} title={"Tak/Nie"}/>
                                            <TreeNode value={"assemblyParameters.firstFloorWarming"} title={"Rodzaj ocieplenia"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.groupSecondFloor"} title={"Piętro 2"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.secondFloor"} title={"Tak/Nie"}/>
                                            <TreeNode value={"assemblyParameters.secondFloorWarming"} title={"Rodzaj ocieplenia"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.atticFloor"} title={"Poddasze"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.attic"} title={"Tak/Nie"}/>
                                            <TreeNode value={"assemblyParameters.atticWarming"} title={"Rodzaj ocieplenia"}/>
                                        </TreeNode>
                                        <TreeNode value={"assemblyParameters.buildingLocation"} title={"Położenie budynku"}/>
                                        <TreeNode value={"assemblyParameters.attachment.hp"} title={"Załączniki"} selectable={false}>
                                            <TreeNode value={"assemblyParameters.attachments.boilerRoom"} title={"Kotłownia"}/>
                                            <TreeNode value={"assemblyParameters.attachments.boilerRoomExternalWall"} title={"Ściana zewnętrzna kotłowni"}/>
                                            <TreeNode value={"assemblyParameters.attachments.switchgearAndCounter"} title={"Rozdzielnia oraz Licznik"}/>
                                            <TreeNode value={"assemblyParameters.attachments.heaters"} title={"Grzejniki (jeśli są)"}/>
                                            <TreeNode value={"assemblyParameters.attachments.buildingFromPerspective"} title={"Budynek z perspektywy"}/>
                                            <TreeNode value={"assemblyParameters.attachments.indoorUnitInstallationLocation"} title={"Miejsce instalacji jednostki wewnętrznej"}/>
                                            <TreeNode value={"assemblyParameters.attachments.outdoorUnitInstallationLocation"} title={"Miejsce instalacji jednostki zewnętrznej"}/>
                                            <TreeNode value={"assemblyParameters.attachments.supplyPipes"} title={"Rury zasilające"}/>
                                        </TreeNode>
                                    </TreeNode>
                                    <TreeNode value={"assemblyParameters.remarks"} title={"Uwagi"}/>
                                </TreeNode>
                            </TreeNode>
                            <TreeNode title="Klient" value={"client"} selectable={false}>
                                <TreeNode value={"client.id"} title={"Id"}/>
                                <TreeNode value={"client.name"} title={"Nazwa"}/>
                                <TreeNode value={"client.address"} title={"Pełny adres (kod pocztowy, miejscowość, ulica)"}/>
                                <TreeNode value={"client.street"} title={"Ulica"}/>
                                <TreeNode value={"client.postCode"} title={"Kod pocztowy"}/>
                                <TreeNode value={"client.city"} title={"Miejscowość"}/>
                                <TreeNode value={"client.nip"} title={"NIP"}/>
                                <TreeNode value={"client.regon"} title={"REGON"}/>
                                <TreeNode value={"client.courtRegister"} title={"KRS"}/>
                                <TreeNode value={"client.courtCity"} title={"Sąd (miasto)"}/>
                                <TreeNode value={"client.courtDepartment"} title={"Sąd (wydział)"}/>
                                <TreeNode value={"client.companyRepresentative1"} title={"Reprezentant spółki 1"}/>
                                <TreeNode value={"client.companyRepresentative2"} title={"Reprezentant spółki 2"}/>
                                <TreeNode value={"client.pesel"} title={"Pesel"}/>
                                <TreeNode value={"client.person"} title={"Konsument?"}/>
                                <TreeNode value={"client.company"} title={"Firma?"}/>
                                <TreeNode value={"client.email"} title={"EMail"}/>
                                <TreeNode value={"client.phoneNumber"} title={"Telefon"}/>
                            </TreeNode>
                            <TreeNode title="Oferta" value={"offer"} selectable={false}>
                                <TreeNode value={"offer.id"} title={"Id"}/>
                                <TreeNode value={"offer.name"} title={"Nazwa"}/>
                                <TreeNode value={"offer.acceptDate"} title={"Data akceptacji"}/>
                                <TreeNode value={"offer.createDate"} title={"Data utworzenia"}/>
                                <TreeNode value={"offer.firstName"} title={"Opiekun: Imię"}/>
                                <TreeNode value={"offer.lastName"} title={"Opiekun: Nazwisko"}/>
                                <TreeNode value={"offer.fullName"} title={"Opiekun: Pełna nazwa"}/>
                                <TreeNode value={"offer.realisationAddress"} title={"Adres realizacji"}/>
                            </TreeNode>
                            <TreeNode title="Umowa" value={"agreement"} selectable={false}>
                                <TreeNode value={"agreement.id"} title={"Id"}/>
                                <TreeNode value={"agreement.name"} title={"Nazwa"}/>
                                <TreeNode value={"agreement.acceptDate"} title={"Data akceptacji"}/>
                                <TreeNode value={"agreement.createDate"} title={"Data utworzenia"}/>
                                <TreeNode value={"agreement.firstName"} title={"Opiekun: Imię"}/>
                                <TreeNode value={"agreement.lastName"} title={"Opiekun: Nazwisko"}/>
                                <TreeNode value={"agreement.fullName"} title={"Opiekun: Pełna nazwa"}/>
                            </TreeNode>
                            <TreeNode title="Użytkownik" value={"user"} selectable={false}>
                                <TreeNode value={"user.firstName"} title={"Imię"}/>
                                <TreeNode value={"user.lastName"} title={"Nazwisko"}/>
                                <TreeNode value={"user.fullName"} title={"Pełna nazwa"}/>
                            </TreeNode>
                            <TreeNode title="System" value={"system"} selectable={false}>
                                <TreeNode value={"system.currentDate"} title={"Data bieżąca"}/>
                            </TreeNode>
                            <TreeNode title="Kalkulator" value={"calculator"} selectable={false}>
                                {rawFields?.map(rf => {
                                    const numericField = rf.fieldType === FieldType.NumericInput || rf.fieldType == FieldType.Formula;
                                    if (numericField) {
                                        return <TreeNode title={rf.field} selectable={false} key={`${rf.fieldId}-group`} value={`${rf.fieldId}-group`}>
                                            <TreeNode value={rf.fieldId} title={`${rf.fieldName} Numerycznie`}/>
                                            {numericField ?
                                                <TreeNode value={`${rf.fieldId}-words`} title={`${rf.fieldName} Słownie`}/> : null}
                                        </TreeNode>
                                    } else {
                                        return <TreeNode value={rf.fieldId} key={rf.fieldId} title={rf.field}/>
                                    }
                                })}
                            </TreeNode>
                        </TreeSelect>
                    </Form.Item>
                })}
                <FooterGap/>
                <Row className="footer-actions">
                    <Col span={24}>
                        <Button disabled={!permissions.edit || fields.length === 0} type="primary" htmlType="submit">
                            Zapisz
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </Card>
}
