import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { notification } from "antd";
import { Model } from "../../models/db";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Query } from "./realisation-query";

export const useSaveRealisation = () => {
    const queryClient = useQueryClient();
    return useMutation(async (realisation: Model.Realisation.Data) => {
        await (realisation.id
                ? backendPut(`/realisations/${realisation.id}`, realisation)
                : backendPost('/realisations', realisation)
        );
        notification.success({
            message: 'Realizacja została zapisana'
        });
        void queryClient.invalidateQueries([Query.Realisation.key(realisation.id)]);
        void queryClient.invalidateQueries([Query.Realisation.Statuses.key(realisation.id)]);
        void queryClient.invalidateQueries([Query.Realisation.Documents.key(realisation.id)]);
        void queryClient.invalidateQueries([Query.Realisation.Payments.key(realisation.id)]);
    });
}

export const useMoveRealisationToInstallation = () => {
    const queryClient = useQueryClient();
    return useMutation((realisationId: number) =>
        backendPut(`/realisations/${realisationId}/installation`)
        .then(() => {
            notification.success({
                message: 'Realizacja została przekazana do montażu'
            });
            void queryClient.invalidateQueries([Query.Realisation.key(realisationId)]);
            void queryClient.invalidateQueries([Query.Realisation.Statuses.key(realisationId)]);
        })
    );
}

export const useMoveRealisationToCommission = () => {
    const queryClient = useQueryClient();
    return useMutation((realisationId: number) =>
        backendPut(`/realisations/${realisationId}/commission`)
        .then(() => {
            notification.success({
                message: 'Realizacja została przekazana do rozliczenia prowizji'
            });
            void queryClient.invalidateQueries([Query.Realisation.key(realisationId)]);
            void queryClient.invalidateQueries([Query.Realisation.Statuses.key(realisationId)]);
        })
    );
}

export const useRemoveRealisation = () => {
    const queryClient = useQueryClient();
    const { mutateAsync: removeRealisation, isLoading: realisationRemoving } = useMutation(async (realisationId: number) => {
        await backendDelete(`/realisations/${realisationId}`);
        void queryClient.invalidateQueries(['realisations']);
        notification.success({
            message: 'Realizacja została usunięta'
        });
    });

    return {
        removeRealisation,
        realisationRemoving
    }
}
