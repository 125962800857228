import React from "react";
import { Button, Checkbox, DatePicker, Input, Select, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { useAssignedUsers, useUser } from "../../hooks/user.hook";
import { useDebounce } from "../../hooks/debaunce.hook";
import { appStore } from "../../store/store";
import { CreatedBy } from "../../components/created-by";
import { RemoveButton } from "../../components/remove-button";
import { TableView } from "../../components/table-view";
import { filterByLabel } from "../../utils/utils";
import { useMakeOfferCopy, useRemoveOffer } from "./offer.hook";
import { useQueryClient } from "@tanstack/react-query";
import { NvlLink } from "../../components/nvl-link";
import { Dayjs } from "dayjs";
import { Query } from "./offer-query";
import { CalculatorType } from "../../components/calculator-type";

export const Offers = () => {
    const queryClient = useQueryClient();

    const user = useUser();
    const filter = appStore.useState(s => s.offerList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const { mutateAsync: removeOffer } = useRemoveOffer();
    const { mutateAsync: makeCopy, isLoading: makingCopy } = useMakeOfferCopy();

    const criteria = {...filter, search: debouncedSearch};
    const { total, data, loading } = Query.Offers.use(criteria);
    const { users, usersLoading } = useAssignedUsers();

    const columns: ColumnsType<Query.Offers.T> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
        width: '30%'
    }, {
        title: 'Klient',
        dataIndex: 'client',
        width: '30%',
        render: (value, row) => <NvlLink value={row.client} link={baseUrl(`/clients/${row.clientId}/data`)}/>
    }, {
        title: 'Kalkulator',
        dataIndex: 'calculator',
        width: '30%'
    }, {
        title: 'Rodzaj',
        dataIndex: 'calculatorType',
        width: 150,
        render: (value, row) => <CalculatorType assemblyType={value}/>,
        filters: [
            { text: 'Fotowoltaika', value: 'photovoltaics' },
            { text: 'Pompa ciepła', value: 'heat-pump' },
        ],
        filteredValue: filter.calculatorType
    }, {
        title: 'Umowa',
        dataIndex: 'agreementId',
        width: 150,
        render: (value, row) => <NvlLink value={row.agreement} link={baseUrl(`/agreements/${row.agreementId}/data`)}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.agreement
    }, {
        title: 'Wizualizacja',
        dataIndex: 'visualisationId',
        width: 150,
        render: (value, row) => <NvlLink value={row.visualisation} link={baseUrl(`/visualisations/${row.visualisationId}/data`)}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.visualisation
    }, {
        title: 'Projekt',
        dataIndex: 'projectId',
        width: 150,
        render: (value, row) => <NvlLink value={row.project} link={baseUrl(`/projects/${row.projectId}/data`)}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.project
    }, {
        title: 'Zaakceptowana',
        dataIndex: 'accepted',
        width: 170,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.accepted
    }, {
        title: 'Wysłana',
        dataIndex: 'sent',
        width: 170,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.sent
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: (value, row) => <CreatedBy createdAt={value} createdBy={row.createdBy} fullInfo={user.permissions.creatorName}/>,
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 210,
        render: (_, item) => {
            return <Space>
                {user.permissions.offerList.edit([item.ownerId]) ? <>
                    <Link to={baseUrl(`/offers/${item.id}/data`)}>Edytuj</Link>
                    {item.accepted ? <Button type="link" onClick={() => makeCopy(item.id)}>Kopiuj</Button> : null}
                </> : null}
                {user.permissions.offerList.remove([item.ownerId]) ?
                    <RemoveButton link disabled={!user.permissions.offerList.removeEnable(item.accepted)}
                        onConfirm={() => removeOffer(item.id)}/> : null}
            </Space>
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading || makingCopy}
        scrollWidth={1740}
        listKey={'offerList'}
        onFiltersChange={filters => {
            appStore.update(s => {
                s.offerList.accepted = filters.accepted;
                s.offerList.agreement = filters.agreementId;
                s.offerList.visualisation = filters.visualisationId;
                s.offerList.project = filters.projectId;
                s.offerList.calculatorType = filters.calculatorType;
            });
        }}
        toolbar={
            <>
                <Link to={baseUrl("/offers/create")}><Button>Dodaj ofertę</Button></Link>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.offerList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.offerList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['offers']);
                        }}
                    />
                    {user.permissions.userFilter ?
                    <Select mode="multiple" placeholder="Opiekun" style={{ width: 250 }} value={filter.users} options={users} loading={usersLoading} onChange={value => {
                        appStore.update(s => {
                            s.offerList.users = value;
                        });
                    }}
                        filterOption={filterByLabel}
                        allowClear={true}/> : null}
                    <Input style={{width: 200}} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.offerList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
