import React from "react";
import { appStore } from "../../store/store";
import { useDebounce } from "../../hooks/debaunce.hook";
import { ColumnsType } from "antd/lib/table";
import { formatDatetime } from "../../utils/utils";
import { getListCriteria } from "../../utils/sql";
import { usePaginatedSqlQuery } from "../../hooks/sql-query.hook";
import { DatePicker, Input, Space } from "antd";
import { TableView } from "../../components/table-view";
import { useQueryClient } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";

export const Audit = () => {
    const queryClient = useQueryClient();
    const filter = appStore.useState(s => s.auditList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const criteria = {...filter, search: debouncedSearch};
    const where = getListCriteria(
        [t.user.firstName, t.user.lastName],
        t.loggedActions.actionTstampClk, null,
        criteria
    );

    const { total, data, loading } = usePaginatedSqlQuery(
        'audit', criteria,
        from(t.loggedActions.as(t.la))
        .join(t.user.id, t.la.userId)
        .where(where)
        .orderByAlias(criteria.sortColumn, criteria.sortDirection)
        .select({
            key: t.la.eventId,
            id: t.la.eventId,
            entityId: t.la.entityId,
            action: t.la.action,
            changedFields: t.la.changedFields.cast("json::text"),
            rowData: t.la.rowData.cast("json::text"),
            schemaName: t.la.schemaName,
            tableName: t.la.tableName_,
            createdAt: t.la.actionTstampClk,
            firstName: t.user.firstName,
            lastName: t.user.lastName
        })
    );

    const columns: ColumnsType<typeof data[number]> = [{
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 80
    }, {
        title: 'Użytkownik',
        dataIndex: 'username',
        key: 'username',
        render: (value, record) => <span>{record.firstName} {record.lastName}</span>
    }, {
        title: 'Tablica',
        dataIndex: 'table',
        key: 'table',
        render: (value, record) => <span>{record.schemaName}.{record.tableName}</span>
    }, {
        title: 'Akcja',
        dataIndex: 'action',
        key: 'action',
        width: 80
    }, {
        title: 'Pola rekordu',
        dataIndex: 'rowData',
        key: 'rowData',
        width: 350,
        render: value => <pre style={{ overflow: 'auto' }}>{JSON.stringify(JSON.parse(value), null, 2)}</pre>
    }, {
        title: 'Zmienione pola',
        dataIndex: 'changedFields',
        key: 'changedFields',
        width: 350,
        render: value => <pre style={{ overflow: 'auto' }}>{JSON.stringify(JSON.parse(value), null, 2)}</pre>
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120,
        render: value => formatDatetime(value)
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading}
        scrollWidth={1400}
        listKey={'auditList'}
        toolbar={
            <>
                <div/>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.auditList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.auditList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['audit']);
                        }}
                    />
                    <Input style={{width: 200}} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.auditList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
