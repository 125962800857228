import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { validateMessages } from "../utils/validators";
import { useForm } from "antd/lib/form/Form";

export const SendEmail = ({ to, subject, content, disabled, onSend }: { onSend: (to: string | null, subject: string, content: string) => 
    Promise<void>, to: string | null, subject: string, content: string, disabled: boolean }) => 
{
    const [edit, setEdit] = useState(false);
    const [sending, setSending] = useState(false);
    const [form] = useForm();

    const showEdit = () => {
        setEdit(true);
    };

    const closeEdit = () => {
        setEdit(false);
    };
    
    return <>
        <Button onClick={showEdit} disabled={disabled}>Wyślij..</Button>
        <Modal centered={true} closable={false}
            open={edit}
            onOk={() => {
                form.validateFields().then(() => {
                    const values = form.getFieldsValue();
                    setSending(true);
                    onSend(values.to, values.subject, values.content).then(() => {
                        closeEdit();
                    }).finally(() => {
                        setSending(false);
                    });
                })
            }}
            okButtonProps={{
                loading: sending,
                disabled: sending
            }}
            onCancel={closeEdit}
            cancelButtonProps={{
                disabled: sending
            }}
            width="80vw"
            styles={{ body: { height: '60vh', overflow: 'auto', border: 'none'} }}>

            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    to,
                    subject,
                    content
                }}
                validateMessages={validateMessages}
                name="mail"
            >
                <Form.Item
                    label="Do"
                    name="to"
                    rules={[{ required: true, type: "email" }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Temat"
                    name="subject"
                    rules={[{ required: true }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Treść"
                    name="content"
                    rules={[{ required: true }]}
                >
                    <Input.TextArea rows={10} style={{ height: '100%' }}/>
                </Form.Item>
            </Form>

        </Modal>
    </>
}
