import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "./icons";
import { InlineHorzGap } from "./gap";

export const NvlLink = (props: { value: any, link: string }) => {
    return props.value ? <>
        {props.value}
        <InlineHorzGap width={5}/>
        <Link to={props.link} style={{ display: 'inline-block' }}><ArrowRightOutlined/></Link>
    </> : null;
}
