import { Space } from "antd";
import { formatDate } from "../utils/utils";
import React from "react";

const statusMap: { [key: string]: string } = {
    'Data completing': 'Kompletowanie danych',
    'In realisation': 'Przyjęty do realizacji',
    'Incomplete documents': 'Dokumenty niekompletne',
    'Completed': 'Zrealizowane'
};

export function getStatusLocale(status: string | undefined) {
    return (status && statusMap[status]) ? statusMap[status] : 'Nieznany status';
}

export function getStatusList(): { id: string, name: string }[] {
    return Object.keys(statusMap).map(status => ({
        id: status,
        name: statusMap[status]
    }));
}

export const VisualisationStatus = (props: { status: string, createdAt: number }) => {
    return <div>
        <Space direction="vertical" size="small">
            <div>{getStatusLocale(props.status)}</div>
            <div style={{ fontSize: 11, color: '#cc7b26' }}>{formatDate(props.createdAt)}</div>
        </Space>
    </div>
}
