import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Dropdown, Input, notification, Select, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { useAssignedUsers, useUser } from "../../hooks/user.hook";
import { appStore } from "../../store/store";
import { useDebounce } from "../../hooks/debaunce.hook";
import { CreatedBy } from "../../components/created-by";
import { RemoveButton } from "../../components/remove-button";
import { backendDelete } from "../../api/backend-api";
import { TableView } from "../../components/table-view";
import { filterByLabel } from "../../utils/utils";
import { useMakeAssemblyParametersCopy } from "./assembly-parameters.hook";
import { MenuInfo } from "rc-menu/lib/interface";
import { DownOutlined } from "../../components/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NvlLink } from "../../components/nvl-link";
import { AssemblyParametersType } from "../../components/assembly-parameter-type";
import { Dayjs } from "dayjs";
import { Query } from "./assembly-parameters-query";

export const AssemblyParameters = () => {
    const queryClient = useQueryClient();
    const user = useUser();
    const filter = appStore.useState(s => s.assemblyParametersList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const [actionAssemblyId, setActionAssemblyId] = useState(-1);

    const { mutateAsync: removeAssembly, isLoading: assemblyRemoving } = useMutation(async (assemblyParametersId: number) => {
        setActionAssemblyId(assemblyParametersId);
        try {
            await backendDelete(`/assembly-parameters/${assemblyParametersId}`);
            void queryClient.invalidateQueries(['assemblies']);
            notification.success({
                message: 'Arkusz został usunięty'
            });
        } finally {
            setActionAssemblyId(-1);
        }
    });

    const { mutateAsync: makeCopy, isLoading: makingCopy } = useMakeAssemblyParametersCopy();
    const criteria = {...filter, search: debouncedSearch};
    const { total, data, loading } = Query.Assemblies.use(criteria)
    const { users, usersLoading } = useAssignedUsers();

    const columns: ColumnsType<typeof data[number]> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
        width: '30%'
    }, {
        title: 'Rodzaj',
        dataIndex: 'type',
        width: '15%',
        render: value => <AssemblyParametersType assemblyType={value}/>
    }, {
        title: 'Klient',
        dataIndex: 'client',
        width: '30%',
        render: (value, row) => <NvlLink value={row.client} link={baseUrl(`/clients/${row.clientId}/data`)}/>
    }, {
        title: 'Zaakceptowany',
        dataIndex: 'accepted',
        width: 170,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ],
        filteredValue: filter.accepted
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: (value, row) =>
            <CreatedBy createdAt={value} createdBy={row.createdBy} fullInfo={user.permissions.creatorName}/>
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 230,
        render: (_, item) => {
            return <Space>
                {user.permissions.assemblyParametersList.edit([item.ownerId]) ? <>
                    <Link to={baseUrl(`/assembly-parameters/${item.id}/data`)}>Edytuj</Link>
                    {item.accepted ? <Button type="link" onClick={() => makeCopy(item.id)}>Kopiuj</Button> : null}
                </> : null}
                {user.permissions.assemblyParametersList.remove([item.ownerId]) ?
                    <RemoveButton link disabled={item.id === actionAssemblyId && assemblyRemoving || user.permissions.assemblyParametersList.removeEnable()}
                        onConfirm={() => removeAssembly(item.id)}/> : null}
            </Space>
        }
    }];

    const navigate = useNavigate();

    const createAssemblyParameters = (info: MenuInfo) => {
        navigate(baseUrl(`/assembly-parameters/create-${info.key}`));
    }

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading || makingCopy}
        scrollWidth={1020}
        listKey={'assemblyParametersList'}
        onFiltersChange={filters => {
            appStore.update(s => {
                s.assemblyParametersList.accepted = filters.accepted;
            });
        }}
        toolbar={
            <>
                <Dropdown menu={{
                    onClick: createAssemblyParameters,
                    items: [
                        {
                            key: 'pv',
                            label: 'Fotowoltaika',
                        },
                        {
                            key: 'hp',
                            label: 'Popmpa ciepła',
                        },
                    ]}}>
                    <Button>
                        <Space>
                            Dodaj arkusz
                            <DownOutlined/>
                        </Space>
                    </Button>
                </Dropdown>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.assemblyParametersList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.assemblyParametersList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['assembly-parameters']);
                        }}
                    />
                    {user.permissions.userFilter ?
                        <Select mode="multiple" placeholder="Opiekun" style={{ width: 250 }} value={filter.users} loading={usersLoading} options={users} onChange={value => {
                            appStore.update(s => {
                                s.assemblyParametersList.users = value;
                            });
                        }}
                            filterOption={filterByLabel}
                            allowClear={true}/> : null}
                    <Input style={{ width: 200 }} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.assemblyParametersList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
