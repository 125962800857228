import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { usePaginatedSqlQuery, useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { getListCriteria, withEqualCriteria, withInCriteria } from "../../utils/sql";
import { VisualisationCriteria } from "../../store/state";
import { Nil } from "../../utils/utils";

export namespace Query {
    export namespace Visualisations {
        export const key = () => `visualisations`;
        export const query = (criteria: VisualisationCriteria & {
            search: string | null
        }) => {
            const where = getListCriteria(
                [t.v.id, t.v.name, t.cl.firstName, t.cl.lastName, t.cl.name],
                t.v.createdAt, t.v.deletedAt,
                criteria
            )
                + withEqualCriteria(t.v.status, criteria.status)
                + withInCriteria(t.v.ownerId, criteria.users);

            return from(t.visualisation.as(t.v))
                .join(t.client.as(t.cl).id, t.v.clientId)
                .join(t.user.as(t.u0).id, t.cl.ownerId)
                .join(t.user.as(t.u1).id, t.v.ownerId)
                .join(t.user.as(t.u2).id, t.v.userId)
                .leftJoin(t.visualisationComment, t.vc)
                .on(t.vc.visualisationId.eq(t.v.id).and(t.vc.isLast.isTrue()))
                .where(where)
                .and(t.cl.deletedAt.isNull())
                .orderByAlias(criteria.sortColumn, criteria.sortDirection)
                .select({
                    key: t.v.id,
                    id: t.v.id,
                    clientOwnerId: t.cl.ownerId,
                    createdAt: t.v.createdAt,
                    createdBy: t.u2.fullName(),
                    name: t.v.name,
                    status: t.v.status,
                    statusUpdatedAt: t.v.statusUpdatedAt,
                    ownerId: t.v.ownerId,
                    lastComment: t.vc.comment,
                    lastCommentCreatedAt: t.vc.createdAt,
                    clientId: t.cl.id,
                    client: t.cl.fullName(),
                    clientCreatedBy: t.u0.fullName(),
                    owner: t.u1.fullName()
                });
        }

        export type T = ReturnType<typeof query>;

        export const use = (criteria: VisualisationCriteria & {
            search: string | null
        }) => {
            return usePaginatedSqlQuery(
                key(), criteria,
                query(criteria)
            );
        }
    }

    export namespace Visualisation {
        export const key = (visualisationId: Nil<number>) => `visualisation-${visualisationId}`;
        export const query = (visualisationId: Nil<number>) =>
            from(t.visualisation.as(t.v))
            .join(t.client.as(t.cl).id, t.v.clientId)
            .join(t.user.as(t.u0).id, t.cl.ownerId)
            .join(t.user.as(t.u1).id, t.v.ownerId)
            .where(t.v.id.eq(visualisationId))
            .select({
                name: t.v.name,
                ownerId: t.v.ownerId,
                clientId: t.cl.id,
                clientOwnerId: t.cl.ownerId,
                client: t.cl.fullName(),
                status: t.v.status,
                statusUpdatedAt: t.v.statusUpdatedAt,
                clientOwner: t.u0.fullName(),
                clientCreatedAt: t.cl.createdAt,
                owner: t.u1.fullName(),
                visualisationCreatedAt: t.v.createdAt,
                attrs: t.v.attrs,
                userId: t.v.userId
            });

        export type T = ReturnType<typeof query>;

        export const use = (visualisationId: Nil<number>) => useSqlQuerySingle(
            key(visualisationId),
            query(visualisationId), {
                enabled: visualisationId,
                map: row => ({
                    ...row,
                    attrs: JSON.parse((row.attrs as any).value)
                })
            }
        );

        export namespace Documents {
            export const key = (visualisationId: Nil<number>) => `visualisation-documents-${visualisationId}`;
            export const query = (visualisationId: Nil<number>) =>
                from(t.visualisationDocument.as(t.vd))
                .join(t.visualisation.as(t.v).id, t.vd.visualisationId)
                .where(t.vd.visualisationId.eq(visualisationId))
                .where(t.vd.deletedAt.isNull())
                .orderByAsc(t.vd.id)
                .select({
                    id: t.vd.id,
                    filename: t.vd.filename,
                    internalFilename: t.vd.internalFilename,
                    documentGroup: t.vd.documentGroup
                });

            export type T = ReturnType<typeof query>;

            export const use = (visualisationId: Nil<number>) => useSqlQuery(
                key(visualisationId),
                query(visualisationId), {
                    enabled: visualisationId
                }
            );
        }

        export namespace Statuses {
            export const key = (visualisationId: Nil<number>) => `visualisation-statuses-${visualisationId}`;
            export const query = (visualisationId: Nil<number>) =>
                from(t.visualisationHistory, t.vh)
                .innerJoin(t.visualisation, t.v)
                .on(t.v.id.eq(t.vh.visualisationId))
                .where(t.vh.visualisationId.eq(visualisationId))
                .orderBy(t.vh.createdAt)
                .select({
                    status: t.vh.status,
                    createdAt: t.vh.createdAt
                });

            export type T = ReturnType<typeof query>;

            export const use = (visualisationId: Nil<number>) => useSqlQuery(
                key(visualisationId),
                query(visualisationId), {
                    enabled: visualisationId
                }
            );
        }

        export namespace Clients {
            export const key = (visualisationId: Nil<number>) => `visualisation-clients-${visualisationId}`;
            export const query = (visualisationId: Nil<number>) =>
                from(t.client, t.cl)
                .leftJoin(t.visualisation, t.v, visualisationId)
                .on(t.v.id.eq(visualisationId).and(t.v.clientId.eq(t.cl.id)))
                .where(t.cl.acceptedAt.isNotNull())
                .or(t.v.id.isNotNull(), visualisationId)
                .orderByAlias("name")
                .selectDistinctOn([t.cl.fullName()], {
                    id: t.cl.id,
                    ownerId: t.cl.ownerId,
                    name: t.cl.fullName(),
                    type: t.cl.clientType
                })

            export type T = ReturnType<typeof query>;

            export const use = (visualisationId: Nil<number>) => useSqlQuery(
                key(visualisationId),
                query(visualisationId)
            );
        }
    }
}
