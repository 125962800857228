import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { usePaginatedSqlQuery, useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { getListCriteria, withEqualCriteria, withFilterValueCriteria, withInCriteria } from "../../utils/sql";
import { RealisationCriteria } from "../../store/state";
import { Nil } from "../../utils/utils";

export namespace Query {
    export namespace Realisations {
        export const key = (realisationType: string) => `realisations-${realisationType}`;
        export const query = (installation: Nil<boolean>, commissions: Nil<boolean>, criteria: RealisationCriteria & {
            search: string | null
        }) => {
            let where = getListCriteria(
                [t.r.id, t.a.name, t.cl.firstName, t.cl.lastName, t.cl.name],
                t.r.createdAt, t.r.deletedAt,
                criteria
            )
            + withEqualCriteria(t.r.status, criteria.status)
            + withInCriteria(installation ? t.r.coordinatorId : t.r.ownerId, criteria.users)
            + withFilterValueCriteria(t.v.id.isNotNull().cast('int'), criteria.visualisation)
            + withFilterValueCriteria(t.p.id.isNotNull().cast('int'), criteria.project)
            ;

            where += withEqualCriteria(t.r.installation, installation ? 1 : 0)
            where += withEqualCriteria(t.r.commission, commissions ? 1 : 0)

            return from(t.realisation.as(t.r))
            .join(t.agreement.as(t.a).id, t.r.agreementId)
            .join(t.offer.as(t.o).id, t.a.offerId)
            .join(t.client.as(t.cl).id, t.o.clientId)
            .join(t.user.as(t.u0).id, t.a.ownerId)
            .join(t.user.as(t.u1).id, t.r.ownerId)
            .join(t.user.as(t.u2).id, t.r.userId)
            .join(t.user.as(t.u3).id, t.r.coordinatorId)
            .leftJoin(t.realisationComment.as(t.rc))
            .on(t.rc.realisationId.eq(t.r.id).and(t.rc.isLast.isTrue()))
            .join(t.visualisation.as(t.v).id, t.o.visualisationId)
            .join(t.project.as(t.p).offerId, t.o.id)
            .where(where)
            .and(t.cl.deletedAt.isNull())
            .orderByAlias(criteria.sortColumn, criteria.sortDirection)
            .select({
                key: t.r.id,
                id: t.r.id,
                agreementOwnerId: t.a.ownerId,
                createdAt: t.a.createdAt,
                createdBy: t.u2.fullName(),
                agreementId: t.a.id,
                agreement: t.a.name,
                status: t.r.status,
                statusUpdatedAt: t.r.statusUpdatedAt,
                ownerId: t.r.ownerId,
                lastComment: t.rc.comment,
                lastCommentCreatedAt: t.rc.createdAt,
                clientId: t.cl.id,
                client: t.cl.fullName(),
                agreementCreatedBy: t.u0.fullName(),
                owner: t.u1.fullName(),
                coordinatorId: t.u3.id,
                coordinator: t.u3.fullName(),
                visualisationId: t.v.id,
                visualisation: t.v.name,
                projectId: t.p.id,
                project: t.p.name,
                mainValue: t.r.mainValue.when(commissions),
                commissionValue: t.r.commissionValue.when(commissions),
                commissionRate: t.r.commissionRate.when(commissions),
                commissionCorrectionType: t.r.commissionCorrectionType.when(commissions),
                commissionCorrection: t.r.commissionCorrection.when(commissions),
                commissionPaymentDate: t.r.commissionPaymentDate.when(commissions)
            });
        }

        export type T = ReturnType<typeof query>;

        export const use = (realisationType: string, installation: Nil<boolean>, commissions: Nil<boolean>, criteria: RealisationCriteria & {
            search: string | null
        }) => {
            return usePaginatedSqlQuery(
                key(realisationType), criteria,
                query(installation, commissions, criteria)
            );
        }
    }

    export namespace Realisation {
        export const key = (realisationId: Nil<number>) => `realisation-${realisationId}`;
        export const query = (realisationId: Nil<number>) =>
            from(t.realisation.as(t.r))
            .join(t.agreement.as(t.a).id, t.r.agreementId)
            .join(t.offer.as(t.o).id, t.a.offerId)
            .join(t.client.as(t.cl).id, t.o.clientId)
            .join(t.template.as(t.t).id, t.a.templateId)
            .join(t.user.as(t.u0).id, t.a.ownerId)
            .join(t.user.as(t.u1).id, t.r.ownerId)
            .join(t.user.as(t.u2).id, t.o.ownerId)
            .join(t.user.as(t.u3).id, t.cl.ownerId)
            .where(t.r.id.eq(realisationId))
            .select({
                agreementId: t.a.id,
                agreement: t.a.name,
                offerId: t.o.id,
                offer: t.o.name,
                clientId: t.cl.id,
                client: t.cl.fullName(),
                status: t.r.status,
                statusUpdatedAt: t.r.statusUpdatedAt,
                templateFlags: t.t.templateFlags,
                agreementOwner: t.u0.fullName(),
                agreementOwnerId: t.u0.id,
                agreementCreatedAt: t.a.createdAt,
                owner: t.u1.fullName(),
                ownerId: t.r.ownerId,
                coordinatorId: t.r.coordinatorId,
                realisationCreatedAt: t.r.createdAt,
                offerOwner: t.u2.fullName(),
                offerCreatedAt: t.o.createdAt,
                clientOwner: t.u3.fullName(),
                clientCreatedAt: t.cl.createdAt,
                installationTaskList1: t.r.installationTaskList1,
                installationTaskListName1: t.r.installationTaskListName1,
                installationTaskList2: t.r.installationTaskList2,
                installationTaskListName2: t.r.installationTaskListName2,
                installationTaskList3: t.r.installationTaskList3,
                installationTaskListName3: t.r.installationTaskListName3,
                installationTaskList4: t.r.installationTaskList4,
                installationTaskListName4: t.r.installationTaskListName4,
                installationTaskList5: t.r.installationTaskList5,
                installationTaskListName5: t.r.installationTaskListName5,
                installation: t.r.installation,
                commission: t.r.commission,
                mainValue: t.r.mainValue,
                commissionValue: t.r.commissionValue,
                commissionRate: t.u0.commissionRate,
                commissionCorrection: t.r.commissionCorrection,
                commissionCorrectionType: t.r.commissionCorrectionType,
                commissionPaymentDate: t.r.commissionPaymentDate
            });

        export type T = ReturnType<typeof query>;

        export const use = (realisationId: Nil<number>) => useSqlQuerySingle(
            key(realisationId),
            query(realisationId), {
                enabled: realisationId
            }
        );
    }

    export namespace Realisation {
        export namespace Documents {
            export const key = (realisationId: Nil<number>) => `realisation-documents-${realisationId}`;
            export const query = (realisationId: Nil<number>) =>
                from(t.realisationDocument.as(t.rd))
                .join(t.realisation.id, t.rd.realisationId)
                .where(t.rd.realisationId.eq(realisationId))
                .and(t.rd.deletedAt.isNull())
                .orderBy(t.rd.id)
                .select({
                    id: t.rd.id,
                    filename: t.rd.filename,
                    internalFilename: t.rd.internalFilename,
                    documentGroup: t.rd.documentGroup
                });

            export type T = ReturnType<typeof query>;

            export const use = (realisationId: Nil<number>) => useSqlQuery(
                key(realisationId),
                query(realisationId), {
                    enabled: realisationId
                }
            );
        }
    }

    export namespace Realisation {
        export namespace Statuses {
            export const key = (realisationId: Nil<number>) => `realisation-statuses-${realisationId}`;
            export const query = (realisationId: Nil<number>) =>
                from(t.realisationHistory.as(t.rh))
                .join(t.realisation.id, t.rh.realisationId)
                .where(t.rh.realisationId.eq(realisationId))
                .orderBy(t.rh.createdAt)
                .select({
                    status: t.rh.status,
                    createdAt: t.rh.createdAt
                });

            export type T = ReturnType<typeof query>;

            export const use = (realisationId: Nil<number>) => useSqlQuery(
                key(realisationId),
                query(realisationId), {
                    enabled: realisationId
                }
            );
        }
    }

    export namespace Realisation {
        export namespace Payments {
            export const key = (realisationId: Nil<number>) => `realisation-payments-${realisationId}`;
            export const query = (realisationId: Nil<number>) =>
                from(t.realisationPayment.as(t.rp))
                .join(t.realisation.id, t.rp.realisationId)
                .where(t.rp.realisationId.eq(realisationId))
                .orderBy(t.rp.ordinalNumber)
                .select({
                    id: t.rp.id,
                    amount: t.rp.amount,
                    invoiceNumber: t.rp.invoiceNumber,
                    paidAt: t.rp.paidAt,
                    expectedAt: t.rp.expectedAt,
                    comment: t.rp.comment
                });

            export type T = ReturnType<typeof query>;

            export const use = (realisationId: Nil<number>) => useSqlQuery(
                key(realisationId),
                query(realisationId), {
                    enabled: realisationId
                }
            );
        }
    }

    export namespace Realisation {
        export namespace InstallationTasks {
            export const key = () => `realisation-installation-tasks`;
            export const query = () =>
                from(t.installationTask.as(t.it))
                .where(t.it.deletedAt.isNull())
                .orderBy(t.it.id)
                .select({
                    id: t.it.id,
                    name: t.it.name,
                    tasks: t.it.tasks
                });

            export type T = ReturnType<typeof query>;

            export const use = () => useSqlQuery(
                key(),
                query()
            );
        }
    }

    export namespace Realisation {
        export namespace CalendarEvents {
            export const key = (realisationId: Nil<number>) => `realisation-calendar-events-${realisationId}`;
            export const query = (realisationId: Nil<number>) =>
                from(t.installationCalendar.as(t.ica))
                .join(t.installationCrew.as(t.ic).id, t.ica.installationCrewId)
                .where(t.ica.realisationId.eq(realisationId))
                .and(t.ica.deletedAt.isNull())
                .orderByAsc(t.ica.dateStart)
                .orderByAsc(t.ica.id)
                .select({
                    name: t.ica.name,
                    crewId: t.ica.installationCrewId,
                    crewName: t.ic.name,
                    dateStart: t.ica.dateStart,
                    dateEnd: t.ica.dateEnd
                });

            export type T = ReturnType<typeof query>;

            export const use = (realisationId: Nil<number>) => useSqlQuery(
                key(realisationId),
                query(realisationId)
            );
        }
    }
}
