import React from "react";
import { Alert, Checkbox, Col, Divider, Form, Input, Radio, Row, Space, Switch } from "antd";
import { Gap } from "../../components/gap";
import { FileData, FileUploader, SetFileList } from "../../components/file-uploader";
import { AssemblyParametersOperation } from "./assembly-parameters-edit";
import { DecimalNumber } from "../../components/decimal-number";
import { DescriptionsItemType } from "antd/es/descriptions";

export const getAssemblyParametersDataHpV1 = (
    operation: AssemblyParametersOperation,
    previewMode: boolean | undefined,
    fileList: FileData[],
    setFileList: SetFileList,
    basement: any,
    groundFloor: any,
    firstFloor: any,
    secondFloor: any,
    attic: any
): DescriptionsItemType[] => {
    const fieldRules = [{ required: operation === 'accept' || operation === 'export' }];

    return [{
        key: 'technical-info',
        label: 'Informacje techniczne',
        children: <>
            <Form.Item name={['attrs', 'buildingType']} label="Rodzaj budynku" rules={fieldRules}>
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value="outbuilding">Budynek gospodarczy</Radio>
                        <Radio value="house">Dom</Radio>
                        <Radio value="serviceBuilding">Budynek usługowy</Radio>
                        <Radio value="underConstruction">W trakcie budowy</Radio>
                        <Radio value="inhabited">Zamieszkały</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={['attrs', 'constructionYear']} label="Rok budowy" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'heatIndicator']} labelCol={{ flex: "450px" }}
                label="Wskaźnik OZC (zapotrzebowanie budynku na ciepło kWh/m2 rocznie, kW)" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Space>
                <Space direction={"vertical"} style={{ width: 400 }}>
                    <Form.Item name={['attrs', 'currentHeatSource']} label="Aktualne źródło energii cieplnej"
                        rules={fieldRules}>
                        <Checkbox.Group>
                            <Space direction="vertical">
                                <Checkbox value="carbon">Węgiel</Checkbox>
                                <Checkbox value="wood">Drewno</Checkbox>
                                <Checkbox value="gas">Gaz</Checkbox>
                                <Checkbox value="ecoPeaCoal">Ekogoszek</Checkbox>
                                <Checkbox value="fuelOil">Olej opałowy</Checkbox>
                            </Space>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item name={['attrs', 'currentHeatSourceRemarks']} label="Uwagi" rules={fieldRules}>
                        <Input.TextArea/>
                    </Form.Item>
                </Space>
                <Space direction={"vertical"} style={{ width: 400 }}>
                    <Form.Item name={['attrs', 'heatSystem']} label="Sposób ogrzewania pomieszczeń" rules={fieldRules}>
                        <Checkbox.Group>
                            <Space direction="vertical">
                                <Checkbox value="castIronRadiators">Grzejniki żeliwne</Checkbox>
                                <Checkbox value="steelRadiators">Grzejniki stalowe</Checkbox>
                                <Checkbox value="aluminumRadiators">Grzejniki Alu</Checkbox>
                                <Checkbox value="floorHeating">Ogrzewanie podłogowe</Checkbox>
                                <Checkbox value="mixedRadiatorsAndFloorHeating">Mieszane grzejniki/podł.</Checkbox>
                            </Space>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item name={['attrs', 'heatSystemRemarks']} label="Uwagi" rules={fieldRules}>
                        <Input.TextArea/>
                    </Form.Item>
                </Space>
            </Space>
            <Form.Item name={['attrs', 'heatingCircuitsNumber']} label="Ilość obiegów grzewczych" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'supplyPipesCrossSection']} label="Przekrój rur zasilających" rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Form.Item name={['attrs', 'pipeMaterial']} label="Materiał z którego wykonane są rury" rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Form.Item name={['attrs', 'indoorHeatingInstallationType']}
                label="Rodzaj instalacji grzewczej wewnątrz budynku" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="planned">Planowana</Radio>
                    <Radio value="existing">Istniejąca</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={['attrs', 'underfloorHeatingWithHeatingZones']}
                label="Ogrzewanie podłogowe ze strefami grzewczymi" valuePropName="checked" rules={fieldRules}
                initialValue={false}>
                <Switch/>
            </Form.Item>
            <Form.Item name={['attrs', 'underfloorHeatingAndRadiators']} label="Ogrzewanie podłogowe i grzejniki"
                valuePropName="checked" rules={fieldRules} initialValue={false}>
                <Switch/>
            </Form.Item>
            <Form.Item name={['attrs', 'electricalInstallation']} label="Instalacja elektryczna" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="singlePhase">1 Fazowa</Radio>
                    <Radio value="threePhase">3 Fazowa</Radio>
                </Radio.Group>
            </Form.Item>
        </>
    }, {
        key: 'building-data',
        label: 'Dane dotyczące budynku',
        children: <>
            <Form.Item name={['attrs', 'heatedBuildingSurface']} label="Ogrzewana powierzchnia budynku (m2)"
                rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'garageArea']} label="Powierzchnia garażu (jeśli będzie ogrzewany) (m2)"
                rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'wallsHeatingThicknessAndType']} label="Grubość i rodzaj ocieplenia ścian (cm)"
                rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'flooringHeatingThicknessAndType']}
                label="Grubość i rodzaj ocieplenia posadzki (cm)" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'atticCeilingHeatingThicknessAndType']}
                label="Grubość i rodzaj ocieplenia poddasza/stropu (materiał/cm)" rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Form.Item name={['attrs', 'boilerRoomDimensions']} label="Wymiary kotłowni (szer/wys)" rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Divider/>
            <Form.Item name={['attrs', 'windowsMaterial']} label="Rodzaj okien (materiał)" rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Form.Item name={['attrs', 'windowsChambers']} label="Rodzaj okien (ilość komór)" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Divider/>
            <Form.Item name={['attrs', 'buildingRecuperation']} label="Czy w budynku jest/będzie rekuperacja"
                rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Form.Item name={['attrs', 'buildingPhotovoltaics']} label="Czy w budynku jest/będzie fotowoltaika (kW)"
                rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Form.Item name={['attrs', 'buildingAlternativeHeating']}
                label="Czy w budynku jest alternatywne ogrzewanie (CWU/CO)" rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Divider/>
            <Form.Item name={['attrs', 'adultsNumber']} label="Ilość mieszkańców (osoby dorosłe)" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'childrenNumber']} label="Ilość mieszkańców (dzieci)" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Divider/>
            <Row>
                <Col span={4} className="centered-content">
                    Łazienka
                </Col>
                <Col>
                    <Space>
                        <Form.Item name={['attrs', 'bathroomNumber']} label="Ilość" className="left-form-item"
                            rules={fieldRules}>
                            <DecimalNumber/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'bathroomDimensions']} label="Wymiary" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'bathroomHeating']} label="Ogrzewanie" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={4} className="centered-content">
                </Col>
                <Col>
                    <Space>
                        <Form.Item name={['attrs', 'bath']} label="Wanna" className="left-form-item" rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'shower']} label="Prysznic" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={4} className="centered-content">
                    Sypialnia
                </Col>
                <Col>
                    <Space>
                        <Form.Item name={['attrs', 'bedroomNumber']} label="Ilość" className="left-form-item"
                            rules={fieldRules}>
                            <DecimalNumber/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'bedroomDimensions']} label="Wymiary" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'bedroomHeating']} label="Ogrzewanie" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={4} className="centered-content">
                    Salon
                </Col>
                <Col>
                    <Space>
                        <Form.Item name={['attrs', 'livingRoomNumber']} label="Ilość" className="left-form-item"
                            rules={fieldRules}>
                            <DecimalNumber/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'livingRoomDimensions']} label="Wymiary" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'livingRoomHeating']} label="Ogrzewanie" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
            <Row>
                <Col span={4} className="centered-content">
                    <Form.Item name={['attrs', 'anotherRoom']} label="Inne" className="left-form-item"
                        style={{ paddingRight: 8 }} rules={fieldRules}>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col>
                    <Space>
                        <Form.Item name={['attrs', 'anotherRoomNumber']} label="Ilość" className="left-form-item"
                            rules={fieldRules}>
                            <DecimalNumber/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'anotherRoomDimensions']} label="Wymiary" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={['attrs', 'anotherRoomHeating']} label="Ogrzewanie" className="left-form-item"
                            rules={fieldRules}>
                            <Input/>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
            <Divider/>
            <Form.Item name={['attrs', 'waterHeatingPreferredTemp']} label="Preferowana temperatura CWU (C)"
                rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'heatingPreferredTemp']} label="Preferowana temperatura CO (C)"
                rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Divider/>
            <Row>
                <Col span={8}>
                    <Form.Item name={['attrs', 'buildingKind']} label="Typ budynku" labelCol={{}} rules={fieldRules}>
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value="singleFamily">Jednorodzinny</Radio>
                                <Radio value="multiFamily">Wielorodzinny</Radio>
                                <Radio value="terraceHouse">Szeregowiec</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={['attrs', 'roofType.hp']} label="Rodzaj dachu" labelCol={{}} rules={fieldRules}>
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value="flatRoof">Dach płaski</Radio>
                                <Radio value="monoPitchedRoof">Dach jednospadowy</Radio>
                                <Radio value="gableRoof">Dach dwuspadowy</Radio>
                                <Radio value="complicatedRoof">Dach skomplikowany</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={4}>
                    <Form.Item name={['attrs', 'basement']} label="Piwnica" labelCol={{}} valuePropName="checked"
                        rules={fieldRules} initialValue={false}>
                        <Switch/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name={['attrs', 'basementWarming']} label="Rodzaj ocieplenia" labelCol={{}}
                        rules={[{ required: operation === 'accept' && basement }]}>
                        <Input disabled={!basement}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Form.Item name={['attrs', 'groundFloor']} label="Parter" labelCol={{}} valuePropName="checked"
                        rules={fieldRules} initialValue={false}>
                        <Switch/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name={['attrs', 'groundFloorWarming']} label="Rodzaj ocieplenia" labelCol={{}}
                        rules={[{ required: operation === 'accept' && groundFloor }]}>
                        <Input disabled={!groundFloor}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Form.Item name={['attrs', 'firstFloor']} label="Piętro 1" labelCol={{}} valuePropName="checked"
                        rules={fieldRules} initialValue={false}>
                        <Switch/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name={['attrs', 'firstFloorWarming']} label="Rodzaj ocieplenia" labelCol={{}}
                        rules={[{ required: operation === 'accept' && firstFloor }]}>
                        <Input disabled={!firstFloor}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Form.Item name={['attrs', 'secondFloor']} label="Piętro 2" labelCol={{}} valuePropName="checked"
                        rules={fieldRules} initialValue={false}>
                        <Switch/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name={['attrs', 'secondFloorWarming']} label="Rodzaj ocieplenia" labelCol={{}}
                        rules={[{ required: operation === 'accept' && secondFloor }]}>
                        <Input disabled={!secondFloor}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Form.Item name={['attrs', 'attic']} label="Poddasze" labelCol={{}} valuePropName="checked"
                        rules={fieldRules} initialValue={false}>
                        <Switch/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name={['attrs', 'atticWarming']} label="Rodzaj ocieplenia" labelCol={{}}
                        rules={[{ required: operation === 'accept' && attic }]}>
                        <Input disabled={!attic}/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name={['attrs', 'buildingLocation']} label="Położenie budynku" labelCol={{}} rules={fieldRules}>
                <Input.TextArea/>
            </Form.Item>
        </>
    }, {

        key: 'attachements',
        label: 'Niezbędne załączniki',
        children: <>
            <Form.Item name={['attrs', 'attachments']} label="Zdjęcia" labelCol={{}} rules={fieldRules}>
                <Checkbox.Group>
                    <Space direction="vertical">
                        <Checkbox value="boilerRoom">Kotłownia</Checkbox>
                        <Checkbox value="boilerRoomExternalWall">Ściana zewnętrzna kotłowni</Checkbox>
                        <Checkbox value="switchgearAndCounter">Rozdzielnia oraz Licznik</Checkbox>
                        <Checkbox value="heaters">Grzejniki (jeśli są)</Checkbox>
                        <Checkbox value="buildingFromPerspective">Budynek z perspektywy</Checkbox>
                        <Checkbox value="indoorUnitInstallationLocation">Miejsce instalacji jednostki
                            wewnętrznej</Checkbox>
                        <Checkbox value="outdoorUnitInstallationLocation">Miejsce instalacji jednostki
                            zewnętrznej</Checkbox>
                        <Checkbox value="supplyPipes">Rury zasilające</Checkbox>
                    </Space>
                </Checkbox.Group>
            </Form.Item>
            {!previewMode ? <>
                <Alert
                    message="Dołącz wymagane zdjęcia"
                    type="warning"/>
                <Gap/>
            </> : null}
            <FileUploader group="assembly_parameters" fileList={fileList} setFileList={setFileList}
                canEdit={!previewMode}/>
        </>
    }, {

        key: 'remarks',
        label: 'Uwagi',
        children: <Form.Item name={['attrs', 'remarks']} rules={fieldRules}>
            <Input.TextArea/>
        </Form.Item>
    }]
}
