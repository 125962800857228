import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import React from "react";
import { Element as AstElement } from "react-markdown/lib/rehype-filter";

function replaceInputEl(children: any[], node: AstElement, disabled: boolean, action: (startPos: number, endPos: number, checked: boolean) => void) {
    for (let i = 0; i < children.length; i++) {
        if (children[i].type === 'input') {
            const checked = children[i].props.checked
            const sp = (node.position?.start?.offset ?? 0);
            const ep = (node.position?.end?.offset ?? 0);
            const key = `task-checkbox-${sp}`;
            children[i] = <input type="checkbox" disabled={disabled} key={key} defaultChecked={checked} onClick={() => {
                action(sp, ep, checked);
            }}/>
        } else if (children[i].props?.children) {
            replaceInputEl(children[i].props.children, node, disabled, action);
        }
    }
}

interface MarkdownTextProps {
    text: string;
    updateText?: (text: string) => void;
    canEdit?: boolean
}

export const MarkdownText = ({ text, updateText, canEdit = true }: MarkdownTextProps) => {
    return <ReactMarkdown
        className={'markdown-body'}
        components={{
            li: ({ className, children, node }) => {
                if (className === "task-list-item") {
                    replaceInputEl(children, node, !canEdit, (sp, ep, checked) => {
                        if (canEdit) {
                            const checkbox = checked ? '[x]' : '[ ]';
                            const idx = text.indexOf(checkbox, sp);
                            if (idx >= sp && idx <= ep) {
                                let cbText = text.substring(0, idx + 1) + (checked ? ' ' : 'x') + text.substring(idx + 2);
                                updateText?.(cbText);
                            }
                        }
                    });
                }

                return React.createElement(node.tagName, node.properties, children);
            }
        }}
        children={text}
        remarkPlugins={[remarkGfm]}
    />
}
