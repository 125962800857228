import { useNavigate } from "react-router-dom";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { notification } from "antd";
import { baseUrl } from "../../utils/navigation";
import { Model } from "../../models/db";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Query } from "./visualisation-query";

export const useSaveVisualisation = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation(async (visualisation: Model.Visualisation.Data) => {
        const visualisationId = await (visualisation.id
            ? backendPut<number>(`/visualisations/${visualisation.id}`, visualisation)
            : backendPost<number>('/visualisations', visualisation)
        );
        notification.success({
            message: 'Wizualizacja została zapisana'
        });
        if (visualisationId) {
            navigate(baseUrl(`/visualisations/${visualisationId}/data`));
        }
        const id = visualisation.id ?? visualisationId;
        void queryClient.invalidateQueries([Query.Visualisation.key(id)]);
        void queryClient.invalidateQueries([Query.Visualisation.Documents.key(id)]);
        void queryClient.invalidateQueries([Query.Visualisation.Statuses.key(id)]);
    });
}

export const useMoveVisualisationToRealisation = () => {
    const queryClient = useQueryClient();
    return useMutation(async (visualisationId: number) => {
        await backendPost(`/visualisations/${visualisationId}/realise`);
        void queryClient.invalidateQueries([Query.Visualisation.key(visualisationId)]);
        void queryClient.invalidateQueries([Query.Visualisation.Statuses.key(visualisationId)]);
        notification.success({
            key: `visualisation-${visualisationId}`,
            message: 'Wizualizacja została przekazana do realizacji',
            duration: 0
        });
    });
}

export const useMarkVisualisationAsRealised = () => {
    const queryClient = useQueryClient();
    return useMutation(async (visualisationId: number) => {
        await backendPost(`/visualisations/${visualisationId}/mark-as-realised`);
        void queryClient.invalidateQueries([Query.Visualisation.key(visualisationId)]);
        void queryClient.invalidateQueries([Query.Visualisation.Statuses.key(visualisationId)]);
        notification.success({
            key: `visualisation-${visualisationId}`,
            message: 'Wizualizacja została przekazana do APH',
            duration: 0
        });
    });
}

export const useRemoveVisualisation = () => {
    const queryClient = useQueryClient();

    const { mutateAsync: removeVisualisation, isLoading: visualisationRemoving } = useMutation(async (visualisationId: number) => {
        await backendDelete(`/visualisations/${visualisationId}`);
        void queryClient.invalidateQueries([Query.Visualisations.key()]);
        notification.success({
            message: 'Wizualizacja została usunięta'
        });
    });

    return {
        removeVisualisation,
        visualisationRemoving
    }
}
