import { Nil } from "./utils";

export const validateMessages = {
    required: "To pole jest wymagane!",
};

export function isPositiveNumber(n: string): boolean {
    return /^\d+$/.test(n)
}

function chr2int(ch: string): number {
    return parseInt(ch);
}

export function validateNip(nip: Nil<string>): string | null {
    let error: string | null = null;
    if (nip && nip.length > 0) {
        if (nip.length == 10) {
            if (isPositiveNumber(nip)) {
                const controlNumber = (
                    chr2int(nip[0]) * 6 +
                    chr2int(nip[1]) * 5 +
                    chr2int(nip[2]) * 7 +
                    chr2int(nip[3]) * 2 +
                    chr2int(nip[4]) * 3 +
                    chr2int(nip[5]) * 4 +
                    chr2int(nip[6]) * 5 +
                    chr2int(nip[7]) * 6 +
                    chr2int(nip[8]) * 7) % 11;

                if (controlNumber != chr2int(nip[9])) {
                    error = "Niepoprawna cyfra kontrolna";
                }
            } else {
                error = "Niepoprawne znaki";
            }
        } else {
            error = "Niepoprawna długość";
        }
    }

    return error;
}

export function validatePesel(pesel: Nil<string>): string | null {
    let error: string | null = null;

    if (pesel && pesel.length > 0) {
        if (pesel.length == 11) {
            if (isPositiveNumber(pesel)) {
                //1 3 7 9 1 3 7 9 1 3
                const controlNumber = (
                    chr2int(pesel[0]) * 9 +
                    chr2int(pesel[1]) * 7 +
                    chr2int(pesel[2]) * 3 +
                    chr2int(pesel[3]) * 1 +
                    chr2int(pesel[4]) * 9 +
                    chr2int(pesel[5]) * 7 +
                    chr2int(pesel[6]) * 3 +
                    chr2int(pesel[7]) * 1 +
                    chr2int(pesel[8]) * 9 +
                    chr2int(pesel[9]) * 7) % 10;

                if (controlNumber != chr2int(pesel[10])) {
                    error = "Niepoprawna cyfra kontrolna";
                }
            } else {
                error = "Niepoprawne znaki";
            }
        } else {
            error = "Niepoprawna długość";
        }
    }

    return error;
}

export function validateRegon(regon: Nil<string>): string | null {
    let error: string | null = null;

    if (regon && regon.length > 0) {
        if (regon.length == 9) {
            if (isPositiveNumber(regon)) {
                let controlNumber = (
                    chr2int(regon[0]) * 8 +
                    chr2int(regon[1]) * 9 +
                    chr2int(regon[2]) * 2 +
                    chr2int(regon[3]) * 3 +
                    chr2int(regon[4]) * 4 +
                    chr2int(regon[5]) * 5 +
                    chr2int(regon[6]) * 6 +
                    chr2int(regon[7]) * 7) % 11;

                if (controlNumber == 10) {
                    controlNumber = 0;
                }

                if (controlNumber != chr2int(regon[8])) {
                    error = "Niepoprawna cyfra kontrolna";
                }
            } else {
                error = "Niepoprawne znaki";
            }
        } else {
            error = "Niepoprawna długość";
        }
    }

    return error;
}

export function validateEmail(email: Nil<string>): string | null {
    let error: string | null = null;

    if (email && email.length > 0 && !email.match(/^\S+@\S+\.\S+$/)) {
        error = "Niepoprawny email"
    }

    return error;
}

export function validateNrb(nrb: string): string | null {
    let error: string | null = null;
    if (isPositiveNumber(nrb)) {
        if (nrb.length == 26) {
            const weights = [1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57];
            const nrb0 = `${nrb}2521`;
            const nrb1 = nrb0.substring(2) + nrb0.substring(0, 2);
            let controlNumber = 0;
            for (let i = 0; i < 30; i++) {
                controlNumber += chr2int(nrb1[29 - i]) * weights[i];
            }
            if (controlNumber % 97 != 1) {
                error = "Niepoprawna cyfra kontrolna";
            }
        } else {
            error = "Niepoprawna długość";
        }
    } else {
        error = "Niepoprawne znaki";
    }
    return error;
}
