import React, { useState } from "react";
import { Button, Checkbox, DatePicker, Input, notification, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { usePaginatedSqlQuery } from "../../hooks/sql-query.hook";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/user.hook";
import { baseUrl } from "../../utils/navigation";
import { backendDelete, backendPost } from "../../api/backend-api";
import { getListCriteria, withFilterValueCriteria } from "../../utils/sql";
import { useDebounce } from "../../hooks/debaunce.hook";
import { appStore } from "../../store/store";
import { formatDate } from "../../utils/utils";
import { RemoveButton } from "../../components/remove-button";
import { TableView } from "../../components/table-view";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CalculatorType } from "../../components/calculator-type";
import { Dayjs } from "dayjs";
import { t } from "../../models/db";
import { from } from "../../utils/sql-builder";

export const Calculators = () => {
    const queryClient = useQueryClient();
    const { user, admin } = useUser();
    const filter = appStore.useState(s => s.calculatorList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const [actionCalculatorId, setActionCalculatorId] = useState(-1);

    const { mutateAsync: removeCalculator, isLoading: calculatorRemoving } = useMutation(async (calculatorId: number) => {
        setActionCalculatorId(calculatorId);
        try {
            await backendDelete(`/calculators/${calculatorId}`);
            void queryClient.invalidateQueries(['calculators']);
            notification.success({
                message: 'Kalkulator został usunięty'
            });
        } finally {
            setActionCalculatorId(-1);
        }
    });

    const { mutateAsync: makeCopy, isLoading: makingCopy } = useMutation(async (calculatorId: number) => {
        await backendPost(`/calculators/${calculatorId}/copy`, { parentless: true });
        return await queryClient.invalidateQueries([`calculators`]);
    });

    const criteria = {...filter, search: debouncedSearch};
    let where = getListCriteria(
        [t.c.id, t.c.name],
        t.c.createdAt, t.c.deletedAt,
        criteria
    );

    if (admin) {
        where += withFilterValueCriteria(t.c.acceptedAt.isNotNull().cast('int'), filter.accepted)
            + withFilterValueCriteria(t.c.active.cast('int'), filter.active)
        ;
    } else {
        where += " and active = true and accepted_at is not null";
    }

    const { total, data, loading } = usePaginatedSqlQuery(
        'calculators', criteria,
        from(t.calculator.as(t.c))
        .where(where).and(t.c.parentId.isNull())
        .orderByAlias(criteria.sortColumn, criteria.sortDirection)
        .select({
            key: t.c.id,
            id: t.c.id,
            userId: t.c.userId,
            name: t.c.name,
            type: t.c.type,
            active: t.c.active,
            createdAt: t.c.createdAt,
            accepted: t.c.acceptedAt.isNotNull().asBool()
        })
    );

    const columns: ColumnsType<typeof data[number]> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
    }, {
        title: 'Rodzaj',
        dataIndex: 'type',
        width: '15%',
        render: value => <CalculatorType assemblyType={value}/>
    }, {
        title: 'Aktywny',
        dataIndex: 'active',
        width: 130,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: admin ? [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ] : undefined,
        filteredValue: filter.active
    }, {
        title: 'Zaakceptowany',
        dataIndex: 'accepted',
        width: 170,
        align: 'center',
        render: value => <Checkbox checked={value} style={{ pointerEvents: 'none' }}/>,
        filters: admin ? [
            { text: 'Tak', value: 1 },
            { text: 'Nie', value: 0 },
        ] : undefined,
        filteredValue: filter.accepted
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: value => formatDate(value)
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 210,
        render: (_, item) => {
            if (item.userId === user.id || admin) {
                return <Space>
                    <Link to={baseUrl(`/calculators/${item.id}/data`)}>Edytuj</Link>
                    <Button type="link" onClick={() => makeCopy(item.id)}>Kopiuj</Button>
                    <RemoveButton link disabled={item.id === actionCalculatorId && calculatorRemoving || (item.accepted && !admin)} onConfirm={() => removeCalculator(item.id)}/>
                </Space>
            } else {
                return <Link to={baseUrl(`/calculators/${item.id}/test`)}>Edytuj</Link>
            }
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading || makingCopy}
        scrollWidth={940}
        listKey={'calculatorList'}
        onFiltersChange={filters => {
            appStore.update(s => {
                s.calculatorList.accepted = filters.accepted;
                s.calculatorList.active = filters.active;
            });
        }}
        toolbar={
            <>
                {admin ? <Link to={baseUrl("/calculators/create")}><Button>Dodaj kalkulator</Button></Link> : null}
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.calculatorList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.calculatorList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['calculators']);
                        }}
                    />
                    <Input style={{width: 200}} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.calculatorList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
