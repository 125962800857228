import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Space, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import { backendDelete, backendPost, backendPut, backendSave } from "../../api/backend-api";
import { sql, useSqlQueryRaw } from "../../hooks/sql-query.hook";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { PopoverForm } from "../../components/popover-form";
import { NumericInput } from "../../components/numeric-input";
import { Gap } from "../../components/gap";
import { useCalculatorStatus } from "../../hooks/calculator-status.hook";
import groupBy from "lodash/groupBy";
import values from "lodash/values";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import zipObject from "lodash/zipObject";
import fromPairs from "lodash/fromPairs";
import { RemoveButton } from "../../components/remove-button";
import { ButtonType } from "antd/lib/button";
import { isFormValid } from "../../utils/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";

enum DictColumnType {
    Number = 0,
    Text = 1,
    Quantity = 2,
    Price = 3,
    Action = 999
}

function getDictColumnTypeDescription(columnType: DictColumnType) {
    switch (columnType) {
        case DictColumnType.Action:
            return "Akcje";
        case DictColumnType.Number:
            return "Liczba";
        case DictColumnType.Price:
            return "Cena";
        case DictColumnType.Quantity:
            return "Ilość";
        default:
            return "Tekst"
    }
}

interface DictItem {
    id: number;
    dictName: string;
    defaultRow: number;
}

interface ColumnDef {
    id: number;
    columnId: string;
    columnType: DictColumnType;
    columnName: string;
}

interface ColumnValueItem extends ColumnDef {
    id: number;
    row: number;
    value: number | string | undefined;
}

type ColumnItem = {
    [columnId: string]: ColumnValueItem;
}

type ColumnValueRow = {
    id: number;
    key: string;
    row: number;
    editing: boolean;
    newRow: boolean;
    columnIds: string[];
} & ColumnItem;

interface ColumnTypeItem {
    id: number;
    name: string;
    type: DictColumnType;
}

interface ValueCellProps extends React.HTMLAttributes<HTMLElement> {
    id: string;
    dataIndex: string;
    record: ColumnItem;
    editing: boolean;
    children: React.ReactNode;
}

function getInputSuffix(columnType: DictColumnType) {
    switch (columnType) {
        case DictColumnType.Price:
            return "PLN";
        case DictColumnType.Quantity:
            return "QTY";
        default:
            return "";
    }
}

const ValueCell: React.FC<ValueCellProps> = (props) => {
    const record = props.dataIndex ? props.record[props.dataIndex] : null;
    if (record && props.editing) {
        const inputSuffix = getInputSuffix(record.columnType);
        const inputNode = record.columnType === DictColumnType.Text
            ? <Input style={{ width: '100%' }} autoFocus/>
            : <NumericInput style={{ width: '100%' }} suffix={inputSuffix}/>;
        const inputType = record.columnType === DictColumnType.Text ? "tekst" : "liczbę";

        return (
            <td style={props.style} className={props.className}>
                <Form.Item
                    name={record.columnId}
                    style={{ margin: 0 }}
                    rules={[{
                        required: true,
                        message: `Wprowadź ${inputType}!`,
                    }]}
                >
                    {inputNode}
                </Form.Item>
            </td>
        );
    }
    return <td style={props.style} className={props.className}>{record ? record.value : props.children}</td>
};

const EditColumn = (props: {
    calculatorDictId: number | null,
    columnId?: number,
    buttonEnabled: boolean,
    showPopover: boolean,
    columnTypes?: ColumnTypeItem[],
    buttonType?: ButtonType,
    title: string,
    onVisibleChange?: (visible: boolean) => void,
    values?: {
        name: string,
        type: number
    }
}) => {
    const queryClient = useQueryClient();
    return <PopoverForm buttonEnabled={props.buttonEnabled}
        showPopover={props.showPopover}
        title={props.title} buttonType={props.buttonType} values={props.values}
        onVisibleChange={props.onVisibleChange}
        onSubmit={async values => {
            await (
                props.columnId
                    ? backendPut(`/calculators/${props.columnId}/column`, values)
                    : backendPost(`/calculators/${props.calculatorDictId}/column`, values)
            );
            void queryClient.invalidateQueries([`columns-${props.calculatorDictId}`]);
            void queryClient.invalidateQueries([`column-data-${props.calculatorDictId}`])
        }}>
        <Form.Item name="name" rules={[{ required: true, message: "Podaj nazwę" }]}>
            <Input autoFocus/>
        </Form.Item>
        <Form.Item name="type" rules={[{ required: true, message: "Podaj rodzaj" }]}>
            <Select>
                {props.columnTypes?.map(ct => <Select.Option key={ct.id} value={ct.id}>
                    {ct.name}
                </Select.Option>)}
            </Select>
        </Form.Item>
    </PopoverForm>
}

export const CalculatorDicts = (props: { calculatorId?: number }) => {
    const [form] = useForm();
    const [calculatorDictId, setCalculatorDictId] = useState<number | null>(null);
    const [calculatorDictDefaultRow, setCalculatorDictDefaultRow] = useState<number | null>(null);
    const [valueTableColumns, setValueTableColumns] = useState<ColumnType<ColumnItem>[]>([]);
    const [saving, setSaving] = useState(false);
    const [columnEditingId, setColumnEditingId] = useState(-1);
    const [valueEditing, setValueEditing] = useState(false);

    const queryClient = useQueryClient();

    const columnTableColumns: ColumnsType<ColumnDef> = [{
        title: 'Nazwa',
        dataIndex: 'columnName',
        key: 'column_name',
    }, {
        title: 'Rodzaj',
        dataIndex: 'columnType',
        key: 'column_type',
        render: value => {
            return getDictColumnTypeDescription(value);
        }
    }, {
        title: 'Id',
        dataIndex: 'columnId',
        key: 'column_id'
    }, {
        key: 'action',
        fixed: 'right',
        width: 170,
        render: (row: ColumnDef) => {
            const actionsEnabled = !columnsLoading && !columnTypesLoading && !!calculatorDictId && (columnEditingId === -1 || columnEditingId === row.id);
            return <Space key={row.id}>
                <EditColumn
                    title="Edytuj"
                    buttonType="link"
                    buttonEnabled={actionsEnabled}
                    showPopover={actionsEnabled}
                    columnTypes={columnTypes}
                    calculatorDictId={calculatorDictId}
                    columnId={row.id}
                    values={{
                        name: row.columnName,
                        type: row.columnType
                    }}
                    onVisibleChange={visible => setColumnEditingId(visible ? row.id : -1)}
                />
                <Button type="link" onClick={async () => {
                        await backendDelete(`/calculators/${row.id}/column`);
                        void queryClient.invalidateQueries([`columns-${calculatorDictId}`]);
                        void queryClient.invalidateQueries([`column-data-${calculatorDictId}`]);
                    }}
                    disabled={!actionsEnabled}>Usuń</Button>
            </Space>
        }
    }];

    const { calculatorAccepted, calculatorStatusLoading } = useCalculatorStatus(props.calculatorId);

    const { data: dictionaries, loading: dictionariesLoading } = useSqlQueryRaw<DictItem>(
        `dictionaries-${props.calculatorId}`,
        sql`select id as key, id, dict_name, default_row, owner_id
            from calculator_dict
            where calculator_id = ${props.calculatorId}
            and deleted_at is null
            order by id`
    );

    const { data: columns, loading: columnsLoading } = useSqlQueryRaw<ColumnDef>(
        `columns-${calculatorDictId}`,
        sql`select id as key, id, column_name, column_type, column_id, owner_id
            from calculator_dict_column
            where calculator_dict_id = ${calculatorDictId}
            and deleted_at is null
            order by id`,
        { enabled: calculatorDictId }
    );

    const { data: columnTypes, loading: columnTypesLoading } = useSqlQueryRaw<ColumnTypeItem>(
        `column-types-${props.calculatorId}`,
        sql`select id as key, id, name, type, owner_id
            from calculator_column_type order by id`
    );

    useEffect(() => {
        setValueTableColumns((columns || []).map(c => ({
            title: c.columnName,
            dataIndex: c.columnId,
            key: c.columnId
        })));
    }, [columns]);

    const [columnValueRows, setColumnValueRows] = useState<ColumnValueRow[]>([]);

    const { data: columnData, loading: columnDataLoading } = useSqlQueryRaw<ColumnValueItem, ColumnValueRow>(
        `column-data-${calculatorDictId}`,
        sql`
            select cdv.id, cdc.column_id, cdv.row, cdv.value, cdc.column_name, cdc.column_type, cdc.owner_id
            from calculator_dict_column cdc
            inner join calculator_dict_value cdv
            on cdc.id = cdv.calculator_dict_column_id
            where cdc.calculator_dict_id = ${calculatorDictId}
            and cdc.deleted_at is null
            order by cdv.row
        `, {
            enabled: calculatorDictId,
            map: rows => values(groupBy(rows, r => r.row))
                .map(items => {
                    return {
                        ...mapValues(
                            keyBy(items, it => it.columnId),
                            it => it
                        ),
                        row: items[0].row,
                        id: items[0].row,
                        key: `${items[0].row}`,
                        editing: false,
                        columnIds: items.map(it => it.columnId)
                    } as ColumnValueRow
                })
        }
    );

    useEffect(() => {
        setColumnValueRows(columnData || []);
    }, [columnData]);

    const addValues = () => {
        setValueEditing(true);
        const rowId = -1;
        const valueRow = merge(
            { key: `${rowId}`, id: rowId, editing: true, newRow: true, columnIds: columns?.map(c => c.columnId) },
            fromPairs(columns?.map(c => [c.columnId, c]))
        ) as ColumnValueRow;
        setRowValues(valueRow);
        setColumnValueRows(columnValueRows.concat([
            valueRow
        ]));
    };

    const compactValues = () => {
        backendPut(`/calculators/${calculatorDictId}/dict/compact`)
            .then(() => queryClient.invalidateQueries([`column-data-${calculatorDictId}`]))
    };

    const setRowValues = (rowValues: ColumnValueRow) => {
        form.setFieldsValue(
            zipObject(
                rowValues.columnIds,
                rowValues.columnIds.map(id => rowValues[id].value)
            )
        );
    };

    const acceptRow = (rowId: number, rowValues?: ColumnValueRow) => {
        setValueEditing(false);
        setColumnValueRows(
            columnValueRows.map(c => ({
                ...c,
                editing: c.id === rowId ? false : c.editing,
                ...(c.id === rowId ? mapValues(rowValues, v => ({
                    ...c,
                    value: v
                })) : {}),
            } as ColumnValueRow))
        );
    };

    const editRow = (rowId: number, rowValues: ColumnValueRow | false) => {
        setValueEditing(!!rowValues);
        if (rowValues) {
            setRowValues(rowValues);
        }
        if (!rowValues && columnValueRows.find(c => c.id === rowId && c.newRow)) {
            setColumnValueRows(columnValueRows.slice(0, -1));
        } else if (rowValues !== undefined) {
            setColumnValueRows(
                columnValueRows.map(c => ({
                    ...c,
                    editing: rowId === c.id ? !!rowValues : c.editing
                } as ColumnValueRow))
            );
        } else {
            acceptRow(rowId);
        }
    };

    const setDefaultRow = (row: number) => {
        setSaving(true);
        backendPut(`/calculators/${calculatorDictId}/dict/${row}/default`)
            .then(() => queryClient.invalidateQueries([`dictionaries-${props.calculatorId}`]))
            .then(() => setCalculatorDictDefaultRow(row))
            .finally(() => setSaving(false));
    };

    const clearDefaultRow = () => {
        setSaving(true);
        backendDelete(`/calculators/${calculatorDictId}/dict/default`)
            .then(() => queryClient.invalidateQueries([`dictionaries-${props.calculatorId}`]))
            .then(() => setCalculatorDictDefaultRow(null))
            .finally(() => setSaving(false));
    };

    const removeRow = (rowId: number) => {
        setSaving(true);
        backendDelete(`/calculators/${calculatorDictId}/dict/${rowId}/values`)
            .then(() => queryClient.invalidateQueries([`column-data-${calculatorDictId}`]))
            .finally(() => setSaving(false));
    };

    const saveRow = (rowId: number, values: any) => {
        if (isFormValid(form)) {
            setSaving(true);
            const rowValues = map(values, (value, key) => ({
                columnId: columns?.find(c => c.columnId === key)?.id ?? -1,
                value
            }));
            acceptRow(rowId, values);
            backendSave(rowId, `calculators/${calculatorDictId}/dict/values`, {
                row: rowId,
                values: rowValues
            })
                .then(() => queryClient.invalidateQueries([`column-data-${calculatorDictId}`]))
                .catch(() => setColumnValueRows(columnValueRows.filter(c => !c.newRow)))
                .finally(() => setSaving(false));
        }
    }

    const { mutateAsync: removeDict } = useMutation((calculatorDictId: number) => {
        return backendDelete(`calculators/${calculatorDictId}/dict`)
            .then(() => {
                setCalculatorDictId(null);
                void queryClient.invalidateQueries([`dictionaries-${props.calculatorId}`]);
            })
    });

    const columnsCount = columns?.length ?? 0;
    const canAddColumn = !columnsLoading && !!calculatorDictId && !calculatorAccepted && !valueEditing;

    return <>
        <Space>
            <PopoverForm title="Dodaj słownik"
                buttonEnabled={!calculatorAccepted && !valueEditing}
                showPopover={!calculatorAccepted && !valueEditing}
                onSubmit={async values => {
                    const calculatorDictId = await backendPost(`/calculators/${props.calculatorId}/dict`, values);
                    await queryClient.invalidateQueries([`dictionaries-${props.calculatorId}`]);
                    setCalculatorDictId(calculatorDictId);
                }}>
                <Form.Item name="name" rules={[{ required: true, message: "Podaj nazwę" }]}>
                    <Input autoFocus/>
                </Form.Item>
            </PopoverForm>

            <Select loading={dictionariesLoading} style={{ width: 300 }}
                disabled={dictionariesLoading || columnsLoading || columnDataLoading || valueEditing}
                value={calculatorDictId ?? ''}
                onChange={dictId => {
                    const id = dictId as number;
                    setCalculatorDictId(id);
                    const defaultRow = dictionaries?.find(d => d.id === id)?.defaultRow ?? null
                    setCalculatorDictDefaultRow(defaultRow);
                }}>
                {dictionaries?.map(d => <Select.Option value={d.id} key={d.id}>
                    {d.dictName}
                </Select.Option>)}
            </Select>
            <RemoveButton disabled={!calculatorDictId || valueEditing} onConfirm={() => {
                return calculatorDictId ? removeDict(calculatorDictId) : Promise.resolve()
            }}/>

        </Space>
        <Gap/>
        <Table bordered columns={columnTableColumns}
            loading={columnsLoading}
            dataSource={columns}
            pagination={false}
            title={() => <EditColumn
                title="Dodaj kolumnę"
                buttonEnabled={canAddColumn}
                showPopover={canAddColumn}
                columnTypes={columnTypes}
                calculatorDictId={calculatorDictId}
                onVisibleChange={visible => setColumnEditingId(visible ? -2 : -1)}
            />}
            scroll={{ x: 400 }}
            style={{ marginBottom: 20 }}
        />
        <Form form={form} component={false}>
            <Table bordered
                columns={[{
                    title: 'Wiersz',
                    width: 80,
                    key: 'row',
                    dataIndex: 'row',
                    render: (value, record) => {
                        let style = {} as React.CSSProperties;
                        if (record.row === calculatorDictDefaultRow) {
                            style = {
                                display: 'inline-block',
                                marginLeft: 5,
                                backgroundColor: 'red',
                                borderRadius: '50%',
                                width: 10,
                                height: 10
                            }
                        }
                        return <div>{value}<div style={style}/></div>
                    }
                } as ColumnType<ColumnValueRow>].concat(
                    valueTableColumns.map(column => {
                    return {
                        ...column,
                        onCell: record => {
                            return {
                                record,
                                dataIndex: column.dataIndex,
                                editing: record.editing
                            }
                        },
                    } as ColumnType<ColumnValueRow>
                }))
                .concat([{
                    key: 'action',
                    fixed: 'right',
                    width: 170,
                    render: (row: ColumnValueRow) => {
                        return <span key={row.id}>
                            {!row.editing && <div>
                                <Space>
                                    <Button type="link" onClick={() => editRow(row.id, row)}
                                        disabled={valueEditing || calculatorAccepted}>Edytuj</Button>
                                    <Button type="link" onClick={() => removeRow(row.id)}
                                        disabled={valueEditing || calculatorAccepted}>Usuń</Button>
                                </Space>
                                {row.row !== calculatorDictDefaultRow && <Button type="link" onClick={() => setDefaultRow(row.row)}
                                    disabled={valueEditing || calculatorAccepted}>Ustaw domyślny</Button>}
                                {row.row === calculatorDictDefaultRow && <Button type="link" onClick={() => clearDefaultRow()}
                                    disabled={valueEditing || calculatorAccepted}>Usuń domyślny</Button>}
                            </div>}
                            {row.editing && <Space>
                                <Button type="link" onClick={() => saveRow(row.id, form.getFieldsValue())}>Zapisz</Button>
                                <Button type="link" onClick={() => editRow(row.id, false)}>Anuluj</Button>
                            </Space>}
                        </span>
                    }
                }])}
                loading={columnsLoading || columnDataLoading || calculatorStatusLoading || saving}
                dataSource={columnValueRows}
                pagination={false}
                title={() => <Space>
                    <Button onClick={addValues}
                        disabled={columnsCount <= 0 || valueEditing || calculatorAccepted}>
                        Dodaj wiersz
                    </Button>
                    <Button onClick={compactValues}
                        disabled={columnsCount <= 0 || valueEditing || calculatorAccepted}>
                        Kompaktuj wiersze
                    </Button>
                </Space>}
                scroll={{ x: Math.max(400, columnsCount * 200) }}
                components={{
                    body: {
                        cell: ValueCell
                    }
                }}
            />
        </Form>
    </>
}
