// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.last-comment {
  overflow: hidden;
}
.last-comment:hover {
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./web/components/last-comment.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,cAAA;AAER","sourcesContent":[".last-comment {\n    overflow: hidden;\n    &:hover {\n        overflow: auto;\n    }\n}\n\n@layout-header-background: #1890ff;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
