import React, { useEffect, useState } from "react";
import { backendGetBlob } from "../api/backend-api";
import { getPreviewFileExtension, makeBlob, withExtension } from "../utils/utils";
import './protected-img.less';
import { Button, Space, Spin } from "antd";
import { DeleteOutlined, EyeOutlined } from "./icons";

interface ProtectedImgProps {
    filename: string | undefined;
    disabled?: boolean,
    actions?: {
        download: () => void;
        preview: () => void;
        remove: () => void;
    },
    style?: React.CSSProperties,
    imgData?: string
}

export const ProtectedImg = (props: ProtectedImgProps) => {
    const [imgData, setImgData] = useState<Blob | string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.filename) {
            setIsLoading(true);
            const path = withExtension(encodeURI(props.filename), getPreviewFileExtension(props.filename));
            backendGetBlob(`/files/${path}`).then(data => {
                setImgData(data.data);
            }).finally(() => setIsLoading(false));
        }
    }, [props.filename]);

    const preview = props.imgData && props.imgData.indexOf("image/jpeg") >= 0;
    const disabled = !!props.disabled;

    return <div className="protected-img" style={props.style}>
        {isLoading ? "Wczytywanie..." : (imgData || preview ? null : <div className="no-preview">Brak podglądu</div>)}
        <a target="_blank" rel="noopener norefferer">
            {preview ? <img src={makeBlob(props.imgData!)} alt={'img'} style={{ width: '100%' }}/> : null}
            {imgData ? <img src={makeBlob(imgData)} alt={'img'} style={{ width: '100%' }}/> : null}
        </a>
        <div className="actions">
            <Space>
                {imgData ? <Button shape="circle" title="Podgląd" icon={<EyeOutlined/>} onClick={() => {
                    props.actions?.preview?.();
                }}/> : null}
                {disabled ? null : <Button shape="circle" danger={true} title="Usuń" icon={<DeleteOutlined/>} onClick={() => props.actions?.remove?.()}/>}
            </Space>
        </div>
    </div>
}
