import React, { useRef } from "react";
import { useResizeObserver } from "../hooks/resize-observer.hook";

export const DynamicContent = (props: { children: (width: number, height: number) => JSX.Element }) => {
    const ref = useRef(null);
    const contentSize = useResizeObserver(ref, ref?.current);
    const w = contentSize?.width ?? 0;
    const h = contentSize?.height ?? 0;
    return <div style={{ width: '100%', height: '100%' }} className="aa-xx" ref={ref}>
        {w > 0 && h > 0 ? props.children(w, h) : null}
    </div>
}
