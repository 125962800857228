import React from "react";
import { DatePicker, Input, Select, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { useAssignedUsers, useUser } from "../../hooks/user.hook";
import { Gap } from "../../components/gap";
import { useDebounce } from "../../hooks/debaunce.hook";
import { appStore } from "../../store/store";
import { CreatedBy } from "../../components/created-by";
import { getStatusList, RealisationStatus } from "../../components/realisation-status";
import { filterByLabel } from "../../utils/utils";
import { UserRole } from "../../models/user";
import { TableView } from "../../components/table-view";
import { LastComment } from "../../components/last-comment";
import { AssignOwner, useAssignedOwners } from "../../components/assign-owner";
import { useQueryClient } from "@tanstack/react-query";
import { NvlLink } from "../../components/nvl-link";
import { Statistic } from "antd/es";
import { Dayjs } from "dayjs";
import { Query } from "./realisation-query";

function getCommissionCorrectionInfo(commissionCorrection: number, commissionCorrectionType: number) {
    if (commissionCorrection !== 0) {
        const sign = commissionCorrection > 0 ? '+' : '';
        if (commissionCorrectionType === 0) {
            return `${sign}${commissionCorrection} zł`;
        } else {
            return `${sign}${commissionCorrection} %`;
        }
    }
    return null;
}

export const Realisations = (props: {
    installation?: boolean,
    commissions?: boolean,
    verification?: boolean
} = { installation: false, commissions: false, verification: false }) => {
    const queryClient = useQueryClient();

    const user = useUser();
    const filter = appStore.useState(s => s.realisationList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const { users: backOfficeUsers, usersLoading: backOfficeUsersLoading } = useAssignedUsers(UserRole.BACK_OFFICE);
    const { users: coordinatorsUsers, usersLoading: coordinatorUsersLoading } = useAssignedUsers(UserRole.COORDINATOR);
    const realisationType = props.installation ? 'installation' : (props.commissions ? 'commissions' : 'verification');
    const listQueryName = `realisations-${realisationType}`
    const assignedOwners = useAssignedOwners('realisations', 'owner', listQueryName);
    const assignedCoordinators = useAssignedOwners('realisations', 'coordinator', listQueryName);

    const criteria = { ...filter, search: debouncedSearch };
    const { total, data, loading } = Query.Realisations.use(realisationType, props.installation, props.commissions, criteria);

    let columns: ColumnsType<Query.Realisations.T> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Umowa',
        dataIndex: 'agreement',
        width: '30%',
        render: (value, row) => <NvlLink value={row.agreement} link={baseUrl(`/agreements/${row.agreementId}/data`)}/>
    }, {
        title: 'Klient',
        dataIndex: 'client',
        width: '30%',
        render: (value, row) => <NvlLink value={row.client} link={baseUrl(`/clients/${row.clientId}/data`)}/>
    }]

    if (!props.commissions) {
        columns = [...columns, {
            title: 'Opiekun',
            dataIndex: 'owner',
            width: '30%',
            render: (value, row) => <AssignOwner
                rowId={row.id}
                ownerId={row.ownerId}
                owner={row.owner}
                value={value}
                assignUsers={backOfficeUsers}
                usersLoading={backOfficeUsersLoading}
                assignedOwners={assignedOwners}
                canSelfAssign={user.backOffice && !props.installation && !props.commissions}
            />
        }, {
            title: 'Koordynator',
            dataIndex: 'coordinator',
            width: '30%',
            render: (value, row) => <AssignOwner
                rowId={row.id}
                ownerId={row.coordinatorId}
                owner={row.coordinator}
                value={value}
                assignUsers={coordinatorsUsers}
                usersLoading={coordinatorUsersLoading}
                assignedOwners={assignedCoordinators}
                canSelfAssign={user.coordinator && !!props.installation}
            />
        }];
    } else {
        columns = [...columns, {
            title: 'Handlowiec',
            dataIndex: 'agreementCreatedBy',
            width: '30%',
            render: (value, row) => <NvlLink value={row.agreementCreatedBy}
                link={baseUrl(`/users/${row.agreementOwnerId}`)}/>
        }];
    }

    columns = [...columns, {
        title: 'Status',
        dataIndex: 'status',
        width: 110,
        render: (value, row) => <RealisationStatus createdAt={row.statusUpdatedAt} status={value}/>
    }]

    if (!props.commissions) {
        columns = [...columns, {
            title: 'Wizualizacja',
            dataIndex: 'visualisationId',
            width: 150,
            render: (value, row) => <NvlLink value={row.visualisation}
                link={baseUrl(`/visualisations/${row.visualisationId}/data`)}/>,
            filters: [
                { text: 'Tak', value: 1 },
                { text: 'Nie', value: 0 },
            ],
            filteredValue: filter.visualisation
        }, {
            title: 'Projekt',
            dataIndex: 'projectId',
            width: 150,
            render: (value, row) => <NvlLink value={row.project} link={baseUrl(`/projects/${row.projectId}/data`)}/>,
            filters: [
                { text: 'Tak', value: 1 },
                { text: 'Nie', value: 0 },
            ],
            filteredValue: filter.project
        }];
    }

    if (props.commissions) {
        columns = [...columns, {
            title: 'Cena',
            dataIndex: 'mainValue',
            width: 120,
            render: (value) => <Statistic value={value} precision={2} suffix="zł" valueStyle={{ fontSize: 14 }}/>
        }, {
            title: 'Prowizja',
            dataIndex: 'commissionValue',
            render: (value, row) => <Space direction="vertical" size={2}>
                <Statistic value={value} precision={2} suffix="zł" valueStyle={{ fontSize: 14 }}/>
                <Space size={8}>
                    <div style={{ fontSize: 11, color: '#cc7b26' }}>{row.commissionRate}</div>
                    <div style={{ fontSize: 11, color: row.commissionCorrection > 0 ? '#44cc26' : '#cc2626' }}>
                        {getCommissionCorrectionInfo(row.commissionCorrection, row.commissionCorrectionType)}
                    </div>
                </Space>
            </Space>,
            width: 120
        }];
    }

    columns = [...columns, {
        title: <div>Ostatni<br/>komentarz</div>,
        dataIndex: 'lastComment',
        width: 350,
        render: (value, row) => <LastComment comment={value} createdAt={row.lastCommentCreatedAt}/>
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: (value, row) => <CreatedBy createdAt={value} createdBy={row.createdBy}
            fullInfo={user.permissions.creatorName}/>,
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (_, item) => {
            return <Space>
                {user.permissions.realisationList.edit([item.agreementOwnerId, item.ownerId, item.coordinatorId]) ?
                    <Link to={baseUrl(`/realisations-${realisationType}/${item.id}/data`)}>Edytuj</Link> : null}
            </Space>
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading}
        scrollWidth={1800 + (props.commissions ? 40 : 0)}
        listKey={'realisationList'}
        onFiltersChange={filters => {
            appStore.update(s => {
                s.realisationList.visualisation = filters.visualisationId;
                s.realisationList.project = filters.projectId;
            });
        }}
        toolbar={
            <>
                <Gap/>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.realisationList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.realisationList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['realisations']);
                        }}
                    />
                    <Select style={{ width: 250 }} placeholder="Status" value={filter.status ?? undefined}
                        onChange={value => {
                            appStore.update(s => {
                                s.realisationList.status = value;
                            });
                        }} allowClear={true}>
                        {getStatusList().map(s => <Select.Option value={s.id} key={s.id}>{s.name}</Select.Option>)}
                    </Select>
                    {user.permissions.userFilter ?
                        <Select mode="multiple" placeholder="Opiekun" style={{ width: 250 }} value={filter.users}
                            options={backOfficeUsers} loading={backOfficeUsersLoading} onChange={value => {
                            appStore.update(s => {
                                s.realisationList.users = value;
                            });
                        }}
                            filterOption={filterByLabel}
                            allowClear={true}>
                        </Select> : null}
                    <Input style={{ width: 200 }} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.realisationList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
