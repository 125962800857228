import { Button, Select, Space, Tooltip } from "antd";
import { filterBySearch } from "../../utils/utils";
import React from "react";
import { AssemblyParametersItem } from "./assembly-parameters-item";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "../icons";
import { useUser } from "../../hooks/user.hook";
import { baseUrl } from "../../utils/navigation";

export const AssemblyParametersSelect = (
    { assemblies, canEdit, ...props }: {
        assemblies: { id: number, name: string, accepted: boolean }[] | undefined,
        canEdit: boolean
    }) => {
    const { permissions } = useUser();
    const navigate = useNavigate();
    const value = (props as any).value;
    return <Space className="select">
        <Select disabled={!canEdit} showSearch={true}
            allowClear={true} filterOption={filterBySearch} {...props}>
            {assemblies?.map(a => <Select.Option key={a.id} value={a.id} search={a.name + a.id}>
                <AssemblyParametersItem id={a.id} name={a.name} accepted={a.accepted}/>
            </Select.Option>)}
        </Select>
        {permissions.panel.assemblies ? <Tooltip title="Przejdź do arkusza" placement="topLeft">
            <Button disabled={!value} icon={<ArrowRightOutlined/>} onClick={() => navigate(baseUrl(`/assembly-parameters/${value}`))}/>
        </Tooltip> : null}
    </Space>


}
