import React from "react";
import { HorzLine, VertLine } from "./utils";
import "./absolute-layout.css";

type Point = {
    x: number;
    y: number;
}

const DEFAULT_WIDTH = 500;
const DEFAULT_HEIGHT = 300;

interface AbsoluteLayoutProps {
    colWidth?: number;
    rowHeight?: number;
    showGrid?: boolean;
    showPins?: boolean;
    showSnappingLines?: boolean;
    snapToGrid?: number;
    snapDist?: number;
    snapToMargins?: number;
    width: number;
    height: number;
    innerWidth?: number;
    innerHeight?: number
    layout: GridLayout;
    editing?: boolean;
    toolbar?: boolean;
    name?: string;
    storage?: any;
    onUpdateLayout?: (layout: GridLayout) => void;
    onSelectElement?: (idx: number) => void;
    onDrop?: (element: GridCell) => void;
    style?: React.CSSProperties;
    innerStyle?: any;
    className?: string;
    topClassName?: string;
    elementIdx?: number;
    id?: string;
    pinning?: boolean;
    newElementSize?: {
        width: number;
        height: number;
    },
    children?: React.ReactNode
}

interface SnapPoints {
    horizontal: number[];
    vertical: number[];
    middleHorizontal: number[];
    middleVertical: number[];
    allHorizontal: number[];
    allVertical: number[];
}

interface AbsoluteLayoutState {
    moving: boolean;
    resizing: boolean;
    dragging: boolean;
    dragOffset: Point | null;
    dragElement: HTMLElement | null,
    mouseStartPos: Point | null;
    mouseCurrPos: Point | null;
    elementStartPos: Point | null;
    elementEndPos: Point | null;
    elementIdx: number;
    grid: GridLayout;
    contentWidth?: number;
    contentHeight?: number;
    colWidth: number;
    rowHeight: number;
    snapPoints: SnapPoints;
}

export const PIN_NONE = 0;
export const PIN_PIXEL = 1;
export const PIN_PERCENT = 2;

export interface GridCellPos {
    // pos, pin mode, locked pos
    x0: number;
    xp0: number;
    xl0: number;
    y0: number;
    yp0: number;
    yl0: number;
    x1: number;
    xp1: number;
    xl1: number;
    y1: number;
    yp1: number;
    yl1: number;
}

export interface GridCell extends GridCellPos {
    key: string | null;
    component: React.ReactElement | null;
}

type GridCellKey = keyof Omit<Omit<GridCell, 'key'>, 'component'>;

export type GridLayout = Array<GridCell>;

export class AbsoluteLayout extends React.Component<AbsoluteLayoutProps, AbsoluteLayoutState> {

    public static defaultProps: AbsoluteLayoutProps = {
        colWidth: 40,
        rowHeight: 40,
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT,
        showGrid: true,
        showSnappingLines: true,
        snapToGrid: 5,
        snapToMargins: 5,
        snapDist: 3,
        showPins: true,
        editing: false,
        toolbar: true,
        pinning: true,
        layout: [],
        name: "default",
        newElementSize: {
            width: 150,
            height: 50
        }
    };

    private readonly gridRef: React.RefObject<HTMLDivElement>;

    constructor(props: AbsoluteLayoutProps) {
        super(props);

        this.gridRef = React.createRef();

        const colWidth = Math.max(props.colWidth ?? 0, 5);
        const rowHeight = Math.max(props.rowHeight ?? 0, 5);

        this.state = {
            grid: this.props.layout,
            moving: false,
            resizing: false,
            dragging: false,
            dragOffset: null,
            dragElement: null,
            mouseStartPos: null,
            mouseCurrPos: null,
            elementStartPos: null,
            elementEndPos: null,
            elementIdx: -1,
            colWidth: colWidth,
            rowHeight: rowHeight,
            contentWidth: 0,
            contentHeight: 0,
            snapPoints: {
                allHorizontal: [],
                allVertical: [],
                horizontal: [],
                vertical: [],
                middleHorizontal: [],
                middleVertical: []
            }
        }
    }

    getContentSize = (grid: GridLayout) => {
        let contentWidth = this.props.width;
        let contentHeight = this.props.height;
        for (const g of grid) {
            if (g.x1 > contentWidth) {
                contentWidth = g.x1;
            }
            if (g.y1 > contentHeight) {
                contentHeight = g.y1;
            }
        }
        return [contentWidth, contentHeight];
    }

    syncGridLayout = (layout: GridLayout | null, width: number, height: number) => {
        if (layout) {
            const { grid, snapPoints } = recalculateLayout(layout, width, height, this.props.snapToMargins!);
            const [contentWidth, contentHeight] = this.getContentSize(grid);

            this.setState({
                grid, snapPoints,
                contentWidth, contentHeight
            }, () => this.updateLayout(grid));
        }
    };

    syncGridComponents = (layout: GridLayout | null) => {
        if (layout && equalStructure(layout, this.state.grid)) {
            for (let i = 0; i < layout.length; i++) {
                this.state.grid[i].component = layout[i].component;
            }

            this.setState({
                grid: this.state.grid
            });
        }
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.onMouseDown);
        document.addEventListener('mousemove', this.onMouseMove, { capture: true });
        document.addEventListener('mouseup', this.onMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onMouseDown);
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    }

    // static getDerivedStateFromProps(props, state) {}
    componentWillReceiveProps(props: AbsoluteLayoutProps) {
        let grid = props.layout;
        if (!this.state.dragging) {
            const layoutChanged = !equalLayout(grid!, this.state.grid);
            if (layoutChanged) {
                this.syncGridLayout(grid!, this.getWidth(), this.getHeight());
            }
            if (!layoutChanged && !equalComponents(grid!, this.state.grid)) {
                this.syncGridComponents(grid!);
            }
        }
    }

    clearSelectedElement = () => {
        this.setState({
            elementIdx: -1,
            elementStartPos: null,
            elementEndPos: null
        }, () => this.onSelectElement(-1));
    };

    selectElement = (idx: number, x: number, y: number, w: number, h: number) => {
        this.setState({
            elementIdx: idx,
            elementStartPos: {
                x: x,
                y: y
            },
            elementEndPos: {
                x: x + w,
                y: y + h
            },
            snapPoints: getSnapPoints(this.state.grid, idx, this.props.snapToMargins!)
        }, () => this.onSelectElement(idx));
    };

    onSelectElement = (idx: number) => {
        if (this.props.onSelectElement) {
            this.props.onSelectElement(idx);
        }
    };

    getDraggableElement(el: Node | null): HTMLElement | null {
        let n = 0;
        while (el && n < 3) {
            if ((el as HTMLElement)?.draggable) {
                return (el as HTMLElement);
            }
            el = el?.parentNode;
            n = n + 1;
        }
        return null;
    }

    onMouseDown = (e: MouseEvent) => {
        const el = this.getDraggableElement(e.target as Node);
        if (el) {
            const r = el.getBoundingClientRect();
            this.setState({
                dragElement: el,
                dragOffset: {
                    x: e.clientX - r.x,
                    y: e.clientY - r.y
                },
            });
        }
        this.mouseDown(e.clientX, e.clientY);
    };

    mouseDown = (clientX: number, clientY: number) => {
        if (this.state.elementIdx >= 0) {
            this.setState({
                mouseStartPos: {x: clientX, y: clientY},
                mouseCurrPos: {x: clientX, y: clientY},
            });
        }
    };

    onMouseMove = (e: MouseEvent) => {
        this.mouseMove(e.clientX, e.clientY);
    };

    mouseMove = (clientX: number, clientY: number) => {
        if (this.state.mouseCurrPos?.x === clientX &&
            this.state.mouseCurrPos?.y === clientY) {
            return;
        }

        const dx = this.state.mouseStartPos ? clientX - this.state.mouseStartPos.x : 0;
        const dy = this.state.mouseStartPos ? clientY - this.state.mouseStartPos.y : 0;

        const idx = this.state.elementIdx;
        const grid = this.state.grid;
        const gi = grid[idx];
        const snapPoints = this.state.snapPoints;

        const colWidth = this.props.snapToGrid ? this.state.colWidth : 0;
        const rowHeight = this.props.snapToGrid ? this.state.rowHeight : 0;

        if (this.state.moving && !this.state.resizing) {
            const gx0 = this.state.elementStartPos!.x + dx;
            const gx1 = this.state.elementEndPos!.x + dx;

            let x0 = snapToGrid(gx0, this.props.snapDist! , snapPoints.vertical, colWidth);
            let fx = x0.pos;

            if (!x0.snapped) {
                let x1 = snapToGrid(gx1, this.props.snapDist!, snapPoints.vertical, colWidth);
                if (x1.snapped) {
                    fx = fx + (x1.pos - gx1);
                } else {
                    let x2 = snapToGrid((gx0 + gx1) / 2, this.props.snapDist!, snapPoints.middleVertical);
                    if (x2.snapped) {
                        fx = x2.pos - Math.abs(gx1 - gx0) / 2;
                    }
                }
            }

            const gy0 = this.state.elementStartPos!.y + dy;
            const gy1 = this.state.elementEndPos!.y + dy;

            const y0 = snapToGrid(gy0, this.props.snapDist!, snapPoints.horizontal, rowHeight);
            let fy = y0.pos;

            if (!y0.snapped) {
                let y1 = snapToGrid(gy1, this.props.snapDist!, snapPoints.horizontal, rowHeight);
                if (y1.snapped) {
                    fy = fy + (y1.pos - gy1);
                } else {
                    let y2 = snapToGrid((gy0 + gy1) / 2, this.props.snapDist!, snapPoints.middleHorizontal);
                    if (y2.snapped) {
                        fy = y2.pos - Math.abs(gy1 - gy0) / 2;
                    }
                }
            }

            gi.x1 = gi.x1 + (fx - gi.x0);
            gi.y1 = gi.y1 + (fy - gi.y0);
            gi.x0 = fx;
            gi.y0 = fy;
        }

        if (this.state.resizing) {
            const gx1 = this.state.elementEndPos!.x + dx;
            const gy1 = this.state.elementEndPos!.y + dy;

            const fx = snapToGrid(gx1, this.props.snapDist!, snapPoints.allVertical, colWidth);
            const fy = snapToGrid(gy1, this.props.snapDist!, snapPoints.allHorizontal, rowHeight);

            gi.x1 = Math.max(gi.x0, fx.pos);
            gi.y1 = Math.max(gi.y0, fy.pos);
        }

        if (this.state.resizing || this.state.moving) {
            syncCellPins(gi, this.getWidth(), this.getHeight());
            const [contentWidth, contentHeight] = this.getContentSize(grid);
            this.setState({
                mouseCurrPos: {x: clientX, y: clientY},
                grid: grid,
                contentWidth, contentHeight
            });
        }
    }

    onMouseUp = (e: MouseEvent) => {
        this.mouseUp();
    };

    mouseUp = () => {
        if (this.state.moving || this.state.resizing) {
            const snapPoints = getSnapPoints(this.state.grid, -1, this.props.snapToMargins!);
            this.setState({
                mouseStartPos: null,
                mouseCurrPos: null,
                elementStartPos: null,
                elementEndPos: null,
                moving: false,
                resizing: false,
                snapPoints: snapPoints
            }, () => {
                this.updateLayout(this.state.grid);
            });
        }
    }

    onDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!this.state.dragging) {
            const clientX = e.clientX;
            const clientY = e.clientY;
            let cell: GridCell = getGridCell(
                clientX, clientY,
                this.props.newElementSize?.width,
                this.props.newElementSize?.height
            );
            const grid = this.state.grid;
            const idx = grid.length;
            cell.key = idx.toString();
            grid.push(cell);
            const gridEl = this.gridRef.current;
            if (gridEl) {
                const gr = gridEl.getBoundingClientRect();
                this.selectElement(idx,
                    clientX - gr.x + gridEl.scrollLeft - (this.state.dragOffset?.x ?? 0) + 10,
                    clientY - gr.y + gridEl.scrollTop - (this.state.dragOffset?.y ?? 0) + 10,
                    this.props.newElementSize!.width,
                    this.props.newElementSize!.height
                );
                this.setState({
                    elementIdx: idx,
                    dragging: true,
                    moving: true,
                    resizing: false,
                    grid
                }, () => {
                    this.mouseDown(clientX, clientY);
                });
            }
        }
    }

    onDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    onDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.dragging) {
            this.mouseMove(e.clientX, e.clientY);
        }
    }

    onDrop = (e: React.DragEvent) => {
        if (this.state.dragging) {
            this.setState({
                dragging: false
            });
            if (this.props.onDrop) {
                this.props.onDrop(this.state.grid[this.state.elementIdx]);
            }
            this.mouseUp();
        }
    }

    togglePinMode = (key: GridCellKey, totalSize: number) => {
        if (this.props.pinning) {
            const grid = this.state.grid;
            const idx = this.state.elementIdx;
            const g = grid[idx];
            const lockKey = (key[0] + 'l' + key[2]) as GridCellKey;
            const posKey = (key[0] + key[2]) as GridCellKey;
            let pinMode = g[key];

            pinMode += 1;
            if (pinMode > 2)
                pinMode = 0;

            if (pinMode === PIN_PERCENT || pinMode === PIN_PIXEL) {
                const pos = key[2] == '0' ? g[posKey] : totalSize - (g[posKey]);
                grid[idx][lockKey] = getLockPos(pos, pinMode, totalSize);
            } else if (pinMode === PIN_NONE) {
                grid[idx][lockKey] = g[posKey];
            }

            grid[idx][key] = pinMode;

            this.syncGridLayout(grid, this.getWidth(), this.getHeight());
        }
    };

    getHighlightColor(pinMode: number): string | undefined {
        if (pinMode === PIN_PIXEL) {
            return "rgba(255, 0, 0, 0.3)";
        } else if (pinMode === PIN_PERCENT) {
            return "rgba(0, 255, 0, 0.3)"
        } else {
            return undefined;
        }
    }

    getPosLabel(pos: number, lockPos: number, pinMode: number): string {
        if (pinMode === PIN_PERCENT) {
            return `${Math.round(lockPos)}%`;
        } else if (pinMode === PIN_PIXEL) {
            return `${Math.round(lockPos)}px`
        } else {
            return `${Math.round(pos)}px`;
        }
    }

    startResizing = (e: React.MouseEvent) => {
        this.setState({
            resizing: true,
            moving: false
        });
        this.onSelectElement(this.state.elementIdx);
    };

    updateLayout = (grid: GridLayout) => {
        if (this.props.onUpdateLayout) {
            this.props.onUpdateLayout(grid);
        }
    };

    isMoving = () => {
        return this.state.dragging || this.state.moving || this.state.resizing
    }

    getWidth = () => this.props.innerWidth ?? Math.max(this.state.contentWidth ?? 0, this.props.width);
    getHeight = () => this.props.innerHeight ?? Math.max(this.state.contentHeight ?? 0, this.props.height);

    render() {
        const width = this.getWidth();
        const height = this.getHeight();

        const grid = this.state.grid;
        const elementIdx = this.state.elementIdx;
        const g = elementIdx >= 0 ? grid[elementIdx] : null;
        const axis = [];

        if (this.props.editing && this.props.showPins && g) {
            axis.push(
                <HorzLine
                    key="axis-x-left"
                    onClick={() => this.togglePinMode('xp0', width)}
                    size={5}
                    pos={(g.y0 + g.y1) / 2}
                    label={this.getPosLabel(g.x0, g.xl0, g.xp0)}
                    from={0}
                    to={g.x0}
                    highlightColor={this.getHighlightColor(g.xp0)}
                    disabled={this.props.pinning === false}
                    onTop={true}/>,
                <HorzLine
                    key="axis-x-right"
                    onClick={() => this.togglePinMode('xp1', width)}
                    size={5}
                    pos={(g.y0 + g.y1) / 2}
                    label={this.getPosLabel(width - g.x1, g.xl1, g.xp1)}
                    from={g.x1}
                    to={width}
                    highlightColor={this.getHighlightColor(g.xp1)}
                    disabled={this.props.pinning === false}
                    onTop={true}/>,
                <VertLine
                    key="axis-y-top"
                    onClick={() => this.togglePinMode('yp0', height)}
                    size={5}
                    pos={(g.x0 + g.x1) / 2}
                    label={this.getPosLabel(g.y0, g.yl0, g.yp0)}
                    from={0}
                    to={g.y0}
                    highlightColor={this.getHighlightColor(g.yp0)}
                    disabled={this.props.pinning === false}
                    onTop={true}/>,
                <VertLine
                    key="axis-y-bottom"
                    onClick={() => this.togglePinMode('yp1', height)}
                    size={5}
                    pos={(g.x0 + g.x1) / 2}
                    label={this.getPosLabel(height - g.y1, g.yl1, g.yp1)}
                    from={g.y1}
                    to={height}
                    highlightColor={this.getHighlightColor(g.yp1)}
                    disabled={this.props.pinning === false}
                    onTop={true}/>
            );
        }

        let colLines = [];
        let rowLines = [];

        let gridStyle = {} as React.CSSProperties;

        if (this.props.showGrid) {
            gridStyle.backgroundImage = "repeating-linear-gradient(#eeeeee 0 1px, transparent 1px 100%)," +
                "repeating-linear-gradient(90deg, #eeeeee 0 1px, transparent 1px 100%)";
            gridStyle.backgroundPosition = "0 0";
            gridStyle.backgroundSize = `${this.state.colWidth}px ${this.state.rowHeight}px`;
        }

        const elements = this.state.grid.map((g: GridCell, idx: number) => {
            const gel = g.component ?? React.createElement("div", { style: { backgroundColor: 'lightcoral'} });

            if (gel) {
                const zIndex = idx === elementIdx ? 10000 : 'auto';

                const posStyle: React.CSSProperties = {
                    position: 'absolute',
                    boxSizing: 'border-box',
                    left: `${g.x0}px`,
                    top: `${g.y0}px`,
                    width: `${g.x1 - g.x0 + 1}px`,
                    height: `${g.y1 - g.y0 + 1}px`
                };

                let style: React.CSSProperties = {
                    ...posStyle,
                    zIndex: zIndex,
                    overflow: 'hidden',
                    // pointerEvents: 'none',//this.state.dragging ? 'none' : 'auto',
                    opacity: idx === elementIdx && (this.state.moving || this.state.resizing) ? 0.8 : 1
                };

                const props = {
                    key: `cell/${idx}`,
                    style: style,
                    className: (gel.props.className ?? '') + ' al-grid-item',
                    onMouseDown: (e: React.MouseEvent<any>) => {}
                };

                if (this.props.editing) {
                    props.onMouseDown = (e: React.MouseEvent<any>) => {
                        e.stopPropagation();
                        let t = e.currentTarget as HTMLElement;
                        this.selectElement(idx, t.offsetLeft, t.offsetTop, t.offsetWidth, t.offsetHeight);
                        this.setState({
                            elementIdx: idx,
                            moving: true
                        }, () => {
                            this.mouseDown(e.clientX, e.clientY);
                        });
                    }
                }

                let editEl = null;

                if (this.props.editing) {
                    editEl = <>
                        {
                            elementIdx == idx &&
                            <div style={{
                                position: 'absolute',
                                left: 5, top: 5, padding: 2,
                                fontSize: 12,
                                zIndex: zIndex,
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                color: 'white'
                            }}>
                                {Math.round(g.x1 - g.x0)}, {Math.round(g.y1 - g.y0)}
                            </div>
                        }
                        {
                            g.xp0 !== PIN_NONE &&
                            <div style={{
                                position: 'absolute',
                                left: 0,
                                top: 'calc(50% - 10px)',
                                width: 3,
                                height: 20,
                                zIndex: zIndex,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)'
                            }}/>
                        }
                        {
                            g.xp1 !== PIN_NONE &&
                            <div style={{
                                position: 'absolute',
                                right: 0,
                                top: 'calc(50% - 10px)',
                                width: 3,
                                height: 20,
                                zIndex: zIndex,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)'
                            }}/>
                        }
                        {
                            g.yp0 !== PIN_NONE &&
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 'calc(50% - 10px)',
                                width: 20,
                                height: 3,
                                zIndex: zIndex,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)'
                            }}/>
                        }
                        {
                            g.yp1 !== PIN_NONE &&
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 'calc(50% - 10px)',
                                width: 20,
                                height: 3,
                                zIndex: zIndex,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)'
                            }}/>
                        }
                        <div onMouseDown={this.startResizing} style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: 20,
                            height: 20,
                            zIndex: zIndex,
                            cursor: 'nwse-resize'
                        }}>
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                width: 8,
                                height: 3,
                                zIndex: zIndex,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)'
                            }}/>
                            <div style={{
                                position: 'absolute',
                                right: 0,
                                width: 3,
                                height: 5,
                                bottom: 3,
                                zIndex: zIndex,
                                backgroundColor: 'rgba(0, 0, 0, 0.2)'
                            }}/>
                        </div>
                    </>
                }

                const el = React.cloneElement(gel, {
                    ...gel.props,
                    ...props,
                    key: `cell-content/${g.key}`,
                    style: {
                        ...gel.props.style,
                        width: '100%',
                        height: '100%',
                        pointerEvents: this.props.editing ? 'none' : 'auto'
                    }
                });

                return <div {...props}>
                    {editEl}
                    {el}
                </div>;
            } else {
                return null;
            }
        });

        if (this.props.showSnappingLines && (this.state.moving || this.state.resizing)) {
            const g = grid[elementIdx];
            const snapPoints = this.state.snapPoints;

            const fx0 = snapToGrid(g.x0, this.props.snapDist!, snapPoints.vertical, this.state.colWidth);
            const fx1 = snapToGrid(g.x1, this.props.snapDist!, snapPoints.vertical, this.state.colWidth);
            const fx2 = snapToGrid((g.x0 + g.x1) / 2, this.props.snapDist!, snapPoints.middleVertical, this.state.colWidth);
            const fy0 = snapToGrid(g.y0, this.props.snapDist!, snapPoints.horizontal, this.state.rowHeight);
            const fy1 = snapToGrid(g.y1, this.props.snapDist!, snapPoints.horizontal, this.state.rowHeight);
            const fy2 = snapToGrid((g.y0 + g.y1) / 2, this.props.snapDist!, snapPoints.middleHorizontal, this.state.rowHeight);

            if (fx0.snapPoint >= 0) {
                colLines.push(
                    <VertLine
                        key={`column-snap-vert-line-0`}
                        pos={fx0.pos}
                        color="rgba(255, 0, 0, 0.17)"
                        disabled={true}
                    />
                );
            }

            if (fx1.snapPoint >= 0) {
                colLines.push(
                    <VertLine
                        key={`column-snap-vert-line-1`}
                        pos={fx1.pos}
                        color="rgba(255, 0, 0, 0.17)"
                        disabled={true}
                    />
                );
            }

            if (fx2.snapPoint >= 0) {
                colLines.push(
                    <VertLine
                        key={`column-snap-vert-line-2`}
                        pos={fx2.pos}
                        color="rgba(255, 0, 0, 0.17)"
                        disabled={true}
                    />
                );
            }

            if (fy0.snapPoint >= 0) {
                rowLines.push(
                    <HorzLine
                        key={`column-snap-horz-line-0`}
                        pos={fy0.pos}
                        color="rgba(255, 0, 0, 0.17)"
                        disabled={true}
                    />
                );
            }

            if (fy1.snapPoint >= 0) {
                rowLines.push(
                    <HorzLine
                        key={`column-snap-horz-line-1`}
                        pos={fy1.pos}
                        color="rgba(255, 0, 0, 0.17)"
                        disabled={true}
                    />
                );
            }

            if (fy2.snapPoint >= 0) {
                rowLines.push(
                    <HorzLine
                        key={`column-snap-horz-line-2`}
                        pos={fy2.pos}
                        color="rgba(255, 0, 0, 0.17)"
                        disabled={true}
                    />
                );
            }
        }

        return <div ref={this.gridRef} className={this.props.className} style={{
            width: this.props.width,
            height: this.props.height,
            position: 'relative',
            boxSizing: 'border-box',
            overflow: 'auto',
            ...this.props.style
        }} onMouseDown={this.clearSelectedElement}>
            <div className={"inner-grid" + (this.isMoving() ? " inner-grid-moving" : "")} style={{
                ...gridStyle,
                ...this.props.style,
                width,
                height,
            }}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
            >
                {colLines}
                {rowLines}
                {elements}
                {axis}
            </div>
        </div>
    }
}

type SnapPoint = {
    pos: number,
    snapped: boolean,
    snapPoint: number
}

function snapToGrid(pos: number, dist: number, snapPoints: number[], cellSize: number = 0): SnapPoint {
    let closestSnapPoint = -1;

    for (const sp of snapPoints) {
        const d = Math.abs(pos - sp);
        if (d <= dist) {
            closestSnapPoint = sp;
            break;
        }
    }

    let outPos = {pos: pos, snapPoint: -1, snapped: false};

    if (closestSnapPoint >= 0) {
        outPos.pos = closestSnapPoint > 0
            ? Math.round(pos / closestSnapPoint) * closestSnapPoint
            : 0;
        outPos.snapPoint = closestSnapPoint;
        outPos.snapped = true;
    } else if (cellSize > 0) {
        const cellPos = cellSize > 0
            ? Math.round(pos / cellSize) * cellSize
            : 0;
        if (Math.abs(pos - cellPos) <= dist) {
            outPos.pos = cellPos;
            outPos.snapped = true;
        }
    }

    return outPos;
}

export function getGridCell(x0: number, y0: number, w: number = 0, h: number = 0, colSize: number = 1, rowSize: number = 1): GridCell {
    return {
        x0: x0 * colSize, xp0: PIN_NONE, xl0: x0 * colSize,
        y0: y0 * rowSize, yp0: PIN_NONE, yl0: y0 * rowSize,
        x1: x0 * colSize + w * colSize, xp1: PIN_NONE, xl1: x0 * colSize + w * colSize,
        y1: y0 * rowSize + h * rowSize, yp1: PIN_NONE, yl1: y0 * rowSize + h * rowSize,
        key: null,
        component: null
    }
}

export function equalCellPos(c0: GridCellPos, c1: GridCellPos): boolean {
    return (
        eqf(c0.x0, c1.x0) && eqf(c0.xl0, c1.xl0) && c0.xp0 === c1.xp0 &&
        eqf(c0.x1, c1.x1) && eqf(c0.xl1, c1.xl1) && c0.xp1 === c1.xp1 &&
        eqf(c0.y0, c1.y0) && eqf(c0.yl0, c1.yl0) && c0.yp0 === c1.yp0 &&
        eqf(c0.y1, c1.y1) && eqf(c0.yl1, c1.yl1) && c0.yp1 === c1.yp1
    )
}

export function equalStructure(grid0: GridLayout, grid1: GridLayout): boolean {
    if (!grid0)
        return false;

    if (!grid1)
        return false;

    return grid0.length === grid1.length;
}

export function equalLayout(grid0: GridLayout, grid1: GridLayout): boolean {
    if (!equalStructure(grid0, grid1)) {
        return false;
    }

    for (let i = 0; i < grid0.length; i++) {
        const g0 = grid0[i];
        const g1 = grid1[i];

        if (g0.key !== g1.key || !equalCellPos(g0, g1)) {
            return false;
        }
    }

    return true;
}

export function equalComponents(grid0: GridLayout, grid1: GridLayout): boolean {
    if (!equalStructure(grid0, grid1)) {
        return false;
    }

    for (let i = 0; i < grid0.length; i++) {
        const g0 = grid0[i];
        const g1 = grid1[i];

        if (g0.component !== g1.component) {
            return false;
        }
    }

    return true;
}

function eqf(a: number, b: number): boolean {
    return Math.abs(a - b) < 1.0e-05;
}

export function getLockPos(pos: number, pinMode: number, size: number): number {
    if (pinMode === PIN_PERCENT) {
        return pos * 100 / size;
    } else {
        return pos;
    }
}

export function syncHorzCellPins(g: GridCell, width: number) {
    g.xl0 = getLockPos(g.x0, g.xp0, width);
    g.xl1 = getLockPos(g.xp1 == PIN_NONE ? g.x1 : width - g.x1, g.xp1, width);
}

export function syncVertCellPins(g: GridCell, height: number) {
    g.yl0 = getLockPos(g.y0, g.yp0, height);
    g.yl1 = getLockPos(g.yp1 == PIN_NONE ? g.y1 : height - g.y1, g.yp1, height);
}

export function syncCellPins(g: GridCell, width: number, height: number) {
    syncHorzCellPins(g, width);
    syncVertCellPins(g, height);
}

export function syncGridPins(grid: GridLayout, width: number, height: number) {
    grid.forEach(g => syncCellPins(g, width, height));
}

function getSnapPoints(grid: GridLayout, selectedIdx: number, snapToMargins: number): SnapPoints {
    const xs = [];
    const ys = [];
    const mxs = [];
    const mys = [];

    for (let i = 0; i < grid.length; i++) {
        if (i !== selectedIdx) {
            const g = grid[i];
            xs.push(g.x0, g.x1);
            if (snapToMargins > 0) {
                xs.push(g.x0 - snapToMargins, g.x1);
                xs.push(g.x0, g.x1 + snapToMargins);
            }
            ys.push(g.y0, g.y1);
            if (snapToMargins > 0) {
                ys.push(g.y0 - snapToMargins, g.y1);
                ys.push(g.y0, g.y1 + snapToMargins);
            }
            mxs.push((g.x0 + g.x1) / 2);
            mys.push((g.y0 + g.y1) / 2);
        }
    }

    return {
        horizontal: ys,
        vertical: xs,
        middleHorizontal: mys,
        middleVertical: mxs,
        allHorizontal: ys.concat(mys),
        allVertical: xs.concat(mxs)
    }
}

export function recalculateLayoutCell(cell: GridCell, width: number, height: number): GridCell {
    const g: GridCell = {
        x0: cell.x0, xl0: cell.xl0, xp0: cell.xp0,
        y0: cell.y0, yl0: cell.yl0, yp0: cell.yp0,
        x1: cell.x1, xl1: cell.xl1, xp1: cell.xp1,
        y1: cell.y1, yl1: cell.yl1, yp1: cell.yp1,
        key: cell.key,
        component: cell.component
    };

    if (g.xp0 == PIN_PIXEL) {
        const x0 = g.x0;
        g.x0 = g.xl0;
        if (g.xp1 == PIN_NONE) {
            g.x1 += (g.x0 - x0);
            g.xl1 = g.x1;
        }
    } else if (g.xp0 == PIN_PERCENT) {
        const x0 = g.x0;
        g.x0 = width * g.xl0 / 100;
        if (g.xp1 == PIN_NONE) {
            g.x1 += (g.x0 - x0);
            g.xl1 = g.x1;
        }
    }

    if (g.yp0 == PIN_PIXEL) {
        const y0 = g.y0;
        g.y0 = g.yl0;
        if (g.yp1 == PIN_NONE) {
            g.y1 += (g.y0 - y0);
            g.yl1 = g.y1;
        }
    } else if (g.yp0 == PIN_PERCENT) {
        const y0 = g.y0;
        g.y0 = height * g.yl0 / 100;
        if (g.yp1 == PIN_NONE) {
            g.y1 += (g.y0 - y0);
            g.yl1 = g.y1;
        }
    }

    if (g.xp1 == PIN_PIXEL) {
        const x1 = g.x1;
        g.x1 = width - g.xl1;
        if (g.xp0 == PIN_NONE) {
            g.x0 += (g.x1 - x1);
            g.xl0 = g.x0;
        }
    } else if (g.xp1 == PIN_PERCENT) {
        const x1 = g.x1;
        g.x1 = width - width * g.xl1 / 100;
        if (g.xp0 == PIN_NONE) {
            g.x0 += (g.x1 - x1);
            g.xl0 = g.x0;
        }
    }

    if (g.yp1 == PIN_PIXEL) {
        const y1 = g.y1;
        g.y1 = height - g.yl1;
        if (g.yp0 == PIN_NONE) {
            g.y0 += (g.y1 - y1);
            g.yl0 = g.y0;
        }
    } else if (g.yp1 == PIN_PERCENT) {
        const y1 = g.y1;
        g.y1 = height - height * g.yl1 / 100;
        if (g.yp0 == PIN_NONE) {
            g.y0 += (g.y1 - y1);
            g.yl0 = g.y0;
        }
    }

    if (g.xp0 == PIN_NONE && g.xp1 == PIN_NONE) {
        g.x0 = g.xl0;
        g.x1 = g.xl1;
    }

    if (g.yp0 == PIN_NONE && g.yp1 == PIN_NONE) {
        g.y0 = g.yl0;
        g.y1 = g.yl1;
    }

    return g;
}

export function recalculateLayout(grid: GridLayout, width: number, height: number, snapToMargins: number): { grid: GridLayout, snapPoints: SnapPoints } {
    let nextGrid = grid;

    if (width > 0 && height > 0) {
        nextGrid = grid.map(cell => recalculateLayoutCell(cell, width, height));
    }

    return {
        grid: nextGrid,
        snapPoints: getSnapPoints(nextGrid, -1, snapToMargins)
    };
}

export function equalChildren(children0: React.ReactElement[], children1: React.ReactElement[]) {
    if (children0.length !== children1.length) {
        return false;
    }

    for (let i = 0; i < children0.length; i++) {
        const c0 = children0[i];
        const c1 = children1[i];

        if (c0.key !== c1.key) {
            return false;
        }
    }

    return true;
}
