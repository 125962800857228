import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { notification } from "antd";
import { UserInfo } from "../models/user";
import { baseUrl } from "../utils/navigation";
import { parseJson } from "../utils/utils";
import { appStore } from "../store/store";

function notifyError(error: any) {
    let description = error.toString();
    let messageType = "Błąd systemu";
    if (error.response && error.response.data) {
        if (error.response.data.message) {
            description = error.response.data.message;
        }
        if (error.response.data.type) {
            messageType = error.response.data.type;
        }
    }

    if (messageType === "Plik nie znaleziony") {
        notification.warning({
            message: messageType,
            duration: 4.5,
            description
        })
    } else {
        notification.error({
            message: messageType,
            duration: 15,
            description
        });
    }
}

function getBackendApi(baseURL: string) {
    const api = Axios.create({
        baseURL
    })

    api.interceptors.request.use(config => {
        const { accessToken } = getTokens();
        if (accessToken && config?.headers) {
            config.headers["Authorization"] = `Bearer ${accessToken}`
        }
        return config;
    })

    api.interceptors.response.use(
        res => res,
        async error => {
            if (error.response) {
                const originalRequest = error.config;
                if (error.response.status === 401 && originalRequest.url.indexOf('/auth/oauth') >= 0) {
                    window.location.assign(baseUrl('/login'));
                } else if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const { storedRefreshToken } = getTokens();
                    try {
                        const res = await api.post<{ access_token: string, refresh_token: string }>(
                            `/auth/oauth`, `grant_type=refresh_token&refresh_token=${storedRefreshToken}`, {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        });
                        const accessToken = res.data.access_token;
                        const refreshToken = res.data.refresh_token;
                        storeTokens(accessToken, refreshToken);
                        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                        return api(originalRequest);
                    } catch {
                        clearStorage();
                        if (originalRequest.url.indexOf('/auth/login') < 0) {
                            window.location.assign(baseUrl('/login'));
                            console.log("Redirect")
                        } else {
                            throw error;
                        }
                    }
                }
            }

            if (!Axios.isCancel(error)) {
                if (
                    error.request.responseType === 'blob' &&
                    error.response.data instanceof Blob &&
                    error.response.data.type &&
                    error.response.data.type.toLowerCase().indexOf('json') != -1
                ) {
                    return new Promise((resolve, reject) => {
                        let reader = new FileReader();
                        reader.onload = () => {
                            error.response.data = JSON.parse(reader.result?.toString() ?? "{}");
                            resolve(Promise.reject(error));
                            notifyError(error);
                        };

                        reader.onerror = () => {
                            reject(error);
                        };

                        reader.readAsText(error.response.data);
                    });
                } else if (error.response.status !== 400 && error.response.status !== 403) {
                    notifyError(error);
                }
            }

            return Promise.reject(error.response.data);
        }
    )

    return api;
}

const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";
const USER_INFO_KEY = "userId";

export function storeTokens(accessToken: string, refreshToken: string) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function storeUser(user: UserInfo) {
    localStorage.setItem(USER_INFO_KEY, JSON.stringify(user));
}

export function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function getTokens() {
    return {
        accessToken: getAccessToken(),
        storedRefreshToken: getRefreshToken()
    }
}

export function getUser(): UserInfo {
    const userInfoItem = localStorage.getItem(USER_INFO_KEY);
    return userInfoItem ? parseJson(userInfoItem) : {
        id: -1
    } as UserInfo;
}

export function clearStorage() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(USER_INFO_KEY);
    appStore.update(s => {
        s.secondaryRole = null;
    });
}

export function delayResponse<T>(resp: AxiosResponse<T>, ms: number = 500) {
    return new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(resp), ms));
}

export const backendApi = getBackendApi(`/${API_PREFIX}`);

export function backendGet<T = any>(url: string): Promise<T> {
    return backendApi.get<T>(url)
        .then(resp => delayResponse(resp))
        .then(resp => resp.data);
}

export function backendGetBlob<T = Blob>(url: string): Promise<AxiosResponse<T>> {
    return backendApi.get<T>(url, {
        responseType: "blob"
    }).then(resp => delayResponse(resp));
}

export function backendPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return backendApi.post(url, data, config)
        .then(resp => delayResponse(resp))
        .then(resp => resp.data)
}

export function backendPut<T = any>(url: string, data?: any): Promise<T> {
    return backendApi.put(url, data)
        .then(resp => delayResponse(resp))
        .then(resp => resp.data)
}

export function backendSave<T = any>(id: number, url: string, data: any): Promise<T> {
    return (id < 0 ? backendPost(url, data) : backendApi.put(url, data))
        .then(resp => delayResponse(resp))
        .then(resp => resp.data)
}

export function backendDelete<T = any>(url: string): Promise<T> {
    return backendApi.delete(url)
        .then(resp => delayResponse(resp))
        .then(resp => resp.data)
}
