import "./dashboard-card.less"
import React from "react";

export const DashboardCard = (props: React.PropsWithChildren & { title: string }) => {
    return <div className="dashboard-card dashboard-card-bordered" style={{ display: "flex", flexDirection: "column" }}
        key="clients">
        <div className="dashboard-card-head">
            <div className="dashboard-card-head-wrapper">
                <div className="dashboard-card-head-title">
                    {props.title}
                </div>
            </div>
        </div>
        <div className="dashboard-card-body">
            {props.children}
        </div>
    </div>
}
