import React from "react";
import { Button, DatePicker, Input, notification, Select, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { usePaginatedSqlQuery } from "../../hooks/sql-query.hook";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { useAssignedUsers, useUser } from "../../hooks/user.hook";
import { appStore } from "../../store/store";
import { useDebounce } from "../../hooks/debaunce.hook";
import { getListCriteria, withInCriteria } from "../../utils/sql";

import { CreatedBy } from "../../components/created-by";
import { RemoveButton } from "../../components/remove-button";
import { backendDelete } from "../../api/backend-api";
import { TableView } from "../../components/table-view";
import { filterByLabel } from "../../utils/utils";
import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import { NameWithDesc } from "../../components/name-with-desc";

export const Clients = () => {
    const queryClient = useQueryClient();
    const user = useUser();
    const filter = appStore.useState(s => s.clientList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const { mutateAsync: removeClient } = useMutation((clientId: number) => {
        return backendDelete(`/clients/${clientId}`)
            .then(() => {
                void queryClient.invalidateQueries(['clients']);
                notification.success({
                    message: 'Klient został usunięty'
                });
            })
    });

    const criteria = {...filter, search: debouncedSearch};
    const where = getListCriteria(
        [t.cl.id, t.cl.firstName, t.cl.lastName, t.cl.name, t.cl.nip, t.cl.pesel, t.cl.regon, t.cl.address, t.cl.city, t.cl.post, t.cl.postCode],
        t.cl.createdAt, t.cl.deletedAt,
        criteria
    )
        + withInCriteria(t.cl.ownerId, filter.users);

    const { total, data, loading } = usePaginatedSqlQuery(
        'clients', criteria,
        from(t.client, t.cl)
        .join(t.user.as(t.u).id, t.cl.userId)
        .where(where)
        .orderByAlias(criteria.sortColumn, criteria.sortDirection)
        .select({
            key: t.cl.id,
            id: t.cl.id,
            clientType: t.cl.clientType,
            ownerId: t.cl.ownerId,
            createdAt: t.cl.createdAt,
            name: t.cl.fullName(),
            uid: t.cl.uid(),
            createdBy: t.u.fullName(),
            acceptedAt: t.cl.acceptedAt
        })
    );

    const { users, usersLoading } = useAssignedUsers();

    const columns: ColumnsType<typeof data[number]> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
        width: '60%',
        render: (value, row) =>
            <NameWithDesc name={value} desc={row.acceptedAt ? '' : 'Oczekuje na akceptację'}/>
    }, {
        title: 'NIP / Pesel',
        dataIndex: 'uid',
        width: '40%'
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: (value, row) =>
            <CreatedBy createdAt={value} createdBy={row.createdBy} fullInfo={user.permissions.creatorName}/>
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (_, item) => {
            return <Space>
                {user.permissions.clientList.edit([item.ownerId]) ?
                    <Link to={baseUrl(`/clients/${item.id}/data`)}>Edytuj</Link> : null}
                {user.permissions.clientList.remove([item.ownerId]) ?
                    <RemoveButton link disabled={!user.permissions.clientList.removeEnable} onConfirm={() => removeClient(item.id)}/> : null}
            </Space>
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading}
        scrollWidth={1000}
        listKey={'clientList'}
        toolbar={
            <>
                <Link to={baseUrl("/clients/create")}><Button>Dodaj klienta</Button></Link>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.clientList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.clientList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['clients']);
                        }}
                    />
                    {user.permissions.userFilter ?
                        <Select mode="multiple" placeholder="Opiekun" style={{ width: 250 }} value={filter.users} loading={usersLoading} options={users} onChange={value => {
                            appStore.update(s => {
                                s.clientList.users = value;
                            });
                        }}
                            filterOption={filterByLabel}
                            allowClear={true}/> : null}
                    <Input style={{ width: 200 }} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.clientList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
