import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { notification } from "antd";
import { baseUrl } from "../../utils/navigation";
import { useNavigate } from "react-router-dom";
import React from "react";
import omit from "lodash/omit";
import { Model } from "../../models/db";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { Query } from "./offer-query";

export function refreshOffer(queryClient: QueryClient, offerId: number | undefined) {
    if (offerId) {
        void queryClient.invalidateQueries([Query.Offer.key(offerId)]);
    }
}

export const useSaveOffer = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation(async (offer: Model.Offer.Data) => {
        const offerId = await (offer.id
            ? backendPut<number>(`/offers/${offer.id}`, omit(offer, 'acceptedAt'))
            : backendPost<number>('/offers', offer)
        );
        notification.success({
            message: 'Oferta została zapisana'
        });
        if (offer.id) {
            refreshOffer(queryClient, offer.id);
        }
        if (offerId) {
            navigate(baseUrl(`/offers/${offerId}/data`));
        }
    });
}

export const useAcceptOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(async (offerId: number) => {
        const offerAccepted = await backendPut(`/offers/${offerId}/accept`);
        if (offerAccepted) {
            notification.success({
                message: 'Oferta została zaakceptowana'
            });
        }
        refreshOffer(queryClient, offerId);
    });
}

export const useRemoveOffer = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation(async (offerId: number) => {
        await backendDelete(`/offers/${offerId}`);
        void queryClient.invalidateQueries([Query.Offers.key()]);
        notification.success({
            message: 'Oferta została usunięta'
        });
        navigate(baseUrl("/offers"));
    });
}

export const useSendOffer = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation(async ({ offerId, to,  subject, content }: { offerId: number, to: string | null, subject: string, content: string }) => {
        await backendPost(`/offers/${offerId}/send`, {
            to,
            subject,
            content
        });
        void queryClient.invalidateQueries([Query.Offers.key()]);
        void queryClient.invalidateQueries([Query.Offer.MailHistory.key(offerId)]);
        notification.success({
            message: 'Oferta została wysłana'
        });
        navigate(baseUrl("/offers"));
    });
}

export const useMakeOfferCopy = () => {
    const queryClient = useQueryClient();
    return useMutation(async (offerId: number) => {
        await backendPost(`/offers/${offerId}/copy`);
        return await queryClient.invalidateQueries([Query.Offers.key()]);
    });
}

export const useCreateAgreement = (offerAccepted: boolean) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { mutate: acceptOffer } = useAcceptOffer();

    return useMutation(async (offerId: number) => {
        const agreementId = await backendPost<number>(`/agreements/${offerId}`);
        if (!offerAccepted) {
            acceptOffer(offerId);
        } else {
            refreshOffer(queryClient, offerId);
        }
        notification.success({
            key: `agreement-${agreementId}`,
            message: <div>
                <div>{`Utworzono umowę ${agreementId}.`}</div>
                <div><a onClick={() => {
                    notification.destroy(`agreement-${agreementId}`);
                    navigate(baseUrl(`/agreements/${agreementId}/data`))
                }}>Przejdź do umowy</a></div>
            </div>,
            duration: 0
        });
    });
}
