import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../hooks/user.hook";
import { baseUrl } from "../utils/navigation";

export const AdminRoute = (props: { children: ReactNode }) => {
    const { admin } = useUser();
    const location = useLocation();
    return <>{admin ? props.children : <Navigate to={baseUrl('/login')} state={{ from: location }} replace={true}/>}</>
}

export const UserRoute = (props: { children: ReactNode }) => {
    const { admin, user, routeId } = useUser();
    const location = useLocation();
    return <>{admin || user.id === routeId ? props.children : <Navigate to={baseUrl('/login')} state={{ from: location }} replace={true}/>}</>
}

export const SecuredRoute = (props: { children: ReactNode }) => {
    const { user } = useUser();
    const location = useLocation();
    return <>{user.id >= 0 ? props.children : <Navigate to={baseUrl('/login')} state={{ from: location }} replace={true}/>}</>
}
