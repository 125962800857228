import { Button, notification, Select, Space } from "antd";
import { uniqBy } from "lodash";
import React, { useState } from "react";
import { useUser } from "../hooks/user.hook";
import { backendPut } from "../api/backend-api";
import { SelectOption } from "../models/ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface AssignOwnerProps {
    rowId: number;
    ownerId: number,
    owner: string,
    assignUsers: SelectOption[] | undefined;
    usersLoading: boolean;
    assignedOwners: AssignedOwnersHook;
    canSelfAssign: boolean;
    value: any;
    disabled?: boolean;
}

export function AssignOwner(
    { rowId, ownerId, owner, assignedOwners, assignUsers, usersLoading, value, canSelfAssign }: AssignOwnerProps
): JSX.Element {
    const { admin, user } = useUser();

    const rowOwner = assignedOwners.getOwnerId(rowId);

    if (admin) {
        let users = assignUsers || [];
        if (ownerId) {
            users = users.concat([{ label: owner, value: ownerId, key: ownerId }]);
        }
        users = users.concat([{ label: 'Nieprzypisany', value: -1, key: -1 }]);

        return <Space direction="vertical" style={{ width: '100%' }}>
            <Select style={{ width: '100%' }} options={uniqBy(users, 'value')} defaultValue={value ?? -1}
                loading={usersLoading}
                disabled={rowOwner?.saving}
                onChange={value => assignedOwners.setOwnerId(rowId, value, false)}/>
            <Button disabled={usersLoading || !rowOwner}
                loading={rowOwner?.saving}
                onClick={() => assignedOwners.assignOwner({ id: rowId, ownerId: rowOwner?.ownerId ?? -1 })}>
                Zapisz
            </Button>
        </Space>
    } else if (!value && canSelfAssign) {
        return <Button onClick={() => assignedOwners.assignOwner({ id: rowId, ownerId: user.id })}
            loading={rowOwner?.saving}
            disabled={rowOwner?.saving}>
            Przypisz się
        </Button>
    } else {
        return value;
    }
}

export type AssignedOwner = {
    ownerId: number;
    saving: boolean;
}

export type AssignedOwners = {
    [key: number]: AssignedOwner
}

export interface AssignedOwnersHook {
    getOwnerId: (rowId: number) => AssignedOwner | undefined;
    setOwnerId: (rowId: number, ownerId: number, saving: boolean) => void;
    assignOwner: (data: { id: number, ownerId: number }) => Promise<any>;
}

export function useAssignedOwners(entity: string, userType: "owner" | "coordinator" = "owner", invalidateQuery: string | null = null): AssignedOwnersHook {
    const queryClient = useQueryClient();
    const [owners, setOwners] = useState<AssignedOwners>({});

    const setOwnerId = (rowId: number, ownerId: number, saving = false) => {
        setOwners({
            ...owners,
            [rowId]: { ownerId, saving }
        });
    }

    const getOwnerId = (rowId: number) => owners[rowId];

    const { mutateAsync: assignOwner } = useMutation((data: {id: number, ownerId: number }) => {
        setOwnerId(data.id, data.ownerId, true);
        return backendPut(`/${entity}/${data.id}/${userType}/${data.ownerId}`).then(() => {
            notification.success({
                message: userType === 'owner' ? 'Opiekun został przypisany' : 'Koordynator został przypisany'
            });
            void queryClient.invalidateQueries([invalidateQuery ?? entity]);
        }).finally(() => {
            setOwnerId(data.id, data.ownerId, false);
        })
    });

    return {
        getOwnerId,
        setOwnerId,
        assignOwner
    }
}
