import React from "react";
import { Button, DatePicker, Input, Select, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { useAssignedUsers, useUser } from "../../hooks/user.hook";
import { useDebounce } from "../../hooks/debaunce.hook";
import { appStore } from "../../store/store";
import { CreatedBy } from "../../components/created-by";
import { getStatusList, VisualisationStatus } from "../../components/visualisation-status";
import { filterByLabel } from "../../utils/utils";
import { UserRole } from "../../models/user";
import { TableView } from "../../components/table-view";
import { LastComment } from "../../components/last-comment";
import { AssignOwner, useAssignedOwners } from "../../components/assign-owner";
import { HorzGap } from "../../components/gap";
import { useQueryClient } from "@tanstack/react-query";
import { NvlLink } from "../../components/nvl-link";
import { Dayjs } from "dayjs";
import { Query } from "./visualisation-query";

export const Visualisations = () => {
    const queryClient = useQueryClient();

    const user = useUser();
    const filter = appStore.useState(s => s.visualisationList);
    const debouncedSearch = useDebounce(filter.search, 500);

    const { users: designerUsers, usersLoading } = useAssignedUsers(UserRole.DESIGNER);
    const assignedOwners = useAssignedOwners('visualisations');

    const criteria = {...filter, search: debouncedSearch};
    const { total, data, loading } = Query.Visualisations.use(criteria);

    const columns: ColumnsType<Query.Visualisations.T> = [{
        title: 'Id',
        dataIndex: 'id',
        width: 80
    }, {
        title: 'Nazwa',
        dataIndex: 'name',
        width: '30%'
    }, {
        title: 'Klient',
        dataIndex: 'client',
        width: '30%',
        render: (value, row) => <NvlLink value={row.client} link={baseUrl(`/clients/${row.clientId}/data`)}/>
    }, {
        title: 'Opiekun',
        dataIndex: 'owner',
        width: '30%',
        render: (value, row) => <AssignOwner
            rowId={row.id}
            ownerId={row.ownerId}
            owner={row.owner}
            value={value}
            assignUsers={designerUsers}
            usersLoading={usersLoading}
            assignedOwners={assignedOwners}
            canSelfAssign={user.designer}
        />
    }, {
        title: 'Status',
        dataIndex: 'status',
        width: 150,
        render: (value, row) => <VisualisationStatus createdAt={row.statusUpdatedAt} status={value}/>
    }, {
        title: <div>Ostatni<br/>komentarz</div>,
        dataIndex: 'lastComment',
        width: 350,
        render: (value, row) => <LastComment comment={value} createdAt={row.lastCommentCreatedAt}/>
    }, {
        title: 'Utworzono',
        dataIndex: 'createdAt',
        width: 120,
        render: (value, row) => <CreatedBy createdAt={value} createdBy={row.createdBy} fullInfo={user.permissions.creatorName}/> ,
    }, {
        title: 'Akcje',
        key: 'action',
        fixed: 'right',
        width: 150,
        render: (_, item) => {
            return <Space>
                {user.permissions.visualisationList.edit([item.clientOwnerId, item.ownerId]) ?
                    <Link to={baseUrl(`/visualisations/${item.id}/data`)}>Edytuj</Link> : null}
            </Space>
        }
    }];

    return <TableView
        columns={columns}
        data={data}
        total={total}
        loading={loading}
        scrollWidth={1640}
        listKey={'visualisationList'}
        toolbar={
            <>
                {user.permissions.visualisationList.create ? <Link to={baseUrl("/visualisations/create")}><Button>Zamów wizualizację</Button></Link> : null}
                <HorzGap/>
                <Space>
                    <DatePicker.RangePicker picker="month" allowClear
                        value={[filter.periodFrom, filter.periodTo]}
                        onChange={e => {
                            appStore.update(s => {
                                s.visualisationList.periodFrom = (e?.[0] as Dayjs) ?? null;
                                s.visualisationList.periodTo = (e?.[1] as Dayjs) ?? null;
                            });
                            void queryClient.invalidateQueries(['visualisations']);
                        }}
                    />
                    <Select style={{ width: 250 }} placeholder="Status" value={filter.status ?? undefined} onChange={value => {
                        appStore.update(s => {
                           s.visualisationList.status = value;
                        });
                    }} allowClear={true}>
                        {getStatusList().map(s => <Select.Option value={s.id} key={s.id}>{s.name}</Select.Option>)}
                    </Select>
                    {user.permissions.userFilter ?
                    <Select mode="multiple" placeholder="Opiekun" style={{ width: 250 }} value={filter.users} options={designerUsers} loading={usersLoading} onChange={value => {
                        appStore.update(s => {
                           s.visualisationList.users = value;
                        });
                    }}
                        filterOption={filterByLabel}
                        allowClear={true}>
                    </Select> : null}
                    <Input style={{width: 200}} placeholder="Szukaj"
                        value={filter.search ?? ''}
                        onChange={e => {
                            appStore.update(s => {
                                s.visualisationList.search = e.target.value
                            });
                        }}
                    />
                </Space>
            </>
        }
    />
}
