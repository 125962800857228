import { useNavigate } from "react-router-dom";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { notification } from "antd";
import { baseUrl } from "../../utils/navigation";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Query } from "./project-query";
import { Model } from "../../models/db";

export const useSaveProject = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation(async (project: Model.Project.Data) => {
        const projectId = await (project.id
            ? backendPut<number>(`/projects/${project.id}`, project)
            : backendPost<number>('/projects', project)
        );
        notification.success({
            message: 'Projekt został zapisany'
        });
        if (projectId) {
            navigate(baseUrl(`/projects/${projectId}/data`));
        }
        const id = project.id ?? projectId;
        void queryClient.invalidateQueries([Query.Project.key(id)]);
        void queryClient.invalidateQueries([Query.Project.Documents.key(id)]);
        void queryClient.invalidateQueries([Query.Project.Statuses.key(id)]);
    });
}

export const useMoveProjectToRealisation = () => {
    const queryClient = useQueryClient();
    return useMutation(async (projectId: number) => {
        await backendPost(`/projects/${projectId}/realise`);
        void queryClient.invalidateQueries([Query.Project.key(projectId)]);
        void queryClient.invalidateQueries([Query.Project.Statuses.key(projectId)])
        notification.success({
            key: `project-${projectId}`,
            message: 'Projekt został przekazany do realizacji',
            duration: 0
        });
    });
}

export const useMarkProjectAsRealised = () => {
    const queryClient = useQueryClient();
    return useMutation(async (projectId: number) => {
        await backendPost(`/projects/${projectId}/mark-as-realised`);
        void queryClient.invalidateQueries([Query.Project.key(projectId)]);
        void queryClient.invalidateQueries([Query.Project.Statuses.key(projectId)])
        notification.success({
            key: `project-${projectId}`,
            message: 'Projekt został przekazany do APH',
            duration: 0
        });
    });
}

export const useRemoveProject = () => {
    const queryClient = useQueryClient();
    return useMutation(async (projectId: number) => {
        await backendDelete(`/projects/${projectId}`);
        void queryClient.invalidateQueries([Query.Projects.key()]);
        notification.success({
            message: 'Projekt został usunięty'
        });
    });
}
