import React, { useEffect, useState } from "react";
import { Button, Col, Descriptions, Form, Input, Row, Select, Space, Spin, Timeline, Typography, } from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatDate, formatDatetime, } from "../../utils/utils";
import { RcFile } from "antd/lib/upload";
import { useUser } from "../../hooks/user.hook";
import { FileData, FileUploader } from "../../components/file-uploader";
import { useMarkProjectAsRealised, useMoveProjectToRealisation, useSaveProject } from "./project.hook";
import { getStatusList, getStatusLocale } from "../../components/project-status";
import { MarkdownEditor } from "../../components/markdown-editor";
import { PopoverForm } from "../../components/popover-form";
import { FooterGap, Gap, Hr } from "../../components/gap";
import { AssemblyParametersPreview } from "../assembly-parameters/assembly-parameters-preview";
import { OfferSelect } from "../../components/selects/offer-select";
import { AssemblyParametersSelect } from "../../components/selects/assembly-parameters-select";
import { defaultLabelCol, wrapperCol } from "../../models/ui";
import { Query } from "./project-query";
import { Model } from "../../models/db";

interface ProjectDataProps {
    projectId?: number;
}

export const ProjectData = ({ projectId }: ProjectDataProps) => {
    const user = useUser();
    const [form] = useForm<Model.Project.Data>();
    const [fileList, setFileList] = useState<FileData[]>([]);

    const [clientId, setClientId] = useState<number | null>(null);
    const [offerAccepted, setOfferAccepted] = useState<boolean>(false);

    const { data: project, loading: projectLoading } = Query.Project.use(projectId);

    const assemblyParametersId = Form.useWatch('assemblyParametersId', form) ?? project?.assemblyParametersId;

    const { data: projectDocuments, loading: projectDocumentsLoading } = Query.Project.Documents.use(projectId);
    const { data: projectStatuses, loading: projectStatusesLoading } = Query.Project.Statuses.use(projectId);
    const { data: offers, loading: offersLoading } = Query.Project.Offers.use(projectId);
    const { data: assemblies, loading: assembliesLoading } = Query.Project.Assemblies.use(projectId, project?.assemblyParametersId, clientId);

    const { mutateAsync: saveProject, isLoading: projectSaving } = useSaveProject();
    const { mutateAsync: moveProjectToRealisation, isLoading: movingProjectToRealisation } = useMoveProjectToRealisation();
    const { mutateAsync: markProjectAsRealised, isLoading: markingProjectAsRealised } = useMarkProjectAsRealised();

    useEffect(() => {
        if (project) {
            form.setFieldsValue(project);
            setClientId(project.clientId);
            setOfferAccepted(project.offerAccepted);
        }
    }, [project]);

    useEffect(() => {
        if (projectDocuments) {
            setFileList(projectDocuments.map(doc => ({
                id: doc.id,
                uid: `${doc.id}`,
                name: doc.filename,
                group: doc.documentGroup,
                fileData: '',
                fileName: doc.internalFilename,
                originFileObj: {} as RcFile,
                url: doc.internalFilename
            })));
        }
    }, [projectDocuments]);

    const permissions = user.permissions.project(
        projectId,
        project?.status,
        [project?.userId, project?.ownerId, project?.offerOwnerId]
    );

    const moveToRealisationWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Przekazujesz projekt do realizacji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const markAsRealisedWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Przekazujesz projekt do APH.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const getProjectData = (values: Model.Project.Data) => ({
        id: projectId,
        name: values.name ?? project?.name,
        offerId: values.offerId ?? project?.offerId,
        assemblyParametersId: values.assemblyParametersId ?? project?.assemblyParametersId ?? null,
        status: projectId ? values.status : 'Data completing',
        comment: values.comment,
        attrs: values.attrs ?? {},
        documents: fileList.map((f: FileData) => ({
            id: f.id,
            fileData: f.fileData,
            filename: f.name,
            documentGroup: f.group
        }))
    } as Model.Project.Data);

    return <Spin spinning={projectLoading || projectStatusesLoading || projectDocumentsLoading || projectSaving || offersLoading || assembliesLoading}>
        <Form
            form={form}
            labelCol={defaultLabelCol}
            wrapperCol={wrapperCol}
            layout={"vertical"}
            onValuesChange={values => {
                if (values.offerId !== undefined && values.offerId !== null) {
                    const offer = offers?.find(o => o.id === values.offerId);
                    if (offer?.clientId) {
                        setClientId(offer.clientId);
                        setOfferAccepted(offer.accepted);
                        form.setFieldsValue({
                            assemblyParametersId: null
                        });
                    }
                }
            }}
            onFinish={values => {
                saveProject(getProjectData(values));
            }}
            name="project">
            <Descriptions bordered style={{ backgroundColor: '#fff' }} labelStyle={{ width: 120 }}>
                {projectId ? <Descriptions.Item label="Opiekun" span={3}>{project?.owner}</Descriptions.Item> : null}
                <Descriptions.Item label="Oferta" span={3}>
                    {permissions.edit ? <Form.Item
                        name="offerId"
                        rules={[{ required: true }]}
                    >
                        <OfferSelect offers={offers} canEdit={true}/>
                    </Form.Item> : <>
                        {project?.offer}
                        <Hr/>
                        <div>{formatDate(project?.offerCreatedAt)}</div>
                        <div style={{ fontSize: 11, color: '#cc7b26' }}>{project?.offerOwner}</div>
                    </>}
                </Descriptions.Item>
                <Descriptions.Item label="Nazwa" span={3}>
                    {permissions.edit ? <Form.Item name="name" rules={[{ required: true }]}>
                        <Input/>
                    </Form.Item> : <>
                        {project?.name}
                    </>}
                </Descriptions.Item>
                <Descriptions.Item label="Arkusz ustaleń montażowych" span={3}>
                    {permissions.edit ? <Form.Item
                        name="assemblyParametersId"
                        rules={[{ required: true }]}
                    >
                        <AssemblyParametersSelect assemblies={assemblies} canEdit={true}/>
                    </Form.Item> : <>
                        {project?.assemblyParametersName}
                        <Hr/>
                        <div>{formatDate(project?.assemblyParametersCreatedAt)}</div>
                        <div style={{ fontSize: 11, color: '#cc7b26' }}>{project?.assemblyParametersOwner}</div>
                        <Gap/>
                    </>}
                    {assemblyParametersId ? <AssemblyParametersPreview
                        assemblyParametersId={assemblyParametersId}
                        projectId={projectId}/> : null}
                </Descriptions.Item>
                {projectId ? <Descriptions.Item label="Status" span={3}>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        {permissions.editStatus ?
                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                             <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Form.Item name="status" noStyle>
                                    <Select style={{ width: "100%" }} disabled={!permissions.changeStatus}>
                                        {getStatusList().map(s => <Select.Option
                                            value={s.id}
                                            key={s.id}
                                            disabled={s.id === 'Completed'}>
                                            {s.name}
                                        </Select.Option>)}
                                    </Select>
                                </Form.Item>
                                <br/>
                                <Form.Item name="comment" label="Komentarz do nowego statusu">
                                    <MarkdownEditor height={100} preview={true}/>
                                </Form.Item>
                            </div>
                        </div> : null}
                        {!permissions.editStatus ?
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Form.Item noStyle initialValue={project?.status}>
                                <div>{getStatusLocale(project?.status)}</div>
                                <div style={{ fontSize: 11, color: '#cc7b26' }}>
                                    {formatDate(project?.statusUpdatedAt)}
                                </div>
                            </Form.Item>
                        </div> : null}
                        <div style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", marginLeft: 10 }}>
                            <div style={{ marginBottom: 30 }}/>
                            <Timeline mode="left" style={{ width: "100%", marginTop: 10 }} items={
                                projectStatuses?.map((status, idx) => ({
                                    key: `status/${idx}`,
                                    label: formatDatetime(status.createdAt),
                                    color: idx === projectStatuses?.length - 1 ? "green" : "gray",
                                    children: getStatusLocale(status.status)
                                }))
                            }/>
                        </div>
                    </div>
                </Descriptions.Item> : null}
                <Descriptions.Item label="Dokumenty" span={3}>
                    <FileUploader group="project" fileList={fileList} setFileList={setFileList} canEdit={permissions.editDocuments}/>
                </Descriptions.Item>
            </Descriptions>
            <FooterGap/>
            <Row className="footer-actions">
                <Col span={24}>
                    <Space>
                        {projectId && permissions.showSendToRealisation ? <PopoverForm
                            title="Przekaż do realizacji"
                            buttonEnabled={permissions.sendToRealisation && offerAccepted}
                            showPopover={permissions.sendToRealisation && offerAccepted}
                            loading={movingProjectToRealisation}
                            onSubmit={() => {
                                form.validateFields().then(() => {
                                    saveProject(getProjectData(form.getFieldsValue()))
                                        .then(() => moveProjectToRealisation(projectId));
                                })
                            }}>
                            {moveToRealisationWarning}
                        </PopoverForm> : null}
                        {projectId && permissions.showMarkAsRealised ? <PopoverForm
                            title="Przekaż do APH"
                            buttonEnabled={permissions.markAsRealised}
                            showPopover={permissions.markAsRealised}
                            loading={markingProjectAsRealised}
                            onSubmit={() => {
                                form.validateFields().then(() => {
                                    saveProject(getProjectData(form.getFieldsValue()))
                                        .then(() => markProjectAsRealised(projectId));
                                })
                            }}>
                            {markAsRealisedWarning}
                        </PopoverForm> : null}
                        <Button type="primary" htmlType="submit" disabled={!permissions.save}>
                            Zapisz
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Spin>
}
