import { Button, Select, Space, Tooltip } from "antd";
import { filterBySearch } from "../../utils/utils";
import React from "react";
import { OfferItem } from "./offer-item";
import { ArrowRightOutlined } from "../icons";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/user.hook";
import { baseUrl } from "../../utils/navigation";
import { getCalculatorTypeDesc } from "../../models/model";

export const OfferSelect = (
    { offers, canEdit, ...props }: {
        offers: { id: number, name: string, calculatorType: string, accepted: boolean, clientName: string }[] | undefined,
        canEdit: boolean
    }) => {
    const { permissions } = useUser();
    const navigate = useNavigate();
    const value = (props as any).value;
    return <Space className="select">
        <Select disabled={!canEdit} showSearch={true}
            allowClear={true} filterOption={filterBySearch} {...props}>
            {offers?.map(o => <Select.Option value={o.id} key={o.id} search={o.name + o.clientName + o.id}>
                <OfferItem
                    id={o.id}
                    name={o.name}
                    accepted={o.accepted}
                    clientName={o.clientName}
                    calculatorType={getCalculatorTypeDesc(o.calculatorType)}/>
            </Select.Option>)}
        </Select>
        {permissions.panel.offers ? <Tooltip title="Przejdź do oferty" placement="topLeft">
            <Button disabled={!value} icon={<ArrowRightOutlined/>} onClick={() => navigate(baseUrl(`/offers/${value}`))}/>
        </Tooltip> : null}
    </Space>

}
