import { camelCase } from "lodash";

export enum QueryFieldType {
    INTEGER,
    STRING,
    BOOLEAN,
    CUSTOM,
    EXPRESSION
}

class QueryText {
    protected query: string
    constructor(query: string) {
        this.query = query;
    }
}

export type QueryRecord<T> = {
    [K in keyof T]: (
        T[K] extends QueryField<infer F> ? F :
            (T[K] extends QueryFieldNull<infer F> ? F | null :
                (T[K] extends string ? string : (T[K] extends boolean ? boolean : number)))
        )
}


export function queryFieldToString(field: QueryFieldBase<any>, tableName?: string) {
    const fieldAlias = field.alias ? ' as ' + `"${field.alias}"` : '';
    return field.fieldType === QueryFieldType.EXPRESSION
        ? `${field.columnName}${fieldAlias}`
        : `${tableName ?? field.tableAlias ?? field.tableName}.${field.columnName}${fieldAlias}`;
}

export function getQueryFieldVal(field: QueryFieldAny<any>, tableName?: string): string | undefined {
    if (field instanceof QueryFieldBase) {
        return queryFieldToString(field, tableName);
    } else if (field instanceof Date) {
        return `'${field.toUTCString()}'`;
    } else if (typeof(field) === 'string') {
        if (field.startsWith('select')) {
            return `(${field})`;
        }
        return `'${field}'`;
    }
    return field?.toString();
}

function isQueryFieldEnabled(field: QueryFieldAny<any>): boolean {
    if (field instanceof QueryField) {
        return field.isEnabled();
    }
    return true;
}

function getQueryTableVal(table: QueryTable | QueryFieldBase<any>, alias?: string | QueryTable): string {
    const tableName = table.tableName!;
    const tableAlias = alias ? (alias instanceof QueryTable ? alias.tableAlias : alias) : table.tableAlias;
    return tableAlias ? `${tableName} ${tableAlias}` : tableName;
}

function applyCond(args: IArguments, applyIdx: number, apply?: any): boolean {
    return !!((args.length < applyIdx + 1) || ((args.length > applyIdx - 1) && (apply || apply === 0)));
}

class QueryCondPart extends QueryText {
    or(cond: QueryCondPart) {
        return new QueryCondPart(this.query + ` or (${cond.query})`);
    }

    and(cond: QueryCondPart) {
        return new QueryCondPart(this.query + ` and (${cond.query})`);
    }

    asBool() {
        return new QueryField<boolean>(QueryFieldType.EXPRESSION, this.query);
    }

    cast(castType: string) {
        return new QueryField(QueryFieldType.EXPRESSION, `(${this.query})::${castType}`);
    }

    constructor(query: string) {
        super(query);
    }
}

type QueryFieldAny<T> = QueryField<T> | QueryFieldNull<T> | QueryRecord<any> | string | number | Date | null | undefined;

export class QueryFieldBase<T extends any> {
    fieldType: QueryFieldType;
    columnName: string;
    tableName: string | undefined;
    tableAlias: string | undefined;
    alias: string | undefined;
    private readonly apply: boolean | undefined | null;
    private readonly baseName: string;
    private readonly fullName: string;

    constructor(fieldType: QueryFieldType, columnName: string, tableName?: string, tableAlias?: string, alias?: string, apply?: boolean | null) {
        this.fieldType = fieldType;
        this.columnName = columnName;
        this.tableName = tableName;
        this.tableAlias = tableAlias;
        this.alias = alias;
        this.apply = apply;
        this.baseName = camelCase(columnName);
        const name = tableAlias ?? tableName;
        this.fullName = name ? `${name}.${this.columnName}` : this.columnName;
    }

    of(alias: string): QueryField<T> {
        return new QueryField<T>(this.fieldType, this.columnName, this.tableName, this.tableAlias, alias);
    }

    as(alias: string): QueryField<T> {
        return new QueryField<T>(this.fieldType, this.columnName, this.tableName, this.tableAlias, alias);
    }

    cast(castType: string): QueryField<T> {
        return new QueryField<T>(QueryFieldType.EXPRESSION, `(${getQueryFieldVal(this)})::${castType}`);
    }

    eq(field: QueryFieldAny<T>): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} = ${getQueryFieldVal(field)}`);
    }

    neq(field: QueryFieldAny<T>): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} <> ${getQueryFieldVal(field)}`);
    }

    lt(field: QueryFieldAny<T>): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} < ${getQueryFieldVal(field)}`);
    }

    gt(field: QueryFieldAny<T>): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} > ${getQueryFieldVal(field)}`);
    }

    lte(field: QueryFieldAny<T>): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} <= ${getQueryFieldVal(field)}`);
    }

    gte(field: QueryFieldAny<T>): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} >= ${getQueryFieldVal(field)}`);
    }

    in(fields: QueryFieldAny<T>[]): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} in (${fields.map(f => getQueryFieldVal(f)).join(",")})`);
    }

    notIn(fields: QueryFieldAny<T>[]): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} not in (${fields.map(f => getQueryFieldVal(f)).join(",")})`);
    }

    isNull(): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} is null`);
    }

    isNotNull(): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} is not null`);
    }

    isTrue(): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} = true`);
    }

    isFalse(): QueryCondPart {
        return new QueryCondPart(`${getQueryFieldVal(this)} = false`);
    }

    nvl(field: QueryField<T> | string | number): QueryField<T> {
        return new QueryField<T>(QueryFieldType.EXPRESSION, `coalesce(${getQueryFieldVal(this)}, ${getQueryFieldVal(field)})`);
    }

    when(cond: boolean | undefined | null) {
        return new QueryField<T>(this.fieldType, this.columnName, this.tableName, this.tableAlias, this.alias, cond);
    }

    isEnabled() {
        return this.apply === undefined || this.apply === null || this.apply;
    }

    get name() {
        return this.baseName;
    }

    get qualifiedName() {
        return this.fullName;
    }

}

export class QueryField<T> extends QueryFieldBase<T> {
    nullable() : QueryFieldNull<T> {
        return new QueryFieldNull<T>(this.fieldType, this.columnName, this.tableName, this.tableAlias, this.alias);
    }

    constructor(fieldType: QueryFieldType, columnName: string, tableName?: string, tableAlias?: string, alias?: string, apply?: boolean | null) {
        super(fieldType, columnName, tableName, tableAlias, alias, apply);
    }
}

export class QueryFieldNull<T> extends QueryFieldBase<T | null> {
    constructor(fieldType: QueryFieldType, columnName: string, tableName?: string, tableAlias?: string, alias?: string, apply?: boolean | null) {
        super(fieldType, columnName, tableName, tableAlias, alias, apply);
    }
}

export class QueryTable {
    tableName: string
    tableAlias: string | undefined

    number(columnName: string, apply: boolean = true): QueryField<number> {
        return new QueryField<number>(QueryFieldType.INTEGER, columnName, this.tableName, this.tableAlias);
    }

    nullNumber(columnName: string, apply: boolean = true): QueryFieldNull<number> {
        return new QueryFieldNull<number>(QueryFieldType.INTEGER, columnName, this.tableName, this.tableAlias);
    }

    string(columnName: string): QueryField<string> {
        return new QueryField<string>(QueryFieldType.STRING, columnName, this.tableName, this.tableAlias);
    }

    boolean(columnName: string): QueryField<boolean> {
        return new QueryField<boolean>(QueryFieldType.BOOLEAN, columnName, this.tableName, this.tableAlias);
    }

    custom<T>(columnName: string): QueryField<T> {
        return new QueryField<T>(QueryFieldType.CUSTOM, columnName, this.tableName, this.tableAlias);
    }

    as<T extends QueryTable>(alias: T): T {
        return alias;
    }

    constructor(tableName: string, alias?: string) {
        this.tableName = tableName
        this.tableAlias = alias
    }
}

class QueryWherePart extends QueryText {
    private conds: string = "";
    private orders: string = "";

    private getCondVal(cond: QueryCondPart | string): string {
        if (cond instanceof QueryCondPart) {
            return cond['query'];
        }
        return cond;
    }

    where(cond: QueryCondPart | string, apply?: any) {
        if (applyCond(arguments, 1, apply)) {
            const condVal = this.getCondVal(cond);
            this.conds = this.conds ? `(${this.conds}) and (${condVal})` : condVal;
        }
        return this;
    }

    and(cond: QueryCondPart | string, apply?: any) {
        // cannot reuse where function as arguments would be passed with apply as undefined
        if (applyCond(arguments, 1, apply)) {
            const condVal = this.getCondVal(cond);
            this.conds = this.conds ? `(${this.conds}) and (${condVal})` : condVal;
        }
        return this;
    }

    or(cond: QueryCondPart | string, apply?: any) {
        if (applyCond(arguments, 1, apply)) {
            const condVal = this.getCondVal(cond);
            this.conds = this.conds ? `(${this.conds}) or (${condVal})` : condVal;
        }
        return this;
    }

    orderBy(field: QueryField<any>, sortDirection: number = 1, defaultField?: QueryField<any>) {
        const fld = field ?? defaultField;
        if (fld) {
            return sortDirection === 1
                ? this.orderByAsc(field)
                : this.orderByDesc(field);
        }
        return this;

    }

    orderByAlias(alias: string | null | undefined, sortDirection: number = 1) {
        if (alias) {
            const direction = sortDirection === 1 ? "asc" : "desc";
            this.orders += (this.orders ? ', ' : '') + `"${alias}" ${direction}`;
        }
        return this;
    }

    orderByAsc(field: QueryField<any>) {
        this.orders += (this.orders ? ', ' : '') + `${getQueryFieldVal(field)} asc`;
        return this;
    }

    orderByDesc(field: QueryField<any>) {
        this.orders += (this.orders ? ', ' : '') + `${getQueryFieldVal(field)} desc`;
        return this;
    }

    private makeQuery(fields: string) {
        return `select ${fields} `
            + this.query
            + (this.conds ? ` where ${this.conds}` : '')
            + (this.orders ? ` order by ${this.orders}` : '');
    }

    select<T extends { [column: string]: QueryField<any> | QueryFieldNull<any> | number | string }>(fields: T): QueryRecord<T> {
        const query = this.makeQuery(Object.keys(fields)
        .filter(f => isQueryFieldEnabled(fields[f]))
        .map(f => {
            const fieldName = getQueryFieldVal(fields[f])
            return `${fieldName} as "${f}"`
        }).join(", "));

        return query as QueryRecord<T>;
    }

    selectDistinctOn<T extends { [column: string]: QueryField<any> | QueryFieldNull<any> | number | string }>(distinctFields: QueryField<any>[], fields: T): QueryRecord<T> {
        const distinctOn = `distinct on (${distinctFields.map(f => getQueryFieldVal(f)).join(", ")}) `
        const query = this.makeQuery(distinctOn + Object.keys(fields).map(f => {
            const fieldName = getQueryFieldVal(fields[f])
            return `${fieldName} as "${f}"`
        }).join(", "));

        return query as QueryRecord<T>;
    }

}

class QueryFromPart extends QueryWherePart {
    innerJoin(table: QueryTable, alias?: string | QueryTable, apply?: any): QueryJoinPart {
        const doApply = applyCond(arguments, 2, apply);
        return new QueryJoinPart(this.query + (doApply ? ` inner join ${getQueryTableVal(table, alias)}` : ''), doApply);
    }

    leftJoin(table: QueryTable, alias?: string | QueryTable, apply?: any): QueryJoinPart {
        const doApply = applyCond(arguments, 2, apply);
        return new QueryJoinPart(this.query + (doApply ? ` left join ${getQueryTableVal(table, alias)}` : ''), doApply);
    }

    rightJoin(table: QueryTable, alias?: string | QueryTable, apply?: any): QueryJoinPart {
        const doApply = applyCond(arguments, 2, apply);
        return new QueryJoinPart(this.query + (doApply ? ` right join ${getQueryTableVal(table, alias)}` : ''), doApply);
    }

    join(leftField: QueryFieldBase<any>, rightSide: QueryFieldBase<any>, apply?: any): QueryFromPart {
        const doApply = applyCond(arguments, 2, apply);
        return new QueryFromPart(this.query + (doApply ? ` left join ${getQueryTableVal(leftField, leftField.tableAlias)} on ${getQueryFieldVal(leftField)} = ${getQueryFieldVal(rightSide)}` : ''));
    }


    constructor(query: string) {
        super(query);
    }
}

class QueryJoinPart extends QueryText {
    private readonly apply: boolean;

    on(cond: QueryCondPart): QueryFromPart {
        return new QueryFromPart(this.query + (this.apply ? " on " + cond['query'] : ''));
    }

    constructor(query: string, apply: boolean) {
        super(query);
        this.apply = apply;
    }
}

export function from(table: QueryTable, alias?: string | QueryTable) {
    return new QueryFromPart(`from ${getQueryTableVal(table, alias)}`);
}
