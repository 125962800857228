import React, { useState } from "react";
import { Button, Modal } from "antd";
import { InstallationCalendar } from "./installation-calendar";

const CALENDAR_MODAL_WIDTH = 1150;

export const InstallationCalendarSelect = ({ crewMode, crewId, realisationId, disabled, onClose }: {
    crewMode?: boolean, crewId?: number, realisationId?: number, disabled?: boolean,
    onClose: () => void
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    // needed to remove modal element completely to fix full calendar reopening (it has now width)

    return <>
        <Button disabled={disabled}
            onClick={() => {
                setModalOpen(true);
            }}>Edytuj terminy</Button>
        <Modal
            destroyOnClose={true}
            title="Kalendarz ekipy montażowej"
            centered
            open={modalOpen}
            width={CALENDAR_MODAL_WIDTH}
            closable={true}
            footer={<Button onClick={() => setModalOpen(false)}>Zamknij</Button>}
            afterClose={() => {
                onClose();
            }}
            onCancel={() => setModalOpen(false)}>
            <div style={{ height: 'calc(100vh - 280px)' }}>
                <InstallationCalendar
                    crewMode={crewMode}
                    crewId={crewId}
                    realisationId={realisationId}
                    embedded={true}
                />
            </div>
        </Modal>
    </>
}
