import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Space,
    Spin,
    Tabs,
    Timeline,
    Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { formatDate, formatDatetime, formatDatetimeRange, } from "../../utils/utils";
import { getStatusList, getStatusLocale } from "../../components/realisation-status";
import { RcFile } from "antd/lib/upload";
import { useUser } from "../../hooks/user.hook";
import range from "lodash/range";
import { MarkdownEditor } from "../../components/markdown-editor";
import { FileData, FileUploader } from "../../components/file-uploader";
import {
    useMoveRealisationToCommission,
    useMoveRealisationToInstallation,
    useSaveRealisation
} from "./realisation.hook";
import { FooterGap, Hr } from "../../components/gap";
import { Model, t } from "../../models/db";
import { Link } from "react-router-dom";
import { DecimalNumber } from "../../components/decimal-number";
import { baseUrl } from "../../utils/navigation";
import { PopoverForm } from "../../components/popover-form";
import { InstallationCalendarSelect } from "../installation-calendar/installation-calendar-select";
import { useQueryClient } from "@tanstack/react-query";
import { defaultLabelCol } from "../../models/ui";
import { RealisationTasks } from "./realisation-tasks";
import { isEmpty } from "lodash";
import { Statistic } from "antd/es";
import { NumericInput } from "../../components/numeric-input";
import isNil from "lodash/isNil";
import { backendPost } from "../../api/backend-api";
import { useDebouncedEffect } from "../../hooks/debaunce.hook";
import dayjs from "dayjs";
import { Query } from "./realisation-query";

interface RealisationDataProps {
    realisationId: number;
    realisation: Query.Realisation.T | undefined,
    realisationLoading: boolean
}

export const RealisationData = ({ realisationId, realisation, realisationLoading }: RealisationDataProps) => {
    const user = useUser();
    const queryClient = useQueryClient();

    const { data: documents, loading: documentsLoading } = Query.Realisation.Documents.use(realisationId);
    const { data: statuses, loading: statusesLoading } = Query.Realisation.Statuses.use(realisationId);
    const { data: payments, loading: paymentsLoading } = Query.Realisation.Payments.use(realisationId);
    const { data: installationTasks, loading: installationTasksLoading } = Query.Realisation.InstallationTasks.use();
    const { data: calendarEvents, loading: calendarEventsLoading } = Query.Realisation.CalendarEvents.use(realisationId);

    const permission = user.permissions.realisation(realisationId,
        realisation?.status,
        [realisation?.ownerId, realisation?.agreementOwnerId, realisation?.coordinatorId],
        !!realisation?.installation,
        !!realisation?.commission
    );

    const [form] = useForm<Model.Realisation.Data>();
    const [verificationFileList, setVerificationFileList] = useState<FileData[]>([]);
    const [installationFileList, setInstallationFileList] = useState<FileData[]>([]);
    const [commissionsFileList, setCommissionsFileList] = useState<FileData[]>([]);

    const realisationInInstallation = realisation?.status === 'Submitted for installation';
    const realisationInCommission = realisation?.status === 'Submitted for commission';

    const [editInstallationTasks, setEditInstallationTasks] = useState(false);

    const { mutateAsync: saveRealisation, isLoading: realisationSaving } = useSaveRealisation();
    const { mutateAsync: moveRealisationToInstallation, isLoading: movingRealisationToInstallation } = useMoveRealisationToInstallation();
    const { mutateAsync: moveRealisationToCommission, isLoading: movingRealisationToCommission } = useMoveRealisationToCommission();

    useEffect(() => {
        if (realisation && documents && payments) {
            form.setFieldsValue({
                ...realisation,
                commissionPaymentDate: realisation.commissionPaymentDate ? dayjs(realisation.commissionPaymentDate) : null,
                payments: payments.map(rp => ({
                    ...rp,
                    paidAt: rp.paidAt ? dayjs(rp.paidAt) : null,
                    expectedAt: rp.expectedAt ? dayjs(rp.expectedAt) : null
                }))
            });
            const docs = documents
                .map(doc => ({
                    id: doc.id,
                    uid: `${doc.id}`,
                    name: doc.filename,
                    group: doc.documentGroup,
                    fileData: '',
                    fileName: doc.internalFilename,
                    originFileObj: {} as RcFile,
                    url: doc.internalFilename
                }));
            setVerificationFileList(docs.filter(doc => doc.group.startsWith('realisation-verification')));
            setInstallationFileList(docs.filter(doc => doc.group === 'realisation-installation'));
            setCommissionsFileList(docs.filter(doc => doc.group === 'realisation-commissions'));
            setCommissionValue(realisation.commissionValue);
        }
    }, [realisation, documents, payments]);

    const paymentCount = Math.max(
        (realisation?.templateFlags ?? 0) === 1 ? 2 : 1,
        payments?.length ?? 0
    );

    const getRealisationData = (values: Model.Realisation.Data) => {
        return {
            id: realisationId,
            agreementId: realisation!!.agreementId,
            status: values.status,
            comment: values.comment,
            installationTaskList1: values.installationTaskList1,
            installationTaskListName1: values.installationTaskListName1,
            installationTaskList2: values.installationTaskList2,
            installationTaskListName2: values.installationTaskListName2,
            installationTaskList3: values.installationTaskList3,
            installationTaskListName3: values.installationTaskListName3,
            installationTaskList4: values.installationTaskList4,
            installationTaskListName4: values.installationTaskListName4,
            installationTaskList5: values.installationTaskList5,
            installationTaskListName5: values.installationTaskListName5,
            commissionCorrectionType: values.commissionCorrectionType,
            commissionCorrection: values.commissionCorrection,
            commissionPaymentDate: values.commissionPaymentDate,
            documents: verificationFileList
                .concat(installationFileList)
                .concat(commissionsFileList)
                .map((f: FileData) => ({
                    id: f.id,
                    fileData: f.fileData,
                    filename: f.name,
                    documentGroup: f.group
                })),
            payments: values.payments.map((p, idx: number) => {
                const rp = payments ? payments[idx] : undefined;
                return {
                    ...p,
                    id: rp?.id,
                }
            })
        } as Model.Realisation.Data;
    }

    const moveToInstallationWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Przekazujesz realizację do montażu.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const moveToCommissionWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Przekazujesz realizację do rozliczenia prowizji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const realisationTasks = range(1, 6).map(n => {
        const tasks = form.getFieldValue(`installationTaskList${n}`)
        const label = form.getFieldValue(`installationTaskListName${n}`);
        const key = (editInstallationTasks || !isEmpty(tasks) || !isEmpty(label))
            ? `rt-${n}`
            : ''
        return {
            label: label ?? `Lista zadań ${n}`,
            key,
            style: { border: "1px solid #f0f0f0", borderTop: 'none', padding: 16 },
            children: <RealisationTasks
                fieldNumber={n}
                canEdit={editInstallationTasks}
                canEditView={permission.editInstallationTaskTemplate}
                form={form}
                installationTasks={installationTasks}/>
        }
    }).filter(tab => tab.key !== '');

    const commissionCorrectionType = Form.useWatch('commissionCorrectionType', form);
    const commissionCorrection = Form.useWatch('commissionCorrection', form);
    const [commissionValue, setCommissionValue] = useState(0);

    useDebouncedEffect(() => {
        if (realisation && !isNil(commissionCorrectionType) && !isNil(commissionCorrection) &&
            (commissionCorrectionType != realisation.commissionCorrectionType ||
            commissionCorrection != realisation.commissionCorrection)
        ) {
            backendPost(`realisations/commission-preview`, {
                mainValue: realisation.mainValue,
                rate: realisation.commissionRate,
                correctionType: commissionCorrectionType,
                correction: commissionCorrection || 0
            }).then(commissionValue => {
                setCommissionValue(commissionValue);
            });
        } else {
            setCommissionValue(realisation?.commissionValue ?? 0);
        }
    }, 500, [realisation, commissionCorrectionType, commissionCorrection]);

    const spinning = realisationLoading || statusesLoading || documentsLoading ||
        paymentsLoading || realisationSaving ||installationTasksLoading || calendarEventsLoading ||
        movingRealisationToInstallation;

    return <Spin spinning={spinning}>
        <Form
            form={form}
            labelCol={{}}
            wrapperCol={{}}
            layout={"vertical"}
            onFinish={values => {
                void saveRealisation(getRealisationData(values));
            }}
            name="realisation">
            <Descriptions bordered style={{ backgroundColor: '#fff' }} labelStyle={{ width: 120 }}>
                <Descriptions.Item label="Opiekun" span={3}>{realisation?.owner}</Descriptions.Item>
                <Descriptions.Item label="Umowa">
                    <InternalLink
                        id={realisation?.agreementId}
                        name={realisation?.agreement}
                        link={baseUrl(`/agreements/${realisation?.agreementId}`)}
                        hasAccess={user.permissions.panel.agreements}/>
                    <Hr/>
                    <div>{formatDate(realisation?.agreementCreatedAt)}</div>
                    <div style={{ fontSize: 11, color: '#cc7b26' }}>{realisation?.agreementOwner}</div>
                </Descriptions.Item>
                <Descriptions.Item label="Oferta">
                    <InternalLink
                        id={realisation?.offerId}
                        name={realisation?.offer}
                        link={baseUrl(`/offers/${realisation?.offerId}`)}
                        hasAccess={user.permissions.panel.offers}/>
                    <Hr/>
                    <div>{formatDate(realisation?.offerCreatedAt)}</div>
                    <div style={{ fontSize: 11, color: '#cc7b26' }}>{realisation?.offerOwner}</div>
                </Descriptions.Item>
                <Descriptions.Item label="Klient">
                    <InternalLink
                        id={realisation?.clientId}
                        name={realisation?.client}
                        link={baseUrl(`/clients/${realisation?.clientId}`)}
                        hasAccess={user.permissions.panel.clients}/>
                    <Hr/>
                    <div>{formatDate(realisation?.clientCreatedAt)}</div>
                    <div style={{ fontSize: 11, color: '#cc7b26' }}>{realisation?.clientOwner}</div>
                </Descriptions.Item>
                <Descriptions.Item label="Status" span={3}>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        {permission.editStatus ?
                        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                             <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: 'flex-start' }}>
                                <Form.Item name="status" noStyle>
                                    <Select style={{ width: "100%" }}>
                                        {getStatusList(user.userRole).map(s => <Select.Option
                                            value={s.id}
                                            key={s.id}
                                            disabled={s.disabled}>
                                                {s.name}
                                        </Select.Option>)}
                                    </Select>
                                </Form.Item>
                                <br/>
                                <Form.Item name="comment" label="Komentarz do nowego statusu" style={{ width: '100%' }}>
                                    <MarkdownEditor height={100} preview={true}/>
                                </Form.Item>
                            </div>
                        </div> : null}
                        {!permission.editStatus ?
                        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Form.Item name="status" noStyle initialValue={realisation?.status}>
                                <>
                                    <div>{getStatusLocale(realisation?.status)}</div>
                                    <div style={{ fontSize: 11, color: '#cc7b26' }}>
                                        {formatDate(realisation?.statusUpdatedAt)}
                                    </div>
                                </>
                            </Form.Item>
                        </div> : null}
                        <div style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", marginLeft: 10 }}>
                            <div style={{ marginBottom: 30 }}/>
                            <Timeline mode="left" style={{ width: "100%", marginTop: 10 }} items={
                                 statuses?.map((status, idx) => ({
                                    key: `status/${idx}`,
                                    label: formatDatetime(status.createdAt),
                                    color: idx === statuses?.length - 1 ? "green" : "gray",
                                    children: getStatusLocale(status.status)
                                 }))
                            }/>
                        </div>
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="Ekipy i terminy" span={3}>
                    <Timeline style={{ marginTop: 10 }} items={
                        !calendarEvents || calendarEvents.length === 0
                            ? [{ children: 'Brak wskazanych terminów' }]
                            : calendarEvents.map((event, idx) => ({
                            key: `event/${idx}`,
                            children: <Space>
                                {formatDatetimeRange(event.dateStart, event.dateEnd, false)}
                                <Divider type="vertical"/>
                                {event.crewName}
                            </Space>
                        }))
                    }/>
                    <InstallationCalendarSelect
                        crewMode={true}
                        realisationId={realisationId}
                        disabled={!permission.editCrew}
                        onClose={() => {
                            void queryClient.invalidateQueries([`calendar-events-${realisationId}`]);
                        }}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Lista zadań do wykonania" span={3}>
                    <Tabs type="card" items={realisationTasks} tabBarStyle={{ margin: 0 }} tabBarExtraContent={{
                        left: realisationTasks.length > 0 ? null : "Brak list zadań",
                        right: <Button type="link" disabled={!permission.editInstallationTaskTemplate}
                            onClick={() => setEditInstallationTasks(!editInstallationTasks)}>
                                {editInstallationTasks ? 'Zamknij' : 'Edytuj'}
                            </Button>
                    }}/>
                </Descriptions.Item>
                <Descriptions.Item label="Dokumenty" span={3}>
                    <FileUploader group="realisation-verification"
                        fileList={verificationFileList}
                        setFileList={setVerificationFileList}
                        canEdit={permission.editDocuments}/>
                </Descriptions.Item>
                <Descriptions.Item label="Dokumenty montażowe" span={3}>
                    <FileUploader group="realisation-installation"
                        fileList={installationFileList}
                        setFileList={setInstallationFileList}
                        canEdit={permission.editInstallationDocuments}/>
                </Descriptions.Item>
                {realisation?.commission ? <>
                    <Descriptions.Item label="Koszty" span={3}>
                        <Space direction="horizontal" size={36}>
                            <Statistic title={<>Wartość umowy<div style={{ fontSize: 11, color: '#cc7b26', height: 20 }}>{realisation?.agreement}</div></>} value={realisation?.mainValue} precision={2} suffix={'zł'}/>
                            <Space direction="vertical" size={0}>
                                <Statistic title={<>Prowizja<div style={{ fontSize: 11, color: '#cc7b26', height: 20 }}>{realisation?.agreementOwner}</div></>} value={realisation?.commissionValue} precision={2} suffix={'zł'}/>
                                {commissionValue !== realisation?.commissionValue ? <Statistic value={commissionValue} valueStyle={{ fontSize: 12, color: '#268ccc' }} precision={2} suffix={'zł'}/> : null}
                            </Space>
                            <Space direction="vertical" size="small">
                                <Form.Item label="Data wypłacenia prowizji" name="commissionPaymentDate" style={{ marginBottom: 0 }}>
                                    <DatePicker style={{ width: '100%' }} disabled={!permission.editCommission}/>
                                </Form.Item>
                            </Space>
                            <Space direction="vertical" size="small">
                                <Form.Item label="Rodzaj korekty" name="commissionCorrectionType" style={{ marginBottom: 0 }}>
                                    <Select disabled={!permission.editCommission}>
                                        <Select.Option value={0}>Kwotowa</Select.Option>
                                        <Select.Option value={1}>Procentowa</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Wartość korekty" name="commissionCorrection" style={{ marginBottom: 0 }}>
                                    <NumericInput disabled={!permission.editCommission}/>
                                </Form.Item>
                            </Space>
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="Dokumenty kosztowe" span={3}>
                        <FileUploader group="realisation-commissions"
                            fileList={commissionsFileList}
                            setFileList={setCommissionsFileList}
                            canEdit={permission.editCommissionsDocuments}/>
                    </Descriptions.Item>
                </> : null}
                <Descriptions.Item label="Płatności" span={3}>
                    <div style={{ display: "flex"}}>
                        {range(paymentCount).map(idx =>
                            <Card key={`${realisationId}/${idx}`} title={`Płatność ${idx + 1}`} style={{ marginRight: 20, width: 300 }}>
                                <Form.Item label="Numer faktury" name={['payments', idx, 'invoiceNumber']} labelCol={defaultLabelCol}>
                                    <Input disabled={!permission.editPayments}/>
                                </Form.Item>
                                <Form.Item label="Kwota" name={['payments', idx, 'amount']} labelCol={defaultLabelCol}>
                                    <DecimalNumber style={{ width: 200 }} disabled={!permission.editPayments}/>
                                </Form.Item>
                                <Form.Item label="Oczekiwana data płatności" name={['payments', idx, 'expectedAt']} labelCol={defaultLabelCol}>
                                    <DatePicker style={{ width: 200 }} disabled={!permission.editPayments}/>
                                </Form.Item>
                                <Form.Item label="Data dokonania płatności" name={['payments', idx, 'paidAt']} labelCol={defaultLabelCol}>
                                    <DatePicker style={{ width: 200 }} disabled={!permission.editPayments}/>
                                </Form.Item>
                                <Form.Item label="Dodatkowe informacje" name={['payments', idx, 'comment']} labelCol={defaultLabelCol}>
                                    <Input.TextArea rows={4} style={{ width: 300 }} disabled={!permission.editPayments}/>
                                </Form.Item>
                                <FileUploader group={`realisation-verification-invoice-${idx}`} fileList={verificationFileList} setFileList={setVerificationFileList} canEdit={permission.editPayments}/>
                            </Card>
                        )}
                    </div>
                </Descriptions.Item>
            </Descriptions>
            <FooterGap/>
            <Row className="footer-actions">
                <Col span={24}>
                    <Space>
                        {!realisation?.installation && !realisation?.commission ? <PopoverForm
                            title="Przekaż do montażu"
                            buttonEnabled={!realisationInInstallation && permission.edit}
                            showPopover={!realisationInInstallation}
                            loading={movingRealisationToInstallation}
                            onSubmit={() => {
                                saveRealisation(getRealisationData(form.getFieldsValue())).then(() => {
                                    void moveRealisationToInstallation(realisationId);
                                });
                            }}>
                            {moveToInstallationWarning}
                        </PopoverForm> : null}
                        {realisation?.installation && !realisation?.commission ? <PopoverForm
                            title="Przekaż do rozliczenia prowizji"
                            buttonEnabled={!realisationInCommission && permission.edit}
                            showPopover={!realisationInCommission}
                            loading={movingRealisationToCommission}
                            onSubmit={() => {
                                saveRealisation(getRealisationData(form.getFieldsValue())).then(() => {
                                    void moveRealisationToCommission(realisationId);
                                });
                            }}>
                            {moveToCommissionWarning}
                        </PopoverForm> : null}
                        <Button type="primary" htmlType="submit" disabled={!permission.save}>
                            Zapisz
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    </Spin>
}

const InternalLink = (params: { id: number | undefined, name: string | undefined, link: string | undefined, hasAccess: boolean }) => {
    return <Space size={0}>
        {params.hasAccess && params.link ? <Link to={params.link }>{params.name}</Link> : params.name}
        <Divider type="vertical" orientationMargin={3}/>
        <Typography.Text type="secondary" style={{ fontSize: 10, whiteSpace: 'nowrap' }}>{params.id}</Typography.Text>
    </Space>
}
