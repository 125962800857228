import { useNavigate } from "react-router-dom";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { notification } from "antd";
import { baseUrl } from "../../utils/navigation";
import { ExportService } from "../../api/export-service";
import { qid, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { from } from "../../utils/sql-builder";
import { Model, t } from "../../models/db";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { Query } from "./assembly-parameters-query";

export const useAssemblyParametersInfo = (assemblyParametersId?: number) => {
    const { data: assemblyParameters, loading: assemblyParametersLoading } = useSqlQuerySingle(
        qid`assembly-parameters-info-${assemblyParametersId}`,
        from(t.assemblyParameters.as(t.ap))
        .join(t.template.as(t.t).id, t.ap.templateId)
        .where(t.ap.id.eq(assemblyParametersId))
        .select({
            templateId: t.ap.templateId,
            accepted: t.ap.acceptedAt.isNotNull().asBool(),
            version: t.t.version,
            type: t.ap.type
        }), {
            enabled: assemblyParametersId
        }
    );

    return {
        assemblyParametersLoading,
        templateConnected: !!assemblyParameters?.templateId,
        assemblyParametersAccepted: !!assemblyParameters?.accepted,
        assemblyParametersType: assemblyParameters?.type,
        version: assemblyParameters?.version
    }
}

export const useAcceptAssemblyParameters = () => {
    const queryClient = useQueryClient();
    return useMutation(async (assemblyParametersId: number) => {
        const assemblyParametersAccepted = await backendPut(`/assembly-parameters/${assemblyParametersId}/accept`);
        if (assemblyParametersAccepted) {
            notification.success({
                message: 'Arkusz został zaakceptowany'
            });
        }
        refreshAssemblyParametersInfo(queryClient, assemblyParametersId);
    });
}

export const useMakeAssemblyParametersCopy = () => {
    const queryClient = useQueryClient();
    return useMutation(async (assemblyParametersId: number) => {
        await backendPost(`/assembly-parameters/${assemblyParametersId}/copy`);
        return await queryClient.invalidateQueries([`assemblies`]);
    });
}

export const useSaveAssemblyParameters = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    return useMutation(async (assemblyParameters: Model.AssemblyParameters.Data) => {
        const assemblyParametersId = await (assemblyParameters.id
            ? backendPut<void>(`/assembly-parameters/${assemblyParameters.id}`, assemblyParameters)
            : backendPost<number>('/assembly-parameters', assemblyParameters)
        );
        notification.success({
            message: 'Arkusz został zapisany'
        });
        if (assemblyParametersId) {
            navigate(baseUrl(`/assembly-parameters/${assemblyParametersId}/data`));
        }
        const id = assemblyParameters.id ?? assemblyParametersId;
        if (id) {
            void queryClient.invalidateQueries([Query.AssemblyParameters.Document.key(id)]);
            refreshAssemblyParametersInfo(queryClient, id);
        }
    })
}

export const useRemoveAssemblyParameters = () => {
    const navigate = useNavigate();
    return useMutation(async (assemblyParametersId: number) => {
        await backendDelete(`/assembly-parameters/${assemblyParametersId}`);
        notification.success({
            message: 'Arkusz został usunięty'
        });
        navigate(baseUrl("/assembly-parameters"));
    })
}

export const useExportAssemblyParameters = () => {
    return useMutation((assemblyParametersId: number) => {
        return ExportService.exportAssemblyParameters(assemblyParametersId);
    });
}


export function refreshAssemblyParametersInfo(queryClient: QueryClient, assemblyParametersId: number | undefined) {
    if (assemblyParametersId) {
        void queryClient.invalidateQueries([`assembly-parameters-info-${assemblyParametersId}`]);
    }
}
