import { InputNumber } from "antd";
import React from "react";
import { InputNumberProps } from "antd/lib";

function makeValidNumber(s: any): string {
    return s ? s.replace(/,+/g, '.').replace(/(\s|[^0-9.])/g, '') : ''
}

export const DecimalNumber = (params: InputNumberProps) => {
    return <InputNumber
        formatter={(value) => makeValidNumber(value)}
        parser={value => Number.parseFloat(makeValidNumber(value))}
        {...params}
    />
}
