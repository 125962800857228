import React from "react";
import { Alert, Checkbox, Col, Form, Input, Radio, Row, Space, Switch, Typography } from "antd";
import { Gap } from "../../components/gap";
import { FileData, FileUploader, SetFileList } from "../../components/file-uploader";
import { AssemblyParametersOperation } from "./assembly-parameters-edit";
import { DecimalNumber } from "../../components/decimal-number";
import { DescriptionsItemType } from "antd/es/descriptions";

export const getAssemblyParametersDataPvV1 = (
    disabled: boolean,
    operation: AssemblyParametersOperation,
    previewMode: boolean | undefined,
    fileList: FileData[],
    setFileList: SetFileList,
    buildingType: any,
    addressType: any,
    roofSheathing: any,
    roofMaterial: any,
    inverterConnection: any,
    attachments: any
): DescriptionsItemType[] => {

    const fieldRules = [{ required: operation === 'accept' || operation === 'export' }];

    return [{
        key: 'installation-location',
        label: 'Lokalizacja instalacji',
        children: <>
            <Form.Item name={['attrs', 'addressType']} label="Adres"
                initialValue="sameAsClient" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="sameAsClient">Taki sam jak klienta</Radio>
                    <Radio value="anotherAddress">Inny</Radio>
                </Radio.Group>
            </Form.Item>
            {addressType === "anotherAddress" ? <>
                <Form.Item name={['attrs', 'address']} label="Ulica i nr budynku / lokalu" rules={fieldRules}>
                    <Input/>
                </Form.Item>
                <Form.Item name={['attrs', 'postCode']} label="Kod pocztowy" rules={fieldRules}>
                    <Input/>
                </Form.Item>
                <Form.Item name={['attrs', 'town']} label="Miejscowość" rules={fieldRules}>
                    <Input/>
                </Form.Item>
            </> : null}
            <Form.Item name={['attrs', 'installationLocation']} label="Umiejscowienie instalacji"
                rules={fieldRules}>
                <Radio.Group>
                    <Radio value="ground">Na gruncie</Radio>
                    <Radio value="roof">Na dachu</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={['attrs', 'buildingType']} label="Rodzaj budynku" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="residential">Budynek mieszkalny</Radio>
                    <Radio value="garage">Garaż</Radio>
                    <Radio value="industrial">Budynek przemysłowy</Radio>
                    <Radio value="another">Inny rodzaj budynku</Radio>
                </Radio.Group>
            </Form.Item>
            {buildingType === 'industrial' || buildingType === 'another' ?
                <Form.Item name={['attrs', 'buildingDescription']}
                    label={buildingType === 'industrial' ? 'Zastosowanie' : (buildingType === 'another' ? 'Opis' : '')}
                    rules={fieldRules}
                >
                    <Input/>
                </Form.Item> : null}
            <Form.Item name={['attrs', 'propertyStatus']} label="Status nieruchomości" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="private">Własność</Radio>
                    <Radio value="rent">Najem</Radio>
                    <Radio value="lease">Dzierżawa</Radio>
                    <Radio value="underMonumentsConservator">Objęty konserwatorem zabytków</Radio>
                </Radio.Group>
            </Form.Item>
        </>
    }, {
        key: 'roof-sheathing',
        label: "Poszycie dachu",
        children: <>
            <Form.Item name={['attrs', 'roofSheathing']} label="Poszycie dachu" rules={fieldRules}>
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value="steelTile">Blachodachówka</Radio>
                        <Radio value="plainTile">Dachówka karpiówka</Radio>
                        <Radio value="papaBoarding">Papa / deskowanie</Radio>
                        <Radio value="trapezoidalMetalSheet">Blacha trapezowa</Radio>
                        <Radio value="ceramicTile">Dachówka ceramiczna</Radio>
                        <Radio value="concreteTile">Dachówka betonowa</Radio>
                        <Radio value="papaConcrete">Papa / beton</Radio>
                        <Radio value="another">Inne</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
            {roofSheathing === 'trapezoidalMetalSheet' ?
                <Form.Item name={['attrs', 'trapezoidalDistance']} label="Odległość między trapezami (cm)"
                    rules={fieldRules}>
                    <DecimalNumber/>
                </Form.Item> : null}
            {roofSheathing === 'another' ?
                <Form.Item name={['attrs', 'anotherRoofSheathingDescription']} label="Opis" rules={fieldRules}>
                    <Input/>
                </Form.Item> : null}
        </>
    }, {
        key: 'roof-material',
        label: 'Konstrukcja dachu',
        children: <>
            <Form.Item name={['attrs', 'roofMaterial']} label="Materiał" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="wood">Drewno</Radio>
                    <Radio value="steal">Stal</Radio>
                    <Radio value="concrete">Beton</Radio>
                    <Radio value="another">Inne</Radio>
                </Radio.Group>
            </Form.Item>
            {roofMaterial === 'wood' ?
                <Form.Item name={['attrs', 'raftersDistance']} label="Odległość pomiędzy krokwiami (cm)"
                    rules={fieldRules}>
                    <DecimalNumber/>
                </Form.Item> : null}
            {roofMaterial === 'another' ?
                <Form.Item name={['attrs', 'anotherRoofMaterialDescription']} label="Opis" rules={fieldRules}>
                    <Input/>
                </Form.Item> : null}
            {!previewMode ? <Alert
                message="Nie montujemy na: płyta ondulinowa, azbest, poliwęglan, struktonit, obiekty wymagające zgody konserwatora zabytków!"
                type="error"/> : null}
        </>
    }, {
        key: 'building-area',
        label: 'Budynek / Działka',
        children: <>
            <Row>
                <Col span={12}>
                    <Form.Item name={['attrs', 'roofType']} label="Rodzaj dachu" rules={fieldRules}>
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value="flat">Płaski</Radio>
                                <Radio value="gableRoof">Dach dwuspadowy</Radio>
                                <Radio value="monoPitchedRoof">Dach jednospadowy</Radio>
                                <Radio value="hipRoof">Dach czterospadowy</Radio>
                                <Radio value="freestandig">Wolnostojący (Grunt)</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={['attrs', 'buildingHeight']} label="Wysokość budynku (m)" rules={fieldRules}>
                        <DecimalNumber/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'roofLength']} label="Długość dachu (m)" rules={fieldRules}>
                        <DecimalNumber/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'roofEdgeLength']} label="Długość krawędzi dachu (m)" rules={fieldRules}>
                        <DecimalNumber/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'roofBackLength']} label="Długość grzebietu (m)" rules={fieldRules}>
                        <DecimalNumber/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'roofInclinationAngle']} label="Kąt pochylenia dachu (°)"
                        rules={fieldRules}>
                        <DecimalNumber/>
                    </Form.Item>
                </Col>
            </Row>
            <Typography.Text>Dostpęna powierzchnia na instalację gruntową:</Typography.Text>
            <Gap/>
            <Form.Item name={['attrs', 'sideALength']} label="Długość boku A (m)" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'sideBLength']} label="Długość boku B (m)" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
        </>
    }, {
        key: 'electricity',
        label: 'Ustalenia elektryczne',
        children: <>
            <Row>
                <Col span={12}>
                    <Form.Item name={['attrs', 'currentInputPower']} label="Obecna moc przyłączeniowa (z faktury, kW)"
                        rules={fieldRules}>
                        <DecimalNumber/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'mainMeterFuse']} label="Bezpiecznik przy liczniku głównym (A)"
                        rules={fieldRules}>
                        <DecimalNumber/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'annualElectricityConsumption']}
                        label="Roczne zużycie energii elektrycznej (kWh)" rules={fieldRules}>
                        <DecimalNumber/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'meterNumber']} label="Nr licznika (z faktury)" rules={fieldRules}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'energyConsumptionPointNumber']} label="Nr PPE (z faktury)"
                        rules={fieldRules}>
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={['attrs', 'electricInstallationType']} label="Instalacja elektryczna w budynku"
                        rules={fieldRules}>
                        <Radio.Group>
                            <Radio value="singlePhase">Jednofazowa</Radio>
                            <Radio value="threePhase">Trójfazowa</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name={['attrs', 'lightningProtection']} label="Instalacja odgromowa" initialValue={false}
                        valuePropName="checked" rules={fieldRules}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'switchboardGround']} label="Uziemienie w rozdzielnicy"
                        initialValue={false} valuePropName="checked" rules={fieldRules}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'moduleInverterDistance']} label="Odległość moduł - falownik"
                        rules={fieldRules}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['attrs', 'inverterSwitchboardDistance']} label="Odległość falownik - rozdielnia"
                        rules={fieldRules}>
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
        </>
    }, {
        key: 'photovoltaics-panels',
        label: 'Panele fotowoltaiczne',
        children: <>
            <Form.Item name={['attrs', 'installationDesiredPower']} label="Wskazanie pożądanej mocy instalacji (kWp)"
                rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
            <Form.Item name={['attrs', 'panelsCount']} label="Ilość paneli (szt)" rules={fieldRules}>
                <DecimalNumber/>
            </Form.Item>
        </>
    }, {
        key: 'inverter',
        label: 'Inwerter',
        children: <>
            <Form.Item name={['attrs', 'inverterInstallationPlace']} label="Miejsce montażu" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="inside">Wewnątrz</Radio>
                    <Radio value="outside">Na zwenątrz</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={['attrs', 'inverterLocation']} label="Lokalizacja" rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Form.Item name={['attrs', 'cableRoute']} label="Trasa kablowa prowadzona będzie" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="technicalShaft">Szacht techniczny</Radio>
                    <Radio value="facade">Po elewacji</Radio>
                    <Radio value="another">Inne</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={['attrs', 'inverterConnection']} label="Połączenie przez" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="lan">Lan</Radio>
                    <Radio value="wifi">Wi-Fi</Radio>
                </Radio.Group>
            </Form.Item>
            {inverterConnection === 'wifi' ?
                <Form.Item name={['attrs', 'wifiRange']} label="Zasięg Wi-Fi" rules={fieldRules}
                    valuePropName="checked">
                    <Switch/>
                </Form.Item> : null}
            <Form.Item name={['attrs', 'connectionDescription']} label="Uwagi" rules={fieldRules}>
                <Input/>
            </Form.Item>
        </>
    }, {
        key: 'additional-information',
        label: 'Dodatkowe materiały i informacje',
        children: <>
            <Form.Item name={['attrs', 'agreementType']} label="Typ umowy z OSD" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="severable">Rozdzielna</Radio>
                    <Radio value="comprehensive">Kompleksowa</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={['attrs', 'agreementRecipient']} label="Na kogo jest umowa z OSD" rules={fieldRules}>
                <Input/>
            </Form.Item>
            <Form.Item name={['attrs', 'modulesBringing']} label="Możliwość wnoszenia modułów" rules={fieldRules}>
                <Radio.Group>
                    <Radio value="hump">Zwyżka</Radio>
                    <Radio value="ladder">Drabina</Radio>
                    <Radio value="roofHatch">Właz dachowy</Radio>
                    <Radio value="crane">Dźwig</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name={['attrs', 'attachments']} label="Załączniki" rules={fieldRules}>
                <Checkbox.Group>
                    <Space direction="vertical">
                        <Checkbox value="planView">Widok planu</Checkbox>
                        <Checkbox value="terrainMap">Mapka terenu</Checkbox>
                        <Checkbox value="constructionPlan">Plan budowy</Checkbox>
                        <Checkbox value="drafts">Szkice</Checkbox>
                        <Checkbox value="pictures">Zdjęcia</Checkbox>
                        <Checkbox value="invoices">Faktury</Checkbox>
                        <Checkbox value="another">Inne</Checkbox>
                    </Space>
                </Checkbox.Group>
            </Form.Item>
            {attachments?.indexOf('another') >= 0 ?
                <Form.Item name={['attrs', 'attachmentsAnotherDescription']} label="Opis" rules={fieldRules}>
                    <Input/>
                </Form.Item> : null}
            <Form.Item name={['attrs', 'attachmentsDescription']} label="Uwagi" rules={fieldRules}>
                <Input/>
            </Form.Item>
        </>
    }, {
        key: 'remarks',
        label: 'Uwagi',
        children: <Form.Item name={['attrs', 'description']} rules={fieldRules}>
            <Input.TextArea/>
        </Form.Item>
    }, {
        key: 'documents',
        label: 'Dokumenty',
        children: <>
            {!previewMode ? <>
                <Alert
                    message="Dołącz wymagane zdjęcia"
                    description="Zdjęcia dachu/nieruchomości/rozdzielni/skrzynek elektrycznych/przyłączy siłowych"
                    type="warning"/>
                <Gap/>
                <Alert
                    message="Dołącz rysunki obiektu i otoczenia"
                    description="Np. obiekty, które są zintegrowane z dachem wraz z ich wymiarami i położeniem (kominy, okna dachowe, itp.) oraz obiekty powodujące zacienienie dachu w miejscu instalacji paneli fotowoltaicznych (drzewa, latarnie, słupy, budynki, itp.)"
                    type="warning"/>
                <Gap/>
            </> : null}
            <FileUploader group="assembly_parameters" fileList={fileList} setFileList={setFileList}
                canEdit={!previewMode}/>
        </>
    }
    ]
}
