import React, { useState } from "react";
import { Space, Tabs, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { OfferData } from "./offer-data";
import { OfferCalculator } from "./offer-calculator";
import { PopoverForm } from "../../components/popover-form";
import { useAcceptOffer, useCreateAgreement } from "./offer.hook";
import { ExportService } from "../../api/export-service";
import { useMutation } from "@tanstack/react-query";
import { Query } from "./offer-query";
import { Model } from "../../models/db";

type OfferTab = 'data' | 'calculator';

export const OfferEdit = (props: { tab?: OfferTab }) => {
    const { id } = useParams();
    const offerId = parseInt(id ?? "") ?? undefined;
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);
    const [calculatorUpdating, setCalculatorUpdating] = useState(false);

    const [offerData, setOfferData] = useState<Model.Offer.Data | undefined>(undefined);

    const { data: offer, loading: offerLoading } = Query.Offer.use(offerId);

    const offerAccepted = !!offer?.acceptedAt;

    const { mutateAsync: exportOfferCalculator, isLoading: exportingCalculator } = useMutation((offerId: number) => {
        return ExportService.exportOfferCalculator(offerId);
    });

    const { mutateAsync: exportOffer, isLoading: exportingOffer } = useMutation((offerId: number) => {
        return ExportService.exportOffer(offerId);
    });

    const { mutateAsync: createAgreement, isLoading: creatingAgreement } = useCreateAgreement(offerAccepted);
    const { mutateAsync: acceptOffer, isLoading: offerAccepting } = useAcceptOffer();

    const acceptWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Eksport oferty spowoduje jej zaakceptowanie</Typography.Text>
        <br/>
        <Typography.Text type="danger">i wyłączenie możliwości jej edycji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const createAgreementWarning = <div style={{ paddingBottom: 10 }}>
        <Typography.Text type="danger">Utworzenie umowy spowoduje zaakceptowanie oferty</Typography.Text>
        <br/>
        <Typography.Text type="danger">i wyłączenie możliwości jej edycji.</Typography.Text>
        <br/>
        <Typography.Text type="danger">Czy na pewno chcesz kontynuować?</Typography.Text>
    </div>;

    const enabled = !exportingCalculator && !exportingOffer && !creatingAgreement && !offerAccepting && !calculatorUpdating && !offerLoading;

    return <Tabs defaultActiveKey="data" activeKey={props.tab} id="offer-tabs"
        onChange={tab => {
            navigate(baseUrl(`/offers/${offerId}/${tab}`));
            setCurrentTab(tab as OfferTab);
        }}
        tabBarExtraContent={offerId && {
            right: <Space>
                <PopoverForm
                    title="Eksportuj kalkulator"
                    buttonEnabled={enabled && offerAccepted}
                    showPopover={!offer?.acceptedAt}
                    loading={exportingCalculator}
                    onSubmit={() => {
                        exportOfferCalculator(offerId).then(() => {
                            if (!offerAccepted) {
                                void acceptOffer(offerId);
                            }
                        });
                    }}>
                    {acceptWarning}
                </PopoverForm>
                <PopoverForm
                    title="Eksportuj ofertę"
                    buttonEnabled={enabled && !!offer?.templateId}
                    showPopover={!offer?.acceptedAt}
                    loading={exportingOffer}
                    onSubmit={() => {
                        acceptOffer(offerId).then(() => {
                            void exportOffer(offerId);
                        });
                    }}>
                    {acceptWarning}
                </PopoverForm>
                <PopoverForm
                    title="Utwórz umowę"
                    buttonEnabled={enabled && !offer?.agreementId && offerAccepted}
                    loading={creatingAgreement}
                    onSubmit={() => {
                        void createAgreement(offerId);
                    }}>
                    {createAgreementWarning}
                </PopoverForm>
            </Space>
        }}
        items={[{
            label: "Dane oferty",
            key: "data",
            children: <OfferData
                offerId={offerId}
                offer={offer}
                offerLoading={offerLoading}
                offerData={offerData}
                setOfferData={setOfferData}
            />
        }, {
            label: "Kalkulator",
            key: "calculator",
            disabled: !offerId,
            children: <OfferCalculator offerId={offerId!} tabsId={"offer-tabs"} onCalculatorUpdate={updating => {
                setCalculatorUpdating(updating);
            }}/>
        }]}
    />
}
