import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Space, Spin, Tag, Timeline, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import { RemoveButton } from "../../components/remove-button";
import { useUser } from "../../hooks/user.hook";
import { useAcceptOffer, useRemoveOffer, useSaveOffer, useSendOffer } from "./offer.hook";
import { Model } from "../../models/db";
import { VisualisationPreview } from "../visualisations/visualisation-preview";
import { ClientSelect } from "../../components/selects/client-select";
import { CalculatorSelect } from "../../components/selects/calculator-select";
import { TemplateSelect } from "../../components/selects/template-select";
import { VisualisationSelect } from "../../components/selects/visualisation-select";
import { Link } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { FooterGap } from "../../components/gap";
import { labelCol, wrapperCol } from "../../models/ui";
import { Query } from "./offer-query";
import { SendEmail } from "../../components/send-email";
import { formatDatetime } from "../../utils/utils";
import { ArrowRightOutlined } from "../../components/icons";

interface OfferDataProps {
    offerId?: number,
    offer: Query.Offer.T | undefined,
    offerLoading: boolean,
    offerData: Model.Offer.Data | undefined;
    setOfferData: (offer: Model.Offer.Data) => void;
}

export const OfferData = ({ offerId, offer, offerLoading, offerData, setOfferData }: OfferDataProps) => {
    const [calculatorId, setCalculatorId] = useState<number | null>(null);
    const [clientId, setClientId] = useState<number | null>(null);
    const [clientEmail, setClientEmail] = useState<string | null>(null);

    const { data: clients, loading: clientsLoading } = Query.Offer.Clients.use(offerId);
    const { data: calculators, loading: calculatorsLoading } = Query.Offer.Calculators.use(offerId, offer?.calculatorId);
    const { data: templates, loading: templatesLoading } = Query.Offer.Templates.use(offerId, offer?.templateId, offer?.calculatorId, calculatorId);
    const { data: visualisations, loading: visualisationsLoading } = Query.Offer.Visualisations.use(offerId, offer?.visualisationId, clientId);
    const { data: mail, loading: mailLoading } = Query.Offer.Mail.use(offerId);
    const { data: mailHistory, loading: mailHistoryLoading } = Query.Offer.MailHistory.use(offerId);

    const user = useUser();
    const [form] = useForm<Model.Offer.Data>();

    useEffect(() => {
        if (offer && calculators) {
            setOfferData(offer);
            form.setFieldsValue(offer!);
        }

        if (offer?.calculatorId != null) {
            setCalculatorId(offer.calculatorId);
        }

        if (offer?.clientId != null) {
            setClientId(offer.clientId);
        }
    }, [offer, calculators]);

    useEffect(() => {
        if (templates && templates.length > 0 && !offerId) {
            setOfferData({ ...offerData, templateId: templates[0].id });
            form.setFieldsValue({
                templateId: templates[0].id
            });
        }
    }, [templates]);

    useEffect(() => {
        if (calculatorId !== null) {
            form.setFieldsValue({
                templateId: null
            });
        }
    }, [calculatorId]);

    useEffect(() => {
        if (clientId !== null) {
            form.setFieldsValue({
                visualisationId: null
            });
            const client = clients?.find(c => c.id === clientId);
            setClientEmail(client?.email ?? null);
        }
    }, [clients, clientId]);

    const { mutateAsync: saveOffer, isLoading: offerSaving } = useSaveOffer();
    const { mutateAsync: acceptOffer, isLoading: offerAccepting } = useAcceptOffer();
    const { mutateAsync: removeOffer, isLoading: offerRemoving } = useRemoveOffer();
    const { mutateAsync: sendOffer, isLoading: offerSending } = useSendOffer();

    const visualisationId = Form.useWatch('visualisationId', form);

    const permission = user.permissions.offer(offerId, !!offer?.acceptedAt, !!offer?.mailSentAt);

    return <Card style={{ padding: 0 }}>
        <Spin spinning={offerLoading || clientsLoading || calculatorsLoading || templatesLoading || visualisationsLoading || offerSaving || offerRemoving || offerAccepting || mailLoading || mailHistoryLoading}>
            <Form
                form={form}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                onValuesChange={(values: Store) => {
                    if (values.calculatorId !== undefined && values.calculatorId !== null) {
                        setCalculatorId(values.calculatorId);
                    }
                    if (values.clientId !== undefined && values.clientId !== null) {
                        setClientId(values.clientId);
                    }
                    setOfferData({ ...offerData, ...values });
                }}
                onFinish={() => saveOffer(offerData!)}
                name="offer"
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input disabled={!permission.edit}/>
                </Form.Item>
                <Form.Item
                    label="Klient"
                    name="clientId"
                    rules={[{ required: true }]}
                >
                    <ClientSelect clients={clients} canEdit={true}/>
                </Form.Item>
                <Form.Item
                    label="Kalkulator"
                    name="calculatorId"
                    rules={[{ required: true }]}
                >
                    <CalculatorSelect calculators={calculators} canEdit={true}/>
                </Form.Item>
                <Form.Item
                    label="Szablon"
                    name="templateId"
                    rules={[{ required: true }]}
                >
                    <TemplateSelect templates={templates} canEdit={true}/>
                </Form.Item>
                <Form.Item label="Wizualizacja">
                    <div style={{ width: '100%', display: 'flex', gap: 8 }}>
                        <Form.Item name="visualisationId" style={{ flexGrow: 1 }} noStyle>
                            <VisualisationSelect visualisations={visualisations} canEdit={permission.edit}/>
                        </Form.Item>
                        <VisualisationPreview visualisationId={visualisationId} offerId={offerId}/>
                    </div>
                </Form.Item>
                <Form.Item
                    label="Adres realizacji"
                    name="realisationAddress"
                    rules={[{ required: true }]}
                >
                    <Input disabled={!permission.edit && !!offer?.realisationAddress}/>
                </Form.Item>
                {offer?.agreementId ?
                    <Form.Item label="Umowa">
                        <Link to={baseUrl(`/agreements/${offer?.agreementId}`)}>{offer?.agreement}</Link>
                    </Form.Item>
                    : null}
                <Form.Item
                    label="Wysłane oferty">
                    {mailHistoryLoading ? null : (mailHistory?.length ?? 0) > 0 ? <Timeline mode="left" style={{ marginTop: 10, marginLeft: 0 }} items={
                        mailHistory?.map((row, idx) => ({
                            key: `hs/${idx}`,
                            color: idx === mailHistory?.length - 1 ? "green" : "gray",
                            children: <Space direction="vertical">
                                <Space direction="horizontal">
                                    <Typography.Text type="warning">{formatDatetime(row.sentAt, false)}</Typography.Text>
                                    <Tag color="magenta" inputMode={"email"}>{row.emailFrom}</Tag>
                                    <ArrowRightOutlined style={{ marginLeft: -8 }}/>
                                    <Tag color="geekblue">{row.emailTo}</Tag>
                                </Space>
                                {row.subject}
                            </Space>,
                        }))
                    }/> : 'Brak'}
                </Form.Item>
                <FooterGap/>
                <Row className="footer-actions">
                    <Col span={24}>
                        <Space>
                            {offerId ? <RemoveButton disabled={!permission.remove} onConfirm={() => removeOffer(offerId)}/> : null}
                            {offerId ? <Button disabled={!permission.accept} onClick={() => acceptOffer(offerId)}>
                                Akceptuj
                            </Button> : null}
                            {offerId && mail ? <SendEmail to={clientEmail} subject={mail.subject} content={mail.content} disabled={!permission.send}
                                onSend={(to, subject, content) => 
                                    acceptOffer(offerId)
                                        .then(() => sendOffer({
                                            offerId, to, subject, content
                                        }))
                            }/> : null}
                            <Button type="primary" htmlType="submit" disabled={!permission.save}>
                                Zapisz
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </Card>
}
