import { Store } from "pullstate";
import { State } from "./state";
import dayjs from "dayjs";

export const appStore = new Store<State>({
    secondaryRole: null,
    agreement: null,
    offerList: {
        periodFrom: dayjs().subtract(1, 'month'),
        periodTo: dayjs(),
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined,
        accepted: null,
        sent: null,
        agreement: null,
        visualisation: null,
        project: null,
        calculatorType: null
    },
    agreementList: {
        periodFrom: dayjs().subtract(1, 'month'),
        periodTo: dayjs(),
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined,
        accepted: null,
        realised: null
    },
    clientList: {
        periodFrom: dayjs().subtract(1, 'month'),
        periodTo: dayjs(),
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined
    },
    assemblyParametersList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined,
        accepted: null
    },
    visualisationList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        status: null,
        users: undefined
    },
    projectList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        status: null,
        users: undefined
    },
    userList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined
    },
    calculatorList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined,
        accepted: null,
        active: null
    },
    templateList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined,
        accepted: null,
        activeCalculator: null,
        active: null
    },
    auditList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined
    },
    realisationList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        status: null,
        users: undefined,
        visualisation: null,
        project: null
    },
    installationCrewList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined
    },
    clientToAcceptList: {
        periodFrom: null,
        periodTo: null,
        search: '',
        currentPage: 0,
        pageSize: 10,
        sortColumn: 'id',
        sortDirection: -1,
        users: undefined
    },

});
