import React from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input/Input";

export class NumericInput extends React.Component<InputProps> {
    onChange = (e: any) => {
        const value = e.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if (this.props.onChange && ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')) {
            this.props.onChange(e);
        }
    };

    // '.' at the end or only '-' in the input box.
    onBlur = (e: any) => {
        const { onBlur, onChange } = this.props;
        const value = this.props.value as string;
        let valueTemp = value;
        if (value && (value.charAt(value.length - 1) === '.' || value === '-')) {
            valueTemp = value.slice(0, -1);
        }
        if (onChange) {
            onChange({ ...e, target: { ...e.target, value: valueTemp && valueTemp.replace(/0*(\d+)/, '$1') } });
        }
        if (onBlur) {
            onBlur(e);
        }
    };

    render() {
        return (
            <Input
                {...this.props}
                onChange={this.onChange}
                onBlur={this.onBlur}
                maxLength={25}
            />
        );
    }
}
