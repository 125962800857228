import { Typography } from "antd";
import { cyan, gold } from '@ant-design/colors'
import React from "react";

export const AssemblyParametersType = (props: { assemblyType: string }) => {
    if (props.assemblyType === 'photovoltaics') {
        return <Typography.Text style={{ color: cyan.at(6) }} type='warning'>Fotowoltaika</Typography.Text>;
    } else if (props.assemblyType === 'heat-pump') {
        return <Typography.Text style={{ color: gold.at(6) }}>Pompa ciepła</Typography.Text>;
    }
    return <Typography.Text type='danger'>Nieznany</Typography.Text>;
}
