// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected-event {
  border: 3px solid #13794b;
}
.read-only-event {
  cursor: default;
}
.fc .fc-daygrid-day-frame {
  height: 125px;
}
`, "",{"version":3,"sources":["webpack://./web/views/installation-calendar/installation-calendar.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAEA;EACE,eAAA;AAAF;AAGA;EACI,aAAA;AADJ","sourcesContent":[".selected-event {\n  border: 3px solid #13794b;\n}\n\n.read-only-event {\n  cursor: default;\n}\n\n.fc .fc-daygrid-day-frame {\n    height: 125px;\n }\n\n@layout-header-background: #1890ff;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
