import React, { useState } from "react";
import { useUser } from "../../hooks/user.hook";
import { Tabs } from "antd";
import { InstallationTasks } from "./installation-tasks";
import { SuperAdmin } from "./super-admin";

type SystemTab = 'installationTaskLists';

export const System = () => {
    const { user } = useUser();
    const [currentTab, setCurrentTab] = useState<SystemTab>("installationTaskLists");

    return <Tabs defaultActiveKey="installationTaskLists" activeKey={currentTab} id="system-tabs"
        items={[
            {
                label: "Szablony list zadań",
                id: "installationTaskLists",
                key: "installationTaskLists",
                children: <InstallationTasks/>
            },
            {
                label: "Super Admin",
                id: "superAdmin",
                key: "superAdmin",
                children: <SuperAdmin/>
            }
        ]}
        onChange={tab => {
            setCurrentTab(tab as SystemTab);
        }}/>
}
