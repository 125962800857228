import { Space } from "antd";
import { formatDate } from "../utils/utils";
import React from "react";

export const CreatedBy = (props: { createdAt?: string, createdBy?: string, fullInfo?: boolean }) => {
    if (!!props.fullInfo) {
        return <div>
            <Space direction="vertical" size="small">
                <div>{formatDate(props.createdAt)}</div>
                <div style={{ fontSize: 11, color: '#cc7b26' }}>{props.createdBy}</div>
            </Space>
        </div>
    } else {
        return <div>{formatDate(props.createdAt)}</div>
    }
}
