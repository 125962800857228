import React, { useState } from "react";
import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/navigation";
import { InstallationCrewData } from "./installation-crew-data";

type ClientTab = 'data';

export const InstallationCrewEdit = (props: { tab?: ClientTab }) => {
    const { id } = useParams();
    const installationCrewId = parseInt(id ?? "") || undefined;
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.tab);

    return <Tabs defaultActiveKey="data" activeKey={props.tab}
        onChange={tab => {
            navigate(baseUrl(`/installation-crew/${installationCrewId}/${tab}`));
            setCurrentTab(tab as ClientTab);
        }}
        items={[{
            label: "Dane klienta",
            key: "data",
            children: <InstallationCrewData installationCrewId={installationCrewId}/>
        }]}
    />
}
