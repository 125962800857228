import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Divider, Row, Spin } from "antd";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import classNames from "classnames";
import "./calculator-layout.less";
import { sql, useSqlQueryRaw } from "../../hooks/sql-query.hook";
import { EllipseText } from "../../components/ellipse-text";
import { useResizeObserver } from "../../hooks/resize-observer.hook";
import { CalculatorData, FieldScope, FieldType, getFieldTypeStr, getLayoutItemPos, LayoutItem } from "./layout-types";
import { ElementProperties } from "./element-properties";
import { SplitPane } from "react-multi-split-pane";
import { readSplitterSizes, storeSplitterSizes } from "../../utils/splitter-pos";
import { CalculatorEvaluator } from "./calculator-evaluator";
import { getGridCellPos, isItemVisible, makeElementStyle } from "./layout-utils";
import { AbsoluteLayout, equalCellPos, GridCell } from "../../components/absolute-layout/absolute-layout";
import { useCalculatorStatus } from "../../hooks/calculator-status.hook";
import { numberToUserRoles } from "../../utils/converters";
import { UserRole } from "../../models/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Nil } from "../../utils/utils";

const CalculatorItem = ({ item, selected, highlightedFieldId, className, key, style, children, ...restProps }: {
    item: LayoutItem,
    selected: boolean,
    highlightedFieldId: boolean,
    className?: string,
    key?: string,
    style?: React.CSSProperties,
    children?: React.ReactNode[]
}) => {
    return <div {...restProps} key={key}
        style={{
            ...style,
            border: selected ? "1px solid #ff0000" : style?.border }}
        className={classNames(className, "calculator-item")}>
        <div className={classNames("field-id", {
            'highlighted-field-id': highlightedFieldId
        })}>{item.alias ?? item.fieldId}</div>
        {children}
    </div>
}

interface DictionaryItem {
    id: number;
    dictName: string;
}

export const CalculatorLayout = (props: { calculatorId?: number, layoutMode: number, roles: UserRole[], fieldIdMode: boolean, fieldInfoMode: boolean }) => {
    const [dragField, setDragField] = useState<FieldType | null>(null);
    const [dragDictionaryId, setDragDictionaryId] = useState<number | null>(null);
    const [currentLayoutItemId, setCurrentLayoutItemId] = useState<number | null>(null);
    const [prevCalculatorId, setPrevCalculatorId] = useState<number | null>(null);
    const queryClient = useQueryClient();

    const updateLayoutItem = (item: LayoutItem) => {
        setLayout(layout => layout.map(l => l.id === item.id ? { ...item, ...getLayoutItemPos(l) } : l));
    }

    const removeLayoutItem = async (item: LayoutItem | null) => {
        if (item) {
            await backendDelete(`/calculators/${item.id}/item`);
            setCurrentLayoutItemId(null);
            setLayout(() => layout.filter(l => l.id !== item.id));
        }
    }

    const { calculatorAccepted, calculatorStatusLoading } = useCalculatorStatus(props.calculatorId);

    const { data: dictionaries, loading: dictionariesLoading } = useSqlQueryRaw<DictionaryItem>(
        `dictionaries-${props.calculatorId}`,
        sql`select id as key, id, dict_name, owner_id
            from calculator_dict
            where calculator_id = ${props.calculatorId}
            and deleted_at is null
            order by id`,
        {
            enabled: props.calculatorId
        }
    );


    const { data: layoutItems, loading: layoutItemsLoading } = useSqlQueryRaw<LayoutItem>(
        `calculator-items-${props.calculatorId}`,
        sql`select id as key, id, owner_id,
            calculator_dict_id,
            field_type, field_scope, field_roles, field_id,
            alias, value,
            background_color,
            text_color, font_size, font_weight, horz_align, vert_align,
            main_value, round_value, format_value,
            x0, xl0, xp0, y0, yl0, yp0,
            x1, xl1, xp1, y1, yl1, yp1
            from calculator_item
            where calculator_id = ${props.calculatorId}
            and deleted_at is null
            order by id`,
        {
            enabled: props.calculatorId,
            map: rows => rows.map(r => ({
                ...r,
                fieldRoles: numberToUserRoles(r.fieldRoles as unknown as number)
            }))
        }
    );

    const { mutateAsync: saveCalculatorItem, isLoading: calculatorItemSaving } = useMutation(
        ({ layoutItem }: { layoutItem: LayoutItem }) =>
            backendPost(`/calculators/${props.calculatorId}/item`, layoutItem),
        {
            onSuccess: ({ id, fieldId }, { layoutItem: layoutItem }) => {
                const ci = { id, ...layoutItem, fieldId }
                setLayout(layout.concat([ci]));
                setCurrentLayoutItemId(id);
                void queryClient.invalidateQueries([`fields-${props.calculatorId}`]);
            }
        }
    );

    useEffect(() => {
        if (props.calculatorId && props.calculatorId !== prevCalculatorId) {
            setPrevCalculatorId(props.calculatorId);
            setCurrentLayoutItemId(null);
            setCurrentFormulaFieldId(null);
        }
    }, [props.calculatorId]);

    useEffect(() => {
        if (layoutItems) {
            setLayout(layoutItems);
        }
    }, [layoutItems]);

    const onDrop = (el: GridCell, fieldType: FieldType) => {
        setDragField(null);
        setDragDictionaryId(null);

        const layoutItem: LayoutItem = {
            fieldType,
            fieldScope: FieldScope.Public,
            fieldRoles: [UserRole.ADMIN],
            fieldId: "fld",
            calculatorDictId: dragDictionaryId,
            value: '',
            fontSize: 16,
            fontWeight: 400,
            horzAlign: 0,
            vertAlign: 1,
            textColor: "#000000",
            backgroundColor: "transparent",
            mainValue: false,
            roundValue: false,
            formatValue: false,
            ...getGridCellPos(el)
        };

        void saveCalculatorItem({ layoutItem });
    };

    const createElement = (item: LayoutItem, highlightedFieldId: string | null, currentLayoutItemId: Nil<number>, roles: UserRole[]) => {
        let description = item.value ? item.value : getFieldTypeStr(item.fieldType);
        if (item.fieldType === FieldType.Dictionary) {
            const dict = dictionaries?.find(d => d.id === item.calculatorDictId);
            if (dict) {
                description = dict.dictName;
            }
        }
        const style = makeElementStyle(item);
        if (!isItemVisible(item, roles)) {
            style.display = 'none';
        }

        return <CalculatorItem item={item} key={`${item.id}`}
            style={style}
            selected={item.id === currentLayoutItemId}
            highlightedFieldId={highlightedFieldId ? (highlightedFieldId === item.fieldId || highlightedFieldId === item.alias) : false}
        >
            {item.fieldType === FieldType.Text && <span>{description}</span>}
            {item.fieldType === FieldType.Formula && <span className="formula-field">{description}</span>}
            {item.fieldType === FieldType.NumericInput && <input disabled={true} value={description} style={{ width: "100%", pointerEvents: "none" }}/>}
            {item.fieldType === FieldType.TextInput && <textarea disabled={true} value={description} style={{ width: "100%", height: "100%", pointerEvents: "none" }}/>}
            {item.fieldType === FieldType.Dictionary && <span>{description}</span>}
        </CalculatorItem>
    }

    const [layout, setLayout] = useState<LayoutItem[]>([]);

    const [contentPosY, setContentPosY] = useState(0);
    const [currentFormulaFieldId, setCurrentFormulaFieldId] = useState<string | null>(null)
    const [calculatorData, setCalculatorData] = useState<CalculatorData | null>(null);

    const ralWrapperRef = useRef<HTMLInputElement | null>(null);
    const testRalWrapperRef = useRef<HTMLInputElement | null>(null);
    const contentRef = useRef<HTMLInputElement | null>(null);

    const ralSize = useResizeObserver(ralWrapperRef, ralWrapperRef?.current);
    const testRalSize = useResizeObserver(testRalWrapperRef, testRalWrapperRef.current);

    useEffect(() => {
        if (contentRef.current) {
            setContentPosY(contentRef.current.getBoundingClientRect().y);
        }
    }, [contentRef.current]);

    return !calculatorStatusLoading ? <div ref={contentRef} style={{ position: 'relative', height: `calc(100vh - ${contentPosY}px)`, overflowY: 'hidden' }}>
        {props.layoutMode === 0 && !calculatorAccepted ? <SplitPane split="horizontal"
            defaultSizes={readSplitterSizes('calculator-layout', [60, 40])}
            onDragFinished={sizes => storeSplitterSizes('calculator-layout', sizes)}
            className={"calculator-layout-split"}>
            <div style={{ width: "100%", height: "100%" }}>
                <Row style={{ flexWrap: 'nowrap', height: "100%" }}>
                    <Col flex="auto" ref={ralWrapperRef} key={2}>
                        {ralSize.width > 0 ? <AbsoluteLayout
                            className="layout-editor"
                            colWidth={35}
                            rowHeight={35}
                            layout={layout.map((l, idx) => ({
                                key: l.id!.toString(),
                                idx: idx,
                                component: createElement(l, currentFormulaFieldId, currentLayoutItemId, props.roles),
                                ...getGridCellPos(l)
                            }))}
                            onUpdateLayout={newLayout => {
                                setLayout(layout => layout.map(l => {
                                    const nl = newLayout.find(nl => nl.key === l.id?.toString())!;

                                    if (nl) {
                                        const layoutItem = {
                                            ...l,
                                            ...getGridCellPos(nl),
                                        } as LayoutItem;

                                        if (l.id && !equalCellPos(l, layoutItem)) {
                                            console.log("Saving item:", l.id);
                                            void backendPut(`/calculators/${l.id}/item`, layoutItem);
                                        }

                                        return layoutItem;
                                    } else {
                                        return l;
                                    }
                                }));
                            }}
                            onDrop={el => onDrop(el, dragField!)}
                            width={ralSize.width}
                            height={400}
                            innerWidth={2500}
                            innerHeight={1500}
                            editing={true}
                            showGrid={true}
                            pinning={false}
                            newElementSize={{
                                width: 150,
                                height: 35
                            }}
                            onSelectElement={(idx => {
                                setCurrentLayoutItemId(idx < 0 ? null : (layout[idx]?.id ?? null));
                            })}
                        /> : null}
                    </Col>
                    <Col flex="220px 0 0" style={{ marginLeft: 10, maxWidth: 220, background: '#fbfbfb' }} key={0}>
                        <Card
                            size="small"
                            style={{ background: 'transparent', height: '100%', overflowY: 'auto' }}
                            bodyStyle={{

                            }}>
                            <Button
                                className="droppable-element draggable-button"
                                draggable={true}
                                unselectable="on"
                                onDragStart={e => {
                                    setDragField(FieldType.Text);
                                    e.dataTransfer.setData("text/plain", "");
                                }}>
                                Pole tekstowe
                            </Button>
                            <Button
                                className="droppable-element draggable-button"
                                draggable={true}
                                unselectable="on"
                                onDragStart={e => {
                                    setDragField(FieldType.NumericInput);
                                    e.dataTransfer.setData("text/plain", "")
                                }}>
                                Liczba
                            </Button>
                            <Button
                                className="droppable-element draggable-button"
                                draggable={true}
                                unselectable="on"
                                onDragStart={e => {
                                    setDragField(FieldType.TextInput);
                                    e.dataTransfer.setData("text/plain", "")
                                }}>
                                Tekst
                            </Button>
                            <Button
                                className="droppable-element draggable-button"
                                draggable={true}
                                unselectable="on"
                                onDragStart={e => {
                                    setDragField(FieldType.Formula);
                                    e.dataTransfer.setData("text/plain", "")
                                }}>
                                Formuła
                            </Button>
                            <Divider style={{ margin: 0, marginBottom: 10 }}/>
                            {dictionaries?.map(item =>
                                <Button
                                    key={item.id}
                                    className="droppable-element draggable-button"
                                    draggable={true}
                                    unselectable="on"
                                    onDragStart={e => {
                                        setDragField(FieldType.Dictionary);
                                        setDragDictionaryId(item.id);
                                        e.dataTransfer.setData("text/plain", "");
                                    }}>
                                    <EllipseText text={item.dictName}/>
                                </Button>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
            <div style={{ width: "100%", minHeight: 250 }}>
                {(currentLayoutItemId && props.calculatorId) ? <ElementProperties
                    calculatorId={props.calculatorId}
                    currentLayoutItem={layout.find(l => l.id === currentLayoutItemId) ?? null}
                    onUpdate={updateLayoutItem}
                    onMatchFieldId={fieldId => {
                        setCurrentFormulaFieldId(fieldId);
                    }}
                    onRemove={removeLayoutItem}/> : null}
            </div>
        </SplitPane> :
        <Row style={{ height: "calc(100% - 16px)" }}>
            <Col flex="auto" ref={testRalWrapperRef} className="layout-editor" key={3}>
                {props.calculatorId ? <CalculatorEvaluator
                    calculatorId={props.calculatorId}
                    calculatorData={calculatorData}
                    width={testRalSize.width}
                    height={testRalSize.height}
                    roles={props.roles}
                    debugData={true}
                    onChange={values => setCalculatorData(values)}
                    fieldId={props.fieldIdMode}
                    fieldInfo={props.fieldInfoMode}
                    items={layout}
                /> : null}
            </Col>
        </Row>}
        <Spin spinning={props.layoutMode === 0 && (dictionariesLoading || layoutItemsLoading || calculatorItemSaving)}
            style={{position: 'absolute', right: 5, bottom: 0}}
        />
    </div> : null;
}
