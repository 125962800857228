import { useSqlQuerySingle } from "./sql-query.hook";
import { useQueryClient } from "@tanstack/react-query";
import { from } from "../utils/sql-builder";
import { t } from "../models/db";

export const useTemplateStatus = (templateId?: number) => {
    const { data: templateStatus, loading: templateStatusLoading } = useSqlQuerySingle(
        `template-enabled-${templateId}`,
        from(t.template)
        .where(t.template.id.eq(templateId))
        .select({
            acceptedAt: t.template.acceptedAt,
            ownerId: t.template.ownerId
        }), {
            enabled: templateId,
        }
    );
    return { templateAccepted: !!templateStatus?.acceptedAt, templateStatusLoading }
}

export function refreshTemplateStatus(templateId: number | undefined) {
    const queryClient = useQueryClient();
    if (templateId) {
        void queryClient.invalidateQueries([`template-enabled-${templateId}`]);
    }
}
