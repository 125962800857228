import { CheckboxOptionType } from "antd";
import { TemplateType } from "./model";

export enum UserRole {
    GUEST = 0,
    TRADER = 1,
    MANAGER = 2,
    BACK_OFFICE = 3,
    DESIGNER = 4,
    DIRECTOR = 5,
    COORDINATOR = 6,
    ADMIN = 9,
    SUPER_ADMIN = 10
}

export const roleMap: { [key: number]: number } = {
    [UserRole.ADMIN]: 1 << 0,
    [UserRole.TRADER]: 1 << 1,
    [UserRole.BACK_OFFICE]: 1 << 2,
    [UserRole.DESIGNER]: 1 << 3,
    [UserRole.MANAGER]: 1 << 4,
    [UserRole.DIRECTOR]: 1 << 5,
    [UserRole.COORDINATOR]: 1 << 6
};

export const allRoles = [
    UserRole.TRADER,
    UserRole.MANAGER,
    UserRole.BACK_OFFICE,
    UserRole.DESIGNER,
    UserRole.COORDINATOR,
    UserRole.DIRECTOR,
    UserRole.ADMIN
];

export const fieldRoles: CheckboxOptionType[] = allRoles.map(role => ({
    label: getUserRoleDescription(role), value: role
}));

export const fieldRolesNoAdmin: CheckboxOptionType[] = allRoles.map(role => ({
    label: getUserRoleDescription(role), value: role, disabled: role === UserRole.ADMIN
}));

export interface User {
    id?: number
    username: string;
    password?: string;
    firstName: string;
    lastName: string;
    role: UserRole,
    commissionRate: number;
    email?: string;
}

export interface UserInfo {
    id: number
    username: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    secondaryRole: UserRole | null;
}

export function getRole(user: UserInfo) {
    return user.secondaryRole ?? user.role;
}

export function isTrader(user: UserInfo) {
    return user && getRole(user) === UserRole.TRADER;
}

export function isManager(user: UserInfo) {
    return user && getRole(user) === UserRole.MANAGER;
}

export function isBackOffice(user: UserInfo) {
    return user && getRole(user) === UserRole.BACK_OFFICE;
}

export function isDirector(user: UserInfo) {
    return user && getRole(user) === UserRole.DIRECTOR;
}

export function isAdmin(user: UserInfo) {
    return user && getRole(user) === UserRole.ADMIN;
}

export function isDesigner(user: UserInfo) {
    return user && getRole(user) === UserRole.DESIGNER;
}

export function isCoordinator(user: UserInfo) {
    return user && getRole(user) === UserRole.COORDINATOR;
}

export function getUserRoleDescription(role: UserRole): string {
    switch (role) {
        case UserRole.ADMIN: return "Administrator";
        case UserRole.BACK_OFFICE: return "Back Office";
        case UserRole.DIRECTOR: return "Dyrektor";
        case UserRole.GUEST: return "Gość";
        case UserRole.MANAGER: return "Menadżer";
        case UserRole.DESIGNER: return "Projektant";
        case UserRole.SUPER_ADMIN: return "Super Admin";
        case UserRole.TRADER: return "Handlowiec";
        case UserRole.COORDINATOR: return "Koordynator";
    }
}

export function getUserRoles(): UserRole[] {
    return [
        UserRole.TRADER,
        UserRole.MANAGER,
        UserRole.DESIGNER,
        UserRole.BACK_OFFICE,
        UserRole.COORDINATOR,
        UserRole.DIRECTOR,
        UserRole.ADMIN
    ]
}

type Owners = (number | undefined)[];

export function getUserPermissions(user: UserInfo) {
    const userRole = user.secondaryRole ?? user.role;

    const forRoles = (includedRoles: UserRole[], excludedRoles: UserRole[] = []): boolean => {
        return userRole === UserRole.ADMIN || (includedRoles.includes(userRole) && !excludedRoles.includes(userRole));
    }

    const isOwner = (owners: Owners) => !!owners.find(ownerId => ownerId === user.id)

    return {
        panel: {
            users: forRoles([]),
            calculators: forRoles(allRoles, [UserRole.DESIGNER]),
            templates: forRoles([]),
            clients: forRoles(allRoles, [UserRole.DESIGNER]),
            assemblies: forRoles(allRoles, [UserRole.DESIGNER]),
            visualisations: forRoles(allRoles),
            offers: forRoles(allRoles, [UserRole.DESIGNER]),
            projects: forRoles(allRoles),
            agreements: forRoles(allRoles, [UserRole.DESIGNER]),
            realisations: forRoles(allRoles, [UserRole.DESIGNER]),
            installationCrews: forRoles(allRoles, [UserRole.DESIGNER, UserRole.TRADER, UserRole.MANAGER]),
            calendar: forRoles(allRoles),
            audit: forRoles([])
        },
        creatorName: forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR, UserRole.DESIGNER]),
        userFilter: forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]),
        calculator: (id: number | undefined, accepted: boolean) => {
            return {
                save: true,
                accept: !accepted,
                remove: !accepted || forRoles([])
            }
        },
        client: (id: number | undefined, owners: Owners) => {
            return {
                save: true,
                accept: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]),
                remove: isOwner(owners) || forRoles([UserRole.MANAGER])
            }
        },
        clientList: {
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]),
            remove: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER]),
            removeEnable: true
        },
        offer: (id: number | undefined, accepted: boolean, sent: boolean) => {
            return {
                save: !accepted || forRoles([]),
                accept: !accepted,
                send: accepted,
                remove: !accepted || forRoles([]),
                edit: !accepted || forRoles([])
            }
        },
        offerList: {
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]),
            remove: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER]),
            removeEnable: (accepted: boolean) => !accepted || forRoles([])
        },
        template: (id: number | undefined, accepted: boolean, templateType: TemplateType | number | undefined | null) => {
            return {
                save: !accepted || templateType === TemplateType.ASSEMBLY_FV || templateType === TemplateType.ASSEMBLY_HP,
                accept: !accepted,
                remove: !accepted || forRoles([UserRole.ADMIN]),
                edit: !accepted
            }
        },
        templateList: {
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER]),
            remove: (accepted: boolean) => !accepted || forRoles([]),
            removeEnable: (accepted: boolean) => !accepted || forRoles([])
        },
        agreement: (id: number | undefined, accepted: boolean) => {
            return {
                accept: !accepted,
                edit: !accepted,
                editTemplate: !accepted || forRoles([]),
                editOffer: !accepted || !id,
                save: !accepted || forRoles([]),
                remove: !accepted || forRoles([])
            }
        },
        agreementList: {
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]),
            remove: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER]),
            removeEnable: (accepted: boolean) => !accepted || forRoles([])
        },
        user: (id: number | undefined) => {
            return {
                save: forRoles([UserRole.MANAGER]),
                remove: forRoles([UserRole.MANAGER])
            }
        },
        userList: {
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER]),
            remove: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER])
        },
        assemblyParameters: (id: number | undefined, accepted: boolean, owners: Owners) => {
            return {
                edit: true,
                editTemplate: !accepted && forRoles([UserRole.TRADER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]),
                save: !accepted,
                accept: !accepted,
                remove: !accepted && (isOwner(owners) || forRoles([UserRole.MANAGER]))
            }
        },
        assemblyParametersList: {
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]),
            remove: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER]),
            removeEnable: () => forRoles([])
        },
        project: (id: number | undefined, status: string | undefined, owners: (number | undefined)[]) => {
            return {
                editStatus: forRoles([UserRole.BACK_OFFICE, UserRole.DIRECTOR, UserRole.DESIGNER, UserRole.COORDINATOR]),
                changeStatus: status !== 'Completed' && status !== 'Data completing',
                sendToRealisation: status === 'Data completing' || status === 'Incomplete documents',
                showSendToRealisation: forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR, UserRole.MANAGER, UserRole.TRADER]),
                markAsRealised: status === 'In realisation',
                showMarkAsRealised: forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR, UserRole.MANAGER, UserRole.DESIGNER]),
                edit: !id || forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]) ||
                    (isOwner(owners) && forRoles([UserRole.TRADER]) && (status === 'Data completing' || status === 'Incomplete documents')),
                editDocuments: !id || forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]) ||
                    (isOwner(owners) && (
                        forRoles([UserRole.TRADER]) && (status === 'Data completing' || status === 'Incomplete documents')) ||
                        forRoles([UserRole.DESIGNER]) && (status === 'In realisation')
                    ),
                save: !id || forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]) ||
                    (isOwner(owners) && (
                        forRoles([UserRole.TRADER]) && (status === 'Data completing' || status === 'Incomplete documents')) ||
                        forRoles([UserRole.DESIGNER]) && (status === 'In realisation')
                    )
            }
        },
        projectList: {
            create: forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.DIRECTOR, UserRole.TRADER, UserRole.COORDINATOR]),
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.ADMIN, UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.DIRECTOR, UserRole.COORDINATOR])
        },
        visualisation: (id: number | undefined, status: string | undefined, owners: (number | undefined)[]) => {
            return {
                editStatus: forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR, UserRole.DESIGNER]),
                changeStatus: status !== 'Completed' && status !== 'Data completing',
                sendToRealisation: status === 'Data completing' || status === 'Incomplete documents',
                showSendToRealisation: forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR, UserRole.MANAGER, UserRole.TRADER]),
                markAsRealised: status === 'In realisation',
                showMarkAsRealised: forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR, UserRole.MANAGER, UserRole.DESIGNER]),
                edit: !id || forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]) ||
                    (isOwner(owners) && forRoles([UserRole.TRADER]) && (status === 'Data completing' || status === 'Incomplete documents')),
                editDocuments: !id || forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]) ||
                    (isOwner(owners) && (
                        forRoles([UserRole.TRADER]) && (status === 'Data completing' || status === 'Incomplete documents')) ||
                        forRoles([UserRole.DESIGNER]) && (status === 'In realisation')
                    ),
                save: !id || forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]) ||
                    (isOwner(owners) && (
                        forRoles([UserRole.TRADER]) && (status === 'Data completing' || status === 'Incomplete documents')) ||
                        forRoles([UserRole.DESIGNER]) && (status === 'In realisation')
                    ),
            }
        },
        visualisationList: {
            create: forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR, UserRole.TRADER]),
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR])
        },
        realisation: (id: number, status: string | undefined, owners: Owners, installation: boolean, commission: boolean) => {
            return {
                edit: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]) || installation && forRoles([UserRole.COORDINATOR]),
                editStatus: forRoles([UserRole.DIRECTOR]) ||
                    !installation && !commission && forRoles([UserRole.BACK_OFFICE]) && status !== 'Submitted for commission' ||
                    installation && !commission && forRoles([UserRole.COORDINATOR]) && status !== 'Submitted for commission',
                editCrew: forRoles([UserRole.DIRECTOR]) || installation && !commission && forRoles([UserRole.COORDINATOR]),
                editDocuments: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]),
                editPayments: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]),
                editInstallationDocuments: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]) || installation && !commission && forRoles([UserRole.COORDINATOR]),
                editInstallationTaskList: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]) || installation && !commission && forRoles([UserRole.COORDINATOR]),
                editInstallationTaskTemplate: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]) || installation && !commission && forRoles([UserRole.COORDINATOR]),
                editCommission: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]) || commission && !installation && forRoles([UserRole.COORDINATOR]),
                editCommissionsDocuments: forRoles([UserRole.DIRECTOR, UserRole.BACK_OFFICE]) || commission && !installation && (forRoles([UserRole.COORDINATOR]) || isOwner(owners)),
                save: forRoles([UserRole.BACK_OFFICE, UserRole.DIRECTOR, UserRole.COORDINATOR]) || commission && isOwner(owners)
            }
        },
        realisationList: {
            commissionFields: forRoles([UserRole.BACK_OFFICE, UserRole.COORDINATOR]),
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]),
        },
        installationCrewList: {
            edit: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER, UserRole.BACK_OFFICE, UserRole.COORDINATOR, UserRole.DIRECTOR]),
            remove: (owners: Owners) => isOwner(owners) || forRoles([UserRole.MANAGER]),
            removeEnable: true
        },
    }
}
