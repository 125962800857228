import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { usePaginatedSqlQuery, useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { Nil } from "../../utils/utils";
import { AgreementCriteria } from "../../store/state";
import { getListCriteria, withFilterValueCriteria, withInCriteria } from "../../utils/sql";

export namespace Query {
    export namespace Agreements {
        export const key = () => `agreements`;
        export const query = (criteria: AgreementCriteria & {
            search: string | null
        }) => {
            const where = getListCriteria(
                [t.a.id, t.a.name, t.cl.firstName, t.cl.lastName, t.cl.name, t.o.name],
                t.a.createdAt, t.a.deletedAt,
                criteria
            )
                + withInCriteria(t.a.ownerId, criteria.users)
                + withFilterValueCriteria(t.a.acceptedAt.isNotNull().cast('int'), criteria.accepted)
                + withFilterValueCriteria(t.r.id.isNotNull().cast('int'), criteria.realised)
            ;

            return from(t.agreement.as(t.a))
            .join(t.offer.as(t.o).id, t.a.offerId)
            .join(t.client.as(t.cl).id, t.o.clientId)
            .join(t.user.as(t.u).id, t.a.userId)
            .leftJoin(t.realisation, t.r)
            .on(t.a.id.eq(t.r.agreementId).and(t.r.deletedAt.isNull()))
            .where(where)
            .and(t.cl.deletedAt.isNull())
            .orderByAlias(criteria.sortColumn, criteria.sortDirection)
            .select({
                key: t.a.id,
                id: t.a.id,
                ownerId: t.a.ownerId,
                name: t.a.name,
                offerId: t.o.id,
                offer: t.o.name,
                createdAt: t.a.createdAt,
                accepted: t.a.acceptedAt.isNotNull().asBool(),
                realised: t.r.id.isNotNull().asBool(),
                clientId: t.cl.id,
                client: t.cl.fullName(),
                createdBy: t.u.fullName()
            })
        }

        export type T = ReturnType<typeof query>;

        export const use = (criteria: AgreementCriteria & {
            search: string | null
        }) => {
            return usePaginatedSqlQuery(
                key(), criteria,
                query(criteria)
            );
        }
    }

    export namespace Agreement {
        export const key = (agreementId: Nil<number>) => `agreement-${agreementId}`;
        export const query = (agreementId: Nil<number>) =>
            from(t.agreement, t.a)
            .join(t.realisation.as(t.r).agreementId, t.a.id)
            .where(t.a.id.eq(agreementId))
            .select({
                id: t.a.id,
                name: t.a.name,
                filename: t.a.filename,
                totalPrice: t.a.totalPrice,
                offerId: t.a.offerId,
                templateId: t.a.templateId,
                acceptedAt: t.a.acceptedAt,
                ownerId: t.a.ownerId,
                realisationId: t.r.id.nullable()
            });

        export type T = ReturnType<typeof query>;

        export const use = (agreementId: Nil<number>) => useSqlQuerySingle(
            key(agreementId),
            query(agreementId), {
                enabled: agreementId
            }
        );
    }

    export namespace AgreementOffers {
        export const key = (agreementId: Nil<number>) => `agreement-offers-${agreementId}`;
        export const query = (offerId: Nil<number>) =>
            from(t.offer.as(t.o))
            .join(t.client.as(t.cl).id, t.o.clientId)
            .join(t.calculator.as(t.c).id, t.o.calculatorId)
            .join(t.agreement.as(t.a).offerId, t.o.id)
            .where(t.o.acceptedAt.isNotNull().and(t.a.id.isNull()))
            .or(t.o.id.eq(offerId), offerId)
            .orderByAsc(t.o.name)
            .select({
                id: t.o.id,
                name: t.o.name,
                calculatorType: t.c.type,
                ownerId: t.o.ownerId,
                clientName: t.cl.fullName(),
                accepted: t.o.acceptedAt.isNotNull().asBool()
            });

        export const use = (agreementId: Nil<number>, offerId: Nil<number>) => useSqlQuery(
            key(agreementId),
            query(offerId), {
                enabled: agreementId && offerId || !agreementId
            }
        );

        export type T = ReturnType<typeof query>
    }

    export namespace AgreementTemplates {
        export const key = (agreementId: Nil<number>) => `agreement-templates-${agreementId}`;
        export const query = (offerId: Nil<number>, templateId: Nil<number>) =>
            from(t.template, t.t)
            .leftJoin(t.offer, t.o, offerId)
            .on(t.t.calculatorId.eq(t.o.calculatorId).and(t.o.id.eq(offerId)))
            .where(t.t.acceptedAt.isNotNull().and(t.t.templateType.eq(1)))
            .and(t.o.id.isNotNull(), offerId)
            .or(t.t.id.eq(templateId), templateId)
            .orderByAsc(t.t.name)
            .select({
                id: t.t.id,
                name: t.t.name,
                type: t.t.templateType,
                ownerId: t.t.ownerId,
                version: t.t.version
            });

        export type T = ReturnType<typeof query>

        export const use = (agreementId: Nil<number>, offerId: Nil<number>, templateId: Nil<number>) => useSqlQuery(
            key(agreementId),
            query(offerId, templateId), {
                enabled: agreementId && templateId || offerId,
                params: {
                    offerId
                }
            }
        );
    }
}
