import { backendPost } from "../api/backend-api";
import { useEffect, useState } from "react";
import camelcaseKeys from "camelcase-keys";
import { useUser } from "./user.hook";
import { QueryField, queryFieldToString, QueryRecord } from "../utils/sql-builder";
import { useQuery } from "@tanstack/react-query";

declare var DEV_BUILD: boolean;

function debugSql(dataKey: string, dataQuery: string, rows?: any) {
    if (DEV_BUILD) {
        console.log(`%cSQL: ${dataKey}\n`, 'color: white', dataQuery);
        if (rows) {
            console.log('%cRESULTS:\n', 'color: white',
                //JSON.stringify(rows, null, 2)
                `${rows?.length} rows`
            );
        }
    }
}

export function sql(template: TemplateStringsArray, ...expressions: any[]) {
    const result = template.reduce((accumulator, part, i) => {
        return accumulator + expressions[i - 1] + part;
    });

    return result.replace(/(\n|\r|\r\n)\s*/g, '$1');
}

export function qid(template: TemplateStringsArray, ...expressions: any[]) {
    const result = template.reduce((accumulator, part, i) => {
        return accumulator + (expressions[i - 1] ? expressions[i - 1] : "-") + part;
    });

    return result.replace(/(\n|\r|\r\n)\s*/g, '$1').replace(/(\-\-)+/, '');
}

interface SqlQueryResult<T> {
    data: T | undefined;
    loading: boolean;
    loaded: boolean;
}

interface SqlPaginatedQueryResult<T> {
    total: number;
    data: T[];
    loading: boolean;
    loaded: boolean;
}

interface SqlQueryBasicOptions {
    enabled?: any;
    cached?: boolean;
}

interface SqlQueryOptions<T, O, D, P> extends SqlQueryBasicOptions {
    params?: P;
    map?: (row: T) => O;
    onData?: (data: D | undefined) => void;
    owners?: (string | QueryField<any>)[];
    defaultValue?: any;
    initialValue?: any;
}

function getOwners(owners: (string | QueryField<any>)[] | undefined): string[] {
    return owners ? owners.map(o => {
        if (o instanceof QueryField) {
            return queryFieldToString(o);
        } else {
            return o;
        }
    }) : [];
}

function isQueryEnabled(options: SqlQueryBasicOptions): boolean {
    if ('enabled' in options) {
        return !!options.enabled;
    }
    return true;
}

export const useSqlQuery = <
    T extends QueryRecord<any>,
    O extends T,
    P = object>(
    dataKey: string, dataQuery: T,
    options: SqlQueryOptions<T, O, O[], P> = { enabled: true }
): SqlQueryResult<O[]> => {

    const [data, setData] = useState<O[] | undefined>(undefined);
    const { user } = useUser();

    const enabled = isQueryEnabled(options);
    let dataMap = options.map;
    if (!dataMap) {
        dataMap = (row) => row as O;
    }

    const query = dataQuery as unknown as string;

    const { data: rows, isLoading, isSuccess } = useQuery(options.params ? [dataKey, options.params] : [dataKey],
        async () => {
            try {
                const rows = await backendPost<T[]>(`/query?n=${dataKey}`, {
                    query,
                    ownerFields: getOwners(options.owners),
                    role: user.secondaryRole ?? undefined
                });
                debugSql(dataKey, query, rows);
                return rows;
            } catch (e) {
                debugSql(dataKey, query);
                throw e;
            }
        }, {
            cacheTime: options?.cached ? 1000 * 60 * 5 : 0,
            enabled
        });

    useEffect(() => {
        const transformedData = rows?.map(row => dataMap!(row))
        setData(transformedData);
        options.onData?.(transformedData);

        if (!isLoading) {
            debugSql(dataKey, query, transformedData);
        }
    }, [rows]);

    return {
        data: data,
        loading: isLoading && enabled,
        loaded: isSuccess && enabled
    }
}

interface Pagination {
    currentPage: number,
    pageSize: number
}

export const usePaginatedSqlQuery = <
    T extends QueryRecord<any>,
    O extends  T,
    P extends Pagination = Pagination>(
    dataKey: string, params: P, dataQuery: T, options: SqlQueryOptions<T, O, O[], P> = { enabled: true }): SqlPaginatedQueryResult<O> => {
    const enabled = isQueryEnabled(options);
    const [data, setData] = useState<O[]>([]);
    const { user } = useUser();

    const query = dataQuery as unknown as string;

    const { data: rows, isError, isSuccess } = useQuery([dataKey, params],
        async () => {
            try {
                const rows = await backendPost<T[]>(`/query?n=${dataKey}`, {
                    query,
                    ownerFields: getOwners(options.owners),
                    offset: params.currentPage * params.pageSize,
                    limit: params.pageSize,
                    role: user.secondaryRole ?? undefined
                });
                debugSql(dataKey, query, rows);
                return rows;
            } catch (e) {
                debugSql(dataKey, query);
                throw e;
            }
        }, {
            enabled
        });

    useEffect(() => {
        if (rows) {
            let transformedRows: O[] = rows as O[];

            if (options.map) {
                transformedRows = transformedRows.map(row => options.map?.(row) ?? row)
            }
            setData(transformedRows);
        }
    }, [rows]);

    return {
        total: data?.length > 0 ? (data[0] as any).total : 0,
        data: data,
        loading: (rows === undefined) && !isError,
        loaded: isSuccess
    }
}

export const useSqlQuerySingle = <
    T extends QueryRecord<any>,
    O extends T,
    P = object>(
    dataKey: string,
    dataQuery: T,
    options: SqlQueryOptions<T, O, O, P> = { enabled: true }
): SqlQueryResult<O> => {
    const enabled = isQueryEnabled(options);
    const { user } = useUser();
    const query = dataQuery as unknown as string;

    if (options.initialValue) {
        return {
            data: options.initialValue as O,
            loading: false,
            loaded: true,
        }
    }

    const q = useQuery([dataKey],
        async () => {
            const resp = await backendPost<T[]>(`/query?n=${dataKey}`, {
                query,
                ownerFields: getOwners(options.owners),
                role: user.secondaryRole ?? undefined
            });

            let row = (resp?.[0] ?? options.defaultValue) as O;
            if (row && options.map) {
                row = options.map(row);
            }
            if (row && options.onData) {
                options.onData(row);
            }
            debugSql(dataKey, query, row);
            return row;
        }, {
            cacheTime: options?.cached ? 1000 * 60 * 5 : 0,
            enabled
        });

    return {
        data: q.data as O,
        loading: q.isLoading && !q.isError && enabled,
        loaded: q.isSuccess && enabled
    }
}

export const useSqlQueryRaw = <
    T extends object = object,
    O extends object = T,
    P extends object = object>(
        dataKey: string, dataQuery: string,
        options: SqlQueryOptions<T[], O[], O[], P> = { enabled: true })
    : SqlQueryResult<O[]> => {
    const [data, setData] = useState<O[] | T[] | undefined>(undefined);
    const { user } = useUser();

    const enabled = isQueryEnabled(options);

    const { data: rows, isLoading, isSuccess } = useQuery(options.params ? [dataKey, options.params] : [dataKey],
        async () => {
            return backendPost<T[]>(`/query?n=${dataKey}`, {
                query: dataQuery,
                ownerFields: getOwners(options.owners),
                role: user.secondaryRole ?? undefined
            });
        }, {
            cacheTime: options?.cached ? 1000 * 60 * 5 : 0,
            enabled,
        });

    useEffect(() => {
        let transformedRows: T[] | O[] | undefined = undefined;
        if (rows) {
            transformedRows = camelcaseKeys(rows as any);

            if (options.map) {
                transformedRows = options.map(transformedRows as any);
            }
        }
        setData(transformedRows);
        if (!isLoading) {
            debugSql(dataKey, dataQuery, transformedRows);
        }
    }, [rows]);

    return {
        data: data as O[],
        loading: isLoading && enabled,
        loaded: isSuccess && enabled
    }
}

export const useSqlQuerySingleRaw = <T, O = T>(dataKey: string, dataQuery: string, options: SqlQueryOptions<T, O, O, {}> = { enabled: true }): SqlQueryResult<O> => {
    const enabled = isQueryEnabled(options);
    const { user } = useUser();

    const q = useQuery([dataKey],
        async () => {
            const resp = await backendPost<T[]>(`/query?n=${dataKey}`, {
                query: dataQuery,
                ownerFields: getOwners(options.owners),
                role: user.secondaryRole ?? undefined
            });
            let rows = {} as T | O;
            if (resp.length > 0) {
                const withCamelKeys = camelcaseKeys(resp[0] as any);
                if (options.map) {
                    rows = options.map(withCamelKeys);
                } else {
                    rows = withCamelKeys;
                }
            }
            debugSql(dataKey, dataQuery, rows);
            return rows;
        }, {
            cacheTime: options?.cached ? 1000 * 60 * 5 : 0,
            enabled
        });

    return {
        data: q.data as O,
        loading: q.isLoading && !q.isError && enabled,
        loaded: q.isSuccess && enabled
    }
}
