import { Button, Select, Space, Tooltip } from "antd";
import { filterBySearch } from "../../utils/utils";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "../icons";
import { ClientItem } from "./client-item";
import { useUser } from "../../hooks/user.hook";
import { baseUrl } from "../../utils/navigation";

export const ClientSelect = (
    { clients, canEdit, ...props }: {
        clients: { id: number, name: string, type: number }[] | undefined,
        canEdit: boolean
    }) => {
    const { permissions } = useUser();
    const navigate = useNavigate();
    const value = (props as any).value;
    return <Space className="select">
        <Select disabled={!canEdit} showSearch={true}
            allowClear={true} filterOption={filterBySearch} {...props}>
            {clients?.map(c => <Select.Option key={c.id} value={c.id} search={c.name + c.id}>
                <ClientItem id={c.id} name={c.name} type={c.type}/>
            </Select.Option>)}
        </Select>
        {permissions.panel.clients ? <Tooltip title="Przejdź do klienta" placement="topLeft">
            <Button disabled={!value} icon={<ArrowRightOutlined/>} onClick={() => navigate(baseUrl(`/clients/${value}`))}/>
        </Tooltip> : null}
    </Space>
}
