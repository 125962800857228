import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useAssemblyParametersInfo } from "./assembly-parameters.hook";
import { useForm } from "antd/lib/form/Form";
import { Model } from "../../models/db";
import { AssemblyParametersData } from "./assembly-parameters-data";

export const AssemblyParametersPreview = ({ assemblyParametersId, projectId }: { assemblyParametersId: number | undefined, projectId: number | undefined }) => {
    const [showAssemblyDetails, setShowAssemblyDetails] = useState(false);
    const { assemblyParametersType, version } = useAssemblyParametersInfo(assemblyParametersId);

    const showAssemblyParameters = () => {
        setShowAssemblyDetails(true);
    };

    const closeAssemblyParameters = () => {
        setShowAssemblyDetails(false);
    };

    const [form] = useForm<Model.AssemblyParameters.Data>();

    return <>
        <Button onClick={showAssemblyParameters} disabled={!assemblyParametersId}>Podgląd</Button>
        <Modal centered={true} closable={false}
            open={showAssemblyDetails}
            onOk={closeAssemblyParameters}
            width="80vw"
            cancelButtonProps={{ style: { display: 'none' } }}
            bodyStyle={{ height: '80vh', overflow: 'auto', border: 'none' }}>
            <AssemblyParametersData
                assemblyType={assemblyParametersType}
                version={version}
                form={form}
                operation={undefined}
                setOperation={() => {}}
                projectId={projectId}
                assemblyParametersId={assemblyParametersId}
                previewMode={true}
            />
        </Modal>
    </>
}
