import { getGridCell, GridCell, GridLayout } from "../../components/absolute-layout/absolute-layout";

export const gridSize = 20;

export function getGridCellPos(key: string, layout: GridLayout, x0: number, y0: number, w: number, h: number): GridCell {
    const l = layout.find(l => l.key === key);
    if (l) {
        return l
    }
    return getGridCell(x0, y0, w, h, gridSize, gridSize);
}
