// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-picker {
  padding: 1px;
}
.color-picker .color {
  width: 46px;
  height: 20px;
  border-radius: 2px;
}
.color-picker .swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
.color-picker .popover {
  position: fixed;
  z-index: 10001;
}
.color-picker .cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
`, "",{"version":3,"sources":["webpack://./web/components/color-picker.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAFA;EAIQ,WAAA;EACA,YAAA;EACA,kBAAA;AACR;AAPA;EAUQ,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,wCAAA;EACA,qBAAA;EACA,eAAA;AAAR;AAfA;EAmBQ,eAAA;EACA,cAAA;AADR;AAnBA;EAwBQ,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;AAFR","sourcesContent":[".color-picker {\n    padding: 1px;\n    \n    .color {\n        width: 46px;\n        height: 20px;\n        border-radius: 2px;\n    }\n\n    .swatch {\n        padding: 5px;\n        background: #fff;\n        border-radius: 1px;\n        box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);\n        display: inline-block;\n        cursor: pointer;\n    }\n\n    .popover {\n        position: fixed;\n        z-index: 10001;\n    }\n\n    .cover {\n        position: fixed;\n        top: 0;\n        right: 0;\n        bottom: 0;\n        left: 0;\n    }\n}\n\n@layout-header-background: #1890ff;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
