import { useMutation, useQueryClient } from "@tanstack/react-query";
import { backendDelete, backendPost, backendPut } from "../../api/backend-api";
import { notification } from "antd";
import { baseUrl } from "../../utils/navigation";
import { refreshTemplateStatus } from "../../hooks/template-status.hook";
import { Model } from "../../models/db";
import { useNavigate } from "react-router-dom";

export const useSaveTemplate = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation(async (template: Model.Template.Data) => {
        const templateId = await (template.id
            ? backendPut<number>(`/templates/${template.id}`, template)
            : backendPost<number>('/templates', template)
        );
        notification.success({
            message: 'Szablon został zapisany'
        });
        if (templateId) {
            navigate(baseUrl(`/templates/${templateId}/data`));
        } else {
            void queryClient.invalidateQueries([`template-data-${template.id}`]);
        }
    });
}

export const useAcceptTemplate = () => {
    return useMutation(async ({ templateId, templateAccepted }: { templateId: number, templateAccepted: boolean }) => {
        await backendPut(`/templates/${templateId}/accept`);
        if (!templateAccepted) {
            notification.success({
                message: 'Szablon został zaakceptowany'
            });
        }
        refreshTemplateStatus(templateId);
    });
}

export const useRemoveTemplate = () => {
    const navigate = useNavigate();
    return useMutation(async (templateId: number) => {
        await backendDelete(`/templates/${templateId}`);
        notification.success({
            message: 'Szablon został usunięty'
        });
        navigate(baseUrl("/templates"));
    });
}
