import { languages } from "monaco-editor/esm/vs/editor/editor.api.js";

export const ecbLanguageConf: languages.LanguageConfiguration = {
    brackets: [["(", ")"], ["[", "]"]],
    autoClosingPairs: [{ open: "(", close: ")" }, { open: "[", close: "]" }],
    surroundingPairs: [{ open: "(", close: ")" }, { open: "[", close: "]" }]
};

export const ecbMonarchLanguage = <languages.IMonarchLanguage>{
    defaultToken: "",
    tokenPostfix: "",
    ignoreCase: true,

    brackets: [
        { open: "[", close: "]", token: "delimiter.square" },
        { open: "(", close: ")", token: "delimiter.parenthesis" }
    ],

    keywords: [
        'if',
        'disabled',
        'disabled_value',
        'hidden',
        'hidden_value',
        'true',
        'false'
    ],
    operators: ['=', '>', '<', '>=', '<=', '+', '-', '*', '/', ':='],
    functions: [
        "if",
        "round_up",
        "round_down",
        "ceil",
        "floor"
    ],
    tokenizer: {
        root: [
            { include: "@whitespace" },
            { include: "@numbers" },
            { include: "@strings" },
            { include: "@scopes" },
            { include: "@fields" },
            [/[:]/, "delimiter"],
            [/[()]/, "@brackets"],
            [
                /[\w]+/,
                {
                    cases: {
                        "@keywords": "keyword",
                        "@operators": "operator",
                        "@functions": "predefined",
                        "@default": "identifier"
                    }
                }
            ],
            [/[<>=+\-*@/]/, "operator"]
        ],
        whitespace: [
            [/\s+/, "white"],
            [/(^#.*$)/, 'comment'],
        ],
        numbers: [
            [/[0-9]+('.' [0-9]+)?/, "number"]
        ],
        strings: [
            [/"/, { token: "string.double", next: "@stringDouble" }]
        ],
        stringDouble: [
            [/[^"]+/, "string.double"],
            [/""/, "string.double"],
            [/"/, { token: "string.double", next: "@pop" }]
        ],
        fields: [
            [/\[[A-Za-z]+[A-Za-z0-9\-]*(:[A-Za-z]+[A-Za-z0-9\-]*(:[A-Za-z0-9\-]+)?)?\]/, { token: "field" }]
        ],
        scopes: [
            // NOT SUPPORTED
        ]
    }
};
