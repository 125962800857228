import { from } from "../../utils/sql-builder";
import { t } from "../../models/db";
import { usePaginatedSqlQuery, useSqlQuery, useSqlQuerySingle } from "../../hooks/sql-query.hook";
import { getListCriteria, withFilterValueCriteria, withInCriteria } from "../../utils/sql";
import { OfferCriteria } from "../../store/state";
import { Nil } from "../../utils/utils";
import { TemplateMailType } from "../../models/model";

export namespace Query {
    export namespace Offers {
        export const key = () => `offers`;
        export const query = (criteria: OfferCriteria & {
            search: string | null
        }) => {
            const where = getListCriteria(
                [t.o.id, t.o.name, t.cl.firstName, t.cl.lastName, t.cl.name, t.c.name],
                t.o.createdAt, t.o.deletedAt,
                criteria
            )
                + withInCriteria(t.o.ownerId, criteria.users)
                + withFilterValueCriteria(t.o.acceptedAt.isNotNull().cast('int'), criteria.accepted)
                + withFilterValueCriteria(t.a.id.isNotNull().cast('int'), criteria.agreement)
                + withFilterValueCriteria(t.v.id.isNotNull().cast('int'), criteria.visualisation)
                + withFilterValueCriteria(t.p.id.isNotNull().cast('int'), criteria.project)
                + withFilterValueCriteria(t.c.type, criteria.calculatorType)
            ;

            return from(t.offer.as(t.o))
                .join(t.calculator.as(t.c).id, t.o.calculatorId)
                .join(t.client.as(t.cl).id, t.o.clientId)
                .join(t.user.as(t.u).id, t.o.ownerId)
                .join(t.user.as(t.u0).id, t.o.userId)
                .join(t.agreement.as(t.a).offerId, t.o.id)
                .join(t.visualisation.as(t.v).id, t.o.visualisationId)
                .join(t.project.as(t.p).offerId, t.o.id)
                .where(where)
                .and(t.cl.deletedAt.isNull())
                .orderByAlias(criteria.sortColumn, criteria.sortDirection)
                .select({
                    key: t.o.id,
                    id: t.o.id,
                    ownerId: t.o.ownerId,
                    calculatorType: t.c.type,
                    name: t.o.name,
                    createdAt: t.o.createdAt,
                    accepted: t.o.acceptedAt.isNotNull().asBool(),
                    sent: t.o.mailSentAt.isNotNull().asBool(),
                    agreementId: t.a.id,
                    agreement: t.a.name,
                    calculator: t.c.name,
                    clientId: t.cl.id,
                    client: t.cl.fullName(),
                    createdBy: t.u0.fullName(),
                    visualisationId: t.v.id,
                    visualisation: t.v.name,
                    projectId: t.p.id,
                    project: t.p.name
                });
        }

        export type T = ReturnType<typeof query>;

        export const use = (criteria: OfferCriteria & {
            search: string | null
        }) => {
            return usePaginatedSqlQuery(
                key(), criteria,
                query(criteria)
            );
        }
    }

    export namespace Offer {
        export const key = (offerId: Nil<number>) => `offer-${offerId}`;
        export const query = (offerId: Nil<number>) =>
            from(t.offer.as(t.o))
            .join(t.agreement.as(t.a).offerId, t.o.id)
            .where(t.o.id.eq(offerId))
            .select({
                id: t.o.id,
                name: t.o.name,
                clientId: t.o.clientId,
                calculatorId: t.o.calculatorId,
                templateId: t.o.templateId,
                visualisationId: t.o.visualisationId,
                realisationAddress: t.o.realisationAddress,
                acceptedAt: t.o.acceptedAt,
                mailSentAt: t.o.mailSentAt,
                agreementId: t.a.id,
                agreement: t.a.name,
                ownerId: t.o.ownerId
            });

        export type T = ReturnType<typeof query>;

        export const use = (offerId: Nil<number>) => useSqlQuerySingle(
            key(offerId),
            query(offerId), {
                enabled: offerId
            }
        );

        export namespace Clients {
            export const key = (offerId: Nil<number>) => `offer-clients-${offerId}`;
            export const query = (offerId: Nil<number>) =>
                from(t.client, t.cl)
                .leftJoin(t.offer, t.o, offerId)
                .on(t.o.id.eq(offerId).and(t.o.clientId.eq(t.cl.id)))
                .where(t.cl.acceptedAt.isNotNull())
                .or(t.o.id.isNotNull(), offerId)
                .orderBy(t.cl.name)
                .select({
                    id: t.cl.id,
                    ownerId: t.cl.ownerId,
                    name: t.cl.fullName(),
                    type: t.cl.clientType,
                    email: t.cl.email
                });

            export type T = ReturnType<typeof query>;

            export const use = (offerId: Nil<number>) => useSqlQuery(
                key(offerId),
                query(offerId)
            );
        }

        export namespace Calculators {
            export const key = (offerId: Nil<number>) => `offer-calculators-${offerId}`;
            export const query = (calculatorId: Nil<number>) =>
                from(t.calculator, t.c)
                .where(t.c.parentId.isNull().and(t.c.active.isTrue()))
                .and(t.c.acceptedAt.isNotNull())
                .or(t.c.id.eq(calculatorId), calculatorId)
                .orderBy(t.c.name)
                .select({
                    id: t.c.id,
                    name: t.c.name,
                    type: t.c.type,
                    active: t.c.active,
                    ownerId: t.c.ownerId
                });

            export type T = ReturnType<typeof query>;

            export const use = (offerId: Nil<number>, calculatorId: Nil<number>) => useSqlQuery(
                key(offerId),
                query(calculatorId), {
                    enabled: offerId && calculatorId || !offerId
                }
            );
        }

        export namespace Templates {
            export const key = (offerId: Nil<number>) => `offer-templates-${offerId}`;
            export const query = (templateId: Nil<number>, calculatorId: Nil<number>, selectedCalculatorId: Nil<number>) =>
                from(t.template, t.t)
                .where(t.t.acceptedAt.isNotNull().and(t.t.templateType.eq(0)))
                .and(t.t.calculatorId.eq(selectedCalculatorId), selectedCalculatorId)
                .or(t.t.id.eq(templateId), templateId && calculatorId === selectedCalculatorId)
                .orderBy(t.t.name)
                .select({
                    id: t.t.id,
                    name: t.t.name,
                    type: t.t.templateType,
                    ownerId: t.t.ownerId,
                    version: t.t.version
                });

            export type T = ReturnType<typeof query>;

            export const use = (offerId: Nil<number>, templateId: Nil<number>, calculatorId: Nil<number>, selectedCalculatorId: Nil<number>) => useSqlQuery(
                key(offerId),
                query(templateId, calculatorId, selectedCalculatorId), {
                    enabled: offerId && templateId || selectedCalculatorId,
                    params: {
                        calculatorId: selectedCalculatorId
                    }
                }
            );
        }

        export namespace Mail {
            export const key = (offerId: Nil<number>) => `offer-mail-${offerId}`;
            export const query = () =>
                from(t.templateMail.as(t.tm))
                .where(t.tm.templateType.eq(TemplateMailType.OFFER).and(t.tm.version.eq(1)))
                .select({
                    subject: t.tm.subject,
                    content: t.tm.content
                });

            export type T = ReturnType<typeof query>;

            export const use = (offerId: Nil<number>) => useSqlQuerySingle(
                key(offerId),
                query(), {
                    enabled: offerId,
                    map: row => ({
                        subject: row.subject.replaceAll("\\n", "\n"),
                        content: row.content.replaceAll("\\n", "\n")
                    })
                }
            );
        }

        export namespace MailHistory {
            export const key = (offerId: Nil<number>) => `offer-mail-history-${offerId}`;
            export const query = (offerId: Nil<number>) =>
                from(t.offerMailHistory.as(t.omh))
                .join(t.user.as(t.u).id, t.omh.userId)
                .where(t.omh.offerId.eq(offerId))
                .orderBy(t.omh.id)
                .select({
                    emailFrom: t.omh.emailFrom,
                    emailTo: t.omh.emailTo,
                    sentAt: t.omh.createdAt,
                    sentBy: t.u.fullName(),
                    subject: t.omh.subject
                });

            export type T = ReturnType<typeof query>;

            export const use = (offerId: Nil<number>) => useSqlQuery(
                key(offerId),
                query(offerId), {
                    enabled: offerId,
                    map: row => ({
                        ...row,
                        subject: row.subject.replaceAll("\\n", "\n"),
                    })
                }
            );
        }

        export namespace Visualisations {
            export const key = (offerId: Nil<number>) => `offer-visualisations-${offerId}`;
            export const query = (visualisationId: Nil<number>, clientId: Nil<number>) =>
                from(t.visualisation, t.v)
                .where(t.v.clientId.eq(clientId), clientId)
                .or(t.v.id.eq(visualisationId), visualisationId)
                .orderBy(t.v.name)
                .select({
                    id: t.v.id,
                    name: t.v.name,
                    ownerId: t.v.ownerId
                });

            export type T = ReturnType<typeof query>;

            export const use = (offerId: Nil<number>, visualisationId: Nil<number>, clientId: Nil<number>) => useSqlQuery(
                key(offerId),
                query(visualisationId, clientId), {
                    enabled: clientId || visualisationId,
                    params: {
                        clientId
                    }
                }
            );
        }
    }

    export namespace SuperAdmin {
        export namespace Calculators {
            export const key = () => `super-admin-calculators`;
            export const query = () =>
                from(t.calculator, t.c)
                .where(t.c.parentId.isNull())
                .orderBy(t.c.name)
                .select({
                    id: t.c.id,
                    name: t.c.name,
                    type: t.c.type,
                    active: t.c.active,
                    ownerId: t.c.ownerId
                });

            export type T = ReturnType<typeof query>;

            export const use = () => useSqlQuery(
                key(),
                query()
            );
        }
    }
}
