import { useSqlQuerySingle } from "./sql-query.hook";
import { useQueryClient } from "@tanstack/react-query";
import { from } from "../utils/sql-builder";
import { t } from "../models/db";

export const useCalculatorStatus = (calculatorId?: number) => {
    const { data: calculatorStatus, loading: calculatorStatusLoading } = useSqlQuerySingle(
        `calculator-enabled-${calculatorId}`,
        from(t.calculator)
        .where(t.calculator.id.eq(
            from(t.calculator)
            .where(t.calculator.id.eq(calculatorId))
            .select({ id: t.calculator.parentId.nvl(t.calculator.id) }))
        ).select({
            acceptedAt: t.calculator.acceptedAt,
            ownerId: t.calculator.ownerId
        }), {
            enabled: calculatorId,
        }
    );
    return { calculatorAccepted: !!calculatorStatus?.acceptedAt, calculatorStatusLoading }
}

export function refreshCalculatorStatus(calculatorId: number | undefined) {
    const queryClient = useQueryClient();
    if (calculatorId) {
        void queryClient.invalidateQueries([`calculator-enabled-${calculatorId}`]);
    }
}
